import {
  DISCONNECT_SOCKET,
  SET_EVENT_SOCKET_ID,
  SET_SOCKET
} from './constants';

export function setSocket(payload: any, socketName: string) {
  return {
    type: SET_SOCKET,
    payload,
    socketName
  };
}

export function setEventSocketId(payload: string) {
  return {
    type: SET_EVENT_SOCKET_ID,
    payload
  };
}

export function disconnectSocket(socketName: string) {
  return {
    type: DISCONNECT_SOCKET,
    socketName
  };
}
