import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import { ModalLayout } from 'layouts/ModalLayout';
import { cancelRegToEventAction } from 'store/events/actions';
import { CancelRegistrationModalProps } from './types';

const CancelRegistrationModal: React.FC<CancelRegistrationModalProps> = ({
  loading,
  name,
  show,
  onHide,
  onResetCancelReg
}) => {
  const dispatch = useDispatch();
  const showCancelRegistrationModal = show === 1;
  const showAfterCancelRegistrationModal = show === 2;

  const {
    auth: { user: { email = '' } = {} },
    events: {
      registrationFields: { appId = '' },
      currentEvent: { event_code = '' }
    }
  } = useSelector((state: AppState) => state);

  const onApproveCanceling = () => {
    dispatch(cancelRegToEventAction(event_code, appId, email));
  };

  return (
    <>
      <ModalLayout
        isVisible={showCancelRegistrationModal}
        onClose={onHide}
        size='lg'
        centered
        modalBodyClassName='py-6 text-center position-relative'
      >
        {loading && (
          <div className='d-flex position-absolute w-100 h-100 top-0 left-0 spin-loader-light'>
            <Spinner animation='border' className='m-auto'></Spinner>
          </div>
        )}
        <p className='mb-10 modal-text'>
          You are going to cancel the registration for{' '}
          <span className='font-weight-bold'>{name}</span>
        </p>
        <Button variant='primary' onClick={onApproveCanceling}>
          Approve
        </Button>{' '}
        <Button variant='secondary' className='ml-4' onClick={onHide}>
          Cancel
        </Button>
      </ModalLayout>

      <ModalLayout
        isVisible={showAfterCancelRegistrationModal}
        onClose={onResetCancelReg}
        size='sm'
        centered
        modalBodyClassName='py-6 text-center'
      >
        <p className='mb-10 modal-text'>
          Your registration has been cancelled. You will receive a cancellation
          email.
        </p>
        <Button variant='link' onClick={onResetCancelReg}>
          OK
        </Button>
      </ModalLayout>
    </>
  );
};

export default CancelRegistrationModal;
