import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { Button, PollAnswers } from 'react-banzai';
import classnames from 'classnames';
import { getMenuItem } from 'helpers';
import { deletePoll, newVote } from 'helpers/poll';
import * as api from 'api';
import { ModalLayout } from 'layouts/ModalLayout';
import { setLastPollDate } from 'store/poll/actions';
import { useActiveSession } from 'hooks/useSessionStatus';

interface Poll {
  poll_id: string;
  poll_options: Array<Object>;
  poll_status: string;
  poll_title: string;
  poll_type: string;
  poll_votes: number;
}

interface IPollItem {
  poll: Poll;
  hostView: boolean;
}

const PollItem = ({ poll, hostView }: IPollItem) => {
  const { id: event_code } = useParams() as { id: string };
  const location = useLocation();
  const [disabled, setDisabled] = useState(false);
  const [deletePollModal, setShowDeletePollModal] = useState(false);
  const [endPollModal, setShowEndPollModal] = useState(false);
  const currentPollRef: any = useRef(null);

  const {
    events: {
      currentEvent: { customMenu = [] },
    },
    app: {
      virtual_room: { chat_id = '' },
    },
    socket: { socket = null, eventSocketId = '' },
    auth: { user: { id: userId = '' } = {}, currentLocation },
    sponsors: {
      currentOrganization: { id: sponsor_id = null },
    },
  } = useSelector((state: AppState) => state);
  const activeSession: any = useActiveSession();

  const { poll_options, poll_id, poll_title, poll_type } = poll;

  const checked = poll_options.some(({ votes }: any) => votes?.includes(userId));

  useEffect(() => {
    setDisabled(!checked);
  }, [checked]);

  const handleNewVote = (type: any, option_id: any, isChecked: boolean) => {
    if (type === 'single' && isChecked) {
      return false;
    }
    newVote(socket, chat_id, {
      poll_id,
      option_id,
      partId: userId,
    });
  };

  const submitQuestion = () => {
    if (disabled) {
      return;
    }
    currentPollRef?.current?.nextSibling?.scrollIntoView({
      behavior: 'smooth',
    });

    socket?.emit('question_archive', chat_id, {
      poll_id,
      partId: userId,
    });
    // Track poll (participants)
    const menuItem = getMenuItem(location, customMenu);
    if (menuItem) {
      api.communicationModulesTrack({
        event_code,
        customMenuId: menuItem.id,
        submitted: 'userPoll',
        sessionId: activeSession?.id || '',
        poll_id,
        sponsor_id,
        socketId: eventSocketId,
        userId,
        location: currentLocation,
      });
    }
    setDisabled(true);
  };

  const endPoll = () => {
    socket?.emit('edit_poll', chat_id, {
      ...poll,
      poll_status: 'ended',
    });
    handleCloseEndPollModal();
  };

  const pollOptionClasses = classnames('m-2 p-2 poll-option', {
    'pointer-events-none': hostView,
  });

  const handleDeletePoll = () => {
    deletePoll(socket, chat_id, poll_id);
    handleCloseDeletePollModal();
  };

  const showDeletePollModal = () => {
    setShowDeletePollModal(true);
  };

  const showEndPollModal = () => {
    setShowEndPollModal(true);
  };

  const handleCloseDeletePollModal = () => {
    setShowDeletePollModal(false);
  };

  const handleCloseEndPollModal = () => {
    setShowEndPollModal(false);
  };

  return hostView ? (
    <div>
      <PollAnswers
        buttonText="End Poll"
        deleteButtonText="Delete"
        onButtonClick={showEndPollModal}
        onDeleteButtonClick={showDeletePollModal}
        pollData={poll}
        showVotesCount
      />

      <ModalLayout
        centered
        isVisible={endPollModal}
        onClose={handleCloseEndPollModal}
        children={
          <div className="session-modal">
            <div className="text text-center mb-3">Are you sure you want to end the poll?</div>
            <div className="d-flex w-100 justify-content-center">
              <Button className="mx-2" onClick={endPoll}>
                Yes
              </Button>
              <Button className="mx-2" variant="outline-dark" onClick={handleCloseEndPollModal}>
                No
              </Button>
            </div>
          </div>
        }
      />

      <ModalLayout
        centered
        isVisible={deletePollModal}
        onClose={handleCloseDeletePollModal}
        children={
          <div className="session-modal">
            <div className="text text-center mb-3">
              Are you sure you want to delete the poll? The results will not be saved
            </div>
            <div className="d-flex w-100 justify-content-center">
              <Button className="mx-2" onClick={handleDeletePoll}>
                Yes
              </Button>
              <Button className="mx-2" variant="outline-dark" onClick={handleCloseDeletePollModal}>
                No
              </Button>
            </div>
          </div>
        }
      />
    </div>
  ) : (
    <div className="border-bottom mb-3 p-2 pb-4" ref={currentPollRef}>
      <div className="m-2 d-flex justify-content-between">
        <span>{poll_title}</span>
      </div>
      <div>
        {poll_options?.length &&
          poll_options.map(({ votes, id, value }: any) => {
            const isChecked = !!votes?.includes(userId);
            return (
              <div
                className={pollOptionClasses}
                key={id}
                onClick={() => handleNewVote(poll_type, id, isChecked)}
              >
                <Form.Check
                  className="poll-form-check d-flex align-items-center"
                  type={poll_type === 'single' ? 'radio' : 'checkbox'}
                  label={value}
                  disabled={hostView}
                  checked={hostView ? false : isChecked}
                />
              </div>
            );
          })}
      </div>
      <Button disabled={disabled} size="sm" onClick={submitQuestion}>
        Submit
      </Button>
    </div>
  );
};

interface IAnswers {
  hostView: boolean;
}

const Questions = ({ hostView }: IAnswers) => {
  const dispatch = useDispatch();
  const {
    app: {
      virtual_room: { chat_id },
    },
    socket: { socket },
    auth: {
      user: { id: userId },
    },
    poll: { data: pollData },
  } = useSelector((state: AppState) => state);
  const lastPoll: any = useRef(null);
  const pollsLength: any = useRef(null);

  const polls = pollData.filter(
    (poll: any) => poll.poll_status === 'published' && !poll?.archivedParticipants?.includes(userId)
  );

  useEffect(() => {
    const pollWasSeenDate = new Date().getTime();
    if (lastPoll && pollsLength.current && pollsLength.current < polls.length) {
      lastPoll?.current?.scrollIntoView({ behavior: 'smooth' });
    }
    pollsLength.current = polls.length;
    socket?.emit('poll_was_seen', chat_id, { date: pollWasSeenDate });
    dispatch(setLastPollDate(pollWasSeenDate));

    // eslint-disable-next-line
  }, [polls?.length]);

  return polls.length ? (
    <div>
      <>
        {polls.map((poll: Poll) => (
          <PollItem hostView={hostView} poll={poll} key={poll.poll_id} />
        ))}
        <div ref={lastPoll} />
      </>
    </div>
  ) : (
    <div className="text-center m-3"> There is no result</div>
  );
};

export default Questions;
