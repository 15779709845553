// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import {
  ControlBar,
  AudioInputControl,
  VideoInputControl,
  ContentShareControl,
  // AudioOutputControl,
  // ControlBarButton,
  useUserActivityState
  // Dots
} from 'meeting/chime/amazon-chime-sdk/src';

import EndMeetingControl from '../EndMeetingControl';
// import { useNavigation } from '../../providers/NavigationProvider'
import { StyledControls } from './Styled';

const MeetingControls = () => {
  const { isUserActive } = useUserActivityState();

  return (
    <StyledControls className='controls' active={!!isUserActive}>
      <ControlBar
        className='controls-menu'
        layout='undocked-horizontal'
        showLabels={true}
      >
        {/*<ControlBarButton*/}
        {/*  className='mobile-toggle'*/}
        {/*  icon={<Dots />}*/}
        {/*  onClick={handleToggle}*/}
        {/*  label='Menu'*/}
        {/*/>*/}
        <AudioInputControl />
        <VideoInputControl />
        <ContentShareControl label='Share Screen' />
        {/*<AudioOutputControl />*/}
        <EndMeetingControl />
      </ControlBar>
    </StyledControls>
  );
};

export default MeetingControls;
