import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import configureStore, { history } from 'store/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/icons/style.css';
import { FlagsProvider } from 'react-unleash-flags';
import { HMSRoomProvider } from "@100mslive/react-sdk";

export const store = configureStore();
ReactDOM.render(
  <FlagsProvider>
    <Provider store={store}>
      <Router>
        {
          // @ts-ignore
          <ConnectedRouter history={history}>
           <HMSRoomProvider>
              <App />
              <ToastContainer hideProgressBar={true} />
            </HMSRoomProvider>
          </ConnectedRouter>
        }
      </Router>
    </Provider>
  </FlagsProvider>,
  document.getElementById('root')
);
