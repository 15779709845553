import {
  RECIEVE_POLL,
  RECIEVE_VOTE,
  DELETE_POLL,
  EDIT_POLL,
  CLEAR_POLL,
  ARCHIVE_QUESTION,
  REORDER_POLLS,
  CLEAR_SHOW_POLL_NOTIFICATION,
  SET_LAST_POLL_DATE
} from './constants';

export function recieveNewPoll(payload: any): any {
  return {
    type: RECIEVE_POLL,
    payload
  };
}

export function recieveNewVote(payload: any): any {
  return {
    type: RECIEVE_VOTE,
    payload
  };
}

export function deletePoll(payload: any): any {
  return {
    type: DELETE_POLL,
    payload
  };
}

export function editPoll(payload: any, userId: string): any {
  return {
    type: EDIT_POLL,
    payload,
    userId
  };
}

export function clearPoll(): any {
  return {
    type: CLEAR_POLL
  };
}

export function archiveQuestion(data: any): any {
  return {
    type: ARCHIVE_QUESTION,
    payload: data
  };
}

export function reorderPolls(data: any): any {
  return {
    type: REORDER_POLLS,
    payload: data
  };
}

export const clearShowPollNotification = () => {
  return {
    type: CLEAR_SHOW_POLL_NOTIFICATION
  };
};

export const setLastPollDate = (payload: any): any => {
  return {
    type: SET_LAST_POLL_DATE,
    payload
  };
};
