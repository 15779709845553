import { REACT_APP_BACKEND_API_URL } from '../constants';
import { instance } from './auth';

export const getSponsorsData = (id: string): any => {
  return instance.get(`${REACT_APP_BACKEND_API_URL}event-organizations/${id}`);
};

export const getOrganizationData = (eventCode: string, id: string): any => {
  return instance.get(`${REACT_APP_BACKEND_API_URL}organization-data/${id}`, {
    params: {
      eventCode
    }
  });
};
