import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import YouTube from '@u-wave/react-youtube';
import { vimeoRegExp, youTubeRegExp } from 'constants/playStreaming';

const OnDemandProvider = ({ url }: any) => {
  const isVimeoType = new RegExp(vimeoRegExp).exec(url);
  const youTubeUrl = new RegExp(youTubeRegExp).exec(url);

  const generateYouTube = () => {
    let videoId: any;
    if (youTubeUrl) {
      videoId = youTubeUrl[1];
    }

    return youTubeUrl ? (
      // @ts-ignore
      <YouTube
        style={{
          width: '100%',
          height: '100%'
        }}
        video={videoId}
        autoplay
      />
    ) : null;
  };

  return isVimeoType ? (
    // @ts-ignore
    <Vimeo
      style={{
        width: '100%',
        height: '100%'
      }}
      className='vimeoVideoIframeFix'
      video={url}
    />
  ) : (
    generateYouTube()
  );
};

export default OnDemandProvider;
