import React, { memo, useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import AgendaCard from 'components/AgendaCard/ContainerAgendaCard';
import { getAllSessions, getBookmarkSessions } from 'store/events/actions';
import AgendaTabs from './AgendaTabs';

const AgendaCardsPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id = '' } = useParams() as { id: string };
  const {
    events: {
      eventBookmarks,
      sessions: { data, isLoading },
      currentEvent: { start_date, end_date },
    },
  } = useSelector((state: AppState) => state);

  useEffect(() => {
    dispatch(getAllSessions(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (!id) {
      navigate(`/events`);
    }
    // eslint-disable-next-line
  }, [id]);

  const [filteredData, setFilteredData] = useState([]);
  const [eventDays, setEventDays] = useState([]);

  useEffect(() => {
    if (start_date && end_date && data.length) {
      const newData = data.filter((session: any) => {
        const eventDataStart = moment(start_date);
        const eventDataEnd = moment(end_date);
        const sessionStartDate = moment(session.start_date);

        return (
          sessionStartDate.isBetween(eventDataStart, eventDataEnd) ||
          sessionStartDate.isSame(eventDataStart)
        );
      });

      setFilteredData(newData);
    }
    // eslint-disable-next-line
  }, [data, data.length, start_date, end_date]);

  useEffect(() => {
    const eventDataStart = moment(start_date);
    const eventDataEnd = moment(end_date);
    const daysLength = eventDataEnd.diff(eventDataStart, 'days');

    const eventDays: any = [];
    for (var i = 0; i < daysLength + 1; i++) {
      const date = moment(eventDataStart).add(i, 'days').format('YYYY-MM-DD');
      const validSessions = filteredData.filter((session: any) => {
        return (
          moment(date).isBetween(session.start_date, session.end_date) ||
          moment(date).isSame(moment(session.start_date).format('YYYY-MM-DD')) ||
          moment(date).isSame(moment(session.end_date).format('YYYY-MM-DD'))
        );
      });
      if (validSessions.length) {
        eventDays.push(validSessions);
      }
      setEventDays(eventDays);
    }
    // eslint-disable-next-line
  }, [filteredData, filteredData.length]);

  useEffect(() => {
    dispatch(getBookmarkSessions(id));
    // eslint-disable-next-line
  }, []);

  const bookmarkedData = data.filter((session: any) => eventBookmarks.includes(session.id));

  const seen = new Set();

  const filteredArr = eventDays.filter((el: any) => {
    const duplicate = seen.has(el[0].id);
    seen.add(el[0].id);
    return !duplicate;
  });

  const filteredArrLength = filteredArr?.length;
  const bookmarkedDataLength = bookmarkedData?.length;

  const bookmarkedDataProp = useMemo(() => {
    if(filteredArrLength){
      return bookmarkedData
    }
    //eslint-disable-next-line
  }, [bookmarkedDataLength, filteredArrLength])

  return (
    <div className="content w-100 d-flex flex-column p-4 h-100 agenda-cards-tab-content">
      {!!filteredArrLength || bookmarkedDataLength ? (
        !isLoading && (
          <AgendaTabs
            eventDays={filteredArr}
            bookmarkedData={bookmarkedDataProp}
            eventBookmarks={eventBookmarks}
          />
        )
      ) : (
        <>
          {filteredData.map((session: any) => {
            return (
              <Row className="h-100 m-0" key={session.id}>
                <Col className="d-flex flex-column h-100 p-4">
                  <AgendaCard session={session} eventBookmarks={eventBookmarks} />
                </Col>
              </Row>
            );
          })}
        </>
      )}
    </div>
  );
};

export default memo(AgendaCardsPage);
