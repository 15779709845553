import React from 'react';
import LeaderBoardTabs from './LeaderBoardTabs';

const LeaderBoardCardsPage: React.FC = () => {
  return (
    <div className='w-100 d-flex flex-column p-4 h-100'>
      <LeaderBoardTabs />
    </div>
  );
};

export default LeaderBoardCardsPage;
