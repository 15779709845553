import React from 'react';

import { LoadSpinner } from './Styled';

const Loader: React.FC = () => {
  return (
    <LoadSpinner>
      <div className='loader'>
        <svg className='circular' viewBox='25 25 50 50'>
          <circle
            className='path'
            cx='50'
            cy='50'
            r='20'
            fill='none'
            strokeWidth='2'
            strokeMiterlimit='10'
          />
        </svg>
      </div>
      <div className='p-3 font-size-lg'>Connecting to booth</div>
    </LoadSpinner>
  );
};

export default Loader;
