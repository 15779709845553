import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHMSActions } from "@100mslive/react-sdk";
import {
  AgendaPage,
  EventPage,
  EventSessionPage,
  EventsPage,
  GreenRoomPage,
  LeaderBoardPage,
  MagicLinkPage,
  MyEventsPage,
  ProfilePage,
  ReceptionPage,
  SponsorRoomPage,
  SponsorsPage,
} from 'pages/index';
import { getUser } from 'store/auth/actions';
import ROUTES from 'constants/routes';
import Loader from 'components/Loader/Loader';
import { AuthToken } from 'helpers/index';
import HelmetCustom from 'HelmetCustom';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import PrivateLayout from 'components/PrivateLayout/PrivateLayout';
import MsVideoChat from 'components/MsVideoChat';
import 'scss/index.scss';


const App: React.FC = () => {
  const hmsActions = useHMSActions();
  const {
    auth: { loggedIn, loggingIn },
    app: { isLoaderShown },
    confirmationModal: { show },
  } = useSelector((state: AppState) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    window.onunload = () => {
      hmsActions.leave();
    };
  }, [hmsActions]);

  useEffect(() => {
    if (AuthToken.get('access_token') && !loggingIn && !loggedIn) {
      dispatch(getUser());
    }
    // eslint-disable-next-line
  }, [loggedIn, loggingIn]);

  return (
    <>
      {<ConfirmationModal isShow={!!show} />}
      <HelmetCustom />
      <Routes>
        <Route path={ROUTES.EVENTS} element={<EventsPage />} />
        <Route path={ROUTES.MAGIC_LINK} element={<MagicLinkPage />} />
        <Route path={ROUTES.MAGIC_LINK_WITH_EVENT_CODE} element={<MagicLinkPage />} />
        <Route path={ROUTES.EVENT} element={<EventPage />} />
        <Route
          path={ROUTES.MY_EVENTS}
          element={
            <PrivateLayout>
              <MyEventsPage />
            </PrivateLayout>
          }
        />
        <Route
          path={ROUTES.AGENDA}
          element={
            <PrivateLayout eventAccess>
              <AgendaPage />
            </PrivateLayout>
          }
        />
        <Route
          path={ROUTES.SPONSORS}
          element={
            <PrivateLayout eventAccess>
              <SponsorsPage />
            </PrivateLayout>
          }
        />
        <Route
          path={ROUTES.SPONSOR_ROOM}
          element={
            <PrivateLayout eventAccess socketConnect>
              <SponsorRoomPage />
            </PrivateLayout>
          }
        />
        <Route
          path={ROUTES.GREEN_ROOM}
          element={
            <PrivateLayout eventAccess socketConnect>
              <GreenRoomPage />
            </PrivateLayout>
          }
        />
        <Route
          path={ROUTES.STAGE}
          element={
            <PrivateLayout eventAccess socketConnect>
              <EventSessionPage />
            </PrivateLayout>
          }
        />
        <Route
          path={ROUTES.RECEPTION}
          element={
            <PrivateLayout eventAccess socketConnect>
              <ReceptionPage />
            </PrivateLayout>
          }
        />
        <Route
          path={ROUTES.PROFILE}
          element={
            <PrivateLayout>
              <ProfilePage />
            </PrivateLayout>
          }
        />
        <Route
          path={ROUTES.LEADERBOARD}
          element={
            <PrivateLayout eventAccess>
              <LeaderBoardPage />
            </PrivateLayout>
          }
        />
        <Route
          path={ROUTES.ROOM}
          element={
            <PrivateLayout eventAccess socketConnect>
              <EventSessionPage />
            </PrivateLayout>
          }
        />
        <Route path={ROUTES.MS_VIDEO_CHAT} element={<MsVideoChat />}/>
        <Route path="*" element={<Navigate to={ROUTES.EVENTS} replace />} />
      </Routes>
      {isLoaderShown && <Loader />}
    </>
  );
};


export default App;
