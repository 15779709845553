import { GET_LEADERS, SET_PRIZES, SET_RULES, GET_MY_POINTS } from './constants';

interface ActionInterface {
  type: string;
  payload: any;
}

const initialState = {
  leaders: [],
  myPoints: [],
  prizes: '',
  rules: {}
};

export const leaderBoard = (state = initialState, action: ActionInterface) => {
  switch (action.type) {
    case GET_LEADERS:
      return {
        ...state,
        leaders: action.payload
      };
    case SET_RULES:
      return {
        ...state,
        rules: action.payload
      };
    case GET_MY_POINTS:
      return {
        ...state,
        myPoints: action.payload
      };
    case SET_PRIZES:
      return {
        ...state,
        prizes: action.payload
      };
    default:
      return state;
  }
};
