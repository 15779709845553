import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveTab } from 'store/communicationModules/actions';
import { useCommunicationTabData } from './useCommunicationTabData';
import { CommunicationTabs } from 'components/CommunicationModules/CommunicationTabs';

export const CommunicationModules: React.FC = () => {
  const dispatch = useDispatch();
  const {
    app: {
      virtual_room,
      virtual_room: {
        show_poll = false,
        show_qa = false,
        show_chat = false,
        show_participants = false,
      } = {},
    },
    events: { currentEvent: { is_host = false } = {} },
  } = useSelector((state: AppState) => state);
  const [isMobileVersion, setIsMobileVersion] = useState(false);
  const tabData = useCommunicationTabData();
  const defaultTab = tabData.find(({show} : any) => show === 1);
  const activeDefaultTitle = defaultTab?.title.replace(/\s/g, '').toLowerCase();
  
  const windowWidth = window.innerWidth;
  const tabContentSelector = document.querySelector(
    '.event-sidebar-container-row .communication-modules .tab-content'
  );

  const showCommunicationTabs = useMemo(
    () => show_chat || show_participants || show_poll || show_qa || is_host,
    // eslint-disable-next-line
    [virtual_room, is_host]
  );

  useEffect(() => {
    windowWidth > 768 && dispatch(setActiveTab(activeDefaultTitle));
    // eslint-disable-next-line
  }, [activeDefaultTitle]);

  const handleResize = useCallback((e: any) => {
    const innerWidth = e.currentTarget.innerWidth;
    if (innerWidth <= 768 && !isMobileVersion) {
      setIsMobileVersion(true);
      dispatch(setActiveTab(''));
      tabContentSelector?.classList.remove('active');
    } else if (innerWidth > 768 && isMobileVersion) {
      setIsMobileVersion(false);
      dispatch(setActiveTab(activeDefaultTitle));
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return showCommunicationTabs && <CommunicationTabs />;
};
