export const CONNECT_SOCKET = 'CONNECT_SOCKET';
export const SET_SOCKET = 'SET_SOCKET';
export const SET_EVENT_SOCKET_ID = 'set_event_socket_id';
export const CONNECT = 'connect';
export const DISCONNECT_SOCKET = 'DISCONNECT_SOCKET';

// realtime update
export const UPDATED_CUSTOM_MENU = 'updated_custom_menu';
export const UPDATED_STAGE = 'updated_stage';
export const UPDATED_ROOM = 'updated_room';
export const UPDATED_SPONSORS = 'updated_sponsors';

// Ticker Messages
export const GET_TICKER_MESSAGES = 'ticker_messages';
export const CREATE_TICKER_MESSAGE = 'create_ticker_message';
export const DELETE_TICKER_MESSAGE = 'ticker_message_deleted';
export const TICKER_MESSAGE_UPDATED = 'ticker_message_updated';

// handle expired token

export const EXPIRED_TOKEN = 'expired_token';
export const NEW_TOKEN = 'new_token';

export const DISABLE_BUTTONS = 'disable_buttons';
export const ENABLE_BUTTONS = 'enable_buttons';
export const ERROR_MESSAGE = 'error-message';
export const HIDE_MUTE_BUTTON = 'hide_mute_button';
export const GREEN_ROOM_END = 'green_room_end';
