import React from 'react';
import '../style.scss';

interface Props {
  title: string;
}

export const Label: React.FC<Props> = ({ title }) => {
  return <div className='my-2'> {title} </div>;
};
