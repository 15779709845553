import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { authResetAction } from 'store/auth/actions';
import ROUTES from 'constants/routes';
import './style.scss';

import { MenuLink } from 'react-banzai';
import Button from 'react-bootstrap/Button';
import {
  ChangePasswordForm,
  ForgotPasswordForm,
  LoginForm,
  MagicLinkForm,
  SignUpForm,
  VerificationForm
} from 'components';
import { ModalLayout } from 'layouts/ModalLayout';
import { banzaiLogo } from 'constants/images';

const initualShowAuthModals = {
  login: false,
  signup: false,
  forgotPassword: false,
  changePassword: false,
  verification: false,
  magicLink: false
};

const authHeader = (onClose: any) => (
  <div className='d-flex w-100 align-items-center justify-content-between'>
    <img src={banzaiLogo} className='modal-logo' alt='' />
    <button
      onClick={onClose}
      type='button'
      className='btn btn-link btn-close'
      data-dismiss='modal'
      aria-label='Close'
    >
      <span aria-hidden='true'>&times;</span>
    </button>
  </div>
);

const PublicHeader: React.FC = () => {
  const { id = '' } = useParams() as { id: string };
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [showAuthModals, setShowAuthModals] = useState(initualShowAuthModals);
  const {
    auth: { signedIn, forgotPasswordSuccess, changePasswordSuccess }
  } = useSelector((state: AppState) => state);

  const LoginFooter = () => (
    <div className='d-flex w-100 align-items-center justify-content-between'>
      <p
        className='btn btn-link'
        onClick={() => changeShowAuthModals('signup', true)}
      >
        Create an account
      </p>
      <p
        className='btn btn-link'
        onClick={() => changeShowAuthModals('forgotPassword', true)}
      >
        Forgot password
      </p>
    </div>
  );

  const SignUpFooter = () => (
    <div className='d-flex w-100 align-items-center justify-content-between'>
      <p
        className='btn btn-link'
        onClick={() => changeShowAuthModals('login', true)}
      >
        Already have an account?
      </p>
    </div>
  );

  const changeShowAuthModals = useCallback(
    (name: string, value: boolean, reset = false) => {
      setShowAuthModals({
        ...initualShowAuthModals,
        [name]: value
      });
      reset && dispatch(authResetAction());
    },
    [dispatch]
  );

  useEffect(() => {
    if (forgotPasswordSuccess) {
      changeShowAuthModals('changePassword', true);
    }
  }, [forgotPasswordSuccess, changeShowAuthModals]);

  useEffect(() => {
    if (changePasswordSuccess) {
      changeShowAuthModals('changePassword', false);
    }
  }, [changePasswordSuccess, changeShowAuthModals]);

  useEffect(() => {
    if (signedIn) {
      changeShowAuthModals('signup', false);
      changeShowAuthModals('verification', true);
    }
  }, [signedIn, changeShowAuthModals]);

  const closeLoginModal = () => changeShowAuthModals('login', false, true);
  const closeSignUpModal = () => changeShowAuthModals('signup', false, true);
  const closeForgotPasswordModal = () =>
    changeShowAuthModals('forgotPassword', false);
  const closeChangePasswordModal = () =>
    changeShowAuthModals('changePassword', false);
  const closeVerificationModal = () =>
    changeShowAuthModals('verification', false);
  const closeMagicLinkModal = () =>
    changeShowAuthModals('magicLink', false, true);

  return (
    <>
      <div className='d-flex '>
        {id && (
          <div className='d-flex align-items-center font-weight-bolder font-size-base text-uppercase header-menu-event border-right'>
            <div className='px-2 mx-3 header-event-link '>
              <MenuLink to={ROUTES.EVENTS} pathname={pathname}>
                <span>Find Events</span>
              </MenuLink>
            </div>
          </div>
        )}
        <Button
          className='mr-2 ml-4 header-btn'
          variant='light'
          onClick={() => changeShowAuthModals('login', true)}
        >
          Sign in
        </Button>
        <Button
          className='header-btn'
          variant='primary'
          onClick={() => changeShowAuthModals('signup', true)}
        >
          Create an account
        </Button>
      </div>
      {/* Auth modals */}
      <ModalLayout
        header={authHeader(closeLoginModal)}
        isVisible={showAuthModals.login}
        onClose={closeLoginModal}
        footer={<LoginFooter />}
      >
        <LoginForm
          onOpenMagicLinkForm={() => changeShowAuthModals('magicLink', true)}
        />
      </ModalLayout>
      <ModalLayout
        header={authHeader(closeSignUpModal)}
        isVisible={showAuthModals.signup}
        onClose={closeSignUpModal}
        footer={<SignUpFooter />}
      >
        <SignUpForm />
      </ModalLayout>
      <ModalLayout
        header={authHeader(closeForgotPasswordModal)}
        isVisible={showAuthModals.forgotPassword}
        onClose={closeForgotPasswordModal}
      >
        <ForgotPasswordForm />
      </ModalLayout>
      <ModalLayout
        header={authHeader(closeChangePasswordModal)}
        isVisible={showAuthModals.changePassword}
        onClose={closeChangePasswordModal}
      >
        <ChangePasswordForm />
      </ModalLayout>
      <ModalLayout
        header={authHeader(closeVerificationModal)}
        isVisible={showAuthModals.verification}
        onClose={closeVerificationModal}
      >
        <VerificationForm parseUrl />
      </ModalLayout>
      <ModalLayout
        header={authHeader(closeMagicLinkModal)}
        isVisible={showAuthModals.magicLink}
        onClose={closeMagicLinkModal}
      >
        <MagicLinkForm />
      </ModalLayout>
    </>
  );
};

export default PublicHeader;
