import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  setActiveTab,
  setIsCommunicationModulesAvailable,
} from 'store/communicationModules/actions';
import { useCommunicationTabData } from './useCommunicationTabData';
import { tabsKeys } from './constants';

export const TabDataView = () => {
  const dispatch = useDispatch();
  const {
    communicationModules: { activeTab },
    events: { currentEvent: { is_host = false } = {} },
    questionsAndAnswers: { showQuestionsNotification },
    poll: { showPollNotification },
  } = useSelector((state: AppState) => state);

  const tabData = useCommunicationTabData();
  const tabContent = document.querySelector(
    '.event-sidebar-container-row .communication-modules .tab-content'
  );
  const handleCloseTabContent = () => {
    tabContent?.classList.remove('active');
    dispatch(setActiveTab(''));
  };

  useEffect(() => {
    const tabDataFilteredByShow = tabData.filter(({ show }: any) => show === 1);
    dispatch(setIsCommunicationModulesAvailable(tabDataFilteredByShow.length));

    // eslint-disable-next-line
  }, [tabData]);

  const handleSelectTab = (tabName: any) => {
    if (window.innerWidth <= 768) {
      if (tabName === activeTab) {
        tabContent?.classList.remove('active');
        return dispatch(setActiveTab(''));
      } else if (activeTab === '') {
        tabContent?.classList.add('active');
      }
    }
    dispatch(setActiveTab(tabName));
  };


  return (
    <Tabs
      className={`${showQuestionsNotification ? 'questions-answers' : ''} ${!is_host && showPollNotification ? 'polls' : ''
        }`}
      activeKey={activeTab}
      onSelect={handleSelectTab}
    >
      {tabData.map(
        (tab: any, index: number) =>
          !!tab.show && (
            <Tab
              className="h-100"
              eventKey={tabsKeys[index]}
              title={tab.title}
              key={index}
              unmountOnExit={tab.title !== 'Chat'}
            >
              <div className="tab-mobile-head">
                <span>{tab.title}</span>
                <FontAwesomeIcon
                  icon={faTimes as IconProp}
                  size="lg"
                  color="ffffff"
                  onClick={handleCloseTabContent}
                />
              </div>
              {tab.component}
            </Tab>
          )
      )}
    </Tabs>
  );
};
