// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import {
  lightTheme,
  // darkTheme,
  MeetingProvider,
  NotificationProvider,
  GlobalStyles
} from 'meeting/chime/amazon-chime-sdk/src';
import { MeetingValidation } from './views';
import Notifications from './containers/Notifications';
import meetingConfig from './meetingConfig';

const App: FC = () => {
  return (
    <Theme>
      <NotificationProvider>
        <Notifications />
        <MeetingProvider {...meetingConfig}>
          <MeetingValidation />
        </MeetingProvider>
      </NotificationProvider>
    </Theme>
  );
};

const Theme: React.FC = ({ children }) => {
  // const {theme} = useSelector((state: AppState) => state.meeting)

  return (
    // @ts-ignore
    <ThemeProvider
      // theme={theme === 'light' ? lightTheme : darkTheme}>
      theme={lightTheme}
    >
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default App;
