import React from 'react';
import { Icon } from 'components/Icon/Icon';

const SocialMediaSharing = () => {
  return (
    <div className='order-4 mt-4'>
      <p>Share on:</p>
      <div className='d-flex'>
        <a
          href={`https://www.facebook.com/sharer.php?u=${encodeURI(
            document.location.href
          )}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          <Icon name='facebook' color='#1877F2' size='28px' />
        </a>
        <a
          href={`https://twitter.com/share?url=${encodeURI(
            document.location.href
          )}`}
          target='_blank'
          className='ml-4'
          rel='noopener noreferrer'
        >
          <Icon name='twitter' color='#1DA1F2' size='28px' />
        </a>
        <a
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(
            document.location.origin
          )}`}
          target='_blank'
          className='ml-4'
          rel='noopener noreferrer'
        >
          <Icon name='linkedIn' color='#0A66C2' size='28px' />
        </a>
      </div>
    </div>
  );
};

export default SocialMediaSharing;
