import React, { ChangeEvent } from 'react';
import Form from 'react-bootstrap/Form';
import '../style.scss';
interface Props {
  name: string;
  title: string;
  required: boolean;
  validationError: boolean;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  min?: string;
  as?: any;
  rows?: string;
}

export const TextInput: React.FC<Props> = ({
  name,
  title,
  required,
  validationError,
  value,
  onChange,
  type,
  min,
  as,
  rows
}) => {
  return (
    <Form.Group controlId={name}>
      <Form.Label className={required ? 'required' : ''}>{title}</Form.Label>
      <Form.Control
        min={min}
        isInvalid={!value && validationError}
        value={value}
        type={type}
        onChange={onChange}
        as={as}
        rows={rows}
      />
    </Form.Group>
  );
};
