export const isArray = (value: any) => {
  return Object.prototype.toString.call(value) === '[object Array]';
};

export const isObject = (value: any) => {
  return Object.prototype.toString.call(value) === '[object Object]';
};

export const isPrimitive = (value: any) => {
  return value !== Object(value);
};

export const isEmpty = (value: any) => {
  if (isArray(value)) {
    return value.length === 0;
  } else if (isObject(value)) {
    return Object.keys(value).length === 0;
  }
  return false;
};
