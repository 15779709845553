import React from 'react';
import CharacterAvatar from '../CharacterAvatar';

type SponsorInfo = Pick<Sponsor, 'name' | 'logo'>;

interface SponsorLogoProps {
  sponsor: SponsorInfo;
}

const SponsorLogo: React.FC<SponsorLogoProps> = ({ sponsor: { name, logo } }) =>
  logo ? (
    <img
      src={logo}
      alt={`This is a ${name} logo`}
      className='mw-100 mh-100 w-auto h-auto'
    />
  ) : (
    <CharacterAvatar name={name} />
  );

export default SponsorLogo;
