import { REACT_APP_BACKEND_API_URL } from '../constants';
import { instance } from './auth';

export const getRegistrationFields = (id: string) => {
  return instance.get(`${REACT_APP_BACKEND_API_URL}registration-fields/${id}`);
};

export const joinToChimeMeeting = (roomId: string, eventCode: string) => {
  return instance.post(`${REACT_APP_BACKEND_API_URL}join-meeting`, {
    roomId,
    eventCode
  });
};

export const RegisterToEvent = (
  id: string,
  data: Object,
  applicationId: string
) => {
  let url = `${REACT_APP_BACKEND_API_URL}event-registration/${id}`;
  if (applicationId) url += `/${applicationId}`;
  return instance.post(url, {
    ...data
  });
};

export const getParticipantRegInfo = (id: string, email: string) => {
  return instance.get(`${REACT_APP_BACKEND_API_URL}event-participant/${id}`, {
    params: {
      email
    }
  });
};

export const joinToVonageMeeting = (roomId: string, eventCode: string) => {
  return instance.post(`${REACT_APP_BACKEND_API_URL}join-call`, {
    roomId,
    eventCode
  });
};

export const finishVonageMeeting = (roomId: string, eventCode: string) => {
  return instance.post(`${REACT_APP_BACKEND_API_URL}finish-call`, {
    roomId,
    eventCode
  });
};

export const endBroadcast = (eventCode: string, broadcastId: string) => {
  return instance.post(`${REACT_APP_BACKEND_API_URL}stop-broadcast`, {
    eventCode,
    broadcastId
  });
};

export const startRecord = (
  sessionId: string,
  eventCode: string,
  roomSessionId: string
) => {
  return instance.post(`${REACT_APP_BACKEND_API_URL}start-recording`, {
    sessionId,
    eventCode,
    roomSessionId
  });
};

export const stopRecord = (archiveId: string, eventCode: string) => {
  return instance.post(`${REACT_APP_BACKEND_API_URL}stop-recording`, {
    archiveId,
    eventCode
  });
};
