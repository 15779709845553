import {
  CANCEL_REG_TO_EVENT_REQUEST,
  CANCEL_REG_TO_EVENT_RESET,
  CANCEL_REG_TO_EVENT_SUCCESS,
  CHANGE_REG_AND_COPY_FIELDS,
  CHANGE_REGISTRATION_COPY_FIELDS,
  CHANGE_REGISTRATION_FIELDS,
  CLEAR_CURRENT_EVENT,
  ClEAR_PARTICIPANTS_REG_DATA,
  CLEAR_REGISTRATION_FIELDS,
  GET_ACCOUNT_EVENTS,
  GET_ALL,
  GET_ALL_SESSIONS_FAILURE,
  GET_ALL_SESSIONS_REQUEST,
  GET_ALL_SESSIONS_SUCCESS,
  GET_CUSTOM_MENU,
  GET_ONE,
  GET_ONE_FAILURE,
  GET_ONE_LOADING,
  GET_ONE_REQUEST,
  GET_ONE_SUCCESS,
  GET_PAST_EVENTS_FAILURE,
  GET_PAST_EVENTS_REQUEST,
  GET_PAST_EVENTS_SUCCESS,
  GET_RECEPTION_DATA_FAILURE,
  GET_RECEPTION_DATA_REQUEST,
  GET_RECEPTION_DATA_SUCCESS,
  GET_UPCOMING_EVENTS_FAILURE,
  GET_UPCOMING_EVENTS_REQUEST,
  GET_UPCOMING_EVENTS_SUCCESS,
  RESET_EVENTS_DATA,
  SET_ACTIVE_SESSIONS_ID,
  SET_ALL_EVENTS_lOADING,
  SET_COUNTRY_STATES,
  SET_EVENT_BOOKMARKS,
  SET_IS_REGISTERED_TO_EVENT,
  SET_REDIRECTION_URL,
  SET_REGISTRATION_FIELDS,
  SET_REGISTRATION_FIELDS_LOADING,
  UPDATE_SESSION_DATA,
  SET_IS_JOINED,
} from 'store/events/constants';

const initialState = {
  allEvents: [],
  myEventsLoading: false,
  myUpcomingEvents: {
    data: [],
    loading: false,
  },
  myPastEvents: {
    data: [],
    loading: false,
  },
  myEventsError: null,
  currentEvent: {
    description: '',
    image: '',
    name: '',
    startDate: '',
    endDate: '',
    registrants: 0,
    sponsors: [],
    pages: [],
    customMenu: [],
    booths: [],
    speakers: [],
    brand: {
      name: '',
      logo: '',
      secondary_logo: '',
      square_logo: '',
    },
    stageLoading: false,
    stageError: null,
    stage: {
      customButtons: [],
      streaming: [],
    },
  },
  receptionLoading: false,
  receptionError: null,
  allEventsLoading: null,
  registrationFields: {
    states: [],
  },
  registrationFieldsLoading: false,
  participantRegData: {},
  participantRegDataCopy: {},
  sessions: {
    isLoading: false,
    data: [],
    error: null,
  },
  oneEventMessage: null,
  cancelReg: {
    loading: false,
    success: false,
  },
  oneEventLoading: false,
  activeSessionsRoomId: [],
  eventBookmarks: [],
  tickerMessages: [],
  redirectionUrl: '',
  isJoined: null,
};

interface RegData {
  key: string;
  value: any;
}

interface ActionInterface {
  type: string;
  events?: Event[];
  event?: Event;
  message?: string;
  eventRegistrationFields?: Object;
  participantRegData: RegData;
  text?: string;
  data: any;
  chat_id?: string;
  states?: Array<Object>;
  payload: any;
}

export const events = (state = initialState, action: ActionInterface) => {
  switch (action.type) {
    case GET_ALL:
      return {
        ...state,
        allEvents: action.events,
        oneEventMessage: null,
      };
    case GET_ONE:
      return {
        ...state,
        currentEvent: {
          ...state.currentEvent,
          ...action.event,
          event_code: action.event?.event_code.toUpperCase(),
        },
      };
    case GET_ONE_REQUEST:
      return {
        ...state,
        oneEventRequesting: true,
        oneEventSuccess: null,
        oneEventFailure: null,
      };
    case GET_ONE_SUCCESS:
      return {
        ...state,
        oneEventRequesting: null,
        oneEventSuccess: true,
        oneEventFailure: null,
      };
    case GET_ONE_FAILURE:
      return {
        ...state,
        oneEventRequesting: null,
        oneEventSuccess: null,
        oneEventFailure: true,
        oneEventMessage: action.message,
      };
    case GET_CUSTOM_MENU:
      return {
        ...state,
        currentEvent: {
          ...state.currentEvent,
          customMenu: action.data,
        },
      };
    case GET_ACCOUNT_EVENTS:
      return {
        ...state,
        accountEvents: action.events,
      };
    case CLEAR_CURRENT_EVENT:
      return {
        ...state,
        currentEvent: {
          ...initialState.currentEvent,
        },
        oneEventRequesting: null,
        oneEventSuccess: null,
        oneEventFailure: null,
      };
    case SET_REGISTRATION_FIELDS: {
      return {
        ...state,
        registrationFields: action.eventRegistrationFields,
      };
    }
    case SET_REGISTRATION_FIELDS_LOADING: {
      return {
        ...state,
        registrationFieldsLoading: action.data,
      };
    }
    case SET_COUNTRY_STATES: {
      return {
        ...state,
        registrationFields: {
          ...state.registrationFields,
          states: action.states,
        },
      };
    }
    case CLEAR_REGISTRATION_FIELDS: {
      return {
        ...state,
        registrationFields: [],
      };
    }
    case CHANGE_REGISTRATION_FIELDS: {
      let { key, value } = action.participantRegData;
      if (key === 'multi' && !value) value = [];
      return {
        ...state,
        participantRegData: {
          ...state.participantRegData,
          [key]: value,
        },
      };
    }
    case CHANGE_REGISTRATION_COPY_FIELDS: {
      let { key, value } = action.participantRegData;
      if (key === 'multi' && !value) value = [];
      return {
        ...state,
        participantRegDataCopy: {
          ...state.participantRegDataCopy,
          [key]: value,
        },
      };
    }
    case CHANGE_REG_AND_COPY_FIELDS: {
      let { key, value } = action.participantRegData;
      if (key === 'multi' && !value) value = [];
      return {
        ...state,
        participantRegData: {
          ...state.participantRegData,
          [key]: value,
        },
        participantRegDataCopy: {
          ...state.participantRegDataCopy,
          [key]: value,
        },
      };
    }
    case SET_IS_REGISTERED_TO_EVENT: {
      return {
        ...state,
        currentEvent: {
          ...state.currentEvent,
          participant_is_registered: true,
        },
      };
    }
    case SET_ALL_EVENTS_lOADING: {
      return {
        ...state,
        allEventsLoading: action.data,
      };
    }
    case ClEAR_PARTICIPANTS_REG_DATA: {
      return {
        ...state,
        participantRegData: {},
        participantRegDataCopy: {},
      };
    }

    // Reception page
    case GET_RECEPTION_DATA_REQUEST: {
      return {
        ...state,
        receptionLoading: true,
      };
    }
    case GET_RECEPTION_DATA_SUCCESS: {
      return {
        ...state,
        receptionLoading: false,
        receptionError: null,
      };
    }
    case GET_RECEPTION_DATA_FAILURE: {
      return {
        ...state,
        receptionLoading: false,
        receptionError: action.message,
      };
    }
    case GET_ALL_SESSIONS_REQUEST: {
      return {
        ...state,
        sessions: {
          ...state.sessions,
          isLoading: true,
        },
      };
    }
    case GET_ALL_SESSIONS_SUCCESS: {
      return {
        ...state,
        sessions: {
          ...state.sessions,
          isLoading: false,
          data: action.data,
          error: null,
        },
      };
    }
    case UPDATE_SESSION_DATA: {
      const { sessionId, rate, speakerId } = action.payload;
      let newSessionsData = [...state.sessions.data];
      let updatedSessionIndex = newSessionsData.findIndex((item: any) => item.id === sessionId);
      let updatedSessionData: any = newSessionsData[updatedSessionIndex] || {};
      if (speakerId) {
        let newSpeakersData: any = [...updatedSessionData.speakers];
        let updatedSpeakerIndex = newSpeakersData.findIndex((item: any) => item.id === speakerId);
        let updatedSpeakerData: any = newSpeakersData[updatedSpeakerIndex];
        updatedSpeakerData.is_rate = rate;
      } else {
        updatedSessionData.is_rate = rate;
      }
      return {
        ...state,
        sessions: {
          ...state.sessions,
          data: newSessionsData,
        },
      };
    }
    case GET_ALL_SESSIONS_FAILURE: {
      return {
        ...state,
        sessions: {
          ...state.sessions,
          isLoading: false,
          data: [],
          error: action.message,
        },
      };
    }
    case GET_UPCOMING_EVENTS_REQUEST: {
      return {
        ...state,
        myUpcomingEvents: {
          ...state.myUpcomingEvents,
          loading: true,
        },
      };
    }
    case GET_UPCOMING_EVENTS_SUCCESS: {
      return {
        ...state,
        myUpcomingEvents: {
          ...state.myUpcomingEvents,
          loading: false,
          data: action.data,
        },
      };
    }
    case GET_UPCOMING_EVENTS_FAILURE: {
      return {
        ...state,
        myUpcomingEvents: {
          ...state.myUpcomingEvents,
          loading: false,
          data: [],
        },
      };
    }
    case GET_PAST_EVENTS_REQUEST: {
      return {
        ...state,
        myPastEvents: {
          ...state.myPastEvents,
          loading: true,
        },
      };
    }
    case GET_PAST_EVENTS_SUCCESS: {
      return {
        ...state,
        myPastEvents: {
          ...state.myPastEvents,
          loading: false,
          data: action.data,
        },
      };
    }
    case GET_PAST_EVENTS_FAILURE: {
      return {
        ...state,
        myPastEvents: {
          ...state.myPastEvents,
          loading: false,
          data: [],
        },
      };
    }
    case CANCEL_REG_TO_EVENT_REQUEST: {
      return {
        ...state,
        cancelReg: {
          ...state.cancelReg,
          loading: true,
          success: false,
        },
      };
    }
    case CANCEL_REG_TO_EVENT_SUCCESS: {
      return {
        ...state,
        cancelReg: {
          ...state.cancelReg,
          loading: false,
          success: true,
        },
      };
    }
    case CANCEL_REG_TO_EVENT_RESET: {
      return {
        ...state,
        cancelReg: {
          ...state.cancelReg,
          success: false,
          loading: false,
        },
      };
    }
    case GET_ONE_LOADING: {
      return {
        ...state,
        oneEventLoading: action.payload,
      };
    }
    case SET_ACTIVE_SESSIONS_ID: {
      return {
        ...state,
        activeSessionsRoomId: action.payload,
      };
    }
    case SET_EVENT_BOOKMARKS: {
      return {
        ...state,
        eventBookmarks: action.payload,
      };
    }
    case SET_REDIRECTION_URL: {
      return {
        ...state,
        redirectionUrl: action.payload,
      };
    }
    case RESET_EVENTS_DATA: {
      return { ...initialState };
    }
    case SET_IS_JOINED: {
      return {
        ...state,
        isJoined: action.payload,
      };
    }
    default:
      return state;
  }
};
