import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';
import '../style.scss';

interface Props {
  name: string;
  type: 'switch' | 'checkbox' | 'radio' | undefined;
  title: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  required: boolean;
  validationError: boolean;
  value: string;
}

export const Checkbox: React.FC<Props> = ({
  name,
  type,
  title,
  onChange,
  checked,
  required,
  validationError,
  value
}) => {
  const classes = classNames({
    invalid: value === 'No' && validationError,
    required
  });
  return (
    <Form.Group controlId={name}>
      <Form.Check
        checked={checked}
        name={name}
        type={type}
        label={title}
        onChange={onChange}
        className={classes}
      />
    </Form.Group>
  );
};
