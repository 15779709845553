import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './styles.scss';

const Loader = () => {
  return (
    <div className='d-flex position-absolute w-100 vh-100 top-0 left-0 spin-loader'>
      <Spinner animation='border' className='m-auto'></Spinner>
    </div>
  );
};

export default Loader;
