import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { UserCard, UserCarousel } from 'react-banzai';
import { toast } from 'react-toastify';
import Duration from 'components/Duration/Duration';
import AddToCalendarShow from 'components/AddToCalendarShow/AddToCalendarShow';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setBookmarkSessions, updateSessionRate } from 'store/events/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { rateSessionAction, rateSpeakerAction } from 'store/sessions/actions';
import RateComponent from 'components/Rate/Rate';
import './AgendaCard.scss';
import {
  useUserIsCurrentEventSpeaker,
  useUserIsSessionSpeaker
} from 'hooks/useUserRole';

interface Props {
  session: any;
  startDateWithTimezone: string | Date;
  settings: any;
  isSidePage: boolean;
  watchSessionRecord: () => void;
  onClick: any;
  eventBookmarks: any;
  style?: any;
}

const AgendaCard: React.FC<Props> = ({
  session: {
    id: sessionId,
    name = '',
    abstract = '',
    start_date,
    end_date,
    speakers = [],
    status,
    record_available,
    is_rate
  },
  settings,
  isSidePage,
  watchSessionRecord,
  onClick,
  eventBookmarks,
  style
}) => {
  const dispatch = useDispatch();

  const {
    events: {
      currentEvent: { allow_session_rating, allow_speaker_rating, is_host }
    }
  } = useSelector((state: AppState) => state);

  const { id: eventCode } = useParams() as { id: string };

  const handleCLickBookmark = () => {
    dispatch(
      setBookmarkSessions(eventCode, sessionId, isBookmarked ? 'remove' : 'add')
    );
  };

  const isBookmarked = eventBookmarks.includes(sessionId);

  const userIsCurrentEventSpeaker = useUserIsCurrentEventSpeaker();
  const userIsSessionSpeaker = useUserIsSessionSpeaker();

  const isSpeaker = userIsCurrentEventSpeaker || userIsSessionSpeaker;

  const showSessionRateComponent =
    allow_session_rating && !is_host && !isSpeaker;
  const showSpeakerRateComponent =
    allow_speaker_rating && !is_host && !isSpeaker;

  const rateAction = async (
    type: string,
    givenRate: number,
    speakerId?: number
  ) => {
    try {
      switch (type) {
        case 'speaker': {
          if (speakerId) {
            await dispatch(
              rateSpeakerAction(+givenRate, +sessionId, +speakerId)
            );
            dispatch(updateSessionRate(sessionId, givenRate, speakerId));
          }
          break;
        }
        case 'session': {
          await dispatch(rateSessionAction(+givenRate, +sessionId));
          dispatch(updateSessionRate(sessionId, givenRate));
          break;
        }
      }
    } catch (err) {
      toast.error(
        'Something went wrong! Please check your internet connection.'
      );
      return false;
    }
    return true;
  };

  return (
    <Card className='agenda-card'>
      <Card.Body className='p-0' style={style}>
        <div className='pl-4 pr-4 pt-4 pb-0'>
          <div className='agenda-card-head d-flex align-items-center justify-content-between p-2 mr-8'>
            <div className='d-flex flex-column'>
              <h3 className='my-1'>{name}</h3>
              {!!showSessionRateComponent && (
                <RateComponent
                  callback={(rate: number) => rateAction('session', rate)}
                  initialRate={is_rate}
                />
              )}
              <p className='d-flex align-items-center ml-2 m-0'>
                {status === 'active' && (
                  <>
                    <span className='red-dot' />
                    Live
                  </>
                )}
              </p>
            </div>
            <div
              className={`agenda-bookmark ${isBookmarked ? 'active' : ''}`}
              onClick={handleCLickBookmark}
            >
              <FontAwesomeIcon icon={faBookmark as IconProp} size='lg' />
            </div>
            {record_available && (
              <Button
                variant='success'
                size='sm'
                className='px-5'
                onClick={watchSessionRecord}
              >
                Watch
              </Button>
            )}
          </div>
          <div className='d-flex pl-2 pr-2 pt-2 pb-0'>
            <div>
              <AddToCalendarShow
                event={{
                  name,
                  start_date,
                  end_date,
                  description: abstract
                }}
                showStartDate={true}
              />
              <Duration endDate={end_date} startDate={start_date} />
            </div>
          </div>
          {abstract && (
            <div className='agenda-card-desc m-0 p-2 text-justify'>
              {parse(abstract)}
            </div>
          )}
        </div>
        <div className='w-100 px-10'>
          <UserCarousel configs={settings}>
            {speakers.map((speaker: any, index: number) => {
              return (
                <UserCard
                  name={`${speaker.first_name} ${speaker.last_name}`}
                  key={index}
                  src={speaker.photo}
                  company={speaker.company}
                  position={speaker.position}
                  className='col-sm-6 mt-5'
                  onClick={() => onClick(speaker)}
                  clickable={isSidePage}
                  initialRate={speaker.is_rate}
                  showRateComponent={showSpeakerRateComponent}
                  rateCallBack={(rate: number) =>
                    rateAction('speaker', rate, speaker.id)
                  }
                />
              );
            })}
          </UserCarousel>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AgendaCard;
