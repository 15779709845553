import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import TextShow from 'components/TextShow/TextShow';
import UserIcon from 'components/userIcon/UserIcon';
import './styles.scss';

const ChatMessage = ({
   isAdminChat,
   className,
   data,
   date,
   isMe,
   isHost,
   sender,
   senderImage,
   variant,
   receiver,
   receiverPartId,
   removeMessage
 }) => {
  const {
    auth: {
      user: id
    }
  } = useSelector(state => state);

  const classes = classNames(
    'd-flex flex-column mt-2 mb-4',
    {
      'align-items-end': isMe,
      'align-items-start': !isMe
    },
    className
  );

  const flexCentered = classNames('d-flex align-items-center', {
    'flex-row-reverse': isMe
  });

  const imgClasses = classNames(
    'symbol symbol-circle symbol-35 mr-3 message-sender-img-wrapper',
    {
      'ml-3': isMe,
      'mr-3': !isMe
    }
  );

  const messageBoxClasses = classNames(
    'font-weight font-size-lg text-left max-w-400px msg-box',
    `bg-light-${variant}`
  );

  const textWrapperClasses = classNames('d-flex mt-2 align-items-center message-content justify-content-end', {
    'flex-row-reverse': !isMe
  });

  const formattedDate = useMemo(() => {
    return moment(new Date(date)).format('HH:mm A');
  }, [date]);

  const messageMargin = classNames('mt-3 mb-3', {
    'text-right': isMe,
    'text-danger': receiverPartId !== 'all' && !isAdminChat
  });

  const receiverName = receiverPartId === id ? ' You' : receiver;
  return (
    <div className={classes}>
      <div className={flexCentered}>
        <UserIcon img={senderImage} className={imgClasses} />
        <div>
          <div className={messageMargin}>
            <b>{isMe ? 'Me' : sender}</b>
            {!receiver || receiver === 'Everyone' ? '' : ' to '}
            {!receiver || receiver === 'Everyone' ? '' : (
              <b> {receiverName}</b>
            )}
          </div>
          <div className={textWrapperClasses}>
            <div className='text-muted font-size-sm text-center message-time p-1 message-content-date'
                 style={{ minWidth: '40px' }}>
              {formattedDate}
            </div>
            {!isAdminChat && isHost && (
              <FontAwesomeIcon
                className='cursor-pointer'
                style={{ width: '24px', height: '14px' }}
                icon={faTrash}
                color='#B5B5C3'
                onClick={removeMessage}
              />
            )}
            <div className={messageBoxClasses}>
              <TextShow text={data} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ChatMessage.propTypes = {
  isAdminChat: PropTypes.bool,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  isMe: PropTypes.bool,
  isHost: PropTypes.bool,
  data: PropTypes.string.isRequired,
  variant: PropTypes.string,
  isMessagePinned: PropTypes.bool,
  onPinClick: PropTypes.func,
  removeMessage: PropTypes.func
};

ChatMessage.defaultProps = {
  isAdminChat: false,
  className: '',
  date: 'Just now',
  isMe: false,
  isHost: false,
  variant: 'secondary',
  data: '',
  onPinClick: () => {
    return;
  },
  removeMessage: () => {
    return;
  }
};

export default React.memo(ChatMessage);
