import React from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './styles.scss'

const UserCarousel = ({ className, configs, children }) => {
  const settings = {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipe: true,
    infinite: false,
    ...configs
  }
  return (
    <Slider className={className} {...settings}>
      {children}
    </Slider>
  )
}

UserCarousel.propTypes = {
  className: PropTypes.string,
  configs: PropTypes.shape({
    dots: PropTypes.bool,
    infinite: PropTypes.bool,
    speed: PropTypes.number,
    slidesToShow: PropTypes.number,
    slidesToScroll: PropTypes.number,
    swipe: PropTypes.bool
  })
}

UserCarousel.defaltProps = {
  className: '',
  configs: PropTypes.shape({
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipe: false,
    infinite: false
  })
}

export default UserCarousel
