import {
  SET_ACTIVE_TAB,
  SET_IS_COMMUNICATION_MODULES_AVAILABLE
} from './constants';

export const setActiveTab = (payload: any) => {
  return {
    type: SET_ACTIVE_TAB,
    payload
  };
};

export const setIsCommunicationModulesAvailable = (payload: any) => {
  return {
    type: SET_IS_COMMUNICATION_MODULES_AVAILABLE,
    payload
  };
};
