import {
  SET_SHOW_CONFIRMATION_MODAL,
  CLOSE_CONFIRMATION_MODAL
} from './constants';

const initialState = {
  text: '',
  callback: undefined,
  show: false
};

export const confirmationModal = (state = { ...initialState }, action: any) => {
  switch (action.type) {
    case SET_SHOW_CONFIRMATION_MODAL: {
      return {
        ...state,
        text: action.payload.text,
        callback: action.payload.callback,
        show: true,
      };
    }

    case CLOSE_CONFIRMATION_MODAL: {
      return initialState;
    }
    default:
      return state;
  }
};
