import React from 'react'
import { Dropdown as RBDropdown, Button } from 'react-bootstrap'

import DropdownCheckbox from '../../molecules/DropdownCheckbox'
import PropTypes from 'prop-types'
import { MoreIcon } from '../../icons/stockholm'
import './styles.scss'

const caret = (
  <svg viewBox='0 0 24 24'>
    <polyline
      fill='none'
      stroke='#fff'
      points='21,8.5 12,17.5 3,8.5 '
      strokeMiterlimit='10'
      strokeWidth='2'
    />
  </svg>
)

const DotsToggle = React.forwardRef(({ children, onClick }, ref) => (
  <span
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    <MoreIcon variant='dark' size='s' />
  </span>
))

const CaretToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    className='dropdown-toggle'
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    {children}
    <span>{caret}</span>
  </Button>
))

const Dropdown = ({ items, dropDownToggleName, showCaret }) => {
  return (
    <RBDropdown className='dropdown mr-5'>
      {/* {showCaret && ( */}
      <RBDropdown.Toggle as={showCaret ? CaretToggle : DotsToggle}>
        {dropDownToggleName}
      </RBDropdown.Toggle>
      {/* // )} */}

      {/* {!showCaret && (
            <RBDropdown.Toggle>
            {dropDownToggleName}
          </RBDropdown.Toggle>
      )} */}
      <RBDropdown.Menu>
        {items.map((item) => (
          <DropdownCheckbox
            key={item.id}
            content={item.content}
            icon={item.icon}
            enabled={item.enabled}
            disabled={item.disabled}
          />
        ))}
      </RBDropdown.Menu>
    </RBDropdown>
  )
}

Dropdown.propTypes = {
  items: PropTypes.array.isRequired,
  dropDownToggleName: PropTypes.any,
  showCaret: PropTypes.bool
}

Dropdown.defaultProps = {
  dropDownToggleName: '',
  showCaret: true
}
export default Dropdown
