// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import Svg, { SvgProps } from '../Svg';

interface DeskPhoneProps extends SvgProps {
  /** Whether or not should show a desk phone icon with strikethrough. */
  disabled?: boolean;
}

const DeskPhone: React.SFC<DeskPhoneProps> = ({
  disabled = false,
  ...rest
}) => (
  <Svg {...rest}>
    {disabled ? (
      <path d='M4.146 4.146c.196-.195.512-.195.707 0l15 15c.196.196.196.512 0 .708-.097.097-.225.146-.353.146-.128 0-.256-.049-.354-.146l-.923-.923c-.119.042-.245.069-.379.069H6.156C5.518 19 5 18.481 5 17.844V7.156c0-.394.211-.726.513-.935L4.146 4.854c-.195-.196-.195-.512 0-.708zM6.293 7h-.137C6.07 7 6 7.07 6 7.156v10.688c0 .086.07.156.156.156h11.137l-1-1H16v-.293l-1.629-1.629h-.37v-.371L12.292 13H12v-.293l-1-1v4.137C11 16.481 10.481 17 9.844 17H8.156C7.518 17 7 16.481 7 15.844V7.707L6.293 7zM13 16v1h-1v-1h1zm2 0v1h-1v-1h1zM9.843 5c.542 0 .979.379 1.103.882L10.97 6h6.875c.595 0 1.086.452 1.15 1.03l.006.126v9.76l-1-1v-8.76c0-.069-.045-.127-.107-.148L17.844 7H11v1h5.171c.422 0 .771.317.823.725L17 8.83v1.342c0 .422-.317.771-.725.823l-.104.006h-3.087l-1-1H16V9h-4.916L10 7.916v-1.76c0-.069-.045-.127-.107-.148L9.843 6H8.156l-.026.008-.025.013-.716-.716c.171-.153.384-.263.621-.295L8.156 5h1.687zM8 8.707v7.137c0 .086.07.156.156.156h1.688c.085 0 .156-.07.156-.156v-5.137l-2-2zm5 5.371v1h-1v-1h1zm4 0v.837l-.838-.837H17zM17 12v1h-1v-1h1zm-2 0v.916L14.084 12H15z' />
    ) : (
      <path d='M9.844 5c.582 0 1.045.44 1.125 1h6.875C18.481 6 19 6.519 19 7.157v10.687c0 .638-.519 1.156-1.156 1.156H6.156C5.519 19 5 18.482 5 17.844V7.157C5 6.519 5.519 6 6.156 6h.875c.08-.56.543-1 1.125-1zM7 7h-.844C6.07 7 6 7.07 6 7.157v10.687c0 .086.07.156.156.156h11.688c.086 0 .156-.07.156-.156V7.157C18 7.07 17.93 7 17.844 7H11v1h5.171c.457 0 .829.372.829.829v1.343c0 .457-.372.828-.829.828H11v4.844C11 16.482 10.481 17 9.844 17H8.156C7.519 17 7 16.482 7 15.844V7zm6 9v1h-1v-1h1zm2 0v1h-1v-1h1zm2 0v1h-1v-1h1zM9.844 6H8.156C8.07 6 8 6.07 8 6.157v9.687c0 .086.07.156.156.156h1.688c.086 0 .156-.07.156-.156V6.157C10 6.07 9.93 6 9.844 6zM13 14.078v1h-1v-1h1zm2 0v1h-1v-1h1zm2 0v1h-1v-1h1zM13 12v1h-1v-1h1zm2 0v1h-1v-1h1zm2 0v1h-1v-1h1zm-1-3h-5v1h5V9z' />
    )}
  </Svg>
);

DeskPhone.displayName = 'DeskPhone';

export default DeskPhone;
