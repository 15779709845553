import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Back, Power1, TweenLite } from 'gsap'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'

import './styles.scss'

const caret = (
  <svg viewBox='0 0 24 24'>
    <polyline
      fill='none'
      points='21,8.5 12,17.5 3,8.5 '
      stroke='#FFF'
      strokeMiterlimit='10'
      strokeWidth='2'
    />
  </svg>
)

const AccordionItem = React.memo(
  ({ content, headerContent, eventKey, selectedKey }) => {
    const isActive = eventKey === selectedKey
    const contentRef = React.useRef()

    React.useEffect(() => {
      if (!isActive) {
        TweenLite.to(contentRef.current, 0.3, {
          height: 0,
          immediateRender: false,
          ease: Power1.easeOut
        })
      } else {
        TweenLite.set(contentRef.current, { height: 'auto' })
        TweenLite.from(contentRef.current, 0.6, {
          height: 0,
          immediateRender: false,
          ease: Back.easeOut
        })
      }
    }, [isActive])
    return (
      <Card className='customCard'>
        <Card.Header className='customAccordion__item__header'>
          <div style={{ marginLeft: '1em' }}>{headerContent}</div>
          <Accordion.Toggle
            as={Button}
            className='customAccordion__item__toggle'
            variant='link'
            eventKey={eventKey}
          >
            <span
              className={classNames('caret', {
                is__active: isActive
              })}
            >
              {caret}
            </span>
          </Accordion.Toggle>
        </Card.Header>
        <div ref={contentRef}>
          <Card.Body>{content}</Card.Body>
        </div>
      </Card>
    )
  }
)
const propTypes = {
  defaultSelected: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object)
}

const defaultProps = {
  defaultSelected: '0',
  items: []
}

const CustomAccordion = ({ defaultSelected, items }) => {
  const [selected, setSelected] = React.useState(defaultSelected)

  return (
    <Accordion
      defaultActiveKey={selected}
      onSelect={setSelected}
      className='customAccordion'
    >
      {items.map((item) => (
        <AccordionItem 
          key={item.eventKey} 
          selectedKey={selected} 
          {...item}
        />
      ))}
    </Accordion>
  )
}

CustomAccordion.propTypes = propTypes
CustomAccordion.defaultProps = defaultProps

// const AccordionExample = () => {
// const [selected, setSelected] = React.useState('0')

// return (
// <Accordion defaultActiveKey={selected} onSelect={setSelected}>
// <AccordionItem
// headerContent='This is an accordion section header'
// content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
// eventKey='0'
// selectedKey={selected}
/// >
// <AccordionItem
// headerContent='Another section header'
// content="Hello, I'm another body"
// eventKey='1'
// selectedKey={selected}
/// >
// </Accordion>
// )
// }

export default CustomAccordion
