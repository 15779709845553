import {
  CLEAR_QA,
  CLEAR_SHOW_QUESTIONS_NOTIFICATION,
  LIKE_QUESTION,
  RECEIVE_ONE_QUESTION,
  RECEIVE_QUESTIONS,
  RECIEVE_COMMENT,
  REMOVE_COMMENT,
  REMOVE_QUESTION,
  SCROLL_TO_LAST_QA,
  SET_TAB_OPEN_LAST_DATE,
} from './constants';
import * as api from 'api';

export const scrollToLastQA = () => {
  return {
    type: SCROLL_TO_LAST_QA,
  };
};

export function recieveQuestion(payload: Object) {
  return {
    type: RECEIVE_ONE_QUESTION,
    payload,
  };
}

export function recieveComment(payload: Object) {
  return {
    type: RECIEVE_COMMENT,
    payload,
  };
}

export function likeQuestion(payload: Object) {
  return {
    type: LIKE_QUESTION,
    payload,
  };
}

export function removeQuestion(payload: Object) {
  return {
    type: REMOVE_QUESTION,
    payload,
  };
}

export function removeComment(payload: Object) {
  return {
    type: REMOVE_COMMENT,
    payload,
  };
}

export function clearQA(): any {
  return {
    type: CLEAR_QA,
  };
}

export const clearShowQuestionsNotification = () => {
  return {
    type: CLEAR_SHOW_QUESTIONS_NOTIFICATION,
  };
};

export const setTabOpenLastDate = (payload: any) => {
  return {
    type: SET_TAB_OPEN_LAST_DATE,
    payload,
  };
};

const setQuestions = (payload: Object, isFirstCall: Boolean, hasMore: Boolean) => {
  return {
    type: RECEIVE_QUESTIONS,
    payload,
    isFirstCall,
    hasMore,
  };
};

export const getQuestions: any = (isFirstCall: boolean, chatId: string, userId: string) => {
  return async (dispatch: any, getState: any) => {
    const {
      questionsAndAnswers: { data: questions = {} },
    } = getState();
    try {
      const count = isFirstCall ? 0 : Object.keys(questions).length;
      const { data: { data, hasMore } = {} } = await api.getQuestions(count, chatId, userId);
      dispatch(setQuestions(data, isFirstCall, hasMore));
    } catch (error: any) {
      console.error(error);
    }
  };
};
