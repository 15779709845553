import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import ROUTES from 'constants/routes';
import InitVonage from 'meeting/vonage/InitVonage';
import { SidebarLayout } from 'layouts/index';
import { Chat } from 'components/Chat/Chat';
import HotJarTracking from 'components/HotJarTracking/HotJarTracking';
import { replaceUrlParams } from 'helpers/replaceUrlParams';
import { useUserIsSessionSpeaker, useIsHost } from 'hooks/useUserRole';
import { getGreenRoomsAction, getSessions } from 'store/sessions/actions';
import { joinVonageMeeting } from 'store/vonage/actions';
import { SET_VIRTUAL_ROOM } from 'store/app/constants';
import { START_SESSION_FROM_GREEN_ROOM } from 'store/sessions/constants';
import { setAudio, setVideo } from 'store/vonage/actions';

export const GreenRoomPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    events: { currentEvent: { event_code = '', customMenu = [], is_host } = {} },
    sessions: { greenRooms = [], sessionStartedFromGreenRoom = null },
  } = useSelector(state => state);
  const userIsSessionSpeaker = useUserIsSessionSpeaker();
  const isHost = useIsHost();
  const hostOrSpeaker = userIsSessionSpeaker || isHost;

  const moveToStagePage = useCallback(() => {
    navigate(replaceUrlParams(ROUTES.STAGE, event_code));
    // eslint-disable-next-line
  }, [event_code]);

  const { greenRoomId } = useParams();
  const { type, room_id } = customMenu.find(({ slug }) => slug === 'stage') || {};

  useEffect(() => {
    if (type === 'room') {
      dispatch(getSessions(event_code, room_id));
    }
    // eslint-disable-next-line
  }, [customMenu, event_code, room_id, type]);

  useEffect(() => {
    event_code && hostOrSpeaker && dispatch(getGreenRoomsAction(event_code, room_id));
    // eslint-disable-next-line
  }, [event_code, room_id]);

  useEffect(() => {
    dispatch(setAudio(false));
    dispatch(setVideo(false));
    // eslint-disable-next-line
  }, [greenRoomId]);

  useEffect(() => {
    if (greenRooms && greenRooms instanceof Array) {
      const currentGreenRoom = greenRooms.find(({ id }) => +greenRoomId === id);

      if (currentGreenRoom) {
        const { virtual_room: { id: greenRoomVirtualRoomId } = {} } = currentGreenRoom || {};

        dispatch(joinVonageMeeting(greenRoomVirtualRoomId, event_code));

        dispatch({
          type: SET_VIRTUAL_ROOM,
          payload: currentGreenRoom.virtual_room,
        });
      } else {
        moveToStagePage();
        dispatch({
          type: SET_VIRTUAL_ROOM,
          payload: '',
        });
      }
    }
    // eslint-disable-next-line
  }, [greenRoomId, greenRooms?.length]);

  useEffect(() => {
    if (sessionStartedFromGreenRoom) {
      setTimeout(() => {
        moveToStagePage();
      }, 2000);
      dispatch({ type: START_SESSION_FROM_GREEN_ROOM, payload: null });
    }
    //eslint-disable-next-line
  }, [sessionStartedFromGreenRoom]);

  return greenRooms?.length ? (
    <>
      <HotJarTracking />
      <SidebarLayout>
        <div className="event-sidebar-container content d-flex flex-column flex-column-fluid p-0">
          <Row className="event-sidebar-container-row h-100 m-0 flex-1">
            <Col md={8} className="d-flex flex-column p-4 h-100">
              <div className="flex-1 overflow-auto h-0">
                <div className="d-flex flex-column h-100">
                  <div className="flex-1 h-0">
                    <InitVonage is_speaker={true} isHost={is_host} />
                  </div>
                </div>
              </div>
            </Col>
            <div className="d-flex flex-column p-0 flex-1 col-md-4">
              <Tabs className="green-room-tabs">
                <Tab
                  eventKey={'green room chat'}
                  title={'Chat'}
                  unmountOnExit={true}
                  className="h-100"
                >
                  <Chat />
                </Tab>
              </Tabs>
            </div>
          </Row>
        </div>
      </SidebarLayout>
    </>
  ) : null;
};
