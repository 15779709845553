import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getMyPoints } from 'store/leaderBoard/actions';

interface Props {
  eventCode: string;
}

export const MyPoints: React.FC<Props> = ({ eventCode = '' }: any) => {
  const dispatch = useDispatch();
  const {
    leaderBoard: {
      myPoints: { rate = '', total_score = '', actions = [] } = []
    }
  } = useSelector((state: AppState) => state);

  const renderActionsTaken = (score: string, type: string, index: number) =>
    !!Number(score) && (
      <li className='my-point-list-item' key={index}>
        <div className='d-flex justify-content-between align-items-center'>
          <span className='col-md-8'>{type}</span>
          <span className='my-points-score col-md-4'>{`+${score}`}</span>
        </div>
      </li>
    );

  useEffect(() => {
    eventCode && dispatch(getMyPoints(eventCode));
    // eslint-disable-next-line
  }, [eventCode]);

  const rateSuffix = useMemo(() => {
    let suffix;
    switch (rate) {
      case 1:
        suffix = 'st';
        break;
      case 2:
        suffix = 'nd';
        break;
      case 3:
        suffix = 'rd';
        break;
      default:
        suffix = 'th';
    }

    return suffix;
  }, [rate]);

  return (
    <div className='my-points-container m-4'>
      <div className='d-flex justify-content-between'>
        <h1 className='col-md-8'>My Rank</h1>
        <h1 className='point-score col-md-4'>{rate + rateSuffix}</h1>
      </div>
      <div className='divider'></div>
      <div className='d-flex justify-content-between'>
        <h1 className='mt-3 col-md-8'>My Points</h1>
        <h1 className='point-score mt-3 col-md-4'>{total_score}</h1>
      </div>
      <div>
        <h1 className='ml-3' style={{ marginTop: '52px' }}>
          Actions Taken
        </h1>
        <ul className='ml-0'>
          {actions.map(({ score = '', type = '' }: any, index: number) =>
            renderActionsTaken(score, type, index)
          )}
        </ul>
      </div>
    </div>
  );
};
