import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsExpectedPage } from 'hooks/usePageLocation';
import ROUTES from 'constants/routes';
import { getLeaders } from 'store/leaderBoard/actions';
import { SET_VIRTUAL_ROOM } from 'store/app/constants';
import { LeaderItem } from './LeaderItem';
import './LeaderBoardstyles.scss';

interface Props {
  eventCode: string;
}

export const LeaderBoard: React.FC<Props> = ({ eventCode }) => {
  const dispatch = useDispatch();
  const {
    leaderBoard: { leaders },
  } = useSelector((state: AppState) => state);
  const isLeaderBoardPage = useIsExpectedPage(ROUTES.LEADERBOARD);

  useEffect(() => {
    eventCode && dispatch(getLeaders(eventCode));
    // eslint-disable-next-line
  }, [eventCode]);

  useEffect(() => {
    if (isLeaderBoardPage) {
      dispatch({
        type: SET_VIRTUAL_ROOM,
        payload: '',
      });
    }
    //  eslint-disable-next-line
  }, [isLeaderBoardPage]);

  const basePoint = leaders[0]?.total_score;
  return (
    <table className="mx-6 mt-4">
      <tbody>
        {leaders.map(({ total_score, company, full_name }: any, index: any) => {
          let colorClassCount = Math.floor(index / 5);
          if (colorClassCount > 11) {
            colorClassCount = 0;
          }
          return (
            <LeaderItem
              key={index}
              now={(total_score / basePoint) * 100}
              place={index + 1}
              company={company}
              name={full_name}
              score={total_score}
              progressVariant={'color-' + colorClassCount}
            />
          );
        })}
      </tbody>
    </table>
  );
};
