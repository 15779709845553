import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-banzai'
import ListGroup from 'react-bootstrap/ListGroup'
import "./styles.scss"
const DraftPoll = ({
  title,
  type,
  options,
  onEditClick,
  onDeleteClick,
  onPublishClick
}) => {
  return (
    <div className ="border-bottom pb-4 poll-item">
      <ListGroup variant='flush' className = "mt-3">
        <div>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='p-3'>
              {title} 
            </div>
          </div>
        </div>
        {options.map((option) => {
          return <ListGroup.Item  className = "mb-2 border border-light" key={option.id}>{option.value}</ListGroup.Item>
        })}
      </ListGroup>
      <div>
      </div>
      <Button variant='primary' className ="ml-3 mw-70px" size = "sm"  onClick={onPublishClick}>
        Publish
      </Button>
      <Button onClick={onEditClick} variant='warning' className ="ml-3 mw-70px" size = "sm">
          Edit
      </Button>
      <Button onClick={onDeleteClick} variant='danger' className ="ml-3 mw-70px" size = "sm">
          Delete
      </Button>
    </div>
  )
}

DraftPoll.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onPublishClick: PropTypes.func
}

DraftPoll.defaultProps = {
  title: '',
  type: '',
  options: [],
  onEditClick: () => {},
  onDeleteClick: () => {},
  onPublishClick: () => {}
}

export default DraftPoll
