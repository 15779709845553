import { Dispatch } from 'redux';
import * as api from 'api/index';
import {
  CHANGE_MEETING_STATUS,
  CLEAR_VONAGE_MEETING,
  SET_ARCHIVE,
  SET_AUDIO,
  SET_DEVICE_ID,
  SET_PUBLISHER,
  SET_SCREEN_SHARING,
  SET_VIDEO,
  SET_VIDEO_SOURCE,
  SET_VONAGE_MEETING_CREDENTIALS,
  SET_VONAGE_SESSION
} from './constants';

export const joinVonageMeeting = (roomId: string, event_code: string): any => {
  return async (dispatch: Dispatch) => {
    try {
      const { data: { data } = {} } = await api.joinToVonageMeeting(
        roomId,
        event_code
      );
      dispatch(setMeetingCredentials(data));
    } catch (err: any) {
      console.error(err);
    }
  };
};

export const startVonageRecording = (event_code: string): any => {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    try {
      const {
        vonage: { sessionId = null } = {},
        socket: { socket = null } = {},
        app: { virtual_room: { chat_id = '' } = {} },
        sessions: { data: sessionData, sessionStartTime = null } = {}
      } = getState();

      const activeSession = sessionData.find(
        ({ status }: any) => status === 'active'
      );
      const {
        data: { data }
      } = await api.startRecord(sessionId, event_code, activeSession.id);
      const { archiveId, archiveName } = data;
      dispatch(setArchive(data));

      const recordStartTime = new Date();

      socket.emit(CHANGE_MEETING_STATUS, chat_id, {
        status: 'started',
        sessionStartTime,
        archiveId,
        archiveName,
        recordStartTime
      });
    } catch (err: any) {
      console.error(err);
    }
  };
};

export const stopVonageRecording = (): any => {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    try {
      const {
        app: { virtual_room: { chat_id = '' } = {} },
        events: { currentEvent: { event_code = '' } = {} },
        vonage: { archive: { archiveId = null } = {} },
        socket: { socket = null },
        sessions: { sessionStartTime = null } = {}
      } = getState();

      if (archiveId) {
        try {
          await api.stopRecord(archiveId, event_code);
        } catch (err: any) {
          console.error('recording stop err', err);
        }
        socket.emit(CHANGE_MEETING_STATUS, chat_id, {
          status: 'started',
          archiveId: null,
          archiveName: null,
          recordStartTime: null,
          sessionStartTime
        });
      }
    } catch (err: any) {
      console.error(err);
    }
  };
};

export const clearVonageMeeting = () => ({
  type: CLEAR_VONAGE_MEETING
});

export const setVonageSession = (payload: Object) => ({
  type: SET_VONAGE_SESSION,
  payload
});

export const setAudio = (payload: boolean) => ({
  type: SET_AUDIO,
  payload
});

export const setVideo = (payload: boolean) => ({
  type: SET_VIDEO,
  payload
});

export const setVideoSource = (payload: string) => ({
  type: SET_VIDEO_SOURCE,
  payload
});

export const setLocaleScreenSharing = (payload: boolean) => ({
  type: SET_SCREEN_SHARING,
  payload
});

export const setArchive = (payload: Object) => ({
  type: SET_ARCHIVE,
  payload
});

export const setPublisher = (payload: any) => ({
  type: SET_PUBLISHER,
  payload
});

export const setMeetingCredentials = (payload: any) => ({
  type: SET_VONAGE_MEETING_CREDENTIALS,
  payload
});

export const setDeviceId = (payload: string) => ({
  type: SET_DEVICE_ID,
  payload
});
