import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactJWPlayer from 'react-jw-player';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeMute } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { REACT_APP_JW_PLAYER_SCRIPT } from 'constants/index';
import { setShowMuteButton } from 'store/sessions/actions';
import { getTime } from 'components/Header/SessionTiming/SessionTiming';
import OnDemandProvider from './OnDemandProvider';
import './index.scss';

const PlayStreaming = ({
  url,
  session,
  setForceRerender,
  renderCount,
  setRenderCount,
  options = {},
}: any) => {
  const [videoRender, setVideoRender] = useState<any>('');
  const dispatch = useDispatch();
  const [hasError, setHasError] = useState('');
  const {
    sessions: { sessionStartTime, showMuteButton },
  } = useSelector((state: AppState) => state);

  const optimalRenderCount = 4;
  const currentWindow: any = window;
  const { seconds = 0, minutes = 0, hours = 0 }: any = getTime(sessionStartTime) || {};
  const startSeconds = Number(seconds) + Number(minutes) * 60 + Number(hours) * 3600;
  const { type: sessionType = '' } = session?.virtual_room_session || {};
  const video = document.querySelector('video');

  useEffect(() => {
    const video = document.querySelector('video');
    if (video && sessionStartTime) {
      video.addEventListener('loadedmetadata', () => {
        video.currentTime = startSeconds;
      });
    }
    // eslint-disable-next-line
  }, [videoRender]);

  const renderPlayerOnError = () => {
    if (renderCount < optimalRenderCount) {
      setTimeout(() => setRenderCount(renderCount + 1), 10000);
    } else {
      return;
    }
  };

  const onPlayerReady = () => {
    setVideoRender('ready');
    if (video && sessionStartTime) {
      video.addEventListener('loadedmetadata', () => {
        video.currentTime = startSeconds;
      });
    }
    const showError = sessionType === 'simulive';
    if (currentWindow.interacted) {
      if (video) video.muted = false;
    } else if (showError) {
      toast.info('Please unmute the video to hear the sound');
      dispatch(setShowMuteButton(true));
    }
  };

  const handleUnMute = () => {
    video!.muted = false;
    dispatch(setShowMuteButton(false));
  };

  const onPlayerError = (error: any) => {
    setHasError(error.code);
    renderPlayerOnError();
    const errorText = document.querySelector('.jw-error-text');
    errorText &&
      (errorText.innerHTML = 'Sorry, the session seems not started yet or already completed.');
  };

  useEffect(() => {
    if (video) {
      video.addEventListener('loadedmetadata', () => {
        video.currentTime = startSeconds;
      });
    }
    // eslint-disable-next-line
  }, [video]);

  useEffect(() => {
    return () => {
      dispatch(setShowMuteButton(false));
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (hasError && renderCount !== 0 && renderCount < optimalRenderCount) {
      setForceRerender(Date.now());
    }

    // eslint-disable-next-line
  }, [renderCount, hasError]);

  const renderView = () => {
    if ((sessionType === 'rtmp' || sessionType === 'simulive') && url) {
      return (
        <ReactJWPlayer
          playerId={'jwp-player'}
          playerScript={REACT_APP_JW_PLAYER_SCRIPT}
          file={url}
          onSetupError={onPlayerError}
          onError={onPlayerError}
          isAutoPlay={true}
          onReady={onPlayerReady}
          {...options}
        />
      );
    } else if (sessionType === 'on_demand') {
      return <OnDemandProvider url={url} />;
    }
  };

  return (
    <div className="player-content">
      <div className="position-relative h-100">
        {showMuteButton && (
          <button className="player-content-unmute" onClick={handleUnMute}>
            <FontAwesomeIcon icon={faVolumeMute as IconProp} size="lg" />
          </button>
        )}
        {renderView()}
      </div>
    </div>
  );
};

export default PlayStreaming;
