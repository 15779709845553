export const QUESTION_EVENTS = {
  NEW_QUESTION: 'new_question',
  NEW_COMMENT: 'new_comment',
  QUESTION_lIKE: 'question_like',
  QUESTION_REMOVE: 'question_remove',
  COMMENT_REMOVE: 'comment_remove',
  LAST_QUESTION_POLL_DATE: 'last_question_poll_date',
};

export const RECEIVE_ONE_QUESTION = 'RECEIVE_ONE_QUESTION';
export const RECEIVE_QUESTIONS = 'RECEIVE_QUESTIONS';
export const RECIEVE_COMMENT = 'RECIEVE_COMMENT';
export const LIKE_QUESTION = 'LIKE_QUESTION';
export const REMOVE_QUESTION = 'REMOVE_QUESTION';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const CLEAR_QA = 'clear_qa';
export const CLEAR_SHOW_QUESTIONS_NOTIFICATION = 'CLEAR_SHOW_QUESTIONS_NOTIFICATION';
export const SET_SHOW_QUESTIONS_NOTIFICATION = 'SET_SHOW_QUESTIONS_NOTIFICATION';
export const SET_TAB_OPEN_LAST_DATE = 'SET_TAB_OPEN_LAST_DATE';
export const SCROLL_TO_LAST_QA = 'SCROLL_TO_LAST_QA';
