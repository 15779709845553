import { getNextSession, getActiveSession } from './getSession';

export const getUserIsSessionSpeaker = (sessions: any, userEmail: string) => {
  const nextSession = getNextSession(sessions);
  const activeSession = getActiveSession(sessions);

  const session = activeSession || nextSession || {};
  return session?.speakers?.find(({ email }: any) => email === userEmail);
};

export const getUserIsCurrentEventSpeaker = (
  speakers: Array<any>,
  userEmail: string
) => {
  return speakers?.find(({ email }: any) => email === userEmail);
};

export const getIsHost = (
  isCurrentOrganizationHost: boolean,
  isEventHost: boolean
) => {
  return isCurrentOrganizationHost || isEventHost;
};