import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import OT from '@opentok/client';
import { REACT_APP_VONAGE_API_KEY } from 'constants/index';
import { useNextSession, useActiveSession } from 'hooks/useSessionStatus';
import { joinVonageMeeting } from 'store/vonage/actions';
import VonageMeeting from './index';

const InitVonage = (props: any) => {
  const dispatch = useDispatch();

  const {
    sessions: { data: sessionsData },
    vonage: { sessionId },
    socket: { socket },
    events: { currentEvent: { event_code = '' } = {} },
  } = useSelector((state: AppState) => state);

  const [loading, setLoading] = useState(true);
  const { greenRoomId }: any = useParams();
  const nextSession = useNextSession();
  const activeSession = useActiveSession();

  const vonageSession = useMemo(
    () => sessionId && OT.initSession(REACT_APP_VONAGE_API_KEY, sessionId),
    // eslint-disable-next-line
    [sessionId]
  );

  const { virtual_room_session: { room_id = 0, type = '' } = {} } = useMemo(() => {
    return activeSession || nextSession || {};
    // eslint-disable-next-line
  }, [sessionsData]);

  useEffect(() => {
    const isVonage = type === 'live';
    if (room_id && event_code && !greenRoomId && isVonage) {
      dispatch(joinVonageMeeting(room_id, event_code));
    }
    // eslint-disable-next-line
  }, [room_id]);

  return (
    <VonageMeeting
      {...props}
      session={vonageSession}
      socket={socket}
      loading={loading}
      setLoading={setLoading}
    />
  );
};

export default memo(InitVonage);
