import React from 'react';
import {
  Participants as ListComponent,
  Button,
  Dropdown,
  CheckIcon,
  SearchBar
} from 'react-banzai';
import classNames from 'classnames';
// TODO: Use or remove it.
// import PropTypes from 'prop-types'

const moreItems = [
  {
    id: 1,
    content: 'Allow participants to unmute themselves',
    icon: <CheckIcon />
  },
  {
    id: 2,
    content: 'Allow participants to turn on videos',
    icon: <CheckIcon />
  },
  {
    id: 3,
    content: ' Allow participants to share screens',
    icon: <CheckIcon />
  },
  {
    id: 4,
    content: ' Mute Participants Upon entry',
    icon: <CheckIcon />
  }
];

interface Props {
  items?: any[];
  className?: string;
}

const defaultProps: Props = {
  items: [],
  className: ''
};

export const ParticipantsInBooths: React.FC<Props> = ({ items, className }) => {
  const _className = classNames('mt-5', className);
  const buttonBar = (
    <div
      className='d-flex justify-content-center w-100 p-2'
      style={{ position: 'absolute', bottom: '1em' }}
    >
      <Button content='Mute All ' className='mr-5' />
      <Button content='Recording' className='mr-5' />
      <Dropdown items={moreItems} dropDownToggleName='More' />
    </div>
  );

  return (
    <div className={_className}>
      <SearchBar variant='dark' />
      <ListComponent items={items} />
      {buttonBar}
    </div>
  );
};

ParticipantsInBooths.defaultProps = defaultProps;
