import React from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
  children: any;
}

//FixMe: Make sure we really need too much d-flex on start page
export const FullLayout: React.FC<Props> = ({ className, children }) => {
  const classes = classNames('d-flex flex-column flex-root', className);
  return (
    <div className={classes}>
      <div className='d-flex flex-row flex-column-fluid page'>
        <div className='d-flex flex-column flex-row-fluid wrapper full-layout-wrapper'>
          {children}
        </div>
      </div>
    </div>
  );
};
