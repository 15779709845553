import {
  SET_THEME,
  SET_MEETING,
  SET_ATTENDEE,
  CLEAR_MEETING
} from './constants';

const initialState = {
  theme: 'light',
  attendee: {},
  meeting: {}
};

export function chime(state = { ...initialState }, action: any) {
  switch (action.type) {
    case SET_THEME: {
      return {
        ...state,
        theme: action.payload
      };
    }
    case SET_ATTENDEE: {
      return {
        ...state,
        attendee: action.payload
      };
    }
    case SET_MEETING: {
      return {
        ...state,
        meeting: action.payload
      };
    }
    case CLEAR_MEETING: {
      return initialState;
    }
    default:
      return state;
  }
}
