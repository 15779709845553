import React from 'react'
// Tatevik , do not worry about this warning, we'll remove it from Eslint
import { default as RBButton } from 'react-bootstrap/Button'

const Button = ({
  children,
  variant,
  content = null,
  outline = false,
  ...restProps
}) => {
  const _content = content || children
  const _variant = outline ? `outline-${variant}` : variant

  return (
    <RBButton variant={_variant} {...restProps}>
      {_content}
    </RBButton>
  )
}

export default Button
