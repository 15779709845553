import React from 'react';
import { EventCardInList } from 'react-banzai';
import { Tabs, Tab, Spinner } from 'react-bootstrap';

interface Props {
  user: User;
  upcomingEventsLoading: boolean;
  pastEventsLoading: boolean;
  upcomingEventsData: Event[];
  pastEventsData: Event[];
  handleTabChange: (key: string | null) => void;
}

interface EventCardProps {
  loading: boolean;
  events: Event[];
}

const TabsContent: React.FC<Props> = ({
  user,
  upcomingEventsLoading,
  pastEventsLoading,
  upcomingEventsData,
  pastEventsData,
  handleTabChange
}) => {
  const sortedUpcomingEvents = upcomingEventsData.sort(
    (a: any, b: any) =>
      new Date(a.start_date).valueOf() - new Date(b.start_date).valueOf()
  );
  const sortedPastEvents = pastEventsData.sort(
    (a: any, b: any) =>
      new Date(b.start_date).valueOf() - new Date(a.start_date).valueOf()
  );

  return (
    <div className='profile-tabs'>
      <Tabs
        defaultActiveKey='upcoming-events'
        id='uncontrolled-tab-example'
        onSelect={handleTabChange}
      >
        <Tab
          eventKey='upcoming-events'
          title='Upcoming Events'
          className='events-tab'
        >
          <EventCard
            loading={upcomingEventsLoading}
            events={sortedUpcomingEvents}
          />
        </Tab>
        <Tab eventKey='past-events' title='Past Events' className='events-tab'>
          <EventCard loading={pastEventsLoading} events={sortedPastEvents} />
        </Tab>
      </Tabs>
    </div>
  );
};

const EventCard: React.FC<EventCardProps> = ({ loading, events }) => {
  return (
    <>
      {loading ? (
        <div className='w-100 h-100 d-flex justify-content-center align-items-center py-8'>
          <Spinner animation='border' className='m-auto'></Spinner>
        </div>
      ) : (
        <div className='flex-wrap d-flex'>
          {events.map((event: Event) => (
            <EventCardInList key={event.event_code} event={event} />
          ))}
        </div>
      )}
    </>
  );
};

export default TabsContent;
