import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { clearCurrentEvent } from 'store/events/actions';
import { FullLayout } from 'layouts/FullLayout';
import { SidebarLayout } from 'layouts/SidebarLayout';
import TabsContent from './TabsContent';
import { getUpcomingEvents, getPastEvents } from 'store/events/actions';
import HotJarTracking from 'components/HotJarTracking/HotJarTracking';
import './MyEventsPage.scss';

export const MyEventsPage: React.FC = () => {
  const dispatch = useDispatch();

  const {
    events: {
      myUpcomingEvents: { data: upcomingEventsData = [], loading: upcomingEventsLoading },
      myPastEvents: { data: pastEventsData = [], loading: pastEventsLoading },
    },
    auth: { user = {} },
  } = useSelector((state: AppState) => state);

  useEffect(() => {
    dispatch(getUpcomingEvents());
    dispatch(clearCurrentEvent());
  }, [dispatch]);

  const handleTabChange = (key: string | null) => {
    if (key === 'upcoming-events' && _.isEmpty(upcomingEventsData)) {
      dispatch(getUpcomingEvents());
    } else if (key === 'past-events' && _.isEmpty(pastEventsData)) {
      dispatch(getPastEvents());
    }
  };

  return (
    <>
      <HotJarTracking />
      <SidebarLayout>
        <FullLayout>
          <TabsContent
            user={user}
            upcomingEventsLoading={upcomingEventsLoading}
            pastEventsLoading={pastEventsLoading}
            upcomingEventsData={upcomingEventsData}
            pastEventsData={pastEventsData}
            handleTabChange={handleTabChange}
          />
        </FullLayout>
      </SidebarLayout>
    </>
  );
};
