import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikValues } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner, Alert } from 'react-bootstrap';
import * as yup from 'yup';
import { FormField } from 'components/index';
import { validationMessages } from 'constants/validationMessages';
import { verifyAction } from 'store/auth/actions';
import { isEmpty } from 'utils/checkTypes';

const verificationValidation = yup.object().shape({
  username: yup.string().email(validationMessages.email).required(validationMessages.required),
  code: yup.string().required(validationMessages.required),
});

const initialValues = {
  username: '',
  code: '',
};

interface Props {
  title?: string;
  successMessage?: string;
  parseUrl?: boolean;
}

export const VerificationForm: React.FC<Props> = ({
  title = 'Enter verification code',
  successMessage = "We'are trying to login. Please wait",
  parseUrl = false,
}) => {
  const [formData, setFormData] = useState(initialValues);
  const dispatch = useDispatch();
  const { loggingIn, signedIn, verifyError, user, verifying, emailForSignup } = useSelector(
    (state: AppState) => state.auth
  );

  const onSubmit = ({ code, username }: FormikValues) => {
    dispatch(verifyAction({ code, email: username }));
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    const { username } = user;
    setFormData(prevState => {
      return { ...prevState, username };
    });
  }, [user]);

  useEffect(() => {
    if (parseUrl) {
      setFormData(prevState => {
        return { ...prevState, username: emailForSignup || '' };
      });
    }
    // eslint-disable-next-line
  }, [parseUrl]);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      onSubmit={onSubmit}
      validationSchema={verificationValidation}
    >
      {({ values, errors }) => {
        const disabledButton = !Object.values(values).every(Boolean);
        return (
          <Form>
            {title && <h4 className="my-4 text-center">{title}</h4>}
            {verifyError && <Alert variant="danger">{verifyError}</Alert>}
            {signedIn && !verifyError && !!successMessage && (
              <Alert variant="success">{successMessage}</Alert>
            )}
            <div style={{ display: parseUrl ? 'block' : 'none' }}>
              <FormField type="text" name="username" placeholder="E-mail" />
            </div>
            <FormField type="text" name="code" placeholder="Verification code" />
            <Button block disabled={disabledButton || !isEmpty(errors)} type="submit">
              {loggingIn || verifying ? (
                <Spinner animation="border" role="status" aria-hidden="true" />
              ) : (
                'Verify'
              )}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
