import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Duration from 'components/Duration/Duration';
import { clearCurrentEvent } from 'store/events/actions';
import AddToCalendarShow from 'components/AddToCalendarShow/AddToCalendarShow';
import TextShow from 'components/TextShow/TextShow';

import './styles.scss';

const EventCardInList = ({ event }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlerUrlValid = event => {
    const urlValid =
      event.registration_url?.startsWith('http:') || event.registration_url?.startsWith('https:');
    if (event.sort === 'banzai' && urlValid === true) {
      return 'banzai-true';
    } else if (event.sort === 'banzai' && urlValid === false) {
      return 'banzai-false';
    } else {
      return 'highattendance';
    }
  };

  const handleRedirect = useCallback(event => {
    const url = handlerUrlValid(event);

    if (url === 'banzai-true') {
      window.open(`${event?.registration_url}`);
    } else if (url === 'highattendance') {
      navigate(`/event/${event.event_code}`);
    }
    dispatch(clearCurrentEvent());
    // eslint-disable-next-line
  }, []);

  const pointer = handlerUrlValid(event);

  return (
    <Col className="px-0 pb-4 eventCard">
      <Card
        className={classNames(
          `card-custom gutter-b m-3 h-100 ${pointer === 'banzai-false' ? '' : 'cursor-pointer'}`
        )}
        onClick={() => handleRedirect(event)}
      >
        {event.image && (
          <img
            alt={event.name}
            src={event.image}
            className={`imgCover ${pointer === 'banzai-false' ? 'cursor-none' : 'cursor-pointer'}`}
          />
        )}
        <div className={`p-2 ${!event.image && 'mt-7'}`}>
          <div className="d-flex align-items-center">
            <h3 className="w-100 overflow-hidden text-nowrap my-2 eventName">{event.name}</h3>
            <b className="text-white py-1 px-3 rounded bg-primary">Attend</b>
          </div>

          <div className="d-flex align-items-center pb-2 fontSizeMd">
            <div className="text-secondary mr-3">
              <AddToCalendarShow event={event} showStartDate={true} />
              <Duration startDate={event.start_date} endDate={event.end_date} />
            </div>
          </div>
          <div className="mb-3 clampe fontSizeMd">
            {event.description && (
              <TextShow text={event.description} textShowCount={200} show="See" />
            )}
          </div>
        </div>
      </Card>
    </Col>
  );
};

EventCardInList.defaultProps = {
  event: {},
};

EventCardInList.propTypes = {
  event: PropTypes.object.isRequired,
};

export default memo(EventCardInList);
