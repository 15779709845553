import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from "react-router-dom";
import { toast } from 'react-toastify';
import routes from 'constants/routes';

const eventPageHOC = (Component: any) => {
  return (props: object) => {
    const { oneEventMessage } = useSelector((state: AppState) => state.events);

    oneEventMessage && toast.error('Event not found');

    return !oneEventMessage ? (
      <Component {...props} />
    ) : (
      <Navigate replace to={routes.EVENTS} />
    );
  };
};

export default eventPageHOC;
