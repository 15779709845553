import React, { FC, memo, useState, useEffect } from 'react';
import parse from 'html-react-parser';
import './TextShow.scss';
import { addProtocol } from 'helpers';

interface IProps {
  text: string;
  textShowCount?: number;
  className?: string;
  show?: string;
  hideLink?: boolean;
}

const TextShow: FC<IProps> = ({
  text,
  textShowCount,
  className,
  show = 'Show',
  hideLink
}) => {
  const isLargeText = textShowCount && text.trim().length > textShowCount;
  const [showAll, setShowState] = useState(false);
  const [newText, setNewText] = useState<any>(text);

  useEffect(() => {
    if (isLargeText) {
      const wordIndex = text.indexOf(' ', textShowCount);
      const slicedText: any = text.slice(0, wordIndex);
      setNewText(slicedText);
    }
    // eslint-disable-next-line
  }, []);

  const changeTextSize = (e: any) => {
    e.stopPropagation();
    if (!textShowCount) return;

    let slicedText: any;

    if (showAll) {
      const wordIndex = text.indexOf(' ', textShowCount);
      slicedText = text.slice(0, wordIndex);
    } else {
      slicedText = text;
    }

    setNewText(slicedText);
    setShowState(!showAll);
  };

  const linkifyWithRegex = (input: any) => {
    let html = input;
    let regx = new RegExp(
      '(?!<a[^>]*>[^<])(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})(?![^<]*<\\/a>)',
      'mg'
    );
    html = html.replace(regx, function (match: any) {
      return `<a href=${addProtocol(
        match
      )} target="_blank" rel="noopener noreferrer">
        ${match}
        </a>`;
    });
    return html;
  };

  const chatMessageGenerator = () => {
    let replacedText: any = linkifyWithRegex(newText);

    const regExForMentioning = /\B@\w+/g;
    const regexForAnchorTag = /<a[^>]*>([^<]+)<\/a>/;

    if (
      !!regExForMentioning.test(newText) ||
      !!regexForAnchorTag.test(replacedText)
    ) {
      return parse(replacedText);
    }

    return replacedText;
  };

  return (
    <div className='text-show'>
      <div className={`text-show-box ${className || ''}`}>
        {chatMessageGenerator()}
        {textShowCount && !hideLink && isLargeText && (
          <span
            className='text-primary cursor-pointer see-more-button pl-1 text-right m-0'
            onClick={changeTextSize}
          >
            {show} {showAll ? ' less' : ' more'}
          </span>
        )}
      </div>
    </div>
  );
};

export default memo(TextShow);
