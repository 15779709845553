import io from 'socket.io-client';
import { Dispatch } from 'redux';
import { getIsHost, getUserIsSessionSpeaker } from 'helpers/getUserRole';
import { disconnectSocket, setSocket } from '../socket/actions';
import {
  clearAdminChat,
  clearChat,
  clearPublicChat,
  pinMessage,
  receiveAdminMessage,
  receiveMessage,
  removeMessage,
} from '../chat/actions';
import {
  archiveQuestion,
  clearPoll,
  deletePoll,
  editPoll,
  recieveNewPoll,
  recieveNewVote,
  setLastPollDate,
} from '../poll/actions';
import { clearParticipantsList, updateParticipantsList } from '../participants/actions';
import {
  getGreenRoomsAction,
  setSessionStartTime,
  setShowMuteButton,
  updateSessions,
} from '../sessions/actions';
import { REACT_APP_SOCKET_URL } from 'constants/index';
import { PARTICIPANTS_EVENTS } from '../participants/constants';
import { CHAT_EVENTS } from '../chat/constants';
import { POLL_EVENTS } from '../poll/constants';
import { CLEAR_QA, QUESTION_EVENTS } from '../questionsAndAnswers/constants';
import {
  CHANGE_SESSIONS_BUTTONS_STATE,
  SESSION_UPDATE,
  START_SESSION_FROM_GREEN_ROOM,
} from '../sessions/constants';
import {
  clearQA,
  likeQuestion,
  recieveComment,
  recieveQuestion,
  removeComment,
  removeQuestion,
  scrollToLastQA,
  setTabOpenLastDate,
} from '../questionsAndAnswers/actions';
import { toast } from 'react-toastify';
import { clearVonageMeeting, setArchive, setAudio, setVideo } from '../vonage/actions';
import { CHANGE_MEETING_STATUS, TURN_OFF_MIC_AND_CAMERA } from '../vonage/constants';
import { AuthToken, getBrowser, getDeviceType, getMenuItem, getOS } from 'helpers';
import * as api from 'api';
import {
  DISABLE_BUTTONS,
  ENABLE_BUTTONS,
  ERROR_MESSAGE,
  GREEN_ROOM_END,
  HIDE_MUTE_BUTTON,
} from './constants';

const socketUrl: string = REACT_APP_SOCKET_URL || '';
const ACCESS_TOKEN = AuthToken.get('access_token');

const chatSocketTransports = ['websocket'];

async function createSocket(query: Object) {
  return io(socketUrl, {
    query,
    transports: chatSocketTransports,
  });
}

const showNotification = ({
  sendNotification,
  text,
  mentions,
  userId,
  first_name,
  last_name,
}: any) => {
  return (
    sendNotification &&
    text.includes(`@${first_name} ${last_name}`) &&
    mentions.indexOf(userId) > -1
  );
};

let socket: any = null;

export default function connectToSocket(
  extUserId: any,
  chat_id: string = '',
  isStagePage: boolean = false,
  deviceId: string = ''
) {
  return async (dispatch: any, getState: () => AppState) => {
    let {
      auth: {
        user: { first_name, last_name, email, image, id: userId, company },
        currentLocation: location,
      },
      events: {
        currentEvent: { is_host, event_code, customMenu: currentEventCustomMenu },
      },
      sponsors: {
        currentOrganization: { id: currentOrganizationId, is_host: isOrganizationHost },
      },
    } = getState();

    if (!chat_id) {
      return toast.error('Settings are not given appropriately. Please contact with event manager');
    }

    const isHost = isOrganizationHost || is_host;

    socket = await createSocket({
      chatId: chat_id,
      externalUserId: extUserId,
      eventId: event_code,
      token: ACCESS_TOKEN || '',
      isHost,
      email,
      name: first_name + ' ' + last_name,
      userImage: image,
      device: deviceId,
      company: company || '',
      browser: getBrowser(),
      OS: getOS(),
      deviceType: getDeviceType(),
      location,
      isStagePage,
    });

    dispatch(setSocket(socket, 'socket'));

    socket.on(CHAT_EVENTS.CHAT_MESSAGE, function (data: any) {
      const { sendNotification, data: text, mentions } = data;
      if (
        showNotification({
          sendNotification,
          text,
          mentions,
          userId,
          first_name,
          last_name,
        })
      )
        toast.info('You have been mentioned in chat');
      dispatch(receiveMessage(data));
    });

    socket.on(CHAT_EVENTS.CHAT_ADMIN_MESSAGE, function (data: any) {
      let {
        chat: { activeChat },
        events: {
          currentEvent: { is_host: isEventHost },
        },
        sponsors: { currentOrganizationData: { is_host: isCurrentOrganizationHost = '' } = {} },
        sessions: { data: sessions = [] },
      } = getState();

      const userIsSpeaker = getUserIsSessionSpeaker(sessions, email);
      const isHost = getIsHost(isCurrentOrganizationHost, isEventHost);

      const isHostOrSpeaker = isHost || !!userIsSpeaker;

      if (data.senderPartId !== userId && activeChat !== 'admin' && isHostOrSpeaker) {
        toast.info('New message in the Admin chat');
      }
      dispatch(receiveAdminMessage(data));
    });

    socket.on(CHAT_EVENTS.CLEAR_PUBLIC_CHAT, () => {
      dispatch(clearPublicChat());
    });

    socket.on(CHAT_EVENTS.CLEAR_ADMIN_CHAT, () => {
      dispatch(clearAdminChat());
    });

    socket.on(CLEAR_QA, () => {
      dispatch(clearQA());
    });

    socket.on(CHAT_EVENTS.MESSAGE_PIN, function (data: any) {
      dispatch(pinMessage(data));
    });

    socket.on(CHAT_EVENTS.MESSAGE_REMOVE, function (data: any) {
      dispatch(removeMessage(data));
    });

    socket.on(POLL_EVENTS.NEW_POLL, function (data: any) {
      dispatch(recieveNewPoll(data));
    });

    socket.on(POLL_EVENTS.EDIT_POLL, function (data: any) {
      dispatch(editPoll(data, userId));
    });

    socket.on(POLL_EVENTS.NEW_VOTE, function (data: any) {
      dispatch(recieveNewVote(data));
    });

    socket.on(POLL_EVENTS.DELETE_POLL, function (data: any) {
      dispatch(deletePoll(data));
    });

    socket.on(POLL_EVENTS.QUESTION_ARCHIVE, function (data: any) {
      dispatch(archiveQuestion(data));
    });

    socket.on(ERROR_MESSAGE, function ({ message }: any) {
      toast.error(message);
    });

    socket.on(CHAT_EVENTS.DISCONNECT, () => {
      dispatch(clearChat());
      dispatch(clearPoll());
      dispatch(clearQA());
      dispatch(clearParticipantsList());
    });

    socket.on(PARTICIPANTS_EVENTS.UPDATE_ROSTER, (data: any) => {
      const { participants: { data: participantData = {} } = {} } = getState();
      const updateData = { ...participantData, ...data };
      dispatch(updateParticipantsList(updateData));
    });

    socket.on(PARTICIPANTS_EVENTS.GET_PARTICIPANTS, (data: any) => {
      dispatch(updateParticipantsList(data));
    });

    socket.on(PARTICIPANTS_EVENTS.PARTICIPANT_DISCONNECT, (data: any) => {
      const { participants: { data: participantData = {} } = {} } = getState();
      const updateData = { ...participantData };
      data?.length &&
        data.forEach((id: string) => {
          delete updateData[id];
        });
      dispatch(updateParticipantsList(updateData));
    });

    socket.on(QUESTION_EVENTS.NEW_QUESTION, function (data: any) {
      const { socket: { eventSocketId } = {} } = getState();
      const { sendNotification, questionData: text, mentions, questionSenderPartId } = data;

      if (
        showNotification({
          sendNotification,
          text,
          mentions,
          userId,
          first_name,
          last_name,
        })
      )
        toast.info('You have been mentioned in question');
      // Track creating questions, check question's isNew prop
      // is true and creator's data to send query only once
      const currentLocation = window?.location?.pathname.toString();

      const menuItem = getMenuItem(currentLocation, currentEventCustomMenu);
      const trackQA = menuItem && data.questionSenderPartId === userId && data.isNew;
      if (trackQA) {
        api.communicationModulesTrack({
          event_code,
          customMenuId: menuItem.id,
          submitted: 'Q&A',
          question_id: data.questionId,
          sponsor_id: currentOrganizationId,
          socketId: eventSocketId,
          userId,
          location,
        });
      }
      dispatch(recieveQuestion(data));
      if (userId === questionSenderPartId) {
        dispatch(scrollToLastQA());
      }
    });

    socket.on(QUESTION_EVENTS.LAST_QUESTION_POLL_DATE, (data: any) => {
      dispatch(setTabOpenLastDate(data.questionDate));
      dispatch(setLastPollDate(data.pollDate));
    });

    socket.on(QUESTION_EVENTS.NEW_COMMENT, function (data: any) {
      const { sendNotification, commentData: text, mentions } = data;
      if (
        showNotification({
          sendNotification,
          text,
          mentions,
          userId,
          first_name,
          last_name,
        })
      ) {
        toast.info('You have been mentioned in comment');
      }
      dispatch(recieveComment(data));
    });

    socket.on(QUESTION_EVENTS.QUESTION_lIKE, function (data: any) {
      dispatch(likeQuestion(data));
    });

    socket.on(QUESTION_EVENTS.QUESTION_REMOVE, function (data: any) {
      dispatch(removeQuestion(data));
    });

    socket.on(QUESTION_EVENTS.COMMENT_REMOVE, function (data: any) {
      dispatch(removeComment(data));
    });

    socket.on(SESSION_UPDATE, function (data: any) {
      let {
        auth: { user: { email: userEmail } } = {},
        events: {
          currentEvent: { is_host: isEventHost },
        },
        sponsors: { currentOrganizationData: { is_host: isCurrentOrganizationHost = '' } = {} },
        sessions: { data: sessionsData = [] },
      } = getState();

      const { sessions, roomId } = data;

      const userIsSpeaker = getUserIsSessionSpeaker(sessionsData, userEmail);
      const isHost = getIsHost(isCurrentOrganizationHost, isEventHost);
      const isHostOrSpeaker = isHost || !!userIsSpeaker;

      dispatch(clearVonageMeeting());
      dispatch(updateSessions(sessions, roomId));
      isHostOrSpeaker && dispatch(getGreenRoomsAction(event_code, String(data.roomId)) as any);
    });

    socket.on(TURN_OFF_MIC_AND_CAMERA, () => {
      dispatch(setAudio(false));
      dispatch(setVideo(false));
    });

    socket.on(CHANGE_MEETING_STATUS, function (data: any) {
      const {
        status,
        archiveId,
        archiveName,
        recordStartTime,
        sessionStartTime,
        sessions,
        roomId,
      } = data;

      if (sessions && roomId) {
        dispatch(updateSessions(sessions, roomId));
        dispatch(getGreenRoomsAction(event_code, String(data.roomId)) as any);
        dispatch(clearVonageMeeting());
      }

      if (status === 'started') {
        dispatch(
          setArchive({
            archiveId,
            archiveName,
            recordStartTime,
          })
        );
        dispatch(setSessionStartTime(sessionStartTime));
      } else {
        dispatch(
          setArchive({
            archiveId: null,
            archiveName: null,
            recordStartTime: null,
          })
        );
        dispatch(setSessionStartTime(null));
      }
    });

    socket.on(HIDE_MUTE_BUTTON, () => {
      dispatch(setShowMuteButton(false));
    });

    socket.on(GREEN_ROOM_END, ({ data }: any) => {
      dispatch({ type: START_SESSION_FROM_GREEN_ROOM, payload: data });
    });

    socket.on(DISABLE_BUTTONS, () => {
      dispatch({ type: CHANGE_SESSIONS_BUTTONS_STATE, payload: true });
    });

    socket.on(ENABLE_BUTTONS, () => {
      setTimeout(() => {
        dispatch({ type: CHANGE_SESSIONS_BUTTONS_STATE, payload: false });
      }, 3000);
    });
  };
}

export function disconnectFromSocket() {
  return async function (dispatch: Dispatch, getState: () => AppState) {
    const { socket: socketData } = getState();
    const socketInstance = socketData['socket'];
    socketInstance?.disconnect();
    socketInstance && dispatch(disconnectSocket('socket'));
  };
}
