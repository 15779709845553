import React, { memo, useMemo } from 'react';
import moment from 'moment';
import './Duration.scss';

interface Props {
  startDate: string | number | Date;
  endDate: string | number | Date;
}

const Duration: React.FC<Props> = ({ startDate, endDate }) => {
  const duration: any = useMemo(() => {
    const durationDif: any = moment.duration(
      moment(endDate).diff(moment(startDate))
    );

    return {
      years: durationDif._data.years,
      months: durationDif._data.months,
      days: durationDif._data.days,
      hours: durationDif._data.hours,
      minutes: durationDif._data.minutes
    };
  }, [startDate, endDate]);

  const durationTimes = [];

  for (let key in duration) {
    const time = duration[key];
    const timeFormat = (time === 1 && key.slice(0, -1)) || key;
    time && durationTimes.push(` ${time} ${timeFormat}`);
  }

  return durationTimes.length ? (
    <p className='agenda-duration mb-0'>
      <span>Duration: </span> {durationTimes}
    </p>
  ) : null;
};

export default memo(Duration);
