export const validationMessages = {
  tooShort: 'The minimum number of characters should be ',
  tooLong: 'Too Long!',
  email: 'Invalid email',
  required: 'This field is required',
  password: 'Too Short!',
  passwordConfirmation: 'This field is required',
  passwordMatch:
    'You must enter the same password twice in order to confirm it',
  endDateLessThanStartDate: "End date can't be less than start date",
  invalidSiteUrl: 'Enter valid site url correct url!',
  invalidTwitterProfileUrl:
    'Invalid profile url. Url should contain twitter.com',
  invalidLinkedInProfileUrl:
    'Invalid profile url. Url should contain linkedin.com/in/',
  invalidFacebookProfileUrl:
    'Invalid profile url. Url should contain facebook.com/',
  bioTooLong: 'Bio should have a length less than or equal to 2048 characters',
  bioStringTooLong: 'Bio string must be no longer than 256 characters'
};
