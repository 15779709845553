import * as yup from 'yup';
import { validationMessages } from 'constants/validationMessages';

export const fieldList = [
  {
    id: 0,
    name: 'first_name',
    text: 'First Name',
    placeholder: 'First name'
  },
  {
    id: 1,
    name: 'last_name',
    text: 'Last Name',
    placeholder: 'Last name'
  },
  {
    id: 2,
    name: 'email',
    text: 'Email',
    placeholder: 'Email address'
  },
  {
    id: 3,
    name: 'company',
    text: 'Company Name',
    placeholder: 'Company name'
  },
  {
    id: 4,
    name: 'headline',
    text: 'Headline',
    placeholder: 'Title'
  },
  {
    id: 5,
    name: 'linkedin',
    text: 'LinkedIn',
    placeholder: 'URL'
  }
];

const regexURL =
  /^(https:\/\/www\.|http:\/\/www\.|www\.|https:\/\/|http:\/\/)[a-zA-Z0-9\-_$]+\.[a-zA-Z]{2,5}/;

export const profileSchema = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .min(1, validationMessages.tooShort + '1')
    .required(validationMessages.required),
  last_name: yup
    .string()
    .trim()
    .min(1, validationMessages.tooShort + '1')
    .required(validationMessages.required),
  email: yup
    .string()
    .trim()
    .email(validationMessages.email)
    .required(validationMessages.required),
  company: yup.string(),
  headline: yup.string(),
  linkedin: yup
    .string()
    .matches(regexURL, validationMessages.invalidLinkedInProfileUrl),
  bio: yup.string().max(2048, validationMessages.bioTooLong)
});

export const changePasswordSchema = yup.object().shape({
  old_password: yup
    .string()
    .trim()
    .min(8, validationMessages.tooShort + '8')
    .required(validationMessages.required),
  password: yup
    .string()
    .trim()
    .min(8, validationMessages.tooShort + '8')
    .required(validationMessages.required),
  password_confirmation: yup
    .string()
    .trim()
    .min(8, validationMessages.tooShort + '8')
    .oneOf([yup.ref('password')], validationMessages.passwordMatch)
    .required(validationMessages.required)
});
