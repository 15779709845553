import React from 'react';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ModalLayout } from 'layouts/ModalLayout';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
  title: string;
  loading: boolean;
  showModal: boolean;
  setShowModal: (v: boolean) => void;
  currentSession: any;
  currentSessionError: string;
}

const ModalAgendaCard: React.FC<Props> = ({
  title,
  loading,
  showModal,
  setShowModal,
  currentSession,
  currentSessionError
}) => {
  return (
    <ModalLayout
      isVisible={showModal}
      onClose={() => setShowModal(false)}
      modalClassName='session-record-modal'
      modalBodyClassName='d-flex p-0 position-relative justify-content-center'
      size='xl'
      centered
    >
      <>
        <FontAwesomeIcon
          icon={faTimes as IconProp}
          size='lg'
          className='position-absolute cursor-pointer'
          style={{
            width: '16px',
            height: '16px',
            right: '6px',
            top: '6px',
            color: '#B5B5C3'
          }}
          onClick={() => setShowModal(false)}
        />
        {loading ? (
          <div className='w-100 h-100 d-flex justify-content-center align-items-center position-absolute left-0 top-0'>
            <Spinner animation='border' role='status' aria-hidden='true' />
          </div>
        ) : currentSessionError ? (
          <h1 className='mt-4'>{currentSessionError}</h1>
        ) : (
          <div className='w-100 d-flex flex-column p-4'>
            <h1>{title}</h1>
            <div className='flex-1'>{currentSession}</div>
          </div>
        )}
      </>
    </ModalLayout>
  );
};

export default ModalAgendaCard;
