import React, { FC, memo, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import './SessionTiming.scss';
import moment from 'moment';

interface IProps {
  archive: object;
  sessionStartTime?: string;
  participantsData: any;
  userId: string;
  activeSession: any;
  isHost: boolean;
}

let difRecordingStartTime: any = undefined;

export const getTime = (date: any) => {
  if (!date) {
    return {};
  }
  const endDate = moment(moment(), 'DD/MM/YYYY HH:mm:ss');
  const startDate = moment(moment(date), 'DD/MM/YYYY HH:mm:ss');
  const utcDate = moment.utc(endDate.diff(startDate));
  return {
    seconds: moment(utcDate).format('ss'),
    minutes: moment(utcDate).format('mm'),
    hours: moment(utcDate).format('HH')
  };
};

const SessionTiming: FC<IProps> = ({
  archive,
  sessionStartTime,
  participantsData,
  userId,
  activeSession,
  isHost
}) => {
  const { recordStartTime, archiveId }: any = archive;

  const [recordingTime, setRecordingTime] = useState<any>(null);
  const [sessionTime, setSessionTime] = useState<any>(null);
  const participantData: any = Object.entries(participantsData).find(
    (partPair: any) => partPair[1]?.partId === userId
  );

  const is_speaker =
    participantData &&
    activeSession?.speakers?.find(
      ({ email: speakerEmail }: any) =>
        speakerEmail === participantData[1]['email']
    );

  useEffect(() => {
    if (sessionStartTime) {
      const timeOut = setTimeout(() => {
        setSessionTime(getTime(sessionStartTime));
      }, 1000);

      return () => clearTimeout(timeOut);
    } else {
      setSessionTime(null);
    }
  }, [sessionStartTime, sessionTime]);

  useEffect(() => {
    if (recordStartTime) {
      const timeOut = setTimeout(() => {
        setRecordingTime(getTime(recordStartTime));
      }, 1000);

      return () => clearTimeout(timeOut);
    } else {
      setRecordingTime(null);
    }
  }, [recordStartTime, recordingTime]);

  useEffect(() => {
    !difRecordingStartTime &&
      recordStartTime &&
      (is_speaker || isHost) &&
      toast.info('The session is being recorded.');
    difRecordingStartTime = recordStartTime;
  }, [recordStartTime, isHost, is_speaker]);

  return (
    <div className='header-session-timing'>
      {!!sessionTime && (
        <fieldset className='session-control-side-legend'>
          <legend>Session time</legend>
          <span>{`${sessionTime.hours} : ${sessionTime.minutes} : ${sessionTime.seconds} `}</span>
        </fieldset>
      )}
      {!!recordingTime && archiveId && (is_speaker || isHost) && (
        <fieldset className='session-control-side-legend'>
          <legend>Recording time</legend>
          <span>{`${recordingTime.hours} : ${recordingTime.minutes}: ${recordingTime.seconds} `}</span>
        </fieldset>
      )}
    </div>
  );
};

export default memo(SessionTiming);
