import { REACT_APP_BACKEND_API_URL } from '../constants';
import { instance } from './auth';

export function getCountryStates(country: string, apiURL: string) {
  return instance.get(`${apiURL}${country}`);
}

export function getReceptionData(eventCode: string) {
  return instance.get(
    `${REACT_APP_BACKEND_API_URL}receptions?eventCode=${eventCode}`
  );
}

export function getAllSessions(eventCode: string) {
  return instance.get(
    `${REACT_APP_BACKEND_API_URL}sessions?eventCode=${eventCode}`
  );
}

export async function getCustomMenu(eventCode: string) {
  return instance.get(
    `${REACT_APP_BACKEND_API_URL}events/custom-menu/${eventCode}`
  );
}

export function joinEventPost(eventCode: string) {
  return instance.post(`${REACT_APP_BACKEND_API_URL}join-event/${eventCode}`);
}

// Session
export function getSessions(eventCode: string, roomId: string | number | null) {
  return instance.get(
    `${REACT_APP_BACKEND_API_URL}room-session/${eventCode}/${roomId}`
  );
}

export function changeSessions(
  roomId: string | number | null,
  action: string,
  eventCode: string,
  customMenuId: string
) {
  return instance.get(
    `${REACT_APP_BACKEND_API_URL}session-state/${roomId}/${action}`,
    {
      params: { eventCode, customMenuId }
    }
  );
}

export function getSessionRecord(id: string | number) {
  return instance.get(`${REACT_APP_BACKEND_API_URL}record/${id}`);
}

export function getEventBookmark(eventCode: string) {
  return instance.get(
    `${REACT_APP_BACKEND_API_URL}get-bookmarked-sessions/${eventCode}`
  );
}

export function setEventBookmark(
  eventCode: string,
  sessionId: number,
  type: string
) {
  return instance.post(`${REACT_APP_BACKEND_API_URL}add-remove-bookmark`, {
    eventCode,
    sessionId,
    type
  });
}

export function cancelRegToEvent(
  event_code: string,
  appId: number,
  email: string
) {
  return instance.post(
    `${REACT_APP_BACKEND_API_URL}cancel-registration/${event_code}/${appId}`,
    {
      email
    }
  );
}

export const sessionRate = (session_id: number, rate: number) => {
  return instance.post(`${REACT_APP_BACKEND_API_URL}session-rate`, {
    session_id,
    rate
  });
};

export const speakerRate = (
  speaker_id: number,
  session_id: number,
  rate: number
) => {
  return instance.post(`${REACT_APP_BACKEND_API_URL}speaker-rate`, {
    speaker_id,
    session_id,
    rate
  });
};

export const getGreenRooms = (event_code: string, roomId: string) => {
  return instance.get(`${REACT_APP_BACKEND_API_URL}green-rooms/${event_code}`, {
    params: {
      roomId
    }
  });
};
