export const POLL_EVENTS = {
  NEW_POLL: 'new_poll',
  REORDER_POLL: 'reorder_poll',
  NEW_VOTE: 'new_vote',
  EDIT_POLL: 'edit_poll',
  DELETE_POLL: 'delete_poll',
  QUESTION_ARCHIVE: 'question_archive'
};

export const RECIEVE_POLL = 'RECIEVE_POLL';
export const RECIEVE_VOTE = 'RECIEVE_VOTE';
export const DELETE_POLL = 'DELETE_POLL';
export const EDIT_POLL = 'EDIT_POLL';
export const CLEAR_POLL = 'CLEAR_POLL';
export const ARCHIVE_QUESTION = 'ARCHIVE_QUESTION';
export const REORDER_POLLS = 'REORDER_POLLS';
export const CLEAR_SHOW_POLL_NOTIFICATION = 'CLEAR_SHOW_POLL_NOTIFICATION';
export const SET_LAST_POLL_DATE = 'SET_LAST_POLL_DATE';
export const SET_SHOW_POLL_NOTIFICATION = 'SET_SHOW_POLL_NOTIFICATION';
