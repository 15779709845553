import { POLL_EVENTS } from 'store/poll/constants';

export const createPoll = (socket: any, chat_id: string, data: any) => {
  socket?.emit(POLL_EVENTS.NEW_POLL, chat_id, data);
};

export const editPoll = (socket: any, chat_id: string, data: any) => {
  socket?.emit(POLL_EVENTS.EDIT_POLL, chat_id, data);
};

export const reorderPoll = (socket: any, chat_id: string, data: any) => {
  socket?.emit(POLL_EVENTS.REORDER_POLL, chat_id, {
    data
  });
};

export const deletePoll = (socket: any, chat_id: string, poll_id: string) => {
  socket?.emit(POLL_EVENTS.DELETE_POLL, chat_id, { poll_id });
};

export const newVote = (socket: any, chat_id: string, data: any) => {
  socket?.emit(POLL_EVENTS.NEW_VOTE, chat_id, data);
};
