import YouTube from '@u-wave/react-youtube';
import Vimeo from '@u-wave/react-vimeo';
import ReactJWPlayer from 'react-jw-player';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { Providers } from './constants';

import { REACT_APP_JW_PLAYER_SCRIPT } from 'constants/index';
import {
  extractProviderAndVideoId,
  mapBrightcovePlayerProps,
  mapVimeoPlayerProps,
  mapYoutubePlayerProps,
  mapJWPlayerProps,
  mapJWPlayerFileProps
} from './PlayerHelpers';

import {
  BaseJWPlayerProps,
  BaseYouTubeProps,
  FactoryParams,
  MappingProviderProps
} from './types';

const mappedPlayerProps = ({
  accountId,
  height,
  provider,
  videoId,
  width
}: MappingProviderProps): any => {
  switch (provider) {
    case Providers.JWPLAYER:
      if (!REACT_APP_JW_PLAYER_SCRIPT)
        throw new Error('JWPlayer requires a playerScript param');

      return mapJWPlayerProps({
        height,
        width,
        playerId: 'jwp-player',
        playlist: videoId,
        playerScript: REACT_APP_JW_PLAYER_SCRIPT,
      });
    case Providers.JWPLAYERFILE:
      if (!REACT_APP_JW_PLAYER_SCRIPT)
        throw new Error('JWPlayer requires a playerScript param');

      return mapJWPlayerFileProps({
        height,
        width,
        playerId: 'jwp-player',
        file: videoId,
        playerScript: REACT_APP_JW_PLAYER_SCRIPT
      });
    case Providers.YOUTUBE:
      return mapYoutubePlayerProps({ videoId, height, width });
    case Providers.VIMEO:
      return mapVimeoPlayerProps({ height, videoId, width });
    case Providers.BRIGHTCOVE:
      if (!accountId) throw new Error('Brightcove URL missing Account ID');
      return mapBrightcovePlayerProps({ accountId, height, videoId, width });
    default:
      throw new Error('Bad Provider on mapping props');
  }
};

const PlayerProviderFactory = (
  url: string,
  params?: FactoryParams
): {
  Player: any;
  provider: string;
  playerProps: BaseYouTubeProps | BaseJWPlayerProps;
} => {
  const { accountId, provider, videoId } = extractProviderAndVideoId(url);

  const Player = ((provider: string): any => {
    switch (provider) {
      case Providers.JWPLAYER:
        return ReactJWPlayer;
      case Providers.JWPLAYERFILE:
        return ReactJWPlayer;
      case Providers.YOUTUBE:
        return YouTube;
      case Providers.VIMEO:
        return Vimeo;
      case Providers.BRIGHTCOVE:
        return ReactPlayerLoader;
    }
  })(provider);

  const props = mappedPlayerProps({ ...params, provider, videoId, accountId });

  return { Player, provider, playerProps: props };
};

export default PlayerProviderFactory;