import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

const PollOptionCreator = ({
  value,
  id,
  onChange,
  showMinusButton,
  onMinusClick
}) => {
  return (
    <div className='mt-2 mb-1 d-flex align-items-center'>
      <Form.Control
        value={value}
        onChange={(event) => onChange(event.target.value, id)}
        type='text'
        name={id}
        placeholder='Type the option name'
      />
      {showMinusButton && (
        <div className="remove-option">
          <Form.Check
            type='radio'
            className='remove-option-input'
            inline={true}
            onChange={() => onMinusClick(id)} />
            <div className="container-for-icon">
              <FontAwesomeIcon
                className="cursor-pointer bin-icon"
                icon={faTrash}
                color='#B5B5C3'
            />
            </div>
        </div>
      )}
    </div>
  )
}

PollOptionCreator.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onMinusClick: PropTypes.func,
  showMinusButton: PropTypes.bool
}

PollOptionCreator.defaultProps = {
  showMinusButton: false,
  onMinusClick: () => {
    return
  }
}
export default PollOptionCreator

