import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { SendMessageIcon } from '../../icons/stockholm';
import { MentionsInput, Mention } from 'react-mentions';
import { useSelector } from 'react-redux';
import { uniqBy } from 'lodash';
import { Form } from 'react-bootstrap';

const SendMessage = React.forwardRef(
  ({ onMessageSent, placeholder = 'Start typing', isAdminChat }, ref) => {
    const [value, setValue] = useState('');
    const [formattedParticipants, setParticipants] = useState([]);
    const { data: participants } = useSelector((state) => state.participants);
    const { id } = useSelector((state) => state.auth.user);
    const { messageRefPartId } = useSelector((state) => state.chat);
    const [mentions, setMentions] = useState([]);
    useEffect(() => {
      let data = [];
      const values = uniqBy(Object.values(participants), 'partId');
      values
        .filter((elm) => elm.partId !== id && elm.name.trim())
        .forEach((participant) => {
          data.push({
            id: participant.partId,
            display: participant.name,
            userImage: participant.userImage
          });
        });

      setParticipants(data);
    }, [participants, id]);

    const sendMessage = (e) => {
      e.preventDefault();
      if (value.trim()) {
        let data = messageRefPartId === "all" ? '<span class="text-primary">@' : '<span>@'
        const messageData = value
          ?.replaceAll('@@@__', data )
          ?.replaceAll('@@@^^^', '</span>');
        onMessageSent(messageData, mentions);
        setValue('');
        setMentions([]);
      }
    };

    const onKeyDown = (e) => {
      if (e && e.key === 'Enter') {
        sendMessage(e);
        return false;
      }
    };

    const renderSuggestion = ({ userImage, display }) => {
      return (
        <div className='dropdown-item d-flex align-items-center'>
          <img className='participant-img' alt={display} src={userImage} />
          <span>{display}</span>
        </div>
      );
    };

    const onAdd = (data) => {
      setMentions([...mentions, data]);
    };

    const variant = value ? 'primary' : 'secondary';
    return (
      <div className='send-message-form py-2'>
        <button onClick={sendMessage}>
          <SendMessageIcon variant={variant} />
        </button>
        {!isAdminChat && messageRefPartId === 'all' ? (
          <MentionsInput
            value={value}
            className='sendMessageInput'
            onChange={(e) => setValue(e.target.value)}
            placeholder={placeholder}
            onKeyDown={onKeyDown}
          >
            <Mention
              trigger='@'
              appendSpaceOnAdd={true}
              data={formattedParticipants}
              onAdd={onAdd}
              markup='@@@____display__@@@^^^'
              displayTransform={(id, display) => `@${display}`}
              renderSuggestion={renderSuggestion}
            />
          </MentionsInput>
        ) : (
          <Form.Control
            value={value.replaceAll("@@@__", "@").replaceAll("@@@^^^", "")}
            onKeyDown={onKeyDown}
            onChange={(e) => setValue(e.target.value)}
            as='textarea'
            className='sendMessageInput'
            placeholder={placeholder}
          />
        )}
      </div>
    );
  }
);

SendMessage.propTypes = {
  onMessageSent: PropTypes.func,
  placeholder: PropTypes.string,
  isAdminChat: PropTypes.bool,
};

export default SendMessage;
