import React from 'react';
import classnames from 'classnames';
import CustomButtonModal from './CustomButtonModal';
import './CustomButton.scss';
import { addProtocol, checkValidURL } from 'helpers';
import { useFeatherIcon } from 'hooks';
import noop from 'lodash/noop';

interface ButtonElementProps {
  color?: string;
  html?: string;
  icon?: string;
  id?: number;
  name?: string;
  type?: string;
  url?: string;
  className?: string;
  disabled?: boolean;
  onClick: any;
}

export const ButtonElement: React.FC<ButtonElementProps> = ({
  icon,
  color,
  name,
  disabled,
  onClick,
  className
}) => {
  const iconURL = useFeatherIcon(icon);

  return (
    <button
      onClick={onClick}
      className={
        className ||
        'align-items-center d-flex flex-column justify-content-center button-item p-2 custom-button-item cursor-pointer'
      }
      style={className ? {} : { color, width: '100px' }}
      title={name}
      disabled={disabled}
    >
      {iconURL && (
        <svg data-src={iconURL} stroke={color} width='20px' height='20px' />
      )}
      <span className='custom-button-desc'>{name}</span>
    </button>
  );
};

interface Props {
  button_color?: string;
  button_text?: string;
  html?: string;
  type?: string;
  url?: string;
  className?: string;
  icon?: string;
  onClick?: () => void;
}

const CustomButton: React.FC<Props> = (props) => {
  const {
    button_color,
    button_text,
    icon,
    type,
    url = '',
    className,
    onClick
  } = props;
  return (
    <div className={classnames(className)}>
      {type === 'HTML' ? (
        <CustomButtonModal
          applyStyles={true}
          trackFn={onClick ? () => onClick() : noop}
          {...props}
        />
      ) : (
        <ButtonElement
          icon={icon}
          color={button_color}
          name={button_text}
          disabled={!checkValidURL(url)}
          onClick={() => (onClick ? onClick() : window.open(addProtocol(url)))}
        />
      )}
    </div>
  );
};

export default CustomButton;
