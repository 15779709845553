import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SponsorCard from 'components/SponsorCard/SponsorCard';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SidebarLayout } from 'layouts/index';
import { useDispatch, useSelector } from 'react-redux';
import { getSponsors } from 'store/sponsors/actions';
import eventPageHOC from 'hocs/eventPageHOC';
import HotJarTracking from 'components/HotJarTracking/HotJarTracking';
import { disconnectFromSocket } from 'store/socket/thunk';

interface RouteParams {
  match: {
    params: { id: string };
  };
}

const Index: React.FC<RouteParams> = props => {
  const {
    sponsors: { sponsors },
    socket: { socket },
  } = useSelector((state: AppState) => state);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id: event_code = '' } = useParams() as { id: string };

  useEffect(() => {
    if (!event_code) navigate(`/events`);

    dispatch(getSponsors(event_code));
    //  eslint-disable-next-line
  }, [event_code]);

  useEffect(() => {
    if (socket) {
      dispatch(disconnectFromSocket());
    }
    // eslint-disable-next-line
  }, [socket]);

  const sponsorsElement = (
    <Row className="p-4 m-0">
      {sponsors.map((sponsor: Sponsors) => (
        <Col key={sponsor.id} xl={4} lg={6} className="p-4">
          <SponsorCard {...sponsor} event_code={event_code} />
        </Col>
      ))}
    </Row>
  );

  return (
    <>
      <HotJarTracking />
      <SidebarLayout>
        <div className="content d-flex flex-column flex-column-fluid p-0">
          <Row className="h-100 m-0">
            <Col className="h-100 overflow-auto p-0">{sponsorsElement}</Col>
          </Row>
        </div>
      </SidebarLayout>
    </>
  );
};

export const SponsorsPage = eventPageHOC(Index);
