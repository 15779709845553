export const checkValidURL = (str: string) => {
  const pattern = new RegExp(
    '^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$'
  );

  return !!pattern.test(str);
};

export const addProtocol = (url: string) => {
  return /(http(s?)):\/\//.test(url) ? url : 'http://' + url;
};
