import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import partial from 'lodash/partial';
import { validURL } from 'utils/UrlUtils';
import { Providers, PlayerSizeDefaults } from './constants';
import {
  BrightcovePlayerProps,
  CommonPlayerPropsWithVideoID,
  JWPlayerProps
} from './types';
import styles from './PlayerHelpers.module.scss';
import { REACT_APP_JW_PLAYER_SCRIPT } from 'constants/index'

const findMatch = (url: string, regex: string): RegExpExecArray | null =>
  new RegExp(regex).exec(url);

interface UrlAttributes {
  _type: string;
  videoId: string;
  accountId?: string;
}

const findJWPlayerResourceTypeAndId = (url: string): UrlAttributes => {
  const playlistRegexp = 'https://cdn.jwplayer.com/v2/playlists/([0-9A-Za-z]+)';
  const videoFileRegexp = '^.*\\.(3gp|avi|mov|mp4|flv|wmv|webm|dash|m3u8)$';

  const playlistMatch = findMatch(url, playlistRegexp);
  const videoFileMatch = findMatch(url, videoFileRegexp);

  if (playlistMatch) {
    const videoId = url;
    return { _type: 'playlist', videoId };
  } else if (videoFileMatch) {
    const videoId = url;
    return { _type: 'file', videoId };
  }
  throw new Error('Invalid JWPlayer resource for player');
};

const findYouTubeResourceTypeAndId = (url: string): UrlAttributes => {
  const videoRegexp =
    '(?:https?:\\/\\/)?(?:www\\.)?youtu\\.?be(?:\\.com)?\\/?.*(?:watch|embed)?(?:.*v=|v\\/|\\/)([\\w\\-_]+)\\&?';

  const videoMatch = findMatch(url, videoRegexp);

  if (videoMatch) {
    const videoId = videoMatch[1];
    return { _type: 'video', videoId };
  }
  throw new Error('Invalid YouTube resource for player');
};

const findVimeoResourceTypeAndId = (url: string): UrlAttributes => {
  // const videoRegexp = 'https://vimeo.com/([0-9]+)';
  const videoRegexp =
    '(https?://)?(www.)?(player.)?vimeo.com/(video/)?([0-9]+)';

  const videoMatch = findMatch(url, videoRegexp);

  if (videoMatch) {
    const videoId = videoMatch[videoMatch?.length - 1];
    return { _type: 'video', videoId };
  }
  throw new Error('Invalid Vimeo resource for player');
};

const findBrightcoveDetails = (url: string): UrlAttributes => {
  const accountIdRegexp = 'https://players.brightcove.net/([0-9]+)/';
  const match = findMatch(url, accountIdRegexp);

  if (match === null)
    throw new Error('Bad brightcove URL. Account ID is not present');

  const accountId = match[1] || '';

  const videoId = new URL(url).searchParams.get('videoId') || '';
  return { _type: 'video', accountId, videoId };
};

const findProvider = (url: string): string => {
  const valueContainsAny = (value: string, patterns: string[]) =>
    patterns.some((pattern) => value.includes(pattern));

  const urlContainsAny = partial(valueContainsAny, url);

  if (urlContainsAny(['cdn.jwplayer.com/v2'])) return Providers.JWPLAYER;
  else if (urlContainsAny(['youtu.be', 'youtube.com']))
    return Providers.YOUTUBE;
  else if (urlContainsAny(['vimeo.com'])) return Providers.VIMEO;
  else if (urlContainsAny(['players.brightcove.net']))
    return Providers.BRIGHTCOVE;
  else return Providers.JWPLAYERFILE;
};

export const extractProviderAndVideoId = (
  url: string
): { provider: string; _type: string; videoId: string; accountId?: string } => {
  if (isEmpty(url)) throw new Error('You must provide a URL');
  else if (!validURL(url)) throw new Error('You must provide a valid URL');

  const provider = findProvider(url);

  switch (provider) {
    case Providers.JWPLAYER:
      return { provider, ...findJWPlayerResourceTypeAndId(url) };
    case Providers.JWPLAYERFILE:
      return { provider, ...findJWPlayerResourceTypeAndId(url) };
    case Providers.YOUTUBE:
      return { provider, ...findYouTubeResourceTypeAndId(url) };
    case Providers.VIMEO:
      return { provider, ...findVimeoResourceTypeAndId(url) };
    case Providers.BRIGHTCOVE:
      return { provider, ...findBrightcoveDetails(url) };
  }

  throw new Error('Invalid resource');
};

export const mapBrightcovePlayerProps = ({
  accountId,
  height,
  videoId,
  width
}: BrightcovePlayerProps): any => {
  let sizeProps = cloneDeep(PlayerSizeDefaults);
  if (width) sizeProps.width = width;
  if (height) sizeProps.height = height;

  // even if you correctly set the width and height styles,
  // they won't be reflected unless a contained video-js tag is
  // set to occupy 100% of the available space
  const cssFix = styles.brightcoveVideoJsFix;

  return {
    accountId,
    videoId,
    attrs: {
      className: cssFix,
      style: { ...sizeProps }
    }
  };
};

export const mapVimeoPlayerProps = ({
  videoId,
  height,
  width
}: CommonPlayerPropsWithVideoID): any => {
  let sizeProps = cloneDeep(PlayerSizeDefaults);
  if (width) sizeProps.width = width;
  if (height) sizeProps.height = height;

  return {
    style: { ...sizeProps },
    className: styles.vimeoVideoIframeFix,
    video: videoId
  };
};

export const mapYoutubePlayerProps = ({
  videoId,
  height,
  width
}: CommonPlayerPropsWithVideoID): any => {
  let sizeProps = cloneDeep(PlayerSizeDefaults);
  if (width) sizeProps.width = width;
  if (height) sizeProps.height = height;

  return {
    ...sizeProps,
    video: videoId
  };
};

export const mapJWPlayerProps = ({
  playerId,
  playlist,
  width,
  height
}: JWPlayerProps) => {
  const getValueFromString = (value: string): string => {
    const errorMessage =
      'Only values within 100%, 75%, 50%, and 25% are allowed';
    const match = new RegExp('[0-9]+').exec(value);

    if (!match || !['100', '75', '50', '25'].includes(match[0])) {
      throw new Error(errorMessage);
    }

    return match[0];
  };

  const mappedWidth = width ? getValueFromString(width) : '100';
  const mappedHeight = height ? getValueFromString(height) : '100';

  return {
    className: styles[`jwplayer-w${mappedWidth}h${mappedHeight}`],
    playerId,
    playerScript: REACT_APP_JW_PLAYER_SCRIPT,
    playlist
  };
};

export const mapJWPlayerFileProps = ({
  playerId,
  file,
  width,
  height
}: JWPlayerProps) => {
  const getValueFromString = (value: string): string => {
    const errorMessage =
      'Only values within 100%, 75%, 50%, and 25% are allowed';
    const match = new RegExp('[0-9]+').exec(value);

    if (!match || !['100', '75', '50', '25'].includes(match[0])) {
      throw new Error(errorMessage);
    }

    return match[0];
  };

  const mappedWidth = width ? getValueFromString(width) : '100';
  const mappedHeight = height ? getValueFromString(height) : '100';

  return {
    className: styles[`jwplayer-w${mappedWidth}h${mappedHeight}`],
    playerId,
    playerScript: REACT_APP_JW_PLAYER_SCRIPT,
    file
  };
};
