import React, { useEffect } from 'react';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ROUTES from 'constants/routes';
import { replaceUrlParams } from 'helpers/replaceUrlParams';
import { useUserIsSessionSpeaker, useIsHost } from 'hooks/useUserRole';
import { getGreenRoomsAction } from 'store/sessions/actions';
import { setAudio, setVideo } from 'store/vonage/actions';

const GreenRoomHeader = () => {
  const {
    events: { currentEvent: { event_code = '', customMenu = [] } = {} } = {},
    sessions: { greenRooms = [], data: sessionsData } = {}
  } = useSelector((state: AppState) => state);
  const userIsSessionSpeaker = useUserIsSessionSpeaker();
  const isHost = useIsHost();
  const hostOrSpeaker = userIsSessionSpeaker || isHost;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { greenRoomId }: any = useParams();
  const { room_id } =
    customMenu.find((item: any) => item.slug === 'stage') || {};

  const moveToStagePage = () => {
    dispatch(setAudio(false));
    dispatch(setVideo(false));
    navigate(replaceUrlParams(ROUTES.STAGE, event_code));
  };

  const moveToGreenRoomPage = (greenRoomId: number) => {
    navigate(`/event/${event_code}/stage/green-room/${greenRoomId}`);
  };

  useEffect(() => {
    event_code &&
      room_id &&
      sessionsData.length &&
      hostOrSpeaker &&
      dispatch(getGreenRoomsAction(event_code, room_id));
    // eslint-disable-next-line
  }, [event_code, room_id, sessionsData.length]);

  return (
    <div className='d-flex green-room-buttons-container'>
      {greenRoomId && (
        <Button className='mx-2' variant='primary' onClick={moveToStagePage}>
          Stage
        </Button>
      )}
      {!!greenRooms?.length && (
        <DropdownButton
          className='green-room-dropdown'
          variant='success'
          title={
            <span>
              Green Rooms
              <FontAwesomeIcon icon={faAngleDown as IconProp} size='lg' />
            </span>
          }
        >
          {greenRooms?.map(({ id, session_title }: any) => (
            <Dropdown.Item key={id} onClick={() => moveToGreenRoomPage(id)}>
              {session_title}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      )}
    </div>
  );
};

export default GreenRoomHeader;
