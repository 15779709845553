export default {
  EVENT: `/event/:id`,
  AGENDA: `/event/:id/agenda`,
  LEADERBOARD: `/event/:id/leaderboard`,
  SPONSORS: `/event/:id/sponsors`,
  ROOM: `/event/:id/room/:roomId`,
  SPONSOR_ROOM: `/event/:id/sponsors/:orgId/stage`,
  RECEPTION: `/event/:id/reception`,
  GREEN_ROOM: `/event/:id/stage/green-room/:greenRoomId`,
  STAGE: `/event/:id/stage`,
  PROFILE: `/profile`,
  EVENTS: `/events`,
  MY_EVENTS: '/my-events',
  MAGIC_LINK_WITH_EVENT_CODE: '/magic-link/:magic_token/:eventCode',
  MAGIC_LINK: '/magic-link/:magic_token',
  MS_VIDEO_CHAT: '/ms-video-chat',
} as any;
