import * as api from 'api';
import { Dispatch } from 'redux';
import { GET_LEADERS, SET_RULES, SET_PRIZES, GET_MY_POINTS } from './constants';

export const getLeaders =
  (eventCode: string): any =>
  async (dispatch: Dispatch) => {
    try {
      const {
        data: { data }
      } = await api.getLeaders(eventCode);
      dispatch({
        type: GET_LEADERS,
        payload: data
      });
    } catch (e) {
      console.log(e);
    }
  };

export const setRules = (data: any) => {
  return {
    type: SET_RULES,
    payload: data
  };
};

export const getRules =
  (eventCode: string): any =>
  async (dispatch: Dispatch) => {
    try {
      const {
        data: { data }
      } = await api.getRules(eventCode);

      dispatch(setRules(data));
    } catch (e) {
      console.log(e);
    }
  };

export const getMyPoints =
  (eventCode: string): any =>
  async (dispatch: Dispatch) => {
    try {
      const {
        data: { data }
      } = await api.getMyPoints(eventCode);

      dispatch({
        type: GET_MY_POINTS,
        payload: data
      });
    } catch (e) {
      console.log(e);
    }
  };

export const setPrizes = (data: any) => {
  return {
    type: SET_PRIZES,
    payload: data
  };
};

export const getPrizes =
  (eventCode: string): any =>
  async (dispatch: Dispatch) => {
    try {
      const {
        data: { data }
      } = await api.getPrizes(eventCode);

      dispatch(setPrizes(data));
    } catch (e) {
      console.log(e);
    }
  };
