import React from 'react';
import { Formik, Form } from 'formik';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import { FormField, TextAreaField } from 'components';
import './ProfileForm.scss';

interface Field {
  id: number;
  name: string;
  text: string;
  placeholder: string;
}

interface Props {
  user: User;
  image: string;
  dropzoneStyle: any;
  fieldList: Field[];
  profileSchema: any;
  loading: boolean;
  getRootProps: any;
  getInputProps: any;
  selectImage: () => void;
  handleFileUpload: (e: any) => void;
  handleSaveChanges: (e: any) => void;
  setChangePassModalShow: () => void;
  chnageActivePage: () => void;
}

const ProfileForm: React.FC<Props> = ({
  user,
  image,
  dropzoneStyle,
  fieldList,
  profileSchema,
  loading,
  getRootProps,
  getInputProps,
  selectImage,
  handleFileUpload,
  handleSaveChanges,
  setChangePassModalShow,
  chnageActivePage
}) => {
  return (
    <>
      {loading && (
        <div className='d-flex position-absolute w-100 vh-100 top-0 left-0 spin-loader-light'>
          <Spinner animation='border' className='m-auto'></Spinner>
        </div>
      )}
      <Formik
        enableReinitialize
        initialValues={{
          first_name: user.first_name || '',
          last_name: user.last_name || '',
          email: user.email || '',
          company: user.company || '',
          headline: user.headline || '',
          linkedin: user.linkedin || '',
          bio: user.bio || ''
        }}
        onSubmit={handleSaveChanges}
        validationSchema={profileSchema}
      >
        <Form className='h-100'>
          <div className='h-100 min-h-100 p-4 overflow-auto'>
            <Row className='profile-form min-h-100 m-0'>
              <Col xl={3} className='image-upload-box p-0'>
                <div>
                  <div
                    className='avatar mb-5'
                    {...getRootProps({ style: dropzoneStyle })}
                  >
                    {image ? (
                      <img src={image} alt='...' />
                    ) : (
                      <FontAwesomeIcon
                        icon={faUser as IconProp}
                        color='#ffffff'
                        size='10x'
                        className='p-4 w-100 mt-auto'
                      />
                    )}
                    <input
                      {...getInputProps()}
                      accept='.png, .jpg, .jpeg'
                      type='file'
                      className='file-upload-input'
                      id='profile-upload-input'
                      onChange={handleFileUpload}
                    />
                  </div>
                  <Button variant='primary' onClick={selectImage}>
                    Select Image
                  </Button>
                </div>
              </Col>
              <Col xl={9} className='p-0'>
                <div className='fields d-flex flex-wrap'>
                  {fieldList.map((field: any) => {
                    return (
                      <div key={field.id} className='input-field'>
                        <FormField
                          name={field.name}
                          text={field.text}
                          placeholder={field.placeholder}
                          type='text'
                        />
                      </div>
                    );
                  })}
                  <div className='input-field'>
                    <TextAreaField
                      name='bio'
                      text='Bio'
                      placeholder='Short text'
                    />
                  </div>
                </div>
                <Button variant='primary' type='submit'>
                  Save Changes
                </Button>
                <Button
                  variant='primary'
                  className='ml-4'
                  onClick={chnageActivePage}
                >
                  Cancel
                </Button>
                <div className='mt-10'>
                  <Button
                    variant='link'
                    className='p-0'
                    onClick={setChangePassModalShow}
                  >
                    Change Password
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default ProfileForm;
