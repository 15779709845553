import React, { ChangeEvent } from 'react';
import Form from 'react-bootstrap/Form';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import '../style.scss';

interface Props {
  name: string;
  type: 'checkbox' | 'radio';
  title: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  options: Array<any>;
  required: boolean;
  value: string;
  validationError: boolean;
}
export const Checkboxes: React.FC<Props> = ({
  name,
  type,
  title,
  onChange,
  options,
  required,
  validationError,
  value
}) => {
  const { participantRegData } = useSelector((state: AppState) => {
    return state.events;
  });
  let invalid = !value && validationError;
  if (Array.isArray(value)) {
    invalid = !value.length && validationError;
  }

  const classes = classnames({
    required,
    invalid
  });

  return (
    <Form.Group controlId={name}>
      <Form.Label className={classes}>{title}</Form.Label>
      {options.map((option) => {
        let checked = false;
        if (type === 'radio') {
          checked = option.value === participantRegData[name];
        } else {
          if (
            participantRegData[name] &&
            Array.isArray(participantRegData[name])
          ) {
            checked = participantRegData[name].indexOf(option.value) > -1;
          }
        }
        return (
          <Form.Check
            key={option.key}
            name={name}
            type={type}
            checked={checked}
            label={option.value}
            value={option.value}
            onChange={onChange}
          />
        );
      })}
    </Form.Group>
  );
};
