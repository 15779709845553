import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { applyTimezone } from 'helpers/parseDate';
import VideoPlayerFactory from 'components/VideoPlayerFactory';
import { toast } from 'react-toastify';
import { getSessionRecord } from 'store/sessions/actions';
import { replaceUrlParams } from 'helpers';
import ROUTES from 'constants/routes';

import { REACT_APP_JW_PLAYER_SCRIPT } from 'constants/index'

import AgendaCard from './AgendaCard';
import ModalAgendaCard from './AgendaCardModal';
import SpeakerModal from 'components/EventInformationTabs/SpeakerModal';
import './AgendaCard.scss';

interface Props {
  session: any;
  eventBookmarks: any;
}

interface Params {
  id: string;
}

const carouselSettings = {
  arrows: true,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const handleCarouselSettings = (length: number) => {
  return length > 2 || window.innerWidth <= 600
    ? carouselSettings
    : {
        arrows: true,
        slidesToShow: length,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };
};

const ContainerAgendaCard: React.FC<Props> = memo(
  ({ session: { id, name = '', start_date, speakers = [] }, session, eventBookmarks }) => {
    const { pathname } = useLocation();
    const { id: eventCode = '' } = useParams() as { id: string };
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [currentSession, setCurrentSession] = useState<any>(null);
    const [currentSessionTitle, setCurrentSessionErrorTitle] = useState('');
    const [currentSessionError, setCurrentSessionError] = useState('');
    const {
      sessionRecord: { data: { URL: sessionRecordURL = '' } = {}, loading: sessionRecordLoading },
    } = useSelector((state: AppState) => state.sessions);
    const [show, setShow] = useState(false);
    const [activeSpeaker, setActiveSpeaker] = useState<any>({});

    const isSidePage = pathname?.split('/')[3] !== 'stage';
    const onClick = (speaker: Speaker) => {
      setShow(true);
      setActiveSpeaker(speaker);
    };
    const handleClose = () => {
      setShow(false);
    };

    const startDateWithTimezone = useMemo(() => applyTimezone(start_date), [start_date]);

    const settings = useMemo(() => handleCarouselSettings(speakers.length), [speakers]);

    const playStreaming = (url: string) => {
      try {
        const { Player, playerProps } = VideoPlayerFactory(url, {
          width: '100%',
          height: '100%',
          playerScript: REACT_APP_JW_PLAYER_SCRIPT,
        });

        return <Player {...playerProps} isAutoPlay={true} />;
      } catch (e: any) {
        return toast.error(e?.message);
      }
    };

    useEffect(() => {
      if (sessionRecordLoading) {
        setCurrentSession(null);
        setCurrentSessionError('');
      } else if (sessionRecordURL) {
        setCurrentSession(playStreaming(sessionRecordURL));
        setCurrentSessionErrorTitle(name);
        setCurrentSessionError('');
      } else {
        setCurrentSession(null);
        setCurrentSessionErrorTitle('');
        setCurrentSessionError('Sorry, the video player failed to load.');
      }
    }, [sessionRecordURL, sessionRecordLoading, name]);

    useEffect(() => {
      if (!showModal && pathname === replaceUrlParams(ROUTES.STAGE, eventCode)) {
        const modal: any = document.querySelector('.custom-menu-popup-type.modal');
        modal && (modal.style.display = 'block');
      }
    }, [showModal, eventCode, pathname]);

    const watchSessionRecord = () => {
      dispatch(getSessionRecord(id));
      setShowModal(true);

      if (pathname === replaceUrlParams(ROUTES.STAGE, eventCode)) {
        const modal: any = document.querySelector('.custom-menu-popup-type.modal');
        modal && (modal.style.display = 'none');
      }
    };

    return (
      <>
        <AgendaCard
          session={session}
          startDateWithTimezone={startDateWithTimezone}
          settings={settings}
          watchSessionRecord={watchSessionRecord}
          isSidePage={isSidePage}
          onClick={onClick}
          eventBookmarks={eventBookmarks}
          style={{ display: 'flex', flexDirection: 'column' }}
        />
        <ModalAgendaCard
          title={currentSessionTitle}
          loading={sessionRecordLoading}
          showModal={showModal}
          setShowModal={setShowModal}
          currentSession={currentSession}
          currentSessionError={currentSessionError}
        />
        {isSidePage && (
          <SpeakerModal
            show={show}
            onHide={handleClose}
            activeSpeaker={activeSpeaker}
            hasNextSession={false}
          />
        )}
      </>
    );
  }
);

export default ContainerAgendaCard;
