import React, { useEffect } from 'react';
import { Navigate, RouteProps, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import * as api from 'api';
import { formattedSender } from 'utils/ChatUtils';
import { AuthToken, getLocation, getMenuItem, getRouteUrl } from 'helpers';
import ROUTES from 'constants/routes';
import { useIsExpectedPage } from 'hooks/usePageLocation';
import { getMenuItems, getOneEvent, setRedirectionURL } from 'store/events/actions';
import connectToSocket, { disconnectFromSocket } from 'store/socket/thunk';
import connectToEventSocket, { disconnectFromEventSocket } from 'store/socket/event-socket';
import { setDeviceId } from 'store/vonage/actions';
import { showTickerMessageBlock } from 'store/tickerMessages/actions';
import { clearChat, getMessages } from 'store/chat/actions';
import { getQuestions } from 'store/questionsAndAnswers/actions';
import Loader from 'components/Loader/Loader';

type Props = RouteProps & {
  loggedIn?: boolean | null;
  eventAccess?: boolean;
  socketConnect?: boolean;
  render?: any;
  location?: any;
  component?: any;
  children: any;
}

const PrivateLayout: React.FC<Props> = ({ children, location, eventAccess, socketConnect }) => {
  const { id = '', greenRoomId = '' }: any = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathLocation = useLocation();
  const {
    events: {
      redirectionUrl,
      currentEvent: { participant_is_registered, event_code, is_host, customMenu = [] },
    },
    auth: {
      loggedIn,
      user: { id: user_id, first_name, last_name },
      currentLocation = false,
    },
    app: { virtual_room: { chat_id = '' } = {} },
    socket: { eventSocket, eventSocketId, socket },
  } = useSelector((state: AppState) => state);

  const isStagePage = useIsExpectedPage(ROUTES.STAGE);
  const isLeaderBoardPage = useIsExpectedPage(ROUTES.LEADERBOARD);

  const accessToken = AuthToken.get('access_token');

  useEffect(() => {
    if (eventAccess && !is_host && participant_is_registered === false) navigate(`/event/${id}`);
    //  eslint-disable-next-line
  }, [eventAccess, participant_is_registered, id, is_host]);

  useEffect(() => {
    if (redirectionUrl) {
      navigate(redirectionUrl);
      dispatch(setRedirectionURL(''));
    }
    // eslint-disable-next-line
  }, [redirectionUrl]);

  useEffect(() => {
    if (
      eventAccess &&
      loggedIn &&
      event_code &&
      accessToken &&
      !greenRoomId &&
      currentLocation !== false
    ) {
      const extUserId = formattedSender({
        id: user_id,
        first_name,
        last_name,
      });
      dispatch(connectToEventSocket(extUserId, event_code) as any);
    }
    return () => {
      eventSocket && dispatch(disconnectFromEventSocket());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event_code, loggedIn, accessToken, greenRoomId, currentLocation]);

  useEffect(() => {
    dispatch(getLocation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      eventAccess &&
      chat_id &&
      loggedIn &&
      socketConnect &&
      event_code &&
      currentLocation !== false
    ) {
      const extUserId = formattedSender({
        id: user_id,
        first_name,
        last_name,
      });

      const isStageOrGreenRoom = isStagePage || greenRoomId;
      if (
        isStageOrGreenRoom &&
        navigator &&
        navigator.mediaDevices &&
        navigator.mediaDevices.enumerateDevices
      ) {
        navigator.mediaDevices.enumerateDevices().then(
          devices => {
            let mediaDeviceId;
            devices.forEach((device: MediaDeviceInfo) => {
              const { kind, deviceId = '' } = device || {};
              if (deviceId && kind === 'videoinput') {
                dispatch(setDeviceId(deviceId));
                mediaDeviceId = deviceId;
              }
            });

            dispatch(connectToSocket(extUserId, chat_id, isStagePage, mediaDeviceId) as any);
          },
          () => {
            dispatch(connectToSocket(extUserId, chat_id, isStagePage) as any);
          }
        );
      } else if (!greenRoomId) {
        dispatch(connectToSocket(extUserId, chat_id, isStagePage) as any);
      }
    }

    return () => {
      dispatch(disconnectFromSocket());
    };
    //  eslint-disable-next-line
  }, [chat_id, loggedIn, socketConnect, event_code, currentLocation]);

  useEffect(() => {
    return () => {
      if (isLeaderBoardPage) {
        dispatch(disconnectFromSocket());
      }
    };
    //  eslint-disable-next-line
  }, [isLeaderBoardPage]);

  useEffect(() => {
    eventAccess && id && loggedIn && dispatch(getMenuItems(id));
    // eslint-disable-next-line
  }, [eventAccess, id, loggedIn]);

  useEffect(() => {
    id && dispatch(getOneEvent(id));
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (chat_id && socket?.connected) {
      dispatch(getQuestions(true, chat_id, id));
      dispatch(getMessages(true));
    }
    return () => dispatch(clearChat());
    // eslint-disable-next-line
  }, [chat_id, socket?.connected]);

  useEffect(() => {
    if (event_code && eventAccess && eventSocketId && !_.isEmpty(customMenu)) {
      const menuItem = getMenuItem(location, customMenu);
      api.customMenuTrack(id, menuItem?.id, eventSocketId, user_id, currentLocation);
    }
    // eslint-disable-next-line
  }, [event_code, eventAccess, eventSocketId, JSON.stringify(customMenu)]);

  useEffect(() => {
    dispatch(showTickerMessageBlock(true));
    return () => {
      dispatch(showTickerMessageBlock(false));
    };
    // eslint-disable-next-line
  }, [eventAccess]);

  const isAllowedRoute = useIsExpectedPage([
    ROUTES.EVENT,
    ROUTES.PROFILE,
    ROUTES.EVENTS,
    ROUTES.MY_EVENTS,
  ]);

  useEffect(() => {
    if (!isAllowedRoute && !_.isEmpty(customMenu)) {
      const pathCurrentLocation = pathLocation?.pathname?.toString();

      const isRouteAvailable = getMenuItem(pathCurrentLocation, customMenu);

      if (!isRouteAvailable) {
        const url = getRouteUrl(customMenu, event_code);
        url && dispatch(setRedirectionURL(url));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customMenu, pathLocation?.pathname]);

  const renderComponent = () => {
    if (accessToken && loggedIn) {
      if (!_.isEmpty(customMenu) || isAllowedRoute) {
        return <>{children}</>;
      } else {
        return <Loader />;
      }
    } else if (!loggedIn) {
      return <Loader />;
    } else {
      return <Navigate to={`/event/${id}`} />;
    }
  };

  return <>{renderComponent()}</>;
};

export default PrivateLayout;
