import React, { createRef, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ChatBox } from 'react-banzai';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { useIsHost, useUserIsSessionSpeaker } from 'hooks/useUserRole';
import { useActiveSession, useNextSession } from 'hooks/useSessionStatus';
import { CHAT_EVENTS } from 'store/chat/constants';
import { changeActiveTab, clearAdminChat, getAdminMessages, getMessages } from 'store/chat/actions';
import { setShowConfirmationModal } from 'store/confirmationModal/actions';
import ROUTES from 'constants/routes';
import { replaceUrlParams } from 'helpers';
import modalTexts from 'constants/modalTexts';
import './Chat.scss';

type PinnedData = {
  messageId: string;
};

export const Chat: React.FC = () => {
  const { id = '', orgId, greenRoomId }: any = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const {
    communicationModules: { activeTab },
    chat: { messages, hasMore, adminMessages, adminHasMore, loaded, activeChat, adminLoaded } = {},
    auth: { user: { email: userEmail, first_name, last_name, id: userId, image } } = {},
    socket: { socket } = {},
    app: { virtual_room: { chat_id = {} } = {} },
    events: { currentEvent: { is_host: currentEventIsHost } } = {},
    sponsors: { currentOrganization: { is_host: currentOrganizationIsHost } } = {},
  } = useSelector((state: AppState) => state);

  const nextSession = useNextSession();
  const activeSession = useActiveSession();
  const isHost = useIsHost();

  let sessionId: any = null;
  if (activeSession) {
    sessionId = activeSession.id;
  } else if (nextSession) {
    sessionId = nextSession.id;
  }

  const chatElement = createRef();
  const adminChatElement = createRef();
  const userIsSessionSpeaker = useUserIsSessionSpeaker();

  const isHostOrSpeaker = useMemo(() => {
    return (
      replaceUrlParams(ROUTES.RECEPTION, id) !== pathname && (isHost || !!userIsSessionSpeaker)
    );
    // eslint-disable-next-line
  }, [currentOrganizationIsHost, currentEventIsHost, activeSession, nextSession, userEmail]);

  // no need to use useMemo unless we use React.memo wrapping the component
  const currentUser = {
    name: first_name + last_name,
    id: userId,
    isMe: true,
  };

  useEffect(() => {
    const { current }: any = chatElement;
    const element: any = current?.children[0];

    const scrollHandler = () => {
      if (chat_id && element?.scrollTop === 0 && element?.clientHeight !== element?.scrollHeight) {
        dispatch(getMessages(false));
      }
    };

    if (hasMore) {
      element?.addEventListener('scroll', scrollHandler);
    } else {
      element?.removeEventListener('scroll', scrollHandler);
    }

    return () => {
      element?.removeEventListener('scroll', scrollHandler);
    };
    // eslint-disable-next-line
  }, [hasMore, chat_id, chatElement]);

  useEffect(() => {
    if (activeChat === 'admin' && sessionId) {
      dispatch(getAdminMessages(true, sessionId));
    }
    return () => {
      dispatch(clearAdminChat());
    };
    // eslint-disable-next-line
  }, [sessionId, activeChat]);

  useEffect(() => {
    const { current }: any = adminChatElement;
    const element: any = current?.children[0];
    const scrollHandler = () => {
      if (chat_id && element?.scrollTop === 0 && element?.clientHeight !== element?.scrollHeight) {
        dispatch(getAdminMessages(false, sessionId));
      }
    };

    if (adminHasMore) {
      element?.addEventListener('scroll', scrollHandler);
    } else {
      element?.removeEventListener('scroll', scrollHandler);
    }

    return () => {
      element?.removeEventListener('scroll', scrollHandler);
    };
    // eslint-disable-next-line
  }, [adminHasMore, chat_id, adminChatElement, activeSession, nextSession]);

  const handleNewMessage = (
    message: string,
    mentions: Array<any>,
    senderId: string,
    senderPartId: string,
    messageRefId: string,
    messageRefPartId: string,
    externalUserId: string
  ): void => {
    if (message.trim()) {
      socket?.emit(CHAT_EVENTS.CHAT_MESSAGE, chat_id, {
        senderImage: image,
        message,
        senderId,
        senderPartId,
        messageRefPartId,
        messageRefId,
        mentions,
        sendNotification: messageRefPartId === 'all',
        externalUserId,
      });
    }
  };

  const handleNewAdminMessage = (
    message: string,
    mentions: Array<any>,
    senderId: string,
    senderExternalUserId: string
  ): void => {
    if (message.trim()) {
      socket?.emit(CHAT_EVENTS.CHAT_ADMIN_MESSAGE, chat_id, {
        senderImage: image,
        message,
        senderExternalUserId,
        sessionId,
      });
    }
  };

  const handlePinClick = (pinData: PinnedData): void => {
    socket?.emit(CHAT_EVENTS.MESSAGE_PIN, chat_id, pinData);
  };

  const removeMessage = (messageId: string) => {
    socket?.emit(CHAT_EVENTS.MESSAGE_REMOVE, chat_id, { messageId });
  };

  const confirmRemoveMessage = (messageId: string) => {
    dispatch(
      setShowConfirmationModal({
        text: modalTexts.removeMessage,
        callback: () => removeMessage(messageId),
      })
    );
  };

  const handleSelectTab = (tab: string | null) => {
    dispatch(changeActiveTab(tab));
  };

  useEffect(() => {
    return () => {
      dispatch(changeActiveTab('public'));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isHostOrSpeaker && !orgId && !greenRoomId ? (
        <Tabs className="chat-tabs" defaultActiveKey="public" onSelect={handleSelectTab}>
          <Tab eventKey="public" title="Public" className="h-100">
            <ChatBox
              activeTab={activeTab}
              ref={chatElement}
              loaded={loaded}
              messages={messages}
              currentUser={currentUser}
              onNewMessage={handleNewMessage}
              onPinClick={handlePinClick}
              removeMessage={confirmRemoveMessage}
            />
          </Tab>
          <Tab eventKey="admin" title="Admin" className="h-100">
            <ChatBox
              activeTab={activeTab}
              isAdminChat
              loaded={adminLoaded}
              ref={adminChatElement}
              messages={adminMessages}
              currentUser={currentUser}
              onNewMessage={handleNewAdminMessage}
              onPinClick={handlePinClick}
            />
          </Tab>
        </Tabs>
      ) : (
        <ChatBox
          activeTab={activeTab}
          ref={chatElement}
          loaded={loaded}
          messages={messages}
          currentUser={currentUser}
          onNewMessage={handleNewMessage}
          onPinClick={handlePinClick}
          removeMessage={confirmRemoveMessage}
        />
      )}
    </>
  );
};
