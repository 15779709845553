import {
  CLEAR_VONAGE_MEETING,
  SET_ARCHIVE,
  SET_AUDIO,
  SET_DEVICE_ID,
  SET_PUBLISHER,
  SET_SCREEN_SHARING,
  SET_VIDEO,
  SET_VIDEO_SOURCE,
  SET_VONAGE_MEETING_CREDENTIALS,
  SET_VONAGE_SESSION
} from './constants';

const initialState = {
  sessionId: null,
  token: null,
  session: null,
  archive: {},
  audioEnabled: false,
  videoEnabled: false,
  videoSource: 'camera',
  localScreenSharing: false,
  publisher: null,
  deviceId: null
};

export function vonage(state = { ...initialState }, action: any) {
  switch (action.type) {
    case SET_VONAGE_MEETING_CREDENTIALS: {
      return {
        ...state,
        ...action.payload
      };
    }
    case SET_VONAGE_SESSION: {
      return {
        ...state,
        session: action.payload
      };
    }
    case SET_ARCHIVE: {
      return {
        ...state,
        archive: action.payload
      };
    }
    case SET_VIDEO_SOURCE: {
      return {
        ...state,
        videoSource: action.payload
      };
    }
    case SET_VIDEO: {
      return {
        ...state,
        videoEnabled: action.payload
      };
    }
    case SET_AUDIO: {
      return {
        ...state,
        audioEnabled: action.payload
      };
    }
    case SET_SCREEN_SHARING: {
      return {
        ...state,
        localScreenSharing: action.payload
      };
    }
    case SET_PUBLISHER: {
      return {
        ...state,
        publisher: action.payload
      };
    }
    case SET_DEVICE_ID: {
      return {
        ...state,
        deviceId: action.payload
      };
    }
    case CLEAR_VONAGE_MEETING: {
      return {
        ...initialState,
        audioEnabled: state.audioEnabled,
        videoEnabled: state.videoEnabled
      };
    }
    default:
      return state;
  }
}
