import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { UserIcon } from 'react-banzai';
import RateComponent from 'components/Rate/Rate';
import noop from 'lodash';

const UserCard = ({
  src,
  name,
  company,
  position,
  shape,
  className,
  onClick,
  clickable,
  showRateComponent,
  initialRate,
  rateCallBack
}) => {
  const imgClassnames = classNames(
    'symbol',
    'symbol-100',
    'mr-5',
    `symbol-${shape}`,
    'user-card-icon'
  );

  return (
    <div className={classNames('d-flex user-card', className)}>
      <div className={imgClassnames} onClick={onClick}>
        {src ? (
          <div
            className={classNames('symbol-label', clickable && 'cursor-pointer')}
            style={{
              backgroundImage: `url(${src})`
            }}
          />
        ) : (
          <UserIcon
            className={classNames('symbol-label', { 'cursor-pointer': clickable }, 'pointer-events-none', 'user-icon')}
          />
        )}
      </div>
      <div className='d-flex justify-content-between flex-column'>
        <div>
          <div
            className={`font-weight-bold font-size-h5 text-dark-75 ${clickable && 'text-hover-primary cursor-pointer'}`}
            onClick={onClick}
          >
            {name}
          </div>
          <div className='text-muted'>{position}</div>
          <div className='navi mt-1'>
            <span className='navi-link p-0 pb-2'>
              <span className='navi-text text-muted company'>
                {company}
              </span>
            </span>
          </div>
        </div>
        <div>
          {!!showRateComponent && <RateComponent initialRate={initialRate} callback={(data) => rateCallBack(data)} />}
        </div>
      </div>
    </div>
  );
};

UserCard.propTypes = {
  name: PropTypes.string.isRequired,
  shape: PropTypes.string,
  src: PropTypes.string,
  onClick: PropTypes.func,
  clickable: PropTypes.bool,
  showRateComponent: PropTypes.bool,
  initialRate: PropTypes.number,
  rateCallBack: PropTypes.func
};

UserCard.defaultProps = {
  company: '',
  position: '',
  shape: '',
  src: '',
  onClick: noop,
  clickable: false,
  showRateComponent: false,
  initialRate: 0,
  rateCallBack: noop
};

export default UserCard;
