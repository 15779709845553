import React from 'react';
import PropTypes from 'prop-types';
import Microphone from 'meeting/chime/amazon-chime-sdk/src/components/ui/icons/Microphone';
import Camera from 'meeting/chime/amazon-chime-sdk/src/components/ui/icons/Camera';
import ScreenShare from 'meeting/chime/amazon-chime-sdk/src/components/ui/icons/ScreenShare';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import UserIcon from 'components/userIcon/UserIcon';
import { useIsHost } from 'hooks/useUserRole';
import './styles.scss';

const Participant = ({
  name,
  userImage,
  moreActions,
  cameraEnabled,
  micEnabled,
  meetingControls,
  screenSharing,
  partId,
  isControlsEnabled,
  company,
  showCompany,
}) => {
  const {
    vonage: { session },
  } = useSelector(state => state);

  const isHost = useIsHost();

  const toggleSpeakerAudio = () => {
    if (micEnabled) {
      // eslint-disable-next-line no-unused-expressions
      session?.signal(
        {
          data: `muteAudio${partId}`,
        },
        function (error) {
          if (error) {
            console.error('signal error (' + error.name + '): ' + error.message);
          }
        }
      );
    }
  };

  const toggleSpeakerVideo = () => {
    if (cameraEnabled) {
      // eslint-disable-next-line no-unused-expressions
      session?.signal(
        {
          data: `turnOffVideo${partId}`,
        },
        function (error) {
          if (error) {
            console.error('signal error (' + error.name + '): ' + error.message);
          }
        }
      );
    }
  };

  const micClasses = classNames({
    'cursor-pointer': isHost && micEnabled && isControlsEnabled,
  });

  const cameraClasses = classNames({
    'cursor-pointer': isHost && cameraEnabled && isControlsEnabled,
  });

  const participantWrapperClasses = classNames('d-flex', {
    'align-items-start': showCompany,
    'align-items-center': !showCompany,
  });

  return (
    <div className="d-flex align-items-center justify-content-between mb-4">
      <div className={participantWrapperClasses}>
        <UserIcon
          img={userImage}
          className="symbol symbol-circle symbol-35 mr-3"
          imgClassName="participant-img"
        />
        <div className="d-flex flex-column">
          <span className="text-dark-75 font-weight-bold font-size-lg participant-info">
            {name}
          </span>
          {!!showCompany && <span className="text-muted mt-2 participant-info">{company}</span>}
        </div>
      </div>
      {!!meetingControls && (
        <div className="d-flex flex-nowrap meeting-icons">
          <Microphone
            className={micClasses}
            onClick={isHost && isControlsEnabled && toggleSpeakerAudio}
            muted={!micEnabled || !isControlsEnabled}
          />
          <Camera
            className={cameraClasses}
            onClick={isControlsEnabled && isHost && toggleSpeakerVideo}
            disabled={!cameraEnabled || !isControlsEnabled}
          />
          {!!screenSharing && <ScreenShare />}
        </div>
      )}
      {moreActions.length ? (
        <div className="d-flex">
          {moreActions.map(data => (
            <span key={data.key}>{data.action}</span>
          ))}
        </div>
      ) : null}
    </div>
  );
};

Participant.propTypes = {
  name: PropTypes.string.isRequired,
  userImage: PropTypes.string,
  moreActions: PropTypes.array,
  meetingControls: PropTypes.bool,
  partId: PropTypes.string.isRequired,
  company: PropTypes.string,
  showCompany: PropTypes.bool.isRequired,
};

Participant.defaultProps = {
  userImage: '',
  meetingControls: false,
  moreActions: [],
  company: '',
};

export default Participant;
