import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { ModalLayout } from 'layouts/ModalLayout';
import { closeConfirmationModal } from 'store/confirmationModal/actions';

type Props = {
  isShow: boolean;
};

const ConfirmationModal = ({ isShow }: Props) => {
  const dispatch = useDispatch();
  const { text, callback } = useSelector((state: AppState) => state.confirmationModal);

  const handleCloseModal = () => dispatch(closeConfirmationModal());

  const handleSubmit = () => {
    handleCloseModal();
    callback();
  };

  return (
    <ModalLayout centered isVisible={isShow} onClose={handleCloseModal}>
      <div className='confirmation-modal'>
        <div className='confirmation-modal-content'>
          <div className='confirmation-modal-content-text m-4'>
            <p>{text}</p>
          </div>
          <div className='d-flex w-100 justify-content-center'>
            <Button className='mx-2' onClick={handleSubmit}>
              Yes
            </Button>
            <Button
              className='mx-2'
              variant='outline-dark'
              onClick={handleCloseModal}
            >
              No
            </Button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ConfirmationModal;
