import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PollBuilder } from 'react-banzai';
import { SortableContainer } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { createPoll, reorderPoll } from 'helpers/poll';
import { reorderPolls } from 'store/poll/actions';
import SortableItem from './SortableItem';
import '../styles.scss';

export interface Poll {
  poll_id: string;
  poll_title: string;
  poll_options: Object[];
  poll_type: string;
  order: number;
}

const options = [
  {
    id: '1',
    value: '1',
    votes: []
  },
  {
    id: '2',
    value: '2',
    votes: []
  }
];

const SortableList = SortableContainer(({ items }: any) => {
  return (
    <div>
      {items.map((poll: Poll, index: number) => (
        // @ts-ignore
        <SortableItem key={`item-${poll.poll_id}`} index={index} poll={poll} />
      ))}
    </div>
  );
});

const Builder = () => {
  const dispatch = useDispatch();
  const {
    socket: { socket },
    app: {
      virtual_room: { chat_id }
    }
  } = useSelector((state: AppState) => state);

  const draftPolls = useSelector((state: AppState) => {
    return state.poll.data
      .filter((poll: any) => {
        return poll.poll_status === 'draft';
      })
      .sort((a: any, b: any) => a.order - b.order);
  });

  const onCreatePoll = (poll: any) => {
    const {
      question: poll_title,
      options: poll_options,
      type: poll_type
    } = poll;
    createPoll(socket, chat_id, {
      poll_title,
      poll_type,
      poll_options,
      poll_status: 'draft',
      order: draftPolls.length
    });
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const reorderedList = arrayMove(draftPolls, oldIndex, newIndex) || [];
    const newData = reorderedList.map((item: any, i: number) => {
      return { ...item, order: i };
    });
    dispatch(reorderPolls(newData));
    reorderPoll(socket, chat_id, newData);
  };

  return (
    
    <div>
      {
      // @ts-ignore
      <SortableList items={draftPolls} onSortEnd={onSortEnd} />
      }
      <PollBuilder
        questionText='question text'
        options={options}
        onSubmit={onCreatePoll}
      />
    </div>
  );
};

export default Builder;
