import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { ContainerProfileForm } from 'components/index';
import HotJarTracking from 'components/HotJarTracking/HotJarTracking';
import { SidebarLayout } from 'layouts/index';
import { applyTimezone } from 'helpers';
import { faEnvelopeOpen, faUser } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUser } from 'store/auth/actions';
import './ProfilePage.scss';

export const ProfilePage: React.FC = () => {
  const dispatch = useDispatch();
  const [avatarLoading, setAvatarLoading] = useState(true);
  const [activePage, setActivePage] = useState('profile');
  let {
    auth: {
      user: {
        first_name = '',
        last_name = '',
        email = '',
        linkedin = '',
        created_at = '',
        last_login_date = '',
        image = '',
        company = '',
        headline = '',
        bio = '',
      } = {},
    },
  } = useSelector((state: AppState) => state);

  useEffect(() => {
    dispatch(getUser());
    // eslint-disable-next-line
  }, []);

  let imageView = image ? (
    <>
      {avatarLoading && (
        <div className="d-flex w-100 h-100 position-absolute top-0 left-0 spin-loader">
          <Spinner animation="border" className="m-auto" />
        </div>
      )}
      <img
        src={image}
        alt="profile"
        onLoad={() => setAvatarLoading(false)}
        onError={() => setAvatarLoading(false)}
      />
    </>
  ) : (
    <FontAwesomeIcon
      icon={faUser as IconProp}
      color="#ffffff"
      size="10x"
      className="p-4 w-100 mt-auto"
    />
  );

  return (
    <>
      <HotJarTracking />
      <SidebarLayout>
        {activePage === 'edit-profile' ? (
          <ContainerProfileForm chnageActivePage={() => setActivePage('profile')} />
        ) : (
          <div className="min-h-100 p-4 overflow-auto">
            <div className="profile-page min-h-100">
              <Row className="profile-main-info justify-content-between m-0 p-4">
                <Col sm={4} className="stats">
                  <h5>Profile Stats</h5>
                  <p>Joined {applyTimezone(created_at)}</p>
                  <p>Last Login {applyTimezone(last_login_date)}</p>
                </Col>
                <Col sm={4} className="user-info p-0">
                  <div>
                    <div className="avatar position-relative d-inline-flex">{imageView}</div>
                    <p>
                      {first_name} {last_name}
                    </p>
                    <p>{company}</p>
                    <p>{headline}</p>
                  </div>
                </Col>
                <Col sm={4} className="edit-profile-button p-0">
                  <Button
                    variant="link"
                    className="p-0"
                    onClick={() => setActivePage('edit-profile')}
                  >
                    Edit Profile
                  </Button>
                </Col>
              </Row>
              <div className="mt-8 border-top">
                <div className="mx-13">
                  {!!bio && (
                    <>
                      <p className="text-primary">Bio:</p>
                      <p className="bio-info">{bio}</p>
                    </>
                  )}
                  <p className="text-primary mt-8">Contact Info:</p>
                  <div className="d-flex align-items-center mb-4">
                    <span style={{ fontSize: '24px' }}>
                      <FontAwesomeIcon icon={faEnvelopeOpen as IconProp} className="mr-3" />
                    </span>
                    <p className="m-0">{email}</p>
                  </div>
                  {!!linkedin && (
                    <div className="d-flex align-items-center mb-4">
                      <span style={{ fontSize: '24px' }}>
                        <FontAwesomeIcon icon={faLinkedin as IconProp} className="mr-3" />
                      </span>
                      <p className="m-0">{linkedin}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </SidebarLayout>
    </>
  );
};
