import React, { useState } from 'react';
import Countdown from 'react-countdown';
import { applyTimezone } from 'helpers/parseDate';
import { UserCard } from 'react-banzai';
import parse from 'html-react-parser';
import SpeakerModal from 'components/EventInformationTabs/SpeakerModal';
import './styles.scss';

const Completionist = () => (
  <span className='ml-1'> Waiting for host to start the session</span>
);

const EventCountdownCard = ({ data }) => {
  const { start_date, name, speakers, abstract } = data;
  const [show, setShow] = useState(false);
  const [activeSpeaker, setActiveSpeaker] = useState({});

  const onClick = (speaker) => {
    setShow(true);
    setActiveSpeaker(speaker);
  };
  const handleClose = () => {
    setShow(false);
  };

  const formattedDate = applyTimezone(start_date);
  const date = new Date(formattedDate).getTime();

  return (
    <div className='stage-countdown-card card '>
      <div className='d-flex countdown-text font-weight-bold '>
        <span className='mr-3'> Coming up next</span>
        {'|'}
        <Countdown date={date} className='ml-2'>
          <Completionist />
        </Countdown>
      </div>
      <h2 className='my-2 text-left'> {name}</h2>
      <div className='text-justify description'>{parse(abstract)}</div>

      <div>
        {!!speakers ? (
          <div className='stage-countdown-card-speakers'>
            {speakers.map((speaker, index) => {
              return (
                <UserCard
                  name={`${speaker.first_name} ${speaker.last_name}`}
                  key={index}
                  src={speaker.photo}
                  company={speaker.company}
                  position={speaker.position}
                  className='col-sm-6 mt-5'
                  onClick={() => onClick(speaker)}
                />
              );
            })}
          </div>
        ) : null}
        <SpeakerModal
          show={show}
          onHide={handleClose}
          activeSpeaker={activeSpeaker}
          hasNextSession={true}
        />
      </div>
    </div>
  );
};

export default EventCountdownCard;
