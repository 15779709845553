import React, { memo } from 'react';
import { EventCardInList } from 'react-banzai';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import './EventsCardList.scss';

interface EventsCardListProps {
  isLoading: boolean | null;
  events: Event[];
}

const EventsCardList: React.FC<EventsCardListProps> = ({ isLoading, events }) =>
  isLoading ? (
    <EventsCardListLoader />
  ) : (
    <EventsCardListItems events={events} isLoading={isLoading} />
  );

const EventsCardListLoader: React.FC = () => (
  <div className='events-card-list__custom-loader'>
    <CustomLoader />
  </div>
);

const EventsCardListItems: React.FC<EventsCardListProps> = ({
  isLoading,
  events
}) => (
  <>
    {isLoading !== null && events?.length === 0 ? (
      <p className='mt-3 text-center'>No events to show</p>
    ) : (
      <div className='flex-wrap d-flex'>
        {events?.map((event: Event) => (
          <EventCardInList key={event.event_code} event={event} />
        ))}
      </div>
    )}
  </>
);

export default memo(EventsCardList);
