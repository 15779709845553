import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMenuItems, getOneEvent } from 'store/events/actions';
import styles from './EventPage.module.scss';
import { FullLayout, SidebarLayout } from 'layouts';
import { EventTabs } from 'react-banzai';
import { Alert, Spinner } from 'react-bootstrap';
import EventOverview from 'components/EventInformationTabs/EventOverview';
import Speakers from 'components/EventInformationTabs/speakers';
import Sponsors from 'components/EventInformationTabs/sponsors';
import Exhibitors from 'components/EventInformationTabs/exhibitors';
import { EventRegistrationForm } from 'components/EventRegistration/EventRegistration';
import Duration from 'components/Duration/Duration';
import eventPageHOC from '../hocs/eventPageHOC';
import AddToCalendarShow from 'components/AddToCalendarShow/AddToCalendarShow';
import HotJarTracking from 'components/HotJarTracking/HotJarTracking';
import { useFlag } from 'react-unleash-flags';

const Index: React.FC = () => {
  const [tabs, setTabs] = useState<any>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id = '' } = useParams() as { id: string };
  const {
    events: { currentEvent, oneEventRequesting, oneEventSuccess, oneEventFailure },
    auth: { loggedIn },
  } = useSelector((state: AppState) => state);
  const { description, speakers, sponsors, exhibitors, event_code, start_date, end_date } =
    currentEvent;

  useEffect(() => {
    loggedIn && dispatch(getMenuItems(id));
    // eslint-disable-next-line
  }, [id, loggedIn]);

  useEffect(() => {
    if (!id) {
      navigate(`/events`);
    } else {
      dispatch(getOneEvent(id, false));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    let shownTabs = [];
    description &&
      shownTabs.push({
        key: 'Overview',
        content: <EventOverview description={description} />,
      });
    speakers?.length > 0 &&
      shownTabs.push({
        key: 'Speakers',
        content: <Speakers speakers={speakers} />,
      });
    sponsors?.length > 0 &&
      shownTabs.push({
        key: 'Sponsors',
        content: <Sponsors sponsors={sponsors} />,
      });
    exhibitors?.length > 0 &&
      shownTabs.push({
        key: 'Exhibitors',
        content: <Exhibitors sponsors={exhibitors} event_code={event_code} />,
      });
    setTabs(shownTabs);
  }, [description, speakers, sponsors, exhibitors, event_code]);

  const showGif = useFlag('show-gif');

  return (
    <>
      <HotJarTracking />
      <SidebarLayout hideEventName>
        <FullLayout>
          {oneEventRequesting && <Spinner animation="border" className="m-auto" />}
          {oneEventFailure && <Alert className="alert-danger m-auto">Event not found</Alert>}
          {oneEventSuccess && (
            <div className="overflow-auto h-100 p-4">
              <div className="bg-white min-h-100">
                <div className="p-4">
                  <h1>{currentEvent.name}</h1>
                  <div>
                    <AddToCalendarShow
                      event={currentEvent}
                      showStartDate={true}
                      description={currentEvent.ics_description}
                    />
                    <Duration endDate={end_date} startDate={start_date} />
                  </div>
                </div>
                <div className="d-flex flex-wrap">
                  <div className="col-md-8 col-sm-12">
                    {currentEvent.image && (
                      <img src={currentEvent.image} alt="img" className={styles.eventImg} />
                    )}
                    {showGif?.enabled && (
                      <img
                        alt=""
                        src="https://i.kym-cdn.com/photos/images/newsfeed/001/418/154/5a1.gif"
                      />
                    )}
                    <div className="mt-6">
                      <EventTabs tabs={tabs} />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <EventRegistrationForm />
                  </div>
                </div>
              </div>
            </div>
          )}
        </FullLayout>
      </SidebarLayout>
    </>
  );
};

export const EventPage = eventPageHOC(Index);
