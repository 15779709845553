import {
  CHANGE_SESSIONS_BUTTONS_STATE,
  GET_STAGE_SESSIONS_LOADING,
  GET_STAGE_SESSIONS_RESET,
  RESET_DATA,
  SESSIONS_RECORD_FAILURE,
  SESSIONS_RECORD_REQUEST,
  SESSIONS_RECORD_SUCCESS,
  SET_GREEN_ROOM_DATA,
  SET_GREEN_ROOM_LOADING,
  SET_SESSION_TIME,
  SET_SHOW_MUTE_BUTTON,
  SET_STAGE_SESSIONS,
  STAGE_SESSIONS_REQUEST_LOADING,
  START_SESSION_FROM_GREEN_ROOM
} from './constants';

const initialState = {
  roomId: null,
  data: [],
  loading: false,
  sessionRecord: {
    data: {},
    loading: false
  },
  isSessionSpeaker: null,
  sessionsLoading: false,
  sessionStartTime: undefined,
  showMuteButton: false,
  greenRooms: null,
  greenRoomsLoading: false,
  sessionStartedFromGreenRoom: null,
  isSessionsControlsButtonsDisabled: false
};

export function sessions(state = { ...initialState }, action: any) {
  switch (action.type) {
    case STAGE_SESSIONS_REQUEST_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }
    case SET_STAGE_SESSIONS: {
      return {
        ...state,
        roomId: action.roomId,
        data: [...action.payload],
        loading: false
      };
    }
    case SESSIONS_RECORD_REQUEST: {
      return {
        ...state,
        sessionRecord: {
          ...state.sessionRecord,
          loading: true
        }
      };
    }
    case SESSIONS_RECORD_SUCCESS: {
      return {
        ...state,
        sessionRecord: {
          ...state.sessionRecord,
          data: action.data,
          loading: false
        }
      };
    }
    case SESSIONS_RECORD_FAILURE: {
      return {
        ...state,
        sessionRecord: {
          ...state.sessionRecord,
          data: {},
          loading: false
        }
      };
    }
    case GET_STAGE_SESSIONS_LOADING: {
      return {
        ...state,
        sessionsLoading: action.payload
      };
    }
    case SET_SESSION_TIME: {
      return {
        ...state,
        sessionStartTime: action.payload
      };
    }
    case SET_SHOW_MUTE_BUTTON: {
      return {
        ...state,
        showMuteButton: action.payload
      };
    }
    case GET_STAGE_SESSIONS_RESET: {
      return { ...initialState };
    }
    case RESET_DATA: {
      return {
        ...state,
        data: [],
        roomId: null
      };
    }
    case SET_GREEN_ROOM_LOADING: {
      return {
        ...state,
        greenRoomsLoading: action.payload
      };
    }
    case SET_GREEN_ROOM_DATA: {
      return {
        ...state,
        greenRooms: action.payload
      };
    }
    case START_SESSION_FROM_GREEN_ROOM: {
      return {
        ...state,
        sessionStartedFromGreenRoom: action.payload
      };
    }
    case CHANGE_SESSIONS_BUTTONS_STATE: {
      return {
        ...state,
        isSessionsControlsButtonsDisabled: action.payload
      };
    }
    default:
      return state;
  }
}
