import {
  RECIEVE_POLL,
  RECIEVE_VOTE,
  DELETE_POLL,
  EDIT_POLL,
  CLEAR_POLL,
  ARCHIVE_QUESTION,
  REORDER_POLLS,
  CLEAR_SHOW_POLL_NOTIFICATION,
  SET_LAST_POLL_DATE,
  SET_SHOW_POLL_NOTIFICATION
} from './constants';

let initialState: any = {
  data: [],
  showPollNotification: false,
  lastPollDate: '',
};

export const poll = (state = initialState, action: any) => {
  switch (action.type) {
    case RECIEVE_POLL:
      return {
        ...state,
        data: [...state.data, action.payload],
        showPollNotification: action.payload.sendNotification
      };
    case RECIEVE_VOTE: {
      let data = [...state.data];
      let currentPollIndex = data.findIndex((item) => {
        return item.poll_id === action.payload.poll_id;
      });

      if (currentPollIndex !== -1) {
        data[currentPollIndex] = action.payload;
      }
      return {
        ...state,
        data
      };
    }
    case DELETE_POLL: {
      let id = action.payload;
      let data = [...state.data];
      data = data.filter((poll) => poll.poll_id !== id);
      return {
        ...state,
        data
      };
    }
    case EDIT_POLL: {
      let data = [...state.data];
      data = data.map((poll) => {
        return poll.poll_id === action.payload.poll_id ? action.payload : poll;
      });
      let isPollExists = data.find(
        (poll) => poll.poll_id === action.payload.poll_id
      );
      !isPollExists && data.push(action.payload);
      return {
        ...state,
        data
      };
    }
    case CLEAR_POLL: {
      return initialState;
    }
    case ARCHIVE_QUESTION: {
      let data = [...state.data];
      const { poll_id } = action.payload;
      data = data.filter((poll) => poll.poll_id !== poll_id);
      return {
        ...state,
        data
      };
    }
    case REORDER_POLLS: {
      let data = [...state.data];
      const newData = action.payload;

      data = data.map((poll) => {
        const pollItem = newData.find(
          (item: any) => item.poll_id === poll.poll_id
        );

        if (poll.poll_status === 'draft') {
          return pollItem;
        }
        return poll;
      });

      return {
        ...state,
        data
      };
    }
    case CLEAR_SHOW_POLL_NOTIFICATION: {
      return {
        ...state,
        showPollNotification: false
      };
    }
    case SET_LAST_POLL_DATE: {
      return {
        ...state,
        lastPollDate: action.payload
      };
    }
    case SET_SHOW_POLL_NOTIFICATION: {
      return {
        ...state,
        showPollNotification: true
      };
    }
    default:
      return state;
  }
};
