import React from 'react';

import { SponsorPanel } from 'react-banzai';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './styles.scss';
interface Props {
  sponsors: Sponsor[];
}

const Sponsors: React.FC<Props> = ({ sponsors = [] }) => {
  return (
    <Row className='m-0'>
      {sponsors.map((sponsor, index) => (
        <Col key={index} xl={4} md={6} sm={12} className='mt-5 sponsors-card'>
          <SponsorPanel
            className={`w-100 h-100 ${
              sponsor.level !== null && 'sponsors-card-header'
            }`}
            key={`${index}`}
            src={sponsor.logo}
            level={sponsor.level}
            url={sponsor.URL}
          />
        </Col>
      ))}
    </Row>
  );
};

export default Sponsors;
