import React, { useEffect } from "react";
import {
    selectIsConnectedToRoom,
    useHMSActions,
    useHMSStore
  } from "@100mslive/react-sdk";
import JoinForm from "./JoinForm/JoinForm";
import Header from "./Header/Header";
import Conference from "./Conference/Conference";
import Footer from "./Footer/Footer";
import './styles.scss';

 const MsVideoChat = () => {
    const isConnected = useHMSStore(selectIsConnectedToRoom);
    const hmsActions = useHMSActions();
  
    useEffect(() => {
      window.onunload = () => {
        if (isConnected) {
          hmsActions.leave();
        }
      };
    }, [hmsActions, isConnected]);
  
    return (
      <div className="ms-video-chat">
        <Header />
        {isConnected ? (
          <>
            <Conference />
            <Footer />
          </>
        ) : (
          <JoinForm />
        )}
      </div>
    );
  }
  
   export default MsVideoChat;