import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Questions as QuestionsAccordion, SendMessage } from 'react-banzai';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { QUESTION_EVENTS } from 'store/questionsAndAnswers/constants';
import { setShowConfirmationModal } from 'store/confirmationModal/actions';
import {
  clearShowQuestionsNotification,
  getQuestions,
  setTabOpenLastDate,
} from 'store/questionsAndAnswers/actions';
import { defaultUserImg } from 'constants/images';
import { formattedSender } from 'utils/ChatUtils';
import modalTexts from 'constants/modalTexts';
import Comments from './Comments';
import './styles.scss';

interface Props {
  anonymous: boolean;
}

export const Questions: React.FC<Props> = ({ anonymous = false }) => {
  const dispatch = useDispatch();
  const lastQuestionRef = useRef<HTMLDivElement>(null);
  const myEl = useRef<any>({});
  const questionsBlockRef = useRef(null);
  const questionFirstItem = useRef(null);
  const [isAnonym, setIsAnonym] = useState(false);
  const [activeComment, setActiveCommentData]: any = useState({});

  const {
    socket: { socket },
    app: {
      virtual_room: { chat_id },
    },
    auth: {
      user: { id, first_name, last_name, image },
    },
    questionsAndAnswers: { hasMore = false, questionsRequestsCount, scrollToLastElement, data: questionsAndAnswersData },
    communicationModules: { activeTab },
  } = useSelector((state: AppState) => state);

  const [localQuestionsRequestsCount, setLocalQuestionsRequestsCount] =
    useState(questionsRequestsCount);

  const likeQuestion = (questionId: string) => {
    socket &&
      socket.emit(QUESTION_EVENTS.QUESTION_lIKE, chat_id, {
        questionId,
        participantId: id,
      });
  };

  const removeQuestion = (questionId: string) => {
    socket &&
      socket.emit(QUESTION_EVENTS.QUESTION_REMOVE, chat_id, {
        questionId,
      });
    setActiveCommentData({});
  };

  const confirmRemoveQuestion = (questionId: string) => {
    dispatch(
      setShowConfirmationModal({
        text: modalTexts.removeQuestion,
        callback: () => removeQuestion(questionId),
      })
    );
  };

  const removeComment = (commentId: string, questionId: string) => {
    socket &&
      socket.emit(QUESTION_EVENTS.COMMENT_REMOVE, chat_id, {
        commentId,
        questionId,
      });
  };

  const confirmRemoveComment = (commentId: string, questionId: string) => {
    dispatch(
      setShowConfirmationModal({
        text: modalTexts.removeComment,
        callback: () => removeComment(commentId, questionId),
      })
    );
  };

  const questions = useSelector((state: AppState) => {
    return Object.values(state.questionsAndAnswers.data)
      .filter((question: any) => question)
      .map((question: any) => {
        const isQuestionLiked = question.questionLikes
          ? Array.prototype.includes.call(question.questionLikes, id)
          : false;
        return {
          key: question.questionId,
          questionData: question.questionData,
          date: question.date,
          questionId: question.questionId,
          questionSender: {
            name: question.questionSender,
            img: question.senderImg,
          },
          questionLikes: question.questionLikes || [],
          isQuestionLiked,
          className: 'btn',
          onLikeClick: (id: string) => likeQuestion(id),
          removeQuestion: (id: string) => confirmRemoveQuestion(id),
          comments: question.comments,
          children: (
            <>
              <Comments
                setActiveCommentData={setActiveCommentData}
                comments={question.comments}
                removeComment={confirmRemoveComment}
              />
              <div
                className="comment-ref"
                id={question.questionId}
                ref={r => (myEl.current[question.questionId] = r)}
              ></div>
            </>
          ),
        };
      });
  });

  useEffect(() => {
    const tabOpenDate = Date.now();
    socket?.emit('tab_open_date', chat_id, { date: tabOpenDate });
    dispatch(setTabOpenLastDate(tabOpenDate));
    //eslint-disable-next-line
  }, [questions?.length]);

  useEffect(() => {
    const current: any = lastQuestionRef?.current;
    let firstQuestionElm: any = questionFirstItem?.current;
    if (!questionsRequestsCount) {
      current?.scrollIntoView({ behavior: 'smooth' });
    } else if (questionsRequestsCount !== localQuestionsRequestsCount) {
      setLocalQuestionsRequestsCount(questionsRequestsCount);
      firstQuestionElm.scrollTop = 400;
    }
    firstQuestionElm.current = current.childNodes[1];
    // eslint-disable-next-line
  }, [chat_id, questionsRequestsCount]);

  useEffect(() => {
    const lastQuestionRefCurrent: any = lastQuestionRef?.current;
    lastQuestionRefCurrent?.scrollIntoView();
    // eslint-disable-next-line
  }, [activeTab, scrollToLastElement]);

  const scrollToLastComment = () => {
    const el = document.querySelector(`#${activeComment?.questionId}`);
    el &&
      el.scrollIntoView({
        block: 'end',
        inline: 'end',
        behavior: 'smooth',
      });
  };

  useEffect(() => {
    activeComment.questionId && scrollToLastComment();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeComment.questionId]);

  const onMessageSent = (text: any, mentions: Array<any>): void => {
    const messageData = text
      ?.replaceAll('@@@__', '<span class="text-primary">@')
      ?.replaceAll('@@@^^^', '</span>');
    const sender = formattedSender({
      id,
      first_name,
      last_name,
    });
    if (questionsAndAnswersData[activeComment.questionId] && activeComment.questionId) {
      socket?.emit('new_comment', chat_id, {
        questionId: activeComment?.questionId,
        sender,
        senderImg: isAnonym ? defaultUserImg : image,
        commentData: messageData,
        isAnonym,
        mentions,
      });
      setTimeout(() => {
        scrollToLastComment();
      }, 500);
    } else {
      socket?.emit('new_question', chat_id, {
        sender,
        questionData: messageData,
        senderImg: isAnonym ? defaultUserImg : image,
        isAnonym: isAnonym,
        mentions,
        date: Date.now(),
      });
    }
  };

  useEffect(() => {
    dispatch(clearShowQuestionsNotification());

    // eslint-disable-next-line
  }, []);

  const { current }: any = questionsBlockRef;
  const element: any = current?.children[0];

  useEffect(() => {
    const scrollHandler = () => {
      if (
        hasMore &&
        chat_id &&
        element?.scrollTop === 0 &&
        element?.clientHeight !== element?.scrollHeight
      ) {
        dispatch(getQuestions(false, chat_id, id));
      }
    };

    if (hasMore) {
      element?.addEventListener('scroll', scrollHandler);
    } else {
      element?.removeEventListener('scroll', scrollHandler);
    }
    return () => {
      element?.removeEventListener('scroll', scrollHandler);
    };
    //eslint-disable-next-line
  }, [element, hasMore]);

  return (
    <div ref={questionsBlockRef} className="questions-wrapper aside-content h-100 p-4">
      <div className="questions" ref={questionFirstItem}>
        {!!Object.keys(questions)?.length && (
          <QuestionsAccordion
            items={questions}
            activeIndex={activeComment.questionId}
            collapseCallBack={scrollToLastComment}
            changeActiveIndex={(item: any) => {
              if (item.questionId !== activeComment.questionId) setActiveCommentData(item);
              else setActiveCommentData({});
            }}
          />
        )}
        <div ref={lastQuestionRef} />
      </div>
      <div className="new-question pt-4">
        <SendMessage
          onMessageSent={onMessageSent}
          placeholder={questionsAndAnswersData[activeComment.questionId] ? 'Reply to the question' : 'Write a question'}
        />
        {questionsAndAnswersData[activeComment.questionId] && activeComment.questionId && (
          <Button variant="link" onClick={() => setActiveCommentData({})}>
            Write a question
          </Button>
        )}
        {anonymous && (
          <Form.Check
            className="d-flex align-items-center pt-4 anonymous-checkbox"
            type="checkbox"
            checked={isAnonym}
            id={'id'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIsAnonym(e.target.checked)}
            label="Send Anonymously"
          />
        )}
      </div>
    </div>
  );
};
