import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Accordion from 'react-bootstrap/Accordion';
import QuestionItem from './QuestionItem';
import './style.scss';

const Questions = ({
  items,
  activeIndex,
  changeActiveIndex,
  className,
  collapseCallBack
}) => {
  return (
    <Accordion activeKey={activeIndex} className={className}>
      {items.map((item, index) => {
        return item ? (
          <QuestionItem
            key={item.questionId}
            activeIndex={activeIndex}
            changeActiveIndex={changeActiveIndex}
            item={item}
            collapseCallBack={collapseCallBack}
            isLastItem={index === (items.length-1)}
          />
        ) : null;
      })}
    </Accordion>
  );
};

Questions.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  variant: PropTypes.string,
  activeIndex: PropTypes.string,
  changeActiveIndex: PropTypes.func
};

Questions.defaultProps = {
  items: [],
  className: '',
  variant: ''
};

export default memo(Questions);
