import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import _ from 'lodash';
import {
  AUTH_RESET,
  CHANGE_PASSSWORD_FAILURE,
  CHANGE_PASSSWORD_REQUEST,
  CHANGE_PASSSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_FAILURE,
  CHANGE_USER_PASSWORD_REQUEST,
  CHANGE_USER_PASSWORD_RESET,
  EDIT_USER_DATA_FAILURE,
  EDIT_USER_DATA_REQUEST,
  EDIT_USER_DATA_SUCCESS,
  FORGOT_PASSSWORD_FAILURE,
  FORGOT_PASSSWORD_REQUEST,
  FORGOT_PASSSWORD_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  MAGIC_LINK_FAILURE,
  MAGIC_LINK_REQUEST,
  MAGIC_LINK_SUCCESS,
  SET_USER_CURRENT_LOCATION,
  SIGNUP_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  VERIFY_FAILURE,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
} from './constants';
import ROUTES from 'constants/routes';
import { AuthToken, getRouteUrl, replaceUrlParams } from 'helpers/index';
import { disconnectFromSocket } from 'store/socket/thunk';
import { setEventSocketId, setSocket } from 'store/socket/actions';
import * as api from 'api';
import {
  getMenuItems,
  getOneEvent,
  resetEventsData,
  setRedirectionURL,
} from 'store/events/actions';
import { disconnectFromEventSocket } from 'store/socket/event-socket';
import { resetSessions } from 'store/sessions/actions';
import { setAudio, setVideo } from '../vonage/actions';

const messagesList: any = {
  404: "User with this email address doesn't exist",
  602: 'Validation error. The password format is invalid. The password confirmation format is invalid.',
  603: "User with this email address doesn't exist or you used wrong password",
  606: 'There already exists a user with this email. Please, try to log in.',
  607: 'Email/verification code is wrong',
  609: 'Email/verification code is wrong',
  612: 'The limit of pasting credentials is completed. Please try again, later!',
};

const getErrorMessage = (statusCode: any) => {
  return messagesList[statusCode] || 'Please check your internet connection';
};

export const authResetAction = () => ({ type: AUTH_RESET });

const verifyRequest = () => ({ type: VERIFY_REQUEST });

const verifySuccess = () => ({
  type: VERIFY_SUCCESS,
});

const verifyFailure = (message: string) => ({
  type: VERIFY_FAILURE,
  error: message,
});

export const verifyAction: any = ({ email, code }: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(verifyRequest());

      const { data: { data } = {} } = await api.confirmAccount({ email, code });
      const { access_token, refresh_token, userInfo } = data;
      AuthToken.set('access_token', access_token);
      AuthToken.set('refresh_token', refresh_token);
      dispatch(loginSuccess(userInfo));
      dispatch(verifySuccess());
    } catch (error: any) {
      const { details: { code = null } = {} } = error.response?.data;
      const message = getErrorMessage(code);
      dispatch(verifyFailure(message));
    }
  };
};

const signupRequest = () => ({ type: SIGNUP_REQUEST });

const signupSuccess = (email: string) => ({
  type: SIGNUP_SUCCESS,
  email,
});

const signupFailure = (message: string) => ({
  type: SIGNUP_FAILURE,
  error: message,
});

export const signupAction: any = ({
  first_name,
  last_name,
  email,
  password,
  passwordСonfirmation,
}: User) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(signupRequest());

      await api.signup({
        first_name: first_name,
        last_name: last_name,
        email,
        password,
        passwordСonfirmation,
      });

      dispatch(signupSuccess(email));
    } catch (error: any) {
      const { details: { code = null } = {} } = error.response?.data;
      const message = getErrorMessage(code);

      dispatch(signupFailure(message));
    }
  };
};

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = (user: Object) => ({
  type: LOGIN_SUCCESS,
  user,
});

export const loginFailure = (message: string) => ({
  type: LOGIN_FAILURE,
  error: message,
});

export const loginAction: any = ({ username, password, routeParams }: any) => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(loginRequest());

      const { data: { data } = {} } = await api.login(username, password);
      const { access_token, refresh_token, userInfo } = data;
      AuthToken.set('access_token', access_token);
      AuthToken.set('refresh_token', refresh_token);
      dispatch(loginSuccess(userInfo));
      dispatch(setRedirectionURL(''));
      if (routeParams) {
        dispatch(getMenuItems(routeParams));
        dispatch(getOneEvent(String(routeParams), false));
      }
    } catch (error: any) {
      const { details: { code = null } = {} } = error.response?.data;
      const message = getErrorMessage(code);

      dispatch(loginFailure(message));
    }
  };
};

export const loginWithMagicLink: any = ({ magic_token, eventCode }: any) => {
  return async (dispatch: Dispatch): Promise<any> => {
    try {
      const res = await api.loginWithMagicLink(magic_token, eventCode);
      const { data: { data = {} } = {} } = res || {};

      const { access_token, refresh_token, userInfo, is_registered } = data;
      AuthToken.set('access_token', access_token);
      AuthToken.set('refresh_token', refresh_token);
      dispatch(loginSuccess(userInfo));
      if (eventCode && is_registered) {
        const { customItems } = await dispatch(getMenuItems(eventCode));
        if (!_.isEmpty(customItems)) {
          const link = getRouteUrl(customItems, eventCode);
          dispatch(setRedirectionURL(link));
          await api.joinEventPost(eventCode);
        } else {
          dispatch(setRedirectionURL(replaceUrlParams(ROUTES.EVENT, eventCode)));
          toast.info('Thank you for your registration. The event will be available soon.');
        }
      } else if (eventCode) {
        dispatch(setRedirectionURL(replaceUrlParams(ROUTES.EVENT, eventCode)));
        toast.error('Please, contact with event manager because you registration was cancelled.');
      } else {
        dispatch(setRedirectionURL(ROUTES.EVENTS));
      }
    } catch (error: any) {
      if (eventCode) {
        dispatch(push(ROUTES.EVENTS));
        toast.error('There is a server error. Please reset your password.');
      }
    }
  };
};

const forgotPasswordRequest = () => ({ type: FORGOT_PASSSWORD_REQUEST });
const forgotPasswordSuccess = (email: string) => ({
  type: FORGOT_PASSSWORD_SUCCESS,
  forgotPasswordEmail: email,
});
export const forgotPasswordFailure = (message: string) => ({
  type: FORGOT_PASSSWORD_FAILURE,
  error: message,
});

export const forgotPasswordAction = (email: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(forgotPasswordRequest());

      const { data: { success } = {} } = await api.forgotPassword(email);
      if (success) dispatch(forgotPasswordSuccess(email));
    } catch (error: any) {
      const { details: { code = null } = {} } = error.response?.data;
      const message = getErrorMessage(code);

      dispatch(forgotPasswordFailure(message));
    }
  };
};

const changePasswordRequest = () => ({ type: CHANGE_PASSSWORD_REQUEST });
const changePasswordSuccess = () => ({ type: CHANGE_PASSSWORD_SUCCESS });
const changePasswordFailure = (message: string) => ({
  type: CHANGE_PASSSWORD_FAILURE,
  error: message,
});

export const changePasswordAction = ({ username, code, password, routeParams }: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(changePasswordRequest());

      const { data: { data } = {} } = await api.resetPassword({
        username,
        code,
        password,
      });

      dispatch(changePasswordSuccess());
      const { access_token, refresh_token, userInfo } = data;
      AuthToken.set('access_token', access_token);
      AuthToken.set('refresh_token', refresh_token);
      dispatch(loginSuccess(userInfo));
      if (routeParams) dispatch(getMenuItems(routeParams));
    } catch (error: any) {
      const { details: { code = null } = {} } = error.response?.data;
      const message = getErrorMessage(code);

      dispatch(changePasswordFailure(message));
    }
  };
};

export const logoutAction: any =
  () =>
  (dispatch: Dispatch): void => {
    AuthToken.remove('access_token');
    AuthToken.remove('refresh_token');
    dispatch({ type: LOGOUT });
    dispatch(disconnectFromSocket() as any);
    dispatch(disconnectFromEventSocket() as any);
    dispatch(resetSessions() as any);
    dispatch(resetEventsData() as any);
    dispatch(setRedirectionURL(`${ROUTES.EVENTS}`));
    dispatch(setSocket(null, 'eventSocket'));
    dispatch(setSocket(null, 'socket'));
    dispatch(setAudio(false));
    dispatch(setVideo(false));
    dispatch(setEventSocketId(''));
  };

export const getUser = () => async (dispatch: Dispatch) => {
  dispatch(loginRequest());

  try {
    const { data: { data } = {} } = await api.getUser();
    dispatch(loginSuccess(data));
  } catch (error: any) {
    dispatch(loginFailure(error));
  }
};

export const sendMagicLinkRequest = (email: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(magicLinkRequest());

      await api.sendMagicToken(email);
      dispatch(magicLinkSuccess());
    } catch (error: any) {
      const { details: { code = null } = {} } = error.response?.data;
      const message = getErrorMessage(code);

      dispatch(magicLinkFailure(message));
    }
  };
};

const magicLinkRequest = () => ({ type: MAGIC_LINK_REQUEST });
const magicLinkSuccess = () => ({ type: MAGIC_LINK_SUCCESS });
const magicLinkFailure = (message: string) => ({
  type: MAGIC_LINK_FAILURE,
  message,
});

// edit user data
const editUserDataRequest = () => ({ type: EDIT_USER_DATA_REQUEST });
const editUserDataSuccess = (user: User) => ({
  type: EDIT_USER_DATA_SUCCESS,
  user,
});
const editUserDataFailure = (message: string) => ({
  type: EDIT_USER_DATA_FAILURE,
  message,
});

export const editUserDataAction = (data: User) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(editUserDataRequest());

      await api.editUserData(data);
      dispatch(editUserDataSuccess(data));
    } catch (error: any) {
      const { details: { code = null } = {} } = error.response?.data;
      const message = getErrorMessage(code);

      dispatch(editUserDataFailure(message));
    }
  };
};

// Change user password
const changeUserPasswordRequest = () => ({
  type: CHANGE_USER_PASSWORD_REQUEST,
});
const changeUserPasswordFailure = () => ({
  type: CHANGE_USER_PASSWORD_FAILURE,
});
export const changeUserPasswordReset = () => ({
  type: CHANGE_USER_PASSWORD_RESET,
});
export const changeUserPasswordAction = (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(changeUserPasswordRequest());
      const res = await api.changePassword(data);
      if (res.status === 200) {
        toast.info('Your password is successfully changed');
      }
      dispatch(changeUserPasswordReset());
    } catch (error: any) {
      dispatch(changeUserPasswordFailure());
    }
  };
};

export const setUserCurrentLocation = (payload: string | null) => {
  return {
    type: SET_USER_CURRENT_LOCATION,
    payload,
  };
};
