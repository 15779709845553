import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import './styles.module.scss'

const MediaControl = ({ className, icon, tooltip }) => {
  const [wrapperClass, setWrapperClass] = useState('symbol-transparent')

  const _className = classNames('symbol', wrapperClass, className)
  const handleOnMouseEnter = () => setWrapperClass('symbol-light-primary')
  const handleOnMouseLeave = () => setWrapperClass('symbol-transparent')

  return (
    <OverlayTrigger
      placement='top'
      overlay={<Tooltip id={tooltip}>{tooltip}</Tooltip>}
    >
      <div className={_className} style={{ cursor: 'pointer' }}>
        <div
          className='symbol-label'
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          style={{ padding: '2rem' }}
        >
          {icon}
        </div>
      </div>
    </OverlayTrigger>
  )
}

MediaControl.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  tooltip: PropTypes.string
}

MediaControl.defaultProps = {
  className: '',
  tooltip: null
}

export default MediaControl
