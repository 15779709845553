import styled, { keyframes } from 'styled-components';

interface TickerWrapperProps {
  duration: string;
}

const moveTickerMessageRTL = keyframes`
  {
    0% {
      -webkit-transform: translatex(0);
      transform: translatex(0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translatex(-100%);
      transform: translatex(-100%);
    }
  }
`;

export const TickerContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  height: 34px;
  padding-left: 100%;
  box-sizing: content-box;
`;

export const TickerWrapper = styled.div<TickerWrapperProps>`
  width: fit-content;
  display: flex;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;
  height: 100%;

  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ${moveTickerMessageRTL};
  animation-name: ${moveTickerMessageRTL};
  -webkit-animation-duration: ${({ duration }) => duration};
  animation-duration: ${({ duration }) => duration};

  ${TickerContainer}:hover & {
    animation-play-state: paused;
  }
`;

export const Item = styled.div`
  display: inline-block;
  padding: 0 2rem;
  font-size: 12px;
  display: flex;
  align-items: center;

  & > p {
    margin-bottom: 0;
  }
`;
