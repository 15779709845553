import { SET_SPONSORS, SET_LOADING, SET_ORGANIZATION } from './constants';

let initialState = {
  sponsors: [],
  currentOrganization: {},
  loading: false
};
export function sponsors(state = initialState, action: any) {
  switch (action.type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: action.data
      };
    }
    case SET_SPONSORS: {
      return {
        ...state,
        sponsors: action.data
      };
    }
    case SET_ORGANIZATION: {
      return {
        ...state,
        currentOrganization: action.data
      };
    }
    default:
      return state;
  }
}
