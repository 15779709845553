import { REACT_APP_SOCKET_URL } from 'constants/index';
import { instance } from 'api/auth';

export function getChatMessages(
  count: number,
  chat_id: string,
  partId: string
) {
  return instance.get(`${REACT_APP_SOCKET_URL}get-messages`, {
    params: {
      count,
      chat_id,
      partId
    }
  });
}

export function getAdminChatMessages(
  count: number,
  chat_id: string,
  partId: string,
  sessionId: number | string | null
) {
  return instance.get(`${REACT_APP_SOCKET_URL}get-admin-messages`, {
    params: {
      count,
      chat_id,
      partId,
      sessionId
    }
  });
}
