import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { fitText } from '../../../utils/fitText'
import './styles.scss'

const propTypes = {
  boxed: PropTypes.bool,
  text: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  subtext: PropTypes.string
}

const defaultProps = {
  boxed: false,
  subtext: ''
}

const BoxedText = ({ boxed, height, width, subtext, text }) => {
  const textContainerRef = useRef()
  const [isResized, setIsResized] = useState(false)

  useEffect(() => {
    if (textContainerRef.current && !isResized) {
      fitText(textContainerRef.current, 0.25)
      setIsResized(true)
    }
  }, [isResized])

  // Note: for now the only box we support is stroke and no fill
  return (
    <div
      className={classNames(
        'bzi-boxedText d-flex flex-column align-items-center justify-content-center',
        { 'box-stroke': boxed }
      )}
      style={{ width, height }}
      ref={textContainerRef}
    >
      <div className='bzi-boxedText__text'>{text}</div>
      {!isEmpty(subtext) && (
        <div className='bzi-boxedText__subtext'>{subtext}</div>
      )}
    </div>
  )
}

BoxedText.propTypes = propTypes
BoxedText.defaultProps = defaultProps

export default React.memo(BoxedText)
