import React, { useState } from 'react';
import { noop } from 'lodash';
import { Editor } from '@tinymce/tinymce-react';
import { REACT_APP_HTML_EDITOR_KEY } from 'constants/index';
import { toast } from 'react-toastify';

const htmlEditorInitConfig = {
  height: 300,
  menubar: false,
  plugins: [
    'advlist autolink lists link image',
    'charmap print preview anchor help',
    'searchreplace visualblocks code',
    'insertdatetime media table powerpaste wordcount'
  ],
  toolbar: 'bold italic underline link'
};

interface Props {
  setMessageText: any;
  messageText: string;
}

const defaultProps: Props = {
  setMessageText: noop,
  messageText: ''
};

const TickerMessageHtmlEditor: React.FC<Props> = ({
  setMessageText,
  messageText
}) => {
  const sizeLimit = 200;
  const [length, setLength] = useState(0);

  const handleInit = (evt: any, EditorArg: any) => {
    setLength(EditorArg.getContent({ format: 'text' }).length);
  };

  const handleUpdate = (messageValue: string, EditorArg: any) => {
    const contentLength = EditorArg.getContent({ format: 'text' }).length;
    contentLength > sizeLimit
      ? toast.error(
          'Please make sure the text has no more than 200 characters.'
        )
      : setMessageText(messageValue);
    setLength(contentLength);
  };

  return (
    <>
    {
      // @ts-ignore
      <Editor
      apiKey={REACT_APP_HTML_EDITOR_KEY}
      value={messageText}
      onInit={handleInit}
      onEditorChange={handleUpdate}
      init={htmlEditorInitConfig}
    />
    }
      <p>Remaining: {sizeLimit - length}</p>
    </>
  );
};

TickerMessageHtmlEditor.defaultProps = defaultProps;

export default TickerMessageHtmlEditor;
