import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { UserCarousel } from 'react-banzai';
import classnames from 'classnames';
import * as api from 'api';
import { getMenuItem } from 'helpers/getRouteUrl';
import { useIsHost } from 'hooks/useUserRole';
import { useActiveSession } from 'hooks/useSessionStatus';
import { setShowConfirmationModal } from 'store/confirmationModal/actions';
import modalTexts from 'constants/modalTexts';
import styles from 'pages/EventSessionPage.module.scss';
import SponsorLogo from './SponsorLogo';
import './index.scss';

interface Sponsor {
  URL: string;
  logo: string;
  name: string;
  id: string;
}

interface Props {
  sponsors: Sponsor[];
  sponsorsData: any;
  eventId: string;
  customMenu: any;
}

const carouselSettings = {
  swipe: true,
  infinite: false,
  slidesToShow: 8,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const SponsorLogoCarousel: React.FC<Props> = ({ sponsors, sponsorsData, eventId, customMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    socket: { eventSocket },
    auth: {
      user: { id: userId },
      currentLocation,
    },
    participants: { data: participants },
  } = useSelector((state: AppState) => state);

  const goToSponsor = (id: string) => {
    const menuItem = getMenuItem(location, customMenu);

    if (menuItem) {
      api.sponsorAreaTrack({
        event_code: eventId,
        submitted: 'sponsorJoin',
        customMenuId: menuItem.id,
        sponsorId: id,
        socketId: eventSocket.id,
        userId,
        location: currentLocation,
      });
      const sponsorsLink = `/event/${eventId}/sponsors/${id}/stage`;
      navigate(sponsorsLink);
    }
  };

  const redirectToSponsor: any = (sponsorData: any) => {
    if (!!sponsorData?.booth_id && !!sponsorData?.enable_meeting) {
      return goToSponsor(sponsorData?.id);
    }
  };
  const isParticipants = Object.entries(participants);
  const isHost = useIsHost();

  const participantData: any =
    isParticipants.length && isParticipants.find((partPair: any) => partPair[1]?.partId === userId);

  const activeSession = useActiveSession();

  const is_speaker =
    participantData &&
    activeSession?.speakers?.find(
      ({ email: speakerEmail }: any) => speakerEmail === participantData[1]['email']
    );

  const handleSponsorLogoClick = (sponsorData: any) => {
    const isShowConfirmationModal: boolean =
      activeSession &&
      (isHost || is_speaker) &&
      !!sponsorData.booth_id &&
      !!sponsorData.enable_meeting;
    if (isShowConfirmationModal) {
      dispatch(
        setShowConfirmationModal({
          text: modalTexts.leaveSession,
          callback: () => redirectToSponsor(sponsorData),
        })
      );
    } else {
      redirectToSponsor(sponsorData);
    }
  };

  return (
    <div className="sponsor-logo-carousel bg-white w-100 mt-4 d-flex align-items-start flex-wrap">
      <span className={classnames(styles.sponsorTitle, 'pt-4 pl-4 font-size-lg')}>
        Sponsored by:
      </span>
      <UserCarousel configs={carouselSettings} className="w-50 mx-10 py-4 flex-1">
        {sponsors.map((sponsor: Sponsor) => {
          const singleSponsor: any =
            sponsorsData.find((elem: any) => elem.name === sponsor.name) || {};
          return (
            <div
              key={'sponsor-' + singleSponsor.booth_id}
              className={classnames(styles.sponsorBand, 'px-2')}
            >
              <Button
                onClick={() => handleSponsorLogoClick(singleSponsor)}
                variant="outline-secondary"
                className={classnames(
                  'sponsor-logo-carousel-button w-100 h-100 p-2 d-flex flex-column bg-hover-white align-items-center justify-content-center',
                  {
                    'cursor-pointer': !!singleSponsor.booth_id && !!singleSponsor.enable_meeting,
                    'cursor-default': !singleSponsor.booth_id || !singleSponsor.enable_meeting,
                  }
                )}
              >
                <SponsorLogo sponsor={sponsor} />
              </Button>
            </div>
          );
        })}
      </UserCarousel>
    </div>
  );
};

export default SponsorLogoCarousel;
