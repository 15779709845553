import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { useIsHost } from 'hooks/useUserRole';
import TextShow from 'components/TextShow/TextShow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import UserIcon from 'components/userIcon/UserIcon';
import './style.scss';

const Comment = ({ commentData, commentSender, date, className, removeComment }) => {
  const classes = classNames(
    'chat-comment d-flex pr-2 pl-2 pt-2 mb-1 w-100 flex-row-reverse',
    className
  );

  const isHost = useIsHost();
  const formattedDate = useMemo(() => {
    return moment(new Date(date)).format('HH:mm A');
  }, [date]);

  const commentBoxClasses = classNames('rounded p-2 d-flex flex-column answer-item');

  const imgClasses = classNames(
    'symbol symbol-circle symbol-35 m-2 comment-img-wrapper'
  );

  return (
    <div className={classes}>
      <UserIcon img={commentSender.img} className={imgClasses} />
      <div className={commentBoxClasses}>
        <div className='font-weight-bolder text-right'>
          {commentSender.name}
        </div>
        <div
          className='mb-3 mt-3 w-100 d-flex align-items-center pb-2 justify-content-end'>
          <div
            className='answer-item-date text-muted font-size-sm text-center message-time p-1 message-content-date'
          >
            {formattedDate}
          </div>
          {isHost && (
            <FontAwesomeIcon
              className="cursor-pointer mr-2"
              style={{ width: "24px", height: "14px" }}
              icon={faTrash}
              color='#B5B5C3'
              onClick={removeComment}
            />
          )}
          <TextShow text={commentData} textShowCount={150} />
        </div>
      </div>
    </div>
  );
};

Comment.propTypes = {
  commentData: PropTypes.string,
  commentSender: PropTypes.shape({
    name: PropTypes.string,
    img: PropTypes.string
  }).isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  removeComment: PropTypes.func
};

Comment.defaultProps = {
  className: '',
  removeComment: () => {
    return
  }
};

export default Comment;
