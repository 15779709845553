import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const EventTabs = ({ tabs }) => {
  return (
    <Tabs id='event-tabs'>
      {tabs.map((data) => (
        <Tab key={data.key} eventKey={data.key} title={data.key}>
          {data.content}
        </Tab>
      ))}
    </Tabs>
  );
};

const tabType = PropTypes.shape({
  key: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string
});

EventTabs.propTypes = {
  tabs: PropTypes.arrayOf(tabType)
};

EventTabs.defaultProps = { tabs: [] };

export default memo(EventTabs);
