import {
  CHANGE_ACTIVE_TAB,
  CHAT_EVENTS,
  CLEAR_CHAT,
  ERROR_MESSAGES,
  LOADED_ADMIN_MESSAGES,
  LOADED_MESSAGES,
  PIN_MESSAGE,
  RECEIVE_ADMIN_MESSAGE,
  RECEIVE_ADMIN_MESSAGES,
  RECEIVE_MESSAGE,
  RECEIVE_MESSAGES,
  REMOVE_MESSAGE,
  SET_MESSAGE_REF_PART_ID
} from './constants';

const initialState = {
  loaded: false,
  messages: [],
  hasMore: true,
  error: null,
  messageRefPartId: 'all',
  adminLoaded: false,
  adminMessages: [],
  adminHasMore: true,
  activeChat: 'public'
};

export function chat(state = { ...initialState }, action: any) {
  switch (action.type) {
    case RECEIVE_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload]
      };
    case RECEIVE_ADMIN_MESSAGE:
      return {
        ...state,
        adminMessages: [...state.adminMessages, action.payload]
      };
    case PIN_MESSAGE: {
      const messages = state.messages;
      const pinnedMessageId = action.payload.messageId;
      const message: any =
        messages.find(
          (message: any) => message.messageId === pinnedMessageId
        ) || null;
      if (message) message.isMessagePinned = action.payload.isMessagePinned;

      return {
        ...state,
        messages
      };
    }
    case REMOVE_MESSAGE: {
      let messages = [...state.messages];
      const messageId = action.payload.messageId;
      messages = messages.filter(
        (message: any) => message.messageId !== messageId
      );

      return {
        ...state,
        messages
      };
    }
    case CLEAR_CHAT:
      return initialState;

    case LOADED_MESSAGES: {
      return {
        ...state,
        loaded: action.payload
      };
    }

    case LOADED_ADMIN_MESSAGES: {
      return {
        ...state,
        activeChat: 'admin',
        adminLoaded: action.payload
      };
    }

    case RECEIVE_MESSAGES: {
      let messages = [];
      if (action.isFirstCall) {
        messages = action.payload.data;
      } else {
        messages = [...action.payload.data, ...state.messages];
      }

      return {
        ...state,
        messages,
        hasMore: action.payload.hasMore
      };
    }

    case RECEIVE_ADMIN_MESSAGES: {
      let adminMessages = [];
      if (action.isFirstCall) {
        adminMessages = action.payload.data;
      } else {
        adminMessages = [...action.payload.data, ...state.adminMessages];
      }

      return {
        ...state,
        adminMessages,
        adminHasMore: action.payload.hasMore
      };
    }

    case ERROR_MESSAGES: {
      return {
        ...state,
        error: action.payload
      };
    }

    case SET_MESSAGE_REF_PART_ID: {
      return {
        ...state,
        messageRefPartId: action.payload
      };
    }

    case CHANGE_ACTIVE_TAB: {
      return {
        ...state,
        activeChat: action.payload
      };
    }

    case CHAT_EVENTS.CLEAR_ADMIN_CHAT: {
      return {
        ...state,
        adminMessages: []
      };
    }

    case CHAT_EVENTS.CLEAR_PUBLIC_CHAT: {
      return {
        ...state,
        messages: []
      };
    }

    default:
      return state;
  }
}
