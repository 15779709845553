export interface Participant {
  id: string;
  name: string;
  userImage: string | boolean;
  alt: string;
  email?: boolean;
}

export interface Props {
  visibleParticipants: Object;
  allParticipants: Array<any>;
  className?: string;
  isHost: boolean;
}

export const defaultProps: Props = {
  visibleParticipants: {},
  allParticipants: [],
  className: '',
  isHost: false,
};
