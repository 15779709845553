import {
  CLEAR_PARTICIPANTS_LIST,
  GET_MORE_PARTICIPANTS,
  SET_MORE_PARTICIPANTS_LOADING,
  UPDATE_PARTICIPANTS_LIST
} from './constants';

export const updateParticipantsList = (payload: Object) => {
  return {
    type: UPDATE_PARTICIPANTS_LIST,
    payload
  };
};

export const clearParticipantsList = () => {
  return {
    type: CLEAR_PARTICIPANTS_LIST
  };
};

export const getMoreParticipants = () => {
  return {
    type: GET_MORE_PARTICIPANTS
  };
};

export const setMoreParticipantsLoading = (payload: boolean) => {
  return {
    type: SET_MORE_PARTICIPANTS_LOADING,
    payload
  };
};
