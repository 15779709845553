import { Modal } from 'react-bootstrap';
import React from 'react';

interface Props {
  isVisible?: boolean;
  onClose?: (() => {}) | (() => void);
  header?: any;
  footer?: any;
  centered?: boolean;
  modalClassName?: string;
  modalBodyClassName?: string;
  size?: 'sm' | 'lg' | 'xl';
}

export const ModalLayout: React.FC<Props> = ({
  isVisible,
  onClose,
  footer,
  children,
  centered,
  header,
  modalClassName = '',
  modalBodyClassName = '',
  size,
}) => {
  return (
    <Modal
      size={size}
      centered={centered}
      show={isVisible}
      onHide={onClose}
      className={modalClassName}
    >
      {!!header && <Modal.Header> {header}</Modal.Header>}
      {
        // @ts-ignore
        <Modal.Body className={modalBodyClassName}>{children}</Modal.Body>
      }
      {!!footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};
