import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { FormField } from 'components/FormField/FormField';
import { Alert, Button, Spinner } from 'react-bootstrap';
import * as yup from 'yup';
import { validationMessages } from 'constants/validationMessages';
import { useDispatch, useSelector } from 'react-redux';
import { sendMagicLinkRequest } from 'store/auth/actions';
import { isEmpty } from 'utils/checkTypes';

const initialValues = {
  username: '',
};

const MagicLinkValidation = yup.object().shape({
  username: yup.string().email(validationMessages.email).required(validationMessages.required),
});

interface Props {
  title?: string;
}

interface FormInterface {
  username: string;
}

export const MagicLinkForm: React.FC<Props> = ({
  title = 'Enter your email to get a magic link',
}) => {
  const { magicLinkRequesting, magicLinkMessage, magicLinkError, magicLinkSuccess } = useSelector(
    (state: AppState) => state.auth
  );
  const dispatch = useDispatch();

  const [counter, setCounter] = useState(30);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    if (!counter) setIsCounting(false);
  }, [counter]);

  useEffect(() => {
    let timer: any;
    if (isCounting && counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [counter, isCounting]);

  const onSubmit = ({ username }: FormInterface) => {
    dispatch(sendMagicLinkRequest(username));
    setIsCounting(true);
    setCounter(30);
  };

  const resend = ({ username }: FormInterface) => {
    setIsCounting(true);
    setCounter(30);
    dispatch(sendMagicLinkRequest(username));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={MagicLinkValidation}
    >
      {({ values, errors }) => {
        const disabledButton = !Object.values(values).every(Boolean);
        return (
          <Form>
            {title && <h4 className="mb-7 text-center">{title}</h4>}
            {magicLinkError && magicLinkMessage && (
              <Alert variant="danger">{magicLinkMessage}</Alert>
            )}
            {!magicLinkSuccess && (
              <>
                <FormField type="text" name="username" placeholder="Enter your email" />
                <Button block disabled={disabledButton || !isEmpty(errors)} type="submit">
                  {magicLinkRequesting ? (
                    <Spinner animation="border" role="status" aria-hidden="true" />
                  ) : (
                    'Send me a magic link'
                  )}
                </Button>
              </>
            )}
            {magicLinkSuccess && (
              <>
                <Alert variant="success" className="text-center">
                  We've sent you magic link. Please check your e-mail.
                </Alert>
                <Button
                  block
                  onClick={() => {
                    resend(values);
                  }}
                  disabled={isCounting}
                >
                  {!isCounting && !magicLinkRequesting && 'Re-send magic link'}
                  {isCounting && !magicLinkRequesting && <>You can resend in {counter} seconds</>}
                  {magicLinkRequesting && (
                    <Spinner animation="border" role="status" aria-hidden="true" />
                  )}
                </Button>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
