import React from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner, Alert, FormGroup, Row, Col } from 'react-bootstrap';
import * as yup from 'yup';
import { FormField, FormCheckbox } from 'components/index';
import { validationMessages } from 'constants/validationMessages';
import { signupAction } from 'store/auth/actions';
import links from 'constants/links';
import { isEmpty } from 'utils/checkTypes';

const SignUpValidation = yup.object().shape({
  first_name: yup
    .string()
    .min(2, validationMessages.tooShort + '2')
    .required(validationMessages.required),
  last_name: yup
    .string()
    .min(2, validationMessages.tooShort + '2')
    .required(validationMessages.required),
  username: yup.string().email(validationMessages.email).required(validationMessages.required),
  password: yup
    .string()
    .min(8, validationMessages.tooShort + '8')
    .required(validationMessages.required),
  passwordСonfirmation: yup
    .string()
    .min(8, validationMessages.tooShort + '8')
    .oneOf([yup.ref('password')], validationMessages.passwordMatch)
    .required(validationMessages.required),
  acceptTerms: yup.bool().oneOf([true], validationMessages.required),
});

const initialValues: any = {
  first_name: '',
  last_name: '',
  username: '',
  password: '',
  passwordСonfirmation: '',
  acceptTerms: false,
  marketing: false,
};

interface Props {
  title?: string;
  successMessage: string;
}

export const SignUpForm: React.FC<any> = ({ title = 'Create Your Account' }) => {
  const dispatch = useDispatch();
  const { signingUp, signupError } = useSelector((state: AppState) => state.auth);

  const onSubmit = ({ username, password, passwordСonfirmation, first_name, last_name }: User) => {
    //FixMe: Check what if we can get rid of username in cognito
    dispatch(
      signupAction({
        first_name,
        last_name,
        email: username,
        password,
        passwordСonfirmation,
      })
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={SignUpValidation}
    >
      {({ values, errors }) => {
        const cloneValues: any = { ...values };
        delete cloneValues['marketing'];
        const enableButton = Object.values(cloneValues).every(Boolean);

        return (
          <Form>
            {title && <h4 className="mb-7 text-center">{title}</h4>}
            {signupError && <Alert variant="danger">{signupError}</Alert>}
            <Row>
              <Col md="6">
                <FormField type="text" name="first_name" placeholder="First Name" />
              </Col>
              <Col md="6">
                <FormField type="text" name="last_name" placeholder="Last Name" />
              </Col>
            </Row>
            <FormField type="text" name="username" placeholder="Email" />
            <FormField type="password" name="password" placeholder="Password" />
            <FormField type="password" name="passwordСonfirmation" placeholder="Confirm Password" />
            <FormGroup>
              <FormCheckbox name="acceptTerms" value="acceptTerms">
                <span>
                  By creating this account, you agree to Banzai's accept{' '}
                  <a target="_blank" href={links.termsAndConditions} rel="noopener noreferrer">
                    Terms &amp; Conditions
                  </a>{' '}
                  and the{' '}
                  <a target="_blank" href={links.privacyPolicy} rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                  .
                </span>
              </FormCheckbox>
            </FormGroup>

            <FormGroup>
              <FormCheckbox name="marketing" value="marketing">
                <span>
                  I agree to receive product updates and marketing communications from Banzai.
                </span>
              </FormCheckbox>
            </FormGroup>
            <Button block disabled={!enableButton || !isEmpty(errors)} type="submit">
              {signingUp ? (
                <Spinner animation="border" role="status" aria-hidden="true" />
              ) : (
                'Sign up'
              )}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
