import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import classnames from 'classnames';
import { Button } from 'react-banzai';
import ROUTES from 'constants/routes';
import { banzaiLogo } from 'constants/images';
import { useIsHost, useUserIsCurrentEventSpeaker } from 'hooks/useUserRole';
import { useActiveSession } from 'hooks/useSessionStatus';
import { useIsExpectedPage } from 'hooks/usePageLocation';
import { getOneEvent, setEventLoading } from 'store/events/actions';
import { startSessionFromGreenRoom } from 'store/sessions/actions';
import SessionControls from './SessionControls/SessionControls';
import PublicHeader from './PublicHeader';
import PrivateHeader from './PrivateHeader';
import SessionTiming from './SessionTiming/SessionTiming';
import GreenRoomHeader from './GreenRoomControls';
import './style.scss';

interface HeaderProps {
  hideEventName?: boolean;
}

const Header: React.FC<HeaderProps> = ({ hideEventName }) => {
  const { pathname = '' } = useLocation();
  const { greenRoomId = '' }: any = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    auth: { loggedIn, user: { id: userId = '' } = {} },
    events: { currentEvent, oneEventLoading, allEventsLoading },
    vonage: { archive },
    sessions: { data: sessionData = [], loading, sessionStartTime, greenRooms },
    participants: { data: participantsData },
  } = useSelector((state: AppState) => state);

  const { brand = {}, company_logo = '', is_host: isEventHost, name, event_code } = currentEvent;

  const isEventType = pathname.includes('/event');
  const isHost = useIsHost();
  const activeSession = useActiveSession();
  const isStagePage = useIsExpectedPage(ROUTES.STAGE);
  const isGreenRoomPage = useIsExpectedPage(ROUTES.GREEN_ROOM);

  const isLastSession =
    sessionData.length && sessionData[sessionData.length - 1].status === 'previous';

  const showBanzaiLogo = useIsExpectedPage([ROUTES.EVENTS, ROUTES.MY_EVENTS, ROUTES.PROFILE]);

  const handleClickBanzaiLogo = () => {
    dispatch(setEventLoading(true));
    navigate('/');
  };

  const handleClickBrandLogo = () => {
    if (oneEventLoading) return;
    dispatch(getOneEvent(event_code, false));
  };

  const brandLogoClasses = classnames('brand-logo', {
    waiting: oneEventLoading,
  });

  const headerLogoClasses = classnames('header-content__logo', {
    waiting: allEventsLoading,
  });

  const isUserLoggedIn = loggedIn && userId;

  const headerRightPart = isUserLoggedIn ? <PrivateHeader /> : <PublicHeader />;

  const userIsCurrentEventSpeaker = useUserIsCurrentEventSpeaker();

  const showGreenRoomHeaderInStagePage = isEventHost || userIsCurrentEventSpeaker;

  const showGreenRoomHeader = !!showGreenRoomHeaderInStagePage && (isStagePage || isGreenRoomPage);

  const headerLeftPartClasses = classnames('d-flex align-items-center header-content__part', {
    'col-xxl-8 col-lg-8 justify-content-between col-sm-12 px-0': showGreenRoomHeader,
  });

  const headerRightPartClasses = classnames('d-flex justify-content-end header-content__part', {
    'col-xxl-4 col-lg-4 justify-content-between justify-content-xxl-between col-sm-12 px-0':
      showGreenRoomHeader,
  });

  const headerContentRowClasses = classnames(
    'header-content__row d-flex justify-content-between align-items-center',
    {
      'row px-4': showGreenRoomHeader,
    }
  );

  const { session_status = null, session_title = '' } =
    greenRooms?.find(({ id }: any) => +id === +greenRoomId) || {};

  const showEventName = !isHost && !hideEventName && isEventType;

  const showStartButton = isGreenRoomPage && isHost && session_status === 'next';

  const showSessionTiming = isStagePage && activeSession && !loading && userId;

  const showSessionControls = isStagePage && isHost && !isLastSession;

  const handleStartSession = () => dispatch(startSessionFromGreenRoom(activeSession));

  return (
    <div className="header-content px-8 container-fluid justify-content-between py-2 bg-white border-bottom border-secondary">
      <div className={headerContentRowClasses}>
        <div className={headerLeftPartClasses}>
          <div className="aside-brand d-flex align-items-center">
            {showBanzaiLogo ? (
              <div className={headerLogoClasses} onClick={handleClickBanzaiLogo}>
                <img className="banzai-logo" src={banzaiLogo} alt="It is a Banzai company logo" />
              </div>
            ) : (
              <div className="d-flex flex-row align-items-center">
                <span className={brandLogoClasses} onClick={handleClickBrandLogo}>
                  <img src={brand?.logo || company_logo} alt={brand?.name} />
                </span>
                <div className="container-fluid p-0">
                  <div className="row m-0">
                    <div className="ml-4 col-12">
                      {showEventName && <h1 className="header-content__event-name">{name}</h1>}
                      <div className="d-flex justify-content-between flex-wrap align-items-center header-content__session">
                        {isGreenRoomPage && (
                          <h5 className="h5 header-content__session-title">{session_title}</h5>
                        )}
                        {showSessionControls && (
                          <SessionControls
                            archive={archive}
                            sessionData={sessionData}
                            loading={loading}
                            currentEvent={currentEvent}
                          />
                        )}
                        {showStartButton && (
                          <Button className="ml-4" onClick={handleStartSession}>
                            Start session
                          </Button>
                        )}
                        {showSessionTiming && (
                          <SessionTiming
                            archive={archive}
                            sessionStartTime={sessionStartTime}
                            participantsData={participantsData}
                            userId={userId}
                            activeSession={activeSession}
                            isHost={isHost}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={headerRightPartClasses}>
          <>
            {showGreenRoomHeader && (
              <div className="d-flex align-items-center green-room-header">
                <GreenRoomHeader />
              </div>
            )}
            {headerRightPart}
          </>
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
