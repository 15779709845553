import { Dispatch } from 'redux';
import {
  SET_MEETING,
  SET_ATTENDEE,
  SET_THEME,
  CLEAR_MEETING
} from './constants';
import * as api from 'api/index';

export const joinMeeting = (): Object => {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    try {
      const state = getState();
      let roomId = state.app.virtual_room.id;
      let eventCode = state.events.currentEvent.event_code;
      if (roomId && eventCode) {
        const { data } = await api.joinToChimeMeeting(roomId, eventCode);
        const { meeting, attendee } = data?.data;
        dispatch({
          type: SET_MEETING,
          payload: meeting
        });
        dispatch({
          type: SET_ATTENDEE,
          payload: attendee
        });
        return {
          meeting,
          attendee
        };
      }
    } catch (err: any) {
      console.error(err);
    }
  };
};

export const setTheme: any = () => {
  return {
    type: SET_THEME
  };
};

export const clearMeeting = () => ({
  type: CLEAR_MEETING
});
