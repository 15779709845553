import React from 'react';
import { Formik, Form, FormikValues } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Spinner, Alert } from 'react-bootstrap';
import * as yup from 'yup';
import { FormField } from 'components/index';
import { validationMessages } from 'constants/validationMessages';
import { loginAction } from 'store/auth/actions';
import { isEmpty } from 'utils/checkTypes';

const LoginValidation = yup.object().shape({
  username: yup.string().email(validationMessages.email).required(validationMessages.required),
  password: yup.string().required(validationMessages.required),
});

const initialValues = {
  username: '',
  password: '',
};

interface Props {
  title?: string;
  successMessage?: string;
  onOpenMagicLinkForm?: () => void;
}

export const LoginForm: React.FC<Props> = ({
  title = 'Sign in to access your account',
  successMessage = '',
  onOpenMagicLinkForm = () => {},
}) => {
  const dispatch = useDispatch();
  const { id: routeParams = '' } = useParams() as { id: string };
  const {
    auth: { loggingIn, loginError, verified },
  } = useSelector((state: AppState) => state);

  const onSubmit = ({ username, password }: FormikValues) => {
    dispatch(loginAction({ username, password, routeParams }));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={LoginValidation}
    >
      {({ values, errors }) => {
        const disabledButton = !Object.values(values).every(Boolean);
        return (
          <Form>
            {title && <h4 className="mb-7 text-center">{title}</h4>}
            {loginError && <Alert variant="danger">{loginError}</Alert>}
            {verified && !loginError && <Alert variant="success">{successMessage}</Alert>}
            <FormField type="text" name="username" placeholder="Email" />
            <FormField type="password" name="password" placeholder="Password" />
            <Button block disabled={disabledButton || !isEmpty(errors)} type="submit">
              {loggingIn ? (
                <Spinner animation="border" role="status" aria-hidden="true" />
              ) : (
                'Sign in'
              )}
            </Button>
            <span className="btn btn-link btn-block" onClick={onOpenMagicLinkForm}>
              Sign in with a magic link
            </span>
          </Form>
        );
      }}
    </Formik>
  );
};
