import React from 'react';
import './CharacterAvatar.scss';

interface CharacterAvatarProps {
  name: string;
  children?: JSX.Element | JSX.Element[];
}

const CharacterAvatar: React.FC<CharacterAvatarProps> = ({ name }) => {
  const character = name.trim().slice(0, 1);
  return <div className='character-avatar'>{character}</div>;
};

export default CharacterAvatar;
