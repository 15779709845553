import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Form } from 'react-bootstrap'
import './styles.scss'

const DropdownCheckbox = ({ content, icon, enabled, disabled }) => {
  let check = icon
  if (!enabled) {
    check = <Form.Check type='checkbox' />
  }
  return (
    <Dropdown.Item disabled={disabled}>
      {check}
      {content}
    </Dropdown.Item>
  )
}

DropdownCheckbox.propTypes = {
  content: PropTypes.string.isRequired,
  check: PropTypes.node,
  enabled: PropTypes.bool,
  disabled: PropTypes.bool
}

DropdownCheckbox.defaultProps = {
  enabled: false,
  disabled: false
}
export default DropdownCheckbox
