import { SET_SHOW_CONFIRMATION_MODAL, CLOSE_CONFIRMATION_MODAL } from './constants';

export const setShowConfirmationModal: any = (payload: any) => ({
  type: SET_SHOW_CONFIRMATION_MODAL,
  payload,
});

export const closeConfirmationModal: any = () => ({
  type: CLOSE_CONFIRMATION_MODAL,
});
