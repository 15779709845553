import React, { ChangeEvent, useEffect, useState } from 'react';
import { Checkbox, Checkboxes, Label, Select, TextInput } from './components';
import './style.scss';
import { IField } from './types';

const Field: React.FC<IField> = ({
  type,
  name,
  title,
  validationErrors,
  required,
  setValidationErrors,
  options,
  initial,
  clicked,
  participantRegData,
  onChangeField
}) => {
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (initial) {
      setTouched(false);
    }
  }, [initial]);

  const validateField = (value: string) => {
    const isValid = required && !value;
    setValidationErrors({ ...validationErrors, [name]: isValid });
  };

  const onChange = (value: any) => {
    setTouched(true);
    validateField(value);
    onChangeField(name, value);
  };

  const textInputChange = (event: any) => {
    const { value } = event.target;
    onChange(value);
  };

  const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    let data = participantRegData[name];
    if (checked) {
      data = [...data, value];
    } else {
      data = data.filter((elm: any) => elm !== value);
    }
    onChange(Array.from(new Set(data)));
  };

  let validationError = (clicked || touched) && validationErrors[name];

  switch (type) {
    case 'opt-in': {
      const props = {
        name,
        required,
        validationError,
        title,
        value: participantRegData[name],
        onChange: (e: any) => {
          const val: string = e.target.checked ? 'Yes' : 'No';
          onChange(val);
        },
        checked: participantRegData[name] === 'Yes'
      };
      return <Checkbox type='checkbox' {...props} />;
    }
    case 'radio':
    case 'checkbox':
    case 'multi-checkbox': {
      const props = {
        required,
        validationError,
        title,
        name,
        value: participantRegData[name],
        type: type !== 'multi-checkbox' ? type : 'checkbox',
        options,
        onChange: type === 'radio' ? textInputChange : onCheckboxChange
      };

      return <Checkboxes {...props} />;
    }
    case 'registrants-count': {
      const props = {
        type: 'number',
        title,
        name,
        onChange: textInputChange,
        min: '1',
        value: participantRegData[name],
        validationError,
        required
      };
      return <TextInput {...props} />;
    }
    case 'label': {
      return <Label title={title} />;
    }
    case 'textarea': {
      const props = {
        name,
        title,
        value: participantRegData[name] || '',
        onChange: textInputChange,
        as: 'textarea',
        rows: '3',
        validationError,
        required
      };
      return <TextInput {...props} />;
    }
    case 'country':
    case 'state':
    case 'category':
    case 'select':
    case 'multi-select': {
      const selectOptions: any =
        type === 'category' || type === 'select' || type === 'multi-select'
          ? options
          : null;
      const changeFn =
        type === 'multi-select'
          ? (val: any) =>
              onChange(val && val.map((select: any) => select.value))
          : (data: any) => onChange(data.value);

      const props = {
        name,
        isMulti: type === 'multi-select',
        type,
        title,
        selectOptions,
        onChange: changeFn,
        validationError,
        required
      };
      return <Select {...props} />;
    }
    default: {
      const props = {
        name,
        title,
        required,
        validationError,
        value: participantRegData[name] || '',
        onChange: textInputChange,
        type
      };
      return <TextInput {...props} />;
    }
  }
};

export default Field;
