import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMeetingManager } from 'meeting/chime/amazon-chime-sdk/src';
import Meeting from '../Meeting';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import Card from 'react-bootstrap/Card';
import { joinMeeting } from 'store/chime/actions';
import { SET_VIRTUAL_ROOM } from 'store/app/constants';
import ROUTES from 'constants/routes';
import { replaceUrlParams } from 'helpers/index';

const MeetingValidation = () => {
  const { pathname } = useLocation();
  const [connected, setConnected] = useState(false);
  const meetingManager = useMeetingManager();
  const { virtual_room } = useSelector((state: AppState) => state.app);
  const {
    currentEvent: { event_code }
  } = useSelector((state: AppState) => state.events);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchMeeting = async () => {
      if (virtual_room.id) {
        const data = await dispatch<any>(joinMeeting());
        if (data) {
          const { meeting, attendee } = data;
          setConnected(true);
          await meetingManager.leave();
          await meetingManager.join({
            meetingInfo: meeting,
            attendeeInfo: attendee
          });
          await meetingManager.start();
          setConnected(true);
        }
      }
    };
    fetchMeeting();
    // eslint-disable-next-line
  }, [virtual_room.id]);

  useEffect(() => {
    return () => {
      if (meetingManager.meetingId) meetingManager.leave();
      if (pathname !== replaceUrlParams(ROUTES.STAGE, event_code)) {
        toast.info('You just left the room', {
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          style: { background: '#3498dbab' }
        });
      }
      dispatch({
        type: SET_VIRTUAL_ROOM,
        payload: ''
      });
      setConnected(false);
    };
    // eslint-disable-next-line
  }, []);

  return <Card className='h-100'>{!connected ? <Loader /> : <Meeting />}</Card>;
};

export default MeetingValidation;
