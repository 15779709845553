// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect } from 'react';
import {
  VideoTileGrid,
  UserActivityProvider,
  useContentShareState,
  useLocalVideo,
  useToggleLocalMute
} from 'meeting/chime/amazon-chime-sdk/src';

import { StyledLayout, StyledContent } from './Styled';
import MeetingDetails from '../../containers/MeetingDetails';
import MeetingControls from '../../containers/MeetingControls';
import useMeetingEndRedirect from '../../hooks/useMeetingEndRedirect';
import { useSelector } from 'react-redux';
import { PARTICIPANTS_EVENTS } from 'store/participants/constants';

const MeetingView = () => {
  const { socket } = useSelector((state: AppState) => state.socket);
  const { user } = useSelector((state: AppState) => state.auth);
  const { is_host } = useSelector(
    (state: AppState) => state.events.currentEvent
  );
  const { id: userId, image } = user;
  const { query: { name = '' } = {}, id = '' } = socket || {};
  const {
    virtual_room: { chat_id }
  } = useSelector((state: AppState) => state.app);
  const { isLocalUserSharing: screenSharing } = useContentShareState();
  const { muted } = useToggleLocalMute();
  const { isVideoEnabled: cameraEnabled } = useLocalVideo();

  useMeetingEndRedirect();

  useEffect(() => {
    socket &&
      socket.emit(PARTICIPANTS_EVENTS.UPDATE_ROSTER, chat_id, {
        [id]: {
          partId: userId,
          name,
          userImage: image,
          micEnabled: !muted,
          cameraEnabled,
          screenSharing,
          is_host
        }
      });
    // eslint-disable-next-line
  }, [muted, cameraEnabled, screenSharing, socket, user]);

  return (
    <UserActivityProvider>
      <StyledLayout showNav={false} showRoster={false}>
        <StyledContent>
          <VideoTileGrid
            className='videos'
            noRemoteVideoView={<MeetingDetails />}
          />
          <MeetingControls />
        </StyledContent>
      </StyledLayout>
    </UserActivityProvider>
  );
};

export default MeetingView;
