import { avatarUrl } from 'constants/vonageMeeting';

export const generateView = (stream: any) => {
  const { name, videoSource, audioEnabled, videoEnabled } = stream;

  return {
    name,
    publishAudio: audioEnabled,
    publishVideo: videoEnabled,
    videoSource: videoSource === 'screen' ? 'screen' : undefined,
    showControls: videoSource !== 'screen',
    style: {
      nameDisplayMode: 'on',
      audioLevelDisplayMode: 'off',
      buttonDisplayMode: 'off',
    },
  };
};

export const generateScreenSharingBlock = (id: string) => {
  const screenBlock = document.getElementById(id);
  if (!screenBlock) {
    const shareBlock: any = document.querySelector('.screen-share-block');
    const div = document.createElement('div');
    div.setAttribute('id', id);
    shareBlock.appendChild(div);
  }
};

export const generateAvatar = (name: string) => {
  const [firstName] = name.split(' ');
  let [firstLetter] = firstName?.split('');
  firstLetter = firstLetter?.toLowerCase() || '';
  return {
    nameDisplayMode: 'on',
    audioLevelDisplayMode: 'off',
    buttonDisplayMode: 'off',
    backgroundImageURI: `${avatarUrl + firstLetter}.png`,
  };
};
