// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import styled from 'styled-components';

export const StyledDesc = styled.div`
  max-height: 250px;
  font-size: 16px;
  line-height: 22px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const StyledLogo = styled.div`
  height: 110px;
  padding: 5px;

  & img {
    width: auto;
    height: 100%;
    max-width: 100%;
  }
`;
