import React, { useState } from 'react';
import linkifyHtml from 'linkify-html';
import Modal from 'react-bootstrap/Modal';
import { ButtonElement } from './CustomButton';
import './CustomButtonModal.scss';

interface Props {
  button_color?: string;
  button_text?: string;
  html?: string;
  type?: string;
  url?: string;
  className?: string;
  icon?: string;
  trackFn: any;
  applyStyles: boolean;
}

const CustomButtonModal: React.FC<Props> = ({
  button_color,
  html = '',
  icon,
  button_text,
  trackFn,
  applyStyles = true
}) => {
  const [show, setShow] = useState(false);

  const handleClose = (): void => setShow(false);
  const handleOpen = (): void => {
    setShow(true);
    trackFn();
  };
  return (
    <>
      {applyStyles ? (
        <ButtonElement
          icon={icon}
          color={button_color}
          name={button_text}
          onClick={handleOpen}
        />
      ) : (
        <button
          className='d-block  border-0 sponsor-card__body-links-item'
          onClick={handleOpen}
        >
          <span>{button_text}</span>
        </button>
      )}
      <Modal
        show={show}
        onHide={handleClose}
        size='lg'
        centered
        className='sponsor-custom-button-modal'
      >
        <Modal.Header closeButton={true} closeLabel='Close'>
          <Modal.Title>{button_text}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: linkifyHtml(html) }} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustomButtonModal;
