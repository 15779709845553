import React, { useEffect } from 'react';
import { Form, Formik, FormikValues } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Spinner } from 'react-bootstrap';
import * as yup from 'yup';
import { FormField } from 'components/FormField/FormField';
import { validationMessages } from 'constants/validationMessages';
import { forgotPasswordAction, forgotPasswordFailure } from 'store/auth/actions';
import { isEmpty } from 'utils/checkTypes';

const initialValues = {
  username: '',
};

const forgotPasswordSchema = yup.object().shape({
  username: yup.string().email(validationMessages.email).required(validationMessages.required),
});

export const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(forgotPasswordFailure(''));
    };
    // eslint-disable-next-line
  }, []);

  const {
    auth: { forgotPasswordRequesting, forgotPasswordError },
  } = useSelector((state: AppState) => state);

  const onSubmit = ({ username }: FormikValues) => {
    dispatch(forgotPasswordAction(username));
  };

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={forgotPasswordSchema}
      initialValues={initialValues}
    >
      {({ values, errors }) => {
        const disabledButton = !Object.values(values).every(Boolean);
        return (
          <Form>
            <h4 className="mb-7 text-center">Reset Your Password</h4>
            {forgotPasswordError && <Alert variant="danger">{forgotPasswordError}</Alert>}
            <FormField type="text" name="username" placeholder="Enter your email" />
            <Button disabled={disabledButton || !isEmpty(errors)} block type="submit">
              {forgotPasswordRequesting ? (
                <Spinner animation="border" role="status" aria-hidden="true" />
              ) : (
                'Submit'
              )}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
