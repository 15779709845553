import React from 'react';
import './style.scss';

interface Props {
  description: string;
}

const EventOverview: React.FC<Props> = ({ description }) => {
  return (
    <div
      className='event-overview-desc text-wrap p-3 line-height-xl overview-tab text-justify'
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );
};

export default EventOverview;
