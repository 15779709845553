export const SESSION_UPDATE = 'update_meeting_session';
export const CHECK_STREAMING_URL = 'check_streaming_url';

export const SET_STAGE_SESSIONS = 'SET_STAGE_SESSIONS';
export const GET_STAGE_SESSIONS_LOADING = 'GET_STAGE_SESSIONS_LOADING';
export const STAGE_SESSIONS_REQUEST_LOADING = 'STAGE_SESSIONS_REQUEST_LOADING';
export const GET_STAGE_SESSIONS_RESET = 'GET_STAGE_SESSIONS_RESET';

export const SESSIONS_RECORD_REQUEST = 'SESSIONS_RECORD_REQUEST';
export const SESSIONS_RECORD_SUCCESS = 'SESSIONS_RECORD_SUCCESS';
export const SESSIONS_RECORD_FAILURE = 'SESSIONS_RECORD_FAILURE';
export const SET_SHOW_MUTE_BUTTON = 'SET_SHOW_MUTE_BUTTON';

export const SET_SESSION_TIME = 'SET_SESSION_TIME';
export const START_SESSION_FROM_GREEN_ROOM = 'START_SESSION_FROM_GREEN_ROOM';
export const RESET_DATA = 'RESET_DATA';

export const SET_GREEN_ROOM_LOADING = 'SET_GREEN_ROOM_LOADING';
export const SET_GREEN_ROOM_DATA = 'SET_GREEN_ROOM_DATA';
export const CHANGE_SESSIONS_BUTTONS_STATE = 'CHANGE_SESSIONS_BUTTONS_STATE';
