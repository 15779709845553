import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Tab, Tabs } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';
import { FullLayout } from 'layouts/FullLayout';
import { SidebarLayout } from 'layouts/SidebarLayout';
import HotJarTracking from 'components/HotJarTracking/HotJarTracking';
import EventsCardList from 'components/EventsCardList/EventsCardList';
import { AuthToken } from 'helpers/Storage';
import { LOGOUT } from 'store/auth/constants';
import { clearCurrentEvent, getAllEvents, setRedirectionURL } from 'store/events/actions';

export const EventsPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [status, setStatus] = useState('upcoming');
  const [searchParam, setSearchParam] = useState('');

  const {
    events: { allEvents, allEventsLoading, redirectionUrl },
  } = useSelector((state: AppState) => state);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: nextValue } = event.target;
    setSearchParam(nextValue);
  };

  const debouncedSave = useCallback(
    _.debounce(text => {
      dispatch(getAllEvents(text, status));
    }, 500),
    [status]
  );

  const handleSelectKey = (key: any) => {
    if (key !== status) {
      setStatus(key);
      dispatch(getAllEvents(searchParam, key));
    }
  };

  useEffect(() => {
    if (redirectionUrl) {
      navigate(redirectionUrl);
      dispatch(setRedirectionURL(''));
    }
    // eslint-disable-next-line
  }, [redirectionUrl]);

  useEffect(() => {
    !AuthToken.get('access_token') &&
      dispatch({ type: LOGOUT }) &&
      dispatch(getAllEvents(searchParam, status));
    // eslint-disable-next-line
  }, [AuthToken.get('access_token')]);

  useEffect(() => {
    dispatch(clearCurrentEvent());
    // eslint-disasble-next-line
  }, [dispatch]);

  useEffect(() => {
    debouncedSave(searchParam);
    // eslint-disable-next-line
  }, [searchParam]);

  const sortedEvents: any = allEvents.sort((a: any, b: any) => {
    return status === 'past'
      ? new Date(b.start_date).valueOf() - new Date(a.start_date).valueOf()
      : new Date(a.start_date).valueOf() - new Date(b.start_date).valueOf();
  });

  const eventsPageContentClasses = classNames(
    'content d-block p-5 h-100',
    allEventsLoading ? 'overflow-hidden' : 'overflow-auto'
  );

  return (
    <>
      <HotJarTracking />
      <SidebarLayout>
        <FullLayout>
          <nav className="events-page-nav">
            <Tabs
              defaultActiveKey="upcoming-events"
              id="uncontrolled-tab-example"
              onSelect={(key: any) => handleSelectKey(key)}
              activeKey={status}
            >
              <Tab
                eventKey="upcoming"
                title="Upcoming Events"
                className="events-tab"
                disabled={allEventsLoading}
              />
              <Tab
                eventKey="past"
                title="Past Events"
                className="events-tab"
                disabled={allEventsLoading}
              />
            </Tabs>
          </nav>
          <div className={eventsPageContentClasses}>
            <Form.Control
              value={searchParam}
              onChange={handleChange}
              type="search"
              className="mx-auto w-100 col-xs-12 col-sm-9 col-md-6"
              placeholder="Find an event"
              disabled={allEventsLoading}
            />
            <EventsCardList isLoading={allEventsLoading} events={sortedEvents} />
          </div>
        </FullLayout>
      </SidebarLayout>
    </>
  );
};
