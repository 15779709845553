import React from 'react'
import PropTypes from 'prop-types'
import { MailIcon } from '../../icons/stockholm'

const UserImageAndActions = ({ actions, email, image, name, title }) => {
  const imageStyle = image
    ? {
        backgroundImage: `url(${image})`
      }
    : {}

  return (
    <div>
      <div className='d-flex align-items-start mt-5'>
        <div className='symbol symbol-100 mr-5'>
          <div className='symbol-label' style={imageStyle} />
          <i className='symbol-badge bg-success' />
        </div>

        <div className='d-flex flex-column'>
          <a
            href='/'
            className='font-weight-bold font-size-h5 text-dark-75 text-hover-primary'
          >
            {name}
          </a>
          <div className='text-muted mt-1'>{title}</div>
          <div className='navi mt-2'>
            <a href='/' className='navi-item'>
              <span className='navi-link p-0 pb-2'>
                <span className='navi-icon mr-1'>
                  <MailIcon />
                </span>
                <span className='navi-text text-muted text-hover-primary'>
                  {email}
                </span>
              </span>
            </a>
          </div>
          {actions}
        </div>
      </div>
    </div>
  )
}

UserImageAndActions.propTypes = {
  actions: PropTypes.node,
  email: PropTypes.string.isRequired,
  image: PropTypes.node,
  name: PropTypes.string.isRequired,
  title: PropTypes.string
}

UserImageAndActions.defaultProps = {
  actions: null,
  image: null,
  title: ''
}

export default UserImageAndActions
