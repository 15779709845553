// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ControlBarButton,
  Phone,
  Modal,
  ModalBody,
  ModalHeader,
  ModalButton,
  ModalButtonGroup,
  useMeetingManager
} from 'meeting/chime/amazon-chime-sdk/src';

import { StyledP } from './Styled';
import { useDispatch, useSelector } from 'react-redux';
import { replaceUrlParams } from 'helpers/replaceUrlParams';
import ROUTES from 'constants/routes';
import { CLEAR_MEETING } from 'store/chime/constants';

const EndMeetingControl: React.FC = () => {
  const meetingManager = useMeetingManager();
  const [showModal, setShowModal] = useState(false);
  const toggleModal = (): void => setShowModal(!showModal);
  const { event_code } = useSelector(
    (state: AppState) => state.events.currentEvent
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const leaveMeeting = async (): Promise<void> => {
    await meetingManager.leave();
    dispatch({ type: CLEAR_MEETING });
    navigate(replaceUrlParams(ROUTES.SPONSORS, event_code));
  };

  return (
    <>
      <ControlBarButton icon={<Phone />} onClick={toggleModal} label='Leave' />
      {showModal && (
        <Modal size='md' onClose={toggleModal} rootId='modal-root'>
          <ModalHeader title='End Meeting' />
          <ModalBody>
            <StyledP>Are you sure you want to leave this booth?</StyledP>
          </ModalBody>
          <ModalButtonGroup
            primaryButtons={[
              <ModalButton
                onClick={leaveMeeting}
                variant='primary'
                label='Leave Meeting'
                closesModal
              />,
              <ModalButton variant='secondary' label='Cancel' closesModal />
            ]}
          />
        </Modal>
      )}
    </>
  );
};

export default EndMeetingControl;
