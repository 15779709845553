import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { loginWithMagicLink } from 'store/auth/actions';
import { setRedirectionURL } from 'store/events/actions';

interface Params {
  eventCode: string;
  magic_token: string;
}

export const MagicLinkPage: React.FC = () => {
  const { eventCode, magic_token }: any = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    events: { redirectionUrl }
  } = useSelector((state: AppState) => state);

  useEffect(() => {
    if (redirectionUrl) {
      navigate(redirectionUrl);
      dispatch(setRedirectionURL(''));
    }
    // eslint-disable-next-line
  }, [redirectionUrl]);

  useEffect(() => {
    magic_token && dispatch(loginWithMagicLink({ magic_token, eventCode }));
    // eslint-disable-next-line
  }, [magic_token, eventCode]);

  return (
    <div className='vh-100 d-flex justify-content-center align-items-center'>
      <Spinner animation='border' role='status' aria-hidden='true' />
    </div>
  );
};
