import React from 'react';
import { ErrorMessage, Field } from 'formik';
import classNames from 'classnames';

interface Props {
  name: string;
  type: string;
  placeholder?: string;
  className?: string;
  text?: string;
}

export const FormField: React.FC<Props> = ({ name, type, placeholder, className, text }) => {
  return (
    <Field type={type} name={name} placeholder={placeholder}>
      {({ field }: any) => (
        <div className={classNames(className, 'form-group')}>
          {text ? <label>{text}</label> : ''}
          <input type={type} {...field} placeholder={placeholder} className="form-control" />
          {
            // @ts-ignore
            <ErrorMessage name={name} className="text-danger">
              {(msg: any) => <div className="text-danger">{msg}</div>}
            </ErrorMessage>
          }
        </div>
      )}
    </Field>
  );
};
