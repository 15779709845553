import React from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { FormField } from 'components';
import { ModalLayout } from 'layouts/ModalLayout';

interface ChnagePasswordProps {
  showModal: boolean;
  changePasswordSchema: any;
  changeUserPassword: any;
  handleCloseModal: () => void;
  handleChangePassword: (values: any) => void;
}

export const ChangePasswordModal: React.FC<ChnagePasswordProps> = ({
  showModal,
  changePasswordSchema,
  changeUserPassword,
  handleCloseModal,
  handleChangePassword
}) => {
  return (
    <ModalLayout
      isVisible={showModal}
      centered
      onClose={handleCloseModal}
      header={<h4 className='mb-0'>Change Password</h4>}
    >
      <Formik
        initialValues={{
          old_password: '',
          password: '',
          password_confirmation: ''
        }}
        onSubmit={handleChangePassword}
        validationSchema={changePasswordSchema}
      >
        <Form className='d-flex flex-column'>
          {changeUserPassword.error && (
            <Alert variant='danger'>
              The existing password is not appropriate.
            </Alert>
          )}
          <FormField
            name='old_password'
            text='Current Password'
            placeholder='Current Password'
            type='password'
          />
          <FormField
            name='password'
            text='New password'
            placeholder='New password'
            type='password'
          />
          <FormField
            name='password_confirmation'
            text='Confirm password'
            placeholder='Confirm password'
            type='password'
          />
          <Button variant='primary' type='submit'>
            {changeUserPassword.loading ? (
              <Spinner animation='border' role='status' aria-hidden='true' />
            ) : (
              'Save'
            )}
          </Button>
        </Form>
      </Formik>
    </ModalLayout>
  );
};
