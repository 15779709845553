import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './index.scss';

interface Props {
  callback: Function;
  initialRate: number;
}

export default ({ callback, initialRate }: Props) => {
  const [rate, setRate] = useState(0);

  useEffect(() => {
    setRate(initialRate);
    return () => {
      setRate(0);
    };
    // eslint-disable-next-line
  }, []);

  const changeRate = async (givenRate: number) => {
    const previousRate = rate;
    if (rate !== givenRate && callback) {
      setRate(givenRate);
      const callBackSuccess = await callback(givenRate);
      !callBackSuccess && setRate(previousRate);
    }
  };

  return (
    <div className='d-flex'>
      {[...Array(5)].map((item, index) => {
        const givenRating = index + 1;
        const starColor =
          givenRating < rate || givenRating === rate ? '#fff200' : '#ccc';
        return (
          <label key={index} className='m-0'>
            <input
              className='rate-radio'
              type='radio'
              value={givenRating}
              onClick={() => changeRate(givenRating)}
            />
            <div className='rating'>
              <FontAwesomeIcon
                color={starColor}
                icon={faStar as IconProp}
                size='lg'
              />
            </div>
          </label>
        );
      })}
    </div>
  );
};
