import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import OT from '@opentok/client';
import classNames from 'classnames';
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faDesktop,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SCREEN_SHARE_STOP } from 'store/vonage/constants';
import { PARTICIPANTS_EVENTS } from 'store/participants/constants';
import {
  setAudio,
  setLocaleScreenSharing,
  setPublisher,
  setVideo,
  setVideoSource,
  setVonageSession,
} from 'store/vonage/actions';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import {
  publisherId,
  screenShareId,
  subscriberClass,
  vonageVideosInOnePage,
} from 'constants/vonageMeeting';
import { generateAvatar, generateScreenSharingBlock, generateView } from './vonageHelpers';
import './style.scss';

let currentEvent;
let currentAudioEnabled, currentVideoEnabled;
let vonagePublisher = null;

const VonageMeeting = ({
  participantData,
  is_speaker,
  isHost,
  session,
  socket,
  loading,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const {
    app: { virtual_room: { chat_id = '' } = {} },
    auth: { user: { first_name, last_name, id: userId } = {} },
    participants: { data: participantsData = {} },
    vonage: { token, audioEnabled, videoEnabled, localScreenSharing, deviceId },
  } = useSelector(state => state);
  const [isCameraDisabled, setIsCameraDisabled] = useState(false);
  const [isMicDisabled, setIsMicDisabled] = useState(false);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [publisherReady, setPublisherReady] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isSessionUpdated, setIsSessionUpdated] = useState(null);
  const vonageMeetingContainerRef = useRef(null);
  const vonageMeetingPaginationButtons = useRef(null);

  const toggleAudio = () => dispatch(setAudio(!audioEnabled));
  const toggleVideo = () => dispatch(setVideo(!videoEnabled));

  const disableScreenShare = () => {
    setIsScreenSharing(false);
    dispatch(setLocaleScreenSharing(false));
  };

  const setPublisherActions = actions => {
    // eslint-disable-next-line no-unused-expressions
    participantData &&
      socket?.emit(PARTICIPANTS_EVENTS.UPDATE_ROSTER, chat_id, {
        [participantData[0]]: {
          ...participantData[1],
          micEnabled: audioEnabled,
          cameraEnabled: videoEnabled,
          ...actions,
        },
      });
  };

  const handleShareScreen = () => {
    OT.checkScreenSharingCapability(function ({
      supported,
      extensionRegistered,
      extensionInstalled,
    }) {
      if (!supported || extensionRegistered === false) {
        dispatch(setVideoSource('camera'));
        console.error('This browser does not support screen sharing.');
      } else if (extensionInstalled === false) {
        dispatch(setVideoSource('camera'));
        console.error('Prompt to install the extension.');
      } else {
        generateScreenSharingBlock(screenShareId);
        // Screen sharing is available. Publish the screen.
        const screenSharePublisher = OT.initPublisher(
          screenShareId,
          generateView({ videoSource: 'screen' }),

          function (error) {
            if (error) {
              if (localScreenSharing) {
                disableScreenShare();
              } else {
                isScreenSharing &&
                  session.subscribe(
                    currentEvent.stream,
                    screenShareId,
                    generateView(currentEvent.stream)
                  );
              }
            } else {
              session.publish(screenSharePublisher, function (error) {
                const isError = !!error;
                dispatch(setLocaleScreenSharing(!isError));
                !isError && socket.emit(SCREEN_SHARE_STOP, chat_id, { date: new Date() });
                setPublisherActions({ screenSharing: !isError });
              });
            }
          }
        );

        dispatch(setPublisher(screenSharePublisher));

        screenSharePublisher.on('streamDestroyed', () => {
          dispatch(setLocaleScreenSharing(false));
          setPublisherActions({ screenSharing: false });
        });
      }
    });
  };

  const sessionConnect = publisherVonageBlock => {
    session.connect(token, error => {
      const {
        currentState = '',
        capabilities: { publish: capabilitiesPublish = {} },
      } = session || {};
      if (error) {
        console.error(error.message);
      } else if (currentState === 'connected') {
        let publisherProperties = {
          name: `${first_name} ${last_name}`,
          audioEnabled,
          videoEnabled,
        };
        if (is_speaker || isHost) {
          const div = document.createElement('div');
          div.setAttribute('id', publisherId);
          publisherVonageBlock.appendChild(div);

          vonagePublisher = OT.initPublisher(
            div,
            generateView(publisherProperties),
            function (error) {
              const { hasVideo } = vonagePublisher?.stream || {};
              if (error) console.error(error);
              if (!hasVideo) vonagePublisher.setStyle(generateAvatar(first_name));
            }
          );
          if (capabilitiesPublish === 1) {
            session.publish(vonagePublisher, err => {
              if (err?.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
                toast.error('Allow microphone and camera access to join session');
                setIsCameraDisabled(true);
                setIsMicDisabled(true);
              }

              setPublisherReady(true);
              setLoading(false);
              setPublisherActions({});
            });
          }
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 3000);
        }
      }
    });
  };

  useEffect(() => {
    let timeoutSession;
    const vonageMeetingPaginationButtonsCopy = vonageMeetingPaginationButtons.current;

    const publisherVonageBlock = document.querySelector('.vonage-meeting');
    if (session && session.currentState !== 'connected') {
      session.disconnect();
      timeoutSession = setTimeout(() => sessionConnect(publisherVonageBlock), 3000);
      session.on('streamCreated', function (event) {
        currentEvent = event;
        if (event.stream.videoType === 'screen') {
          generateScreenSharingBlock(screenShareId);

          setIsScreenSharing(true);
          session.subscribe(event.stream, screenShareId, generateView(event.stream));
        } else {
          const previousSubscriberBlock = document.getElementById(event.stream.id);
          if (previousSubscriberBlock) {
            previousSubscriberBlock.remove();
          }
          const div = document.createElement('div');
          div.setAttribute('id', event.stream.id);
          div.classList.add(subscriberClass);

          const subscriber = session.subscribe(event.stream, div, error => {
            const { name: subscriberName } = event.stream;
            if (error) console.error(error.message);
            if (!subscriber.stream.hasVideo) {
              subscriber.setStyle(generateAvatar(subscriberName));
            }
          });

          publisherVonageBlock.appendChild(div);

          setTimeout(() => {
            if (document.getElementById(event.stream.id)) {
              Promise.resolve().then(() => setIsSessionUpdated(new Date().getTime()));
            }
          }, 2000);
        }
      });

      //session destroy
      session.on('streamDestroyed', function (event) {
        event.preventDefault();
        event.stream.videoType === 'screen' && setIsScreenSharing(false);
        setIsSessionUpdated(new Date().getTime());
        const element = document.getElementById(event.stream.id);
        element && element.remove();
      });

      session.on({
        signal: function (event) {
          setIsSessionUpdated(new Date().getTime());
          const { data } = event;
          if (data === `muteAudio${userId}`) {
            dispatch(setAudio(false));
          }
          if (data === `turnOffVideo${userId}`) {
            dispatch(setVideo(false));
          }
        },
      });
      dispatch(setVonageSession(session));
    }
    return () => {
      vonagePublisher = null;
      setIsCameraDisabled(false);
      setIsMicDisabled(false);
      setIsScreenSharing(false);
      setPublisherReady(false);
      vonageMeetingPaginationButtonsCopy.hidden = true;
      clearTimeout(timeoutSession);
      if (session && session?.connection) {
        session.forceDisconnect(session.connection);
        dispatch(setVonageSession(null));
      }
    };
    // eslint-disable-next-line
  }, [session]);

  useEffect(() => {
    if (
      (currentAudioEnabled !== audioEnabled || currentVideoEnabled !== videoEnabled) &&
      currentAudioEnabled !== undefined &&
      currentVideoEnabled !== undefined
    ) {
      setPublisherActions({
        micEnabled: audioEnabled,
        cameraEnabled: videoEnabled,
      });

      if (vonagePublisher) {
        currentVideoEnabled !== videoEnabled && vonagePublisher.publishVideo(videoEnabled);
        currentAudioEnabled !== audioEnabled && vonagePublisher.publishAudio(audioEnabled);
      }
    }

    currentAudioEnabled = audioEnabled;
    currentVideoEnabled = videoEnabled;

    //eslint-disable-next-line
  }, [audioEnabled, videoEnabled]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    session?.signal({ data: 'Session Changed' });
  }, [session, videoEnabled]);

  useEffect(() => {
    if (Object.values(participantsData)?.length) {
      let count = 0;
      // eslint-disable-next-line
      Object.values(participantsData)?.some(participant => {
        if (participant.deviceId === deviceId) count++;
      });
      if (count > 1 && deviceId) {
        toast.error('Your camera is in use. Please close highatt.com in other tabs.');
      }
    }
    // eslint-disable-next-line
  }, [Object.values(participantsData)?.length]);

  useEffect(() => {
    if (!loading) {
      const connectedUsers = Array.from(vonageMeetingContainerRef.current.children).slice(1);
      const connectedUserCount = connectedUsers?.length;
      const shouldSetVonageVideoPagination = connectedUserCount > vonageVideosInOnePage;
      if (shouldSetVonageVideoPagination) {
        vonageMeetingPaginationButtons.current.hidden = false;
        const [previousPageButton, nextPageButton] =
          vonageMeetingPaginationButtons.current?.children;
        const vonageVideoPagesCount = Math.ceil(connectedUserCount / vonageVideosInOnePage);
        const isNextButtonHidden = currentPage >= vonageVideoPagesCount - 1;
        const isPreviousButtonHidden = currentPage < 1;
        previousPageButton.hidden = isPreviousButtonHidden;
        nextPageButton.hidden = isNextButtonHidden;
        const fromFirstCurrentPageVideo = currentPage * vonageVideosInOnePage;
        const toLastCurrentPageVideo = fromFirstCurrentPageVideo + vonageVideosInOnePage;
        const isCurrentPageVideo = currentIndex =>
          currentIndex >= fromFirstCurrentPageVideo && currentIndex < toLastCurrentPageVideo;

        for (let currentIndex in connectedUsers) {
          if (!connectedUsers[currentIndex]) break;
          if (isCurrentPageVideo(currentIndex)) {
            connectedUsers[currentIndex].hidden = false;
          } else {
            connectedUsers[currentIndex].hidden = true;
          }
        }
      } else {
        connectedUsers.forEach(enabledVonageVideo => (enabledVonageVideo.hidden = false));
        vonageMeetingPaginationButtons.current.hidden = true;
        setCurrentPage(0);
      }
    }
  }, [currentPage, isSessionUpdated, videoEnabled, isHost, is_speaker, loading]);

  const handleToNextPage = () => {
    setCurrentPage(page => page + 1);
  };

  const handleToPreviousPage = () => {
    setCurrentPage(page => page - 1);
  };

  return (
    <>
      <div
        className={classNames('opentok-wrapper d-flex h-100', {
          'screen-sharing': isScreenSharing || localScreenSharing,
        })}
      >
        <div className="vonage-meeting" ref={vonageMeetingContainerRef}>
          {loading && <CustomLoader />}
          <div className="pagination-buttons" ref={vonageMeetingPaginationButtons} hidden>
            <button className="pagination-btn previous-page-btn" onClick={handleToPreviousPage}>
              <FontAwesomeIcon size="3x" icon={faArrowCircleLeft} />
            </button>
            <button className="pagination-btn next-page-btn" onClick={handleToNextPage}>
              <FontAwesomeIcon size="3x" icon={faArrowCircleRight} />
            </button>
          </div>
        </div>
        <div className="screen-share-block" />
        {(is_speaker || isHost) && publisherReady && (
          <div className="control-container d-flex align-items-center vonage-controls">
            <Button
              onClick={toggleAudio}
              className={classNames('ots-video-control audio', {
                muted: !audioEnabled,
                disabled: isCameraDisabled,
              })}
            />
            <Button
              onClick={toggleVideo}
              className={classNames('ots-video-control video', {
                muted: !videoEnabled,
                disabled: isMicDisabled,
              })}
            />
            <Button
              onClick={handleShareScreen}
              className={'ots-video-control d-flex align-items-center justify-content-center'}
              disabled={isScreenSharing || localScreenSharing}
            >
              <FontAwesomeIcon icon={faDesktop} color="white" size="2x" />
              {isScreenSharing && !localScreenSharing && (
                <span className="screen-sharing-message">
                  Other participant is already sharing the screen
                </span>
              )}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(VonageMeeting);
