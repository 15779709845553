import {
  AUTH_RESET,
  CHANGE_PASSSWORD_FAILURE,
  CHANGE_PASSSWORD_REQUEST,
  CHANGE_PASSSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_FAILURE,
  CHANGE_USER_PASSWORD_REQUEST,
  CHANGE_USER_PASSWORD_RESET,
  EDIT_USER_DATA_FAILURE,
  EDIT_USER_DATA_REQUEST,
  EDIT_USER_DATA_SUCCESS,
  FORGOT_PASSSWORD_FAILURE,
  FORGOT_PASSSWORD_REQUEST,
  FORGOT_PASSSWORD_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  MAGIC_LINK_FAILURE,
  MAGIC_LINK_REQUEST,
  MAGIC_LINK_SUCCESS,
  SIGNUP_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  VERIFY_FAILURE,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  SET_USER_CURRENT_LOCATION
} from './constants';

import { defaultUserImg } from 'constants/images';

const initialState = {
  user: {},
  editUserLoading: false,
  changeUserPassword: {
    loading: false,
    error: false
  },
  loggedIn: false,
  loginError: '',
  currentLocation: false
};

interface ActionInterface {
  type: string;
  user?: User;
  error?: string;
  password?: string;
  forgotPasswordEmail?: string;
  message?: string;
  email?: string;
  payload: any;
}

export function auth(
  state: any = { ...initialState },
  action: ActionInterface
) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        loginError: null
      };
    case LOGIN_SUCCESS: {
      let { id, image }: any = action.user || {};
      if (!image) {
        image = defaultUserImg;
      }
      return {
        ...state,
        loggedIn: true,
        loggingIn: false,
        user: {
          ...state.user,
          ...action.user,
          image,
          id: String(id)
        },
        loginError: null
      };
    }
    case SET_USER_CURRENT_LOCATION: {
      return {
        ...state,
        currentLocation: action.payload
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        loggingIn: false,
        user: {},
        loggedIn: false,
        loginError: action.error
      };
    }

    case SIGNUP_REQUEST:
      return {
        signingUp: true
      };
    case SIGNUP_SUCCESS:
      return {
        emailForSignup: action.email,
        signedIn: true,
        signupError: null
      };
    case SIGNUP_FAILURE:
      return {
        signupError: action.error
      };
    case VERIFY_REQUEST:
      return {
        ...state,
        verifying: true,
        verifyError: null
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        verified: true,
        verifying: false,
        verifyError: null,
        emailForSignup: null
      };
    case VERIFY_FAILURE:
      return {
        ...state,
        verifying: false,
        verifyError: action.error,
        emailForSignup: null
      };
    case FORGOT_PASSSWORD_REQUEST:
      return {
        forgotPasswordRequesting: true
      };
    case FORGOT_PASSSWORD_SUCCESS:
      return {
        forgotPasswordSuccess: true,
        forgotPasswordEmail: action.forgotPasswordEmail,
        forgotPasswordError: null
      };
    case FORGOT_PASSSWORD_FAILURE:
      return {
        forgotPasswordError: action.error
      };
    case CHANGE_PASSSWORD_REQUEST:
      return {
        changePasswordRequesting: true
      };
    case CHANGE_PASSSWORD_SUCCESS:
      return {
        changePasswordSuccess: true,
        changePasswordError: null
      };
    case CHANGE_PASSSWORD_FAILURE:
      return {
        changePasswordError: action.error
      };
    case AUTH_RESET:
      return { ...initialState };
    case MAGIC_LINK_REQUEST:
      return {
        ...state,
        magicLinkRequesting: true,
        magicLinkError: null
      };
    case MAGIC_LINK_SUCCESS:
      return {
        ...state,
        magicLinkSuccess: true,
        magicLinkRequesting: null,
        magicLinkError: null
      };
    case MAGIC_LINK_FAILURE:
      return {
        ...state,
        magicLinkSuccess: null,
        magicLinkError: true,
        magicLinkRequesting: null,
        magicLinkMessage: action.message
      };
    case EDIT_USER_DATA_REQUEST:
      return {
        ...state,
        editUserLoading: true
      };
    case EDIT_USER_DATA_SUCCESS:
      return {
        ...state,
        editUserLoading: false,
        user: {
          ...state.user,
          ...action.user
        }
      };
    case EDIT_USER_DATA_FAILURE:
      return {
        ...state,
        editUserLoading: false
      };
    case CHANGE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        changeUserPassword: {
          ...state.changeUserPassword,
          loading: true
        }
      };
    case CHANGE_USER_PASSWORD_FAILURE:
      return {
        ...state,
        changeUserPassword: {
          ...state.changeUserPassword,
          loading: false,
          error: true
        }
      };
    case CHANGE_USER_PASSWORD_RESET:
      return {
        ...state,
        changeUserPassword: {
          ...state.changeUserPassword,
          loading: false,
          error: false
        }
      };
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
}
