export const CHAT_EVENTS = {
  CHAT_MESSAGE: 'chat_message',
  CHAT_ADMIN_MESSAGE: 'chat_admin_message',
  DISCONNECT: 'disconnect',
  MESSAGE_PIN: 'message_pin',
  MESSAGE_REMOVE: 'message_remove',
  CLEAR_PUBLIC_CHAT: 'clear_chat',
  CLEAR_ADMIN_CHAT: 'clear_admin_chat'
};

export const CLEAR_CHAT = 'CLEAR_CHAT';
export const MESSAGE_ALL = 'all';
export const PIN_MESSAGE = 'PIN_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const RECEIVE_ADMIN_MESSAGE = 'RECEIVE_ADMIN_MESSAGE';
export const SEND_MESSAGE = 'SEND_MESSAGE';

export const LOADED_MESSAGES = 'LOADED_MESSAGES';
export const LOADED_ADMIN_MESSAGES = 'LOADED_ADMIN_MESSAGES';
export const RECEIVE_MESSAGES = 'RECEIVE_MESSAGES';
export const RECEIVE_ADMIN_MESSAGES = 'RECEIVE_ADMIN_MESSAGES';
export const ERROR_MESSAGES = 'ERROR_MESSAGES';

export const SET_MESSAGE_REF_PART_ID = 'SET_MESSAGE_REF_PART_ID';
export const CHANGE_ACTIVE_TAB = 'CHANGE_ACTIVE_TAB';
