import React, { useState, useEffect, useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import { Button, Accordion, Card } from 'react-bootstrap';
import { noop } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import TickerMessageHtmlEditor from 'components/TickerMessageHtmlEditor/TicketMessageHtmlEditor';

interface Props {
  messageKey: any;
  value: any;
  messageIndex: any;
  isMessageToggled: boolean;
  toggleMessageArrow: any;
}

const defaultProps: Props = {
  messageKey: '',
  value: '',
  messageIndex: 0,
  isMessageToggled: false,
  toggleMessageArrow: noop,
};

const TickerMessageCard: React.FC<Props> = ({
  messageKey,
  messageIndex,
  isMessageToggled,
  toggleMessageArrow,
}) => {
  const {
    socket: { eventSocket },
    tickerMessages: {
      data: {
        [messageKey]: { text: defaultMessageText = '', status: defaultStatus = '' } = {},
      } = {},
    },
    events: {
      currentEvent: { event_code = '' },
    },
  } = useSelector((state: AppState) => state);

  const [messageText, setMessageText]: any = useState<string>();
  const messageTextLength = messageText?.length;

  const saveOptionIsDisabled = useMemo(
    () => messageText === defaultMessageText,
    [defaultMessageText, messageText]
  );

  const publishOptionDisabled = useMemo(
    () => !messageTextLength && defaultStatus === 'draft',
    [messageTextLength, defaultStatus]
  );

  const handleMessageDelete = () => {
    eventSocket?.emit('ticker_message_deleted', event_code, {
      id: messageKey,
    });
  };

  const handleMessageUpdate = (status: string) => {
    eventSocket?.emit('ticker_message_updated', event_code, {
      id: messageKey,
      value: {
        text: messageText,
      },
      status,
    });
  };

  const handleSaveClick = () => {
    const statusCondition =
      defaultStatus === 'draft' || (defaultStatus === 'published' && !messageTextLength);
    handleMessageUpdate(statusCondition ? 'draft' : 'published');
  };

  useEffect(() => {
    defaultMessageText !== messageText && setMessageText(defaultMessageText);
    // eslint-disable-next-line
  }, [defaultMessageText]);

  const publishDisable: boolean = useMemo(
    () =>
      (!messageTextLength && !defaultMessageText.length) ||
      (!messageTextLength && defaultStatus === 'draft'),
    [defaultStatus, defaultMessageText, messageTextLength]
  );

  const publishTextGenerator: string = useMemo(
    () => (defaultStatus === 'published' && defaultMessageText.length ? 'Unpublish' : 'Publish'),
    [defaultStatus, defaultMessageText]
  );

  return (
    <Card key={messageKey}>
      <Card.Header>
        <Accordion.Toggle
          as={Button}
          variant="button"
          eventKey={messageKey}
          onClick={toggleMessageArrow}
          style={{
            width: '100%',
            fontWeight: '500',
            fontSize: '16px',
          }}
        >
          {`Message ${messageIndex + 1}`}
          <FontAwesomeIcon
            className="ml-4"
            icon={isMessageToggled ? (faAngleDown as IconProp) : (faAngleUp as IconProp)}
            size="lg"
            color="ffffff"
          />
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={messageKey}>
        <Card.Body className="pb-1">
          <TickerMessageHtmlEditor setMessageText={setMessageText} messageText={messageText} />
          <div style={{ textAlign: 'right' }}>
            <Button
              variant="success"
              disabled={publishDisable}
              onClick={() => handleMessageUpdate(defaultStatus === 'draft' ? 'published' : 'draft')}
              style={{ cursor: publishOptionDisabled ? 'initial' : 'pointer' }}
            >
              {publishTextGenerator}
            </Button>
            <Button
              variant="warning"
              className="m-2"
              disabled={saveOptionIsDisabled}
              onClick={handleSaveClick}
              style={{ cursor: saveOptionIsDisabled ? 'initial' : 'pointer' }}
            >
              Save
            </Button>
            <Button variant="danger" onClick={handleMessageDelete}>
              Delete
            </Button>
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

TickerMessageCard.defaultProps = defaultProps;

export default memo(TickerMessageCard);
