import React, { FC, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { changeSessionAction } from 'store/sessions/actions';
import {
  startVonageRecording,
  stopVonageRecording
} from 'store/vonage/actions';
import { Button } from 'react-banzai';
import { ModalLayout } from 'layouts/ModalLayout';
import { applyTimezone } from 'helpers/parseDate';
import './SessionControls.scss';

interface IProps {
  archive: object;
  sessionData: any;
  loading: boolean;
  currentEvent: object;
}

const SessionControls: FC<IProps> = ({
  archive,
  sessionData,
  loading,
  currentEvent
}) => {
  const dispatch = useDispatch();
  const { id: event_code = '', roomId: roomIdParam }: any = useParams();
  const { archiveId }: any = archive;
  const [roomId, setRoomId] = useState('');
  const [previousSession, setPrevSession] = useState(false);
  const [activeSession, setActiveSession] = useState(false);
  const [nextSession, setNextSession] = useState<any>(false);
  const [showStartSessionModal, setShowStartSessionModal] = useState(false);
  const [showPrevSessionModal, setShowPrevSessionModal] = useState(false);
  const [showEndSessionModal, setShowEndSessionModal] = useState(false);
  const [showNextSessionModal, setShowNextSessionModal] = useState(false);
  const [isEarly, setIsEarly] = useState<boolean>(true);
  const {
    vonage: { sessionId },
    sessions: { isSessionsControlsButtonsDisabled }
  } = useSelector((state: AppState) => state);

  useEffect(() => {
    if (isSessionsControlsButtonsDisabled) {
      setShowStartSessionModal(false);
      setShowPrevSessionModal(false);
      setShowEndSessionModal(false);
      setShowNextSessionModal(false);
    }
  }, [isSessionsControlsButtonsDisabled]);

  useEffect(() => {
    if (!roomIdParam) {
      const { customMenu = [] }: any = currentEvent;
      const stageMenuPage =
        customMenu.find((item: any) => item.slug === 'stage') || {};
      if (stageMenuPage.type === 'room') {
        setRoomId(stageMenuPage.room_id);
      }
    } else {
      setRoomId(roomIdParam);
    }
  }, [currentEvent, roomIdParam]);

  useEffect(() => {
    if (sessionData) {
      setActiveSession(
        sessionData.some((item: any) => item.status === 'active')
      );
      setNextSession(sessionData.some((item: any) => item.status === 'next'));
      setPrevSession(
        sessionData.some((item: any) => item.status === 'previous')
      );
    }
    // eslint-disable-next-line
  }, [sessionData]);

  useEffect(() => {
    if (nextSession?.start_date) {
      const formattedDate = applyTimezone(nextSession?.start_date);
      const startDate = new Date(formattedDate).getTime();
      const dateNow = new Date().getTime();
      const difference = startDate - dateNow;
      setTimeout(() => {
        setIsEarly(false);
      }, difference);
    }
    // eslint-disable-next-line
  }, [nextSession?.start_date]);

  const handlePrevSession = () => {
    dispatch(
      changeSessionAction(
        event_code,
        roomId,
        'previous'
      )
    );
    setShowPrevSessionModal(false);
  };

  const handleStartSession = () => {
    dispatch(
      changeSessionAction(
        event_code,
        roomId,
        'start'
      )
    );
    setShowStartSessionModal(false);
  };

  const handleNextSession = () => {
    dispatch(
      changeSessionAction(
        event_code,
        roomId,
        'next'
      )
    );
    setShowNextSessionModal(false);
  };

  const handleStopSession = () => {
    dispatch(
      changeSessionAction(
        event_code,
        roomId,
        'stop'
      )
    );
    setShowEndSessionModal(false);
  };

  const startRecording = () => {
    dispatch(startVonageRecording(event_code));
  };

  const stopRecording = () => {
    dispatch(stopVonageRecording());
  };

  const recording = () => (
    <Button
      onClick={archiveId ? stopRecording : startRecording}
      variant='outline-danger'
      disabled={loading || isSessionsControlsButtonsDisabled}
    >
      {archiveId ? 'Stop Recording' : 'Start Recording'}
    </Button>
  );

  return (
    <>
      {previousSession && (
        <Button
          onClick={() => setShowPrevSessionModal(true)}
          disabled={loading || isSessionsControlsButtonsDisabled}
        >
          Previous Session
        </Button>
      )}
      {!activeSession && nextSession && (
        <Button
          onClick={() => setShowStartSessionModal(true)}
          disabled={loading || isSessionsControlsButtonsDisabled}
        >
          Start session
        </Button>
      )}
      {activeSession && (
        <>
          <Button
            onClick={() => setShowEndSessionModal(true)}
            disabled={loading || isSessionsControlsButtonsDisabled}
          >
            Stop Session
          </Button>
          {sessionId && recording()}
        </>
      )}
      {activeSession && nextSession && (
        <Button
          onClick={() => setShowNextSessionModal(true)}
          disabled={loading || isSessionsControlsButtonsDisabled}
        >
          Next Session
        </Button>
      )}

      <ModalLayout
        centered
        isVisible={showPrevSessionModal}
        onClose={() => setShowPrevSessionModal(false)}
        children={
          <div className='session-modal'>
            <div className='text text-center mb-3'>
              Are you sure you want start previous session?
            </div>
            <div className='d-flex w-100 justify-content-center'>
              <Button className='mx-2' onClick={handlePrevSession}>
                Yes
              </Button>
              <Button
                className='mx-2'
                variant='outline-dark'
                onClick={() => setShowPrevSessionModal(false)}
              >
                No
              </Button>
            </div>
          </div>
        }
      />

      <ModalLayout
        centered
        isVisible={showStartSessionModal}
        onClose={() => setShowStartSessionModal(false)}
        children={
          <div className='session-modal'>
            <div className='text text-center mb-3'>
              {isEarly
                ? `Are you sure you want to start it early?`
                : 'Are you sure you want to start the session'}
            </div>

            <div className='d-flex w-100 justify-content-center'>
              <Button className='mx-2' onClick={handleStartSession}>
                Yes
              </Button>
              <Button
                className='mx-2'
                variant='outline-dark'
                onClick={() => setShowStartSessionModal(false)}
              >
                No
              </Button>
            </div>
          </div>
        }
      />

      <ModalLayout
        centered
        isVisible={showEndSessionModal}
        onClose={() => setShowEndSessionModal(false)}
        children={
          <div className='session-modal'>
            <div className='text text-center mb-3'>
              Are you sure you want to end this session?
            </div>

            <div className='d-flex w-100 justify-content-center'>
              <Button className='mx-2' onClick={handleStopSession}>
                Yes
              </Button>
              <Button
                className='mx-2'
                variant='outline-dark'
                onClick={() => setShowEndSessionModal(false)}
              >
                No
              </Button>
            </div>
          </div>
        }
      />

      <ModalLayout
        centered
        isVisible={showNextSessionModal}
        onClose={() => setShowNextSessionModal(false)}
        children={
          <div className='session-modal'>
            <div className='text text-center mb-3'>
              Are you sure you want to end this session?
            </div>

            <div className='d-flex w-100 justify-content-center'>
              <Button className='mx-2' onClick={handleNextSession}>
                Yes
              </Button>
              <Button
                className='mx-2'
                variant='outline-dark'
                onClick={() => setShowNextSessionModal(false)}
              >
                No
              </Button>
            </div>
          </div>
        }
      />
    </>
  );
};

export default memo(SessionControls);
