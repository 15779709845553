import React from 'react';
import classNames from 'classnames';
import Card from 'react-bootstrap/Card';
import './styles.scss';
import { addProtocol } from 'helpers';
const SponsorPanel = ({ src, level, url, className }) => {
  const _className = classNames(className);

  const CardLink = (url, src) => {
    if (url) {
      return (
        <Card.Link
          href={addProtocol(url)}
          target='_blank'
          rel='noopener noreferrer'
          className='w-100 h-100 d-flex align-items-center justify-content-center p-3'
        >
          <Card.Img
            variant='top'
            src={src}
            className='mw-100 mh-100 w-auto h-auto'
          />
        </Card.Link>
      );
    } else {
      return (
        <div className='w-100 h-100 d-flex align-items-center justify-content-center p-3'>
          <Card.Img
            variant='top'
            src={src}
            className='mw-100 mh-100 w-auto h-auto'
          />
        </div>
      );
    }
  };

  return (
    <Card className={_className}>
      {level !== null ? (
        <>
          <Card.Header className='p-0'>{CardLink(url, src)}</Card.Header>
          <Card.Body className='p-3 text-center'>
            <Card.Text className='font-weight-bold font-size-h5 text-capitalize'>
              {level}
            </Card.Text>
          </Card.Body>
        </>
      ) : (
        <Card.Body className='p-0'>{CardLink(url, src)}</Card.Body>
      )}
    </Card>
  );
};

export default SponsorPanel;
