import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { app } from 'store/app';
import { auth } from 'store/auth';
import { events } from 'store/events';
import { chat } from 'store/chat';
import { socket } from 'store/socket';
import { vonage } from 'store/vonage';
import { chime } from 'store/chime';
import { questionsAndAnswers } from 'store/questionsAndAnswers';
import { participants } from 'store/participants';
import { poll } from 'store/poll';
import { sponsors } from 'store/sponsors';
import { sessions } from 'store/sessions';
import { confirmationModal } from 'store/confirmationModal';
import { tickerMessages } from 'store/tickerMessages';
import { leaderBoard } from 'store/leaderBoard';
import { communicationModules } from 'store/communicationModules';

const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    app,
    auth,
    events,
    chat,
    socket,
    vonage,
    chime,
    questionsAndAnswers,
    participants,
    poll,
    sponsors,
    sessions,
    confirmationModal,
    tickerMessages,
    leaderBoard,
    communicationModules
  });

export default createRootReducer;
