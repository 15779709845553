import {
  SET_TICKER_MESSAGE_VALUE,
  GET_TICKER_MESSAGES,
  DELETE_TICKER_MESSAGE,
  SHOW_TICKER_MESSAGE
} from './constants';

export const addTickerMessageField = (payload: any): any => {
  return {
    type: GET_TICKER_MESSAGES,
    payload: payload
  };
};

export const setTickerMessageValue = (payload: any): any => {
  return {
    type: SET_TICKER_MESSAGE_VALUE,
    payload
  };
};

export const getTickerMessages = (payload: any): any => {
  return {
    type: GET_TICKER_MESSAGES,
    payload
  };
};

export const deleteTickerMessage = (id: any): any => {
  return {
    type: DELETE_TICKER_MESSAGE,
    id
  };
};

export const showTickerMessageBlock = (payload: boolean): any => {
  return {
    type: SHOW_TICKER_MESSAGE,
    payload
  };
};
