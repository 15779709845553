import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import { applyTimezone } from 'helpers/parseDate';
import { Card, Col } from 'react-bootstrap';
import { HeaderDisplay, EventTabs } from 'react-banzai';
import EventOverview from 'components/EventInformationTabs/EventOverview';
import Speakers from 'components/EventInformationTabs/speakers';
import Sponsors from 'components/EventInformationTabs/sponsors/index';
import './styles.scss';

interface Props {
  event: Event;
}

export const EventCard: React.FC<Props> = memo(({ event }) => {
  const {
    communicationModules: { isCommunicationModulesAvailable },
  } = useSelector((state: AppState) => state);

  const [tabs, setTabs] = useState<any>([]);
  const { sponsors = [], speakers = [], description = '' } = event;
  const date = applyTimezone(event.start_date, 'dddd, MMMM Do, YYYY [at] h:mm A z');

  useEffect(() => {
    let shownTabs = [];
    description &&
      shownTabs.push({
        key: 'Overview',
        content: <EventOverview description={description} />,
      });
    speakers?.length > 0 &&
      shownTabs.push({
        key: 'Speakers',
        content: <Speakers speakers={speakers} />,
      });
    sponsors?.length > 0 &&
      shownTabs.push({
        key: 'Sponsors',
        content: <Sponsors sponsors={sponsors} />,
      });
    setTabs(shownTabs);
  }, [description, speakers, sponsors]);

  return (
    <div className="h-100 overflow-auto bg-white">
      <Card className={`border-0 rounded-0 align-items-start`}>
        <Card.Img
          src={event.image}
          variant="top"
          className="event-image w-100 mw-100"
          style={{
            maxHeight: isCommunicationModulesAvailable ? '300px' : 'unset',
          }}
        />
        <Card.Body className="pb-0">
          <Card.Title className="d-flex justify-content space-between">
            <HeaderDisplay content={event.name} variant={4} />
          </Card.Title>
          <div className="mb-2 text-muted d-flex h4">{date}</div>
        </Card.Body>
      </Card>
      <Col md={12} className="d-flex flex-column flex-column-fluid bg-white py-5 reception-tabs">
        <EventTabs tabs={tabs} />
      </Col>
    </div>
  );
});
