import { Dispatch } from 'redux';
import { SET_LOADING, SET_ORGANIZATION, SET_SPONSORS } from './constants';
import { getOrganizationData, getSponsorsData } from 'api/sponsors';
import { SET_VIRTUAL_ROOM } from '../app/constants';

export const setLoading = (data: boolean) => ({
  type: SET_LOADING,
  data
});

export const setSponsorsData = (data: Array<Object>) => ({
  type: SET_SPONSORS,
  data
});

export const setOrganization = (data: any) => ({
  type: SET_ORGANIZATION,
  data
});

export const clearCurrentOrganization = () => async (dispatch: Dispatch) => {
  dispatch({
    type: SET_ORGANIZATION,
    data: {}
  });

  dispatch({
    type: SET_VIRTUAL_ROOM,
    payload: ''
  });
};

export const getSponsors =
  (eventCode: string): any =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    try {
      const {
        data: { data, success }
      } = await getSponsorsData(eventCode);
      if (success) {
        dispatch(setSponsorsData(data));
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getOrganization =
  (eventCode: string, id: string, isRealTimeUpdate: boolean = false) =>
  async (dispatch: Dispatch) => {
    !isRealTimeUpdate && dispatch(setLoading(true));
    try {
      const {
        data: { data, success }
      } = await getOrganizationData(eventCode, id);
      if (success) {
        dispatch(setOrganization(data));
        dispatch({
          type: SET_VIRTUAL_ROOM,
          payload: data.virtual_room
        });
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      !isRealTimeUpdate && dispatch(setLoading(false));
    }
  };
