export const fitText = (el, kompressor, options) => {
  var settings = {
    minFontSize: -1 / 0,
    maxFontSize: 1 / 0,
    ...options
  }

  const fit = (el) => {
    const compressor = kompressor || 1

    const resizer = () => {
      el.style.fontSize =
        Math.max(
          Math.min(
            el.clientWidth / (compressor * 10),
            parseFloat(settings.maxFontSize)
          ),
          parseFloat(settings.minFontSize)
        ) + 'px'
    }

    // Call once to set.
    resizer()

    // Bind events
    // If you have any js library which support Events, replace this part
    // and remove addEvent function (or use original jQuery version)
    // addEvent(window, 'resize', resizer)
    // addEvent(window, 'orientationchange', resizer)
  }
  // many DOM elements captured by selector
  if (el.length) {
    for (var i = 0; i < el.length; i++) fit(el[i])
  } else {
    // one DOM element captured
    fit(el)
  }

  // return set of elements
  return el
}
