import React, { lazy, memo, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import ROUTES from 'constants/routes';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import { useIsExpectedPage } from 'hooks/usePageLocation';
import './SideBarLayout.scss';

const Aside = lazy(() => import('components/Aside/Aside'));

interface LayoutProps {
  hideEventName?: boolean;
}

export const SidebarLayout: React.FC<LayoutProps> = memo(({ children, hideEventName }) => {
  const { pathname } = useLocation();
  const {
    vonage: { localScreenSharing = false },
    events: { oneEventLoading = false },
  } = useSelector((state: AppState) => state);

  const sidebarLayoutClasses = classnames('d-flex flex-column flex-root h-100 sidebar-layout', {
    bordered: localScreenSharing,
  });

  const isPrivateRoute = useIsExpectedPage([
    ROUTES.AGENDA,
    ROUTES.SPONSORS,
    ROUTES.SPONSOR_ROOM,
    ROUTES.GREEN_ROOM,
    ROUTES.STAGE,
    ROUTES.RECEPTION,
    ROUTES.LEADERBOARD,
    ROUTES.ROOM,
  ]);

  return (
    <div className={sidebarLayoutClasses}>
      <Header hideEventName={hideEventName} />
      <div className={`d-flex with-aside flex-row h-100 page overflow-auto`}>
        {isPrivateRoute && (
          <Suspense
            fallback={
              <div className="flex align-items-center">
                <CustomLoader />
              </div>
            }
          >
            <Aside />
          </Suspense>
        )}
        <div className="d-flex flex-column flex-row-fluid wrapper sidebar-layout-content">
          {pathname.includes('event') && oneEventLoading && <CustomLoader />}
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
});
