import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './CustomLoader.scss';

const CustomLoader = () => (
  <div className='d-flex custom-loader w-100'>
    <Spinner animation='border' className='m-auto'></Spinner>
  </div>
);

export default CustomLoader;
