export const keylocalStorage = 'jwt_token';
export const {
  REACT_APP_BACKEND_API_URL,
  REACT_APP_API_URL,
  REACT_APP_VONAGE_API_KEY,
  REACT_APP_SOCKET_URL,
  REACT_APP_GOOGLE_MAPS_KEY,
  REACT_APP_HTML_EDITOR_KEY,
  REACT_APP_TICKER_MESSAGES_MAX_COUNT,
  REACT_APP_FLAGS_CTX_APP_NAME,
  REACT_APP_JW_PLAYER_SCRIPT
}: any = process.env;

export const intialUserState: Partial<User> = {
  first_name: '',
  last_name: '',
  headline: '',
  bio: '',
  email: '',
  twitter: '',
  linkedIn: '',
  facebook: '',
  website: '',
  picture: '',
  isChangePassword: false,
  currentPassword: '',
  password: '',
  confirmPassword: '',
};
