import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { default as ReactSelect } from 'react-select';
import Form from 'react-bootstrap/Form';
import { changeRegAndCopyFields, getStates } from 'store/events/actions';
import classnames from 'classnames';
import { isEmpty } from 'utils/checkTypes';
import '../style.scss';

interface Country {
  id: number;
  code: string;
  name: string;
}

interface State {
  code: string;
  name: string;
}

interface SelectOptions {
  value: string;
  label: string;
}

interface Props {
  title: string;
  name: string;
  type: string;
  onChange: (data: any) => void;
  selectOptions: Array<any>;
  isMulti: boolean;
  validationError: boolean;
  required?: boolean;
}

export const Select: React.FC<Props> = ({
  title,
  name,
  type,
  onChange,
  isMulti,
  selectOptions,
  validationError,
  required
}) => {
  const {
    participantRegData,
    registrationFields: { countries = [], countryDefault, states }
  } = useSelector((state: AppState) => {
    return state.events;
  });
  const dispatch = useDispatch();

  let options = [];
  let value: any = { label: '', value: '' };
  if (type === 'country') {
    options = countries.map((country: Country) => {
      return {
        value: country.code,
        label: country.name
      };
    });
    value =
      options.find(
        (country: SelectOptions) =>
          country.value === participantRegData[name] ||
          country.label === participantRegData[name]
      ) ||
      options.find(
        (country: SelectOptions) => country.value === countryDefault
      );
  } else if (type === 'state') {
    options = states.map((state: State) => {
      return {
        value: state.code,
        label: state.name
      };
    });
    value =
      options.find(
        (state: SelectOptions) =>
          state.value === participantRegData[name] ||
          state.label === participantRegData[name]
      ) || options[0];
  } else if (type === 'multi-select') {
    options = selectOptions.map((option) => {
      return {
        value: option.key,
        label: option.value,
        default: option.default
      };
    });
    value = options.filter((option: any, i: number): any => {
      return participantRegData[name] && Array.isArray(participantRegData[name])
        ? participantRegData[name].indexOf(option.value) > -1 ||
            participantRegData[name].indexOf(option.label) > -1
        : false;
    });
  } else if (selectOptions?.length) {
    options = selectOptions.map((option) => {
      return {
        value: option.key,
        label: option.value
      };
    });
    value =
      options.find(
        (data: SelectOptions) =>
          participantRegData[name] &&
          (data.value === participantRegData[name] ||
            data.label === participantRegData[name])
      ) || selectOptions[0];
  }

  useEffect(() => {
    if (!participantRegData[name])
      dispatch(changeRegAndCopyFields(name, value.value));
    // eslint-disable-next-line
  }, [dispatch, participantRegData[name]]);

  useEffect(() => {
    if (
      name === 'country' &&
      participantRegData.country &&
      type === 'country'
    ) {
      dispatch(getStates(participantRegData.country));
    }
    // eslint-disable-next-line
  }, [participantRegData.country]);

  const checkValue = name === 'multi' ? isEmpty(value) : !value?.value;
  const invalid = checkValue && validationError;
  const classes = classnames({
    required,
    invalid
  });
  return (
    <Form.Group>
      <Form.Label className={classes}>{title}</Form.Label>
      <ReactSelect
        isMulti={isMulti}
        className='select'
        onChange={onChange}
        value={value}
        options={options}
      ></ReactSelect>
    </Form.Group>
  );
};
