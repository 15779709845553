import React from 'react';

import { Flex, Heading } from 'meeting/chime/amazon-chime-sdk/src';

import { StyledDesc, StyledLogo } from './Styled';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import CharacterAvatar from 'components/CharacterAvatar';

const MeetingDetails = () => {
  const {
    currentOrganization: { name = '', description = '', logo = '' } = {}
  } = useSelector((state: AppState) => state.sponsors);

  return (
    <Flex container flexDirection='column'>
      <StyledLogo>
        {logo ? <img src={logo} alt={name} /> : <CharacterAvatar name={name} />}
      </StyledLogo>
      <Flex container layout='fill-space-centered'>
        <Flex
          mb='2rem'
          mr={{ md: '2rem' }}
          alignItems={'center'}
          justifyContent={'center'}
          className='w-75'
          px='1rem'
        >
          <Heading level={4} tag='h1' mb={2}>
            {name}
          </Heading>
          <StyledDesc>{parse(description)}</StyledDesc>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MeetingDetails;
