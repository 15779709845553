import {
  DISCONNECT_SOCKET,
  SET_EVENT_SOCKET_ID,
  SET_SOCKET
} from './constants';

const initialState = {
  socket: null,
  eventSocket: null,
  eventSocketId: ''
};

interface ActionInterface {
  type: string;
  payload?: any;
  socketName?: any;
}

export function socket(state = { ...initialState }, action: ActionInterface) {
  switch (action.type) {
    case SET_SOCKET:
      return {
        ...state,
        [action.socketName]: action.payload
      };
    case SET_EVENT_SOCKET_ID: {
      return {
        ...state,
        eventSocketId: action.payload
      };
    }
    case DISCONNECT_SOCKET:
      return {
        ...state,
        [action.socketName]: null
      };

    default:
      return state;
  }
}
