import React, { ChangeEvent, createRef, useEffect, useState } from 'react';
import { Participants as ListComponent, SearchBar } from 'react-banzai';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { initialCount } from 'constants/participnats';
import ROUTES from 'constants/routes';
import { useActiveSession, useNextSession } from 'hooks/useSessionStatus';
import { useIsExpectedPage } from 'hooks/usePageLocation';
import { getMoreParticipants, setMoreParticipantsLoading } from 'store/participants/actions';
import { Participant, Props, defaultProps } from './types';

export const Participants: React.FC<Props> = ({
  visibleParticipants = {},
  allParticipants,
  className = '',
  isHost,
}) => {
  const dispatch = useDispatch();
  const {
    sessions: { data },
    auth: {
      user: { id },
    },
    app: { virtual_room: { show_company = null } = {} },
    participants: { visibleParticipantsLoading },
  } = useSelector((state: AppState) => state);
  const [currentSession, setCurrentSession] = useState<any>({});
  const nextSession = useNextSession();
  const activeSession = useActiveSession();
  const isStagePage = useIsExpectedPage(ROUTES.STAGE);

  useEffect(() => {
    if (data?.length) {
      const activeOrNextSession = activeSession || nextSession;
      activeOrNextSession && setCurrentSession(activeOrNextSession);
    }
    // eslint-disable-next-line
  }, [data]);

  const [searchParticipant, setSearchParticipant] = useState<string>('');
  const participantsRef = createRef();

  useEffect(() => {
    const { current }: any = participantsRef;
    // prevent scroll for first time
    if (current && Object.keys(visibleParticipants).length > initialCount) {
      const scrollHeight = current.scrollHeight;
      const height = current.clientHeight;
      // calculated next 5 items height
      const maxScrollTop = scrollHeight - height - 200;
      current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
    // eslint-disable-next-line
  }, [Object.keys(visibleParticipants).length]);

  useEffect(() => {
    const { current }: any = participantsRef;
    const scrollHandler = () => {
      if (
        Math.ceil(current.scrollTop + current.offsetHeight) >= current.scrollHeight &&
        Object.keys(visibleParticipants).length < allParticipants.length
      ) {
        dispatch(setMoreParticipantsLoading(true));
        dispatch(getMoreParticipants());
      }
    };

    current?.addEventListener('scroll', scrollHandler);
    return () => {
      current?.removeEventListener('scroll', scrollHandler);
    };
    // eslint-disable-next-line
  }, [participantsRef]);

  const upperCaseSearchParticipant = searchParticipant.toUpperCase();

  const filterParticipantsBySearchName =
    allParticipants?.filter(({ name }: Participant) => {
      return name?.toUpperCase().includes(upperCaseSearchParticipant);
    }) || [];

  let participants: any = searchParticipant.trim()
    ? filterParticipantsBySearchName
    : Object.values(visibleParticipants) || [];

  let speakers: any = [];

  speakers = isStagePage
    ? allParticipants?.filter(({ email: participantEmail }: Participant) =>
        currentSession?.speakers?.find(
          ({ email: speakerEmail }: any) => speakerEmail === participantEmail
        )
      )
    : [];

  participants = isStagePage
    ? participants?.filter(
        (participant: Participant) =>
          !currentSession?.speakers?.find(
            ({ email: speakerEmail }: any) => speakerEmail === participant?.email
          )
      ) || []
    : participants;

  const isSpeaker = speakers.some(({ partId }: any) => partId === id);
  return (
    <div className={`${classnames(className)} aside-content`}>
      <div className="h-100 d-flex flex-column p-4">
        <SearchBar
          variant="secondary"
          placeholder="Search participants"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchParticipant(e.target.value)}
        />
        {currentSession && !!speakers.length && (isHost || isSpeaker) && (
          <ListComponent
            showCompany={!!show_company}
            meetingControls={true}
            items={speakers}
            isControlsEnabled={currentSession?.virtual_room_session?.type === 'live'}
            label={'Speakers'}
          />
        )}
        {!!participants.length && (
          <ListComponent
            ref={participantsRef}
            loading={visibleParticipantsLoading}
            showCompany={!!show_company}
            items={participants}
            label={!!speakers.length ? 'Participants' : ''}
          />
        )}
      </div>
    </div>
  );
};

Participants.defaultProps = defaultProps;
