import React from 'react'
import PropTypes from 'prop-types'

const ImageCard = ({ img, height, width, style }) => {
  const _style = { ...style, backgroundImage: `url(${img})`, width, height }
  return (
    <div
      className='card bgi-no-repeat bgi-size-cover card-stretch border-0 ml-0'
      style={_style}
    >
      <div className='card-body d-flex flex-column align-items-start justify-content-start'>
        <div className='p-1 flex-grow-1' />
      </div>
    </div>
  )
}

ImageCard.propTypes = {
  img: PropTypes.node.isRequired,
  width: PropTypes.string,
  height: PropTypes.string
}

ImageCard.defaultProps = {
  height: '200px',
  width: '300px'
}

export default ImageCard
