import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'

import { typeContent } from '../../../custom-types'
// import styles from './styles.module.scss';

const TabbedContent = ({ defaultActiveKey, tabItems }) => {
  const _defaultActiveKey = isEmpty(defaultActiveKey)
    ? tabItems[0].key
    : defaultActiveKey

  return (
    <Card className='h-100 pt-10' style={{ overflow: 'hidden' }}>
      <Card.Body className='h-100'>
        <Tab.Container
          defaultActiveKey={_defaultActiveKey}
          style={{ height: '100%' }}
        >
          <Row>
            <Col className='d-flex'>
              {map(tabItems, (item) => (
                <Nav variant='pills' key={item.key}>
                  <Nav.Item key={item.key} className='mr-10'>
                    <Nav.Link eventKey={item.key}>{item.navContent}</Nav.Link>
                  </Nav.Item>
                </Nav>
              ))}
            </Col>
          </Row>
          <Row className='h-100 mt-10'>
            <Col className='h-100'>
              <Tab.Content className='h-100'>
                {map(tabItems, (item) => (
                  <Tab.Pane
                    key={item.key}
                    eventKey={item.key}
                    className='h-100'
                  >
                    {item.content}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Card.Body>
    </Card>
  )
}

const tabItemType = PropTypes.shape({
  key: PropTypes.string,
  navContent: typeContent,
  content: typeContent
})

TabbedContent.propTypes = {
  defaultActiveKey: PropTypes.string,
  tabItems: PropTypes.arrayOf(tabItemType).isRequired
}

TabbedContent.defaultProps = {
  defaultActiveKey: ''
}

export default TabbedContent
