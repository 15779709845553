import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import {
  CANCEL_REG_TO_EVENT_REQUEST,
  CANCEL_REG_TO_EVENT_RESET,
  CANCEL_REG_TO_EVENT_SUCCESS,
  CHANGE_REG_AND_COPY_FIELDS,
  CHANGE_REGISTRATION_COPY_FIELDS,
  CHANGE_REGISTRATION_FIELDS,
  CLEAR_CURRENT_EVENT,
  ClEAR_PARTICIPANTS_REG_DATA,
  CLEAR_REGISTRATION_FIELDS,
  GET_ACCOUNT_EVENTS,
  GET_ALL,
  GET_ALL_SESSIONS_FAILURE,
  GET_ALL_SESSIONS_REQUEST,
  GET_ALL_SESSIONS_SUCCESS,
  GET_CUSTOM_MENU,
  GET_ONE,
  GET_ONE_FAILURE,
  GET_ONE_LOADING,
  GET_ONE_REQUEST,
  GET_ONE_SUCCESS,
  GET_PAST_EVENTS_FAILURE,
  GET_PAST_EVENTS_REQUEST,
  GET_PAST_EVENTS_SUCCESS,
  GET_RECEPTION_DATA_FAILURE,
  GET_RECEPTION_DATA_REQUEST,
  GET_RECEPTION_DATA_SUCCESS,
  GET_TICKER_MESSAGES,
  GET_UPCOMING_EVENTS_FAILURE,
  GET_UPCOMING_EVENTS_REQUEST,
  GET_UPCOMING_EVENTS_SUCCESS,
  RESET_EVENTS_DATA,
  SET_ACTIVE_SESSIONS_ID,
  SET_ALL_EVENTS_lOADING,
  SET_COUNTRY_STATES,
  SET_IS_REGISTERED_TO_EVENT,
  SET_REDIRECTION_URL,
  SET_REGISTRATION_FIELDS,
  SET_REGISTRATION_FIELDS_LOADING,
  UPDATE_SESSION_DATA,
  SET_IS_JOINED
} from 'store/events/constants';
import { disconnectFromSocket } from 'store/socket/thunk';
import { SET_VIRTUAL_ROOM } from 'store/app/constants';

import { REACT_APP_BACKEND_API_URL } from 'constants/index';
import { instance } from 'api/auth';
import * as api from 'api';
import { getOrganization } from '../sponsors/actions';
import { getGreenRoomsAction } from '../sessions/actions';

export const getRegFields = (id: string, userData: any): any => {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await api.getRegistrationFields(id);

      if (data.data) {
        dispatch({
          type: SET_REGISTRATION_FIELDS,
          eventRegistrationFields: data.data
        });
        data.data.coreField.forEach((item: any) => {
          if (!userData[item.name]) {
            if (item.name === 'opt-in') {
              dispatch(changeRegAndCopyFields(item.name, 'No'));
            } else if (
              item.name === 'multicheck' ||
              item.name === 'checkbox' ||
              item.name === 'multi'
            ) {
              let filteredOptions = item.options.filter((elm: any) => {
                return elm.default;
              });
              let values = filteredOptions.length
                ? filteredOptions.map((elm: any) => elm.value)
                : [];
              dispatch(changeRegAndCopyFields(item.name, values));
            } else if (item.name === 'registrants-count') {
              dispatch(
                changeRegAndCopyFields(item.name, item.required ? 1 : 0)
              );
            } else {
              dispatch(changeRegAndCopyFields(item.name, ''));
            }
          } else {
            dispatch(changeRegAndCopyFields(item.name, userData[item.name]));
          }
        });
      }
    } catch (e: any) {
      dispatch({
        type: SET_REGISTRATION_FIELDS,
        eventRegistrationFields: {}
      });
    }
  };
};

export const getAllEvents =
  (search: string, status?: string): any =>
  (dispatch: Dispatch) => {
    dispatch(setEventLoading(true));
    let getUrl = `${REACT_APP_BACKEND_API_URL}events-list?search=${search}&page=1`;
    if (status) {
      getUrl = getUrl + `&status=${status}`;
    }

    instance
      .get(getUrl)
      .then(({ data }) => {
        dispatch({
          type: GET_ALL,
          events: data.data
        });
      })
      .finally(() => {
        dispatch(setEventLoading(false));
      });
  };

export const getParticipantsRegistartionInfo =
  (eventCode: string): any =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setRegistrationFieldsLoading(true));
    try {
      const {
        auth: { loggedIn, user },
        events: {
          currentEvent: { participant_is_registered }
        }
      } = getState();
      var userData = {};
      if (loggedIn) {
        if (participant_is_registered) {
          const res = await api.getParticipantRegInfo(eventCode, user.email);
          userData = res.data.data;
          Object.entries(userData).forEach(([key, value]) => {
            dispatch(changeRegAndCopyFields(key, value));
          });
        } else {
          userData = user;
        }
      }
      if (userData) await dispatch(getRegFields(eventCode, userData));
    } catch (e: any) {
      console.error(e);
    } finally {
      dispatch(setRegistrationFieldsLoading(false));
    }
  };

export const getMenuItems =
  (eventCode: string): any =>
  async (dispatch: any) => {
    try {
      const {
        data: { data: customMenu }
      } = await api.getCustomMenu(eventCode);
      let customItems = customMenu.filter(
        (data: any) => !data.disable_from_event
      );
      dispatch(getCustomMenu(customItems));
      return { customItems };
    } catch (error: any) {}
  };

export const getOneEvent = (eventCode: string, isRealTimeUpdate?: boolean) => {
  return async (dispatch: any) => {
    !isRealTimeUpdate && dispatch(getOneEventLoading(true));
    try {
      !isRealTimeUpdate && dispatch(getOneEventRequest());
      const { data } = await instance.get(
        `${REACT_APP_BACKEND_API_URL}events/${eventCode}`
      );
      const { data: event } = data;
      dispatch({
        type: GET_ONE,
        event
      });
      !isRealTimeUpdate && dispatch(getOneEventSuccess());
    } catch (err: any) {
      dispatch(getOneEventFailure(err.message));
    } finally {
      !isRealTimeUpdate && dispatch(getOneEventLoading(false));
    }
  };
};

const getOneEventLoading = (payload: boolean) => ({
  type: GET_ONE_LOADING,
  payload
});

export const clearCurrentEvent = (): any => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_CURRENT_EVENT
  });
  dispatch(disconnectFromSocket() as any);
};

const getOneEventRequest = () => ({ type: GET_ONE_REQUEST });
const getOneEventSuccess = () => ({ type: GET_ONE_SUCCESS });
const getOneEventFailure = (message: string) => ({
  type: GET_ONE_FAILURE,
  message
});
export const getCustomMenu = (data: any) => ({
  type: GET_CUSTOM_MENU,
  data
});

const setRegistrationFieldsLoading = (data: any) => ({
  type: SET_REGISTRATION_FIELDS_LOADING,
  data
});

export const getAccountEvents = (): any => (dispatch: Dispatch) => {
  const events = {
    upcoming: ['1', '2', '3', '8'],
    past: ['4', '5', '6', '7']
  };

  dispatch({
    type: GET_ACCOUNT_EVENTS,
    events
  });
};

export const setActiveSessionsId = (payload: any) => ({
  type: SET_ACTIVE_SESSIONS_ID,
  payload
});

export const clearRegistrationFields = () => ({
  type: CLEAR_REGISTRATION_FIELDS
});

export const clearParticipantsRegData = () => ({
  type: ClEAR_PARTICIPANTS_REG_DATA
});

export const changeRegistrationFields = (key: string, value: any) => {
  return {
    type: CHANGE_REGISTRATION_FIELDS,
    participantRegData: { key, value }
  };
};

export const changeRegistrationCopyFields = (key: string, value: any) => {
  return {
    type: CHANGE_REGISTRATION_COPY_FIELDS,
    participantRegData: { key, value }
  };
};

export const changeRegAndCopyFields = (key: string, value: any) => {
  return {
    type: CHANGE_REG_AND_COPY_FIELDS,
    participantRegData: { key, value }
  };
};

export const registerToEvent = (link: string) => {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    try {
      const {
        events: {
          participantRegData,
          registrationFields: { appId = '' } = {},
          currentEvent: { event_code = '' } = {}
        },
        auth: { loggedIn }
      } = getState();

      const response = await api.RegisterToEvent(
        event_code,
        participantRegData,
        appId
      );
      const {
        data: { success }
      } = response;

      if (success) {
        if (loggedIn) {
          dispatch({
            type: SET_IS_REGISTERED_TO_EVENT,
            data: true
          });
          if (link) {
            dispatch(push(link));
          } else {
            toast.info(
              'Thank you for your registration. The event will be available soon.'
            );
          }
        } else {
          toast.info(
            'Thank you for registering. Please, check your inbox for confirmation.'
          );
          dispatch(clearParticipantsRegData());
        }
      }
    } catch (error: any) {
      const {
        response: { data: { details: { message = '' } = {} } = {} } = {}
      } = error;
      message && toast.error(message);
    }
  };
};

export const setCountryStates = (states: Array<Object>) => ({
  type: SET_COUNTRY_STATES,
  states
});

export const getStates = (country: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    try {
      const { events: { registrationFields: { coreField = [] } = {} } = {} } =
        getState();
      const stateField = coreField.find((field: any) => field.name === 'state');
      if (stateField) {
        const { source } = stateField;
        const res = await api.getCountryStates(country, source);
        if (res.status === 200) {
          dispatch(setCountryStates(res.data.states));
        }
      }
    } catch (e: any) {
      console.error(e);
    }
  };
};

// Reception page

export const getReceptionData = () => {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    try {
      dispatch(getReceptionDataRequest());

      const {
        events: { currentEvent: { customMenu = [] } = {} }
      } = getState();

      const { virtual_room } =
        customMenu.find((item: any) => item.slug === 'reception') || {};

      if (virtual_room) {
        dispatch({
          type: SET_VIRTUAL_ROOM,
          payload: virtual_room
        });
      }
      dispatch(getReceptionDataSuccess());
    } catch (error: any) {
      dispatch(getReceptionDataFailure(error.message));
    }
  };
};

export const clearReceptionData = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_VIRTUAL_ROOM,
      payload: ''
    });
  };
};

const getReceptionDataRequest = () => ({ type: GET_RECEPTION_DATA_REQUEST });
const getReceptionDataSuccess = () => ({ type: GET_RECEPTION_DATA_SUCCESS });
const getReceptionDataFailure = (message: string) => ({
  type: GET_RECEPTION_DATA_FAILURE,
  message
});

// get all sessions
const getAllSessionsRequest = () => ({ type: GET_ALL_SESSIONS_REQUEST });
const getAllSessionsSuccess = (data: any) => ({
  type: GET_ALL_SESSIONS_SUCCESS,
  data
});

const getAllSessionsFailure = (message: string) => ({
  type: GET_ALL_SESSIONS_FAILURE,
  message
});

export const getAllSessions = (
  eventCode: string,
  isRealTimeUpdate: boolean = false
) => {
  return async (dispatch: Dispatch) => {
    try {
      !isRealTimeUpdate && dispatch(getAllSessionsRequest());
      const { data: { data = [] } = {} } = await api.getAllSessions(eventCode);

      dispatch(getAllSessionsSuccess(data));
    } catch (e: any) {
      !isRealTimeUpdate && dispatch(getAllSessionsFailure(e.message));
    }
  };
};

// get upcoming events
const getUpcomingEventsRequest = () => ({ type: GET_UPCOMING_EVENTS_REQUEST });
const getUpcomingEventsSuccess = (data: any) => ({
  type: GET_UPCOMING_EVENTS_SUCCESS,
  data
});
const getUpcomingEventsFailure = () => ({ type: GET_UPCOMING_EVENTS_FAILURE });

export const getUpcomingEvents = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getUpcomingEventsRequest());
      const { data: { data } = {} } = await api.getMyEvents('upcoming');

      dispatch(getUpcomingEventsSuccess(data));
    } catch (error: any) {
      dispatch(getUpcomingEventsFailure());
    }
  };
};

// get past events
const getPastEventsRequest = () => ({ type: GET_PAST_EVENTS_REQUEST });
const getPastEventsSuccess = (data: any) => ({
  type: GET_PAST_EVENTS_SUCCESS,
  data
});
const getPastEventsFailure = () => ({ type: GET_PAST_EVENTS_FAILURE });

export const getPastEvents = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getPastEventsRequest());
      const { data: { data } = {} } = await api.getMyEvents('past');

      dispatch(getPastEventsSuccess(data));
    } catch (error: any) {
      dispatch(getPastEventsFailure());
    }
  };
};

export const eventUpdated = (isHostOrSpeaker: boolean) => {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    try {
      const {
        events: {
          currentEvent: { event_code = '', customMenu = [] }
        },
        sponsors: {
          currentOrganization: { id: orgId }
        },
        socket: { eventSocket }
      } = getState();

      const { room_id } =
        customMenu.find((item: any) => item.slug === 'stage') || {};
      dispatch(getOneEvent(event_code, true) as any);
      room_id &&
        isHostOrSpeaker &&
        dispatch(getGreenRoomsAction(event_code, room_id) as any);
      dispatch(getAllSessions(event_code, true) as any);
      orgId && dispatch(getOrganization(event_code, orgId, true) as any);

      if (eventSocket && event_code) {
        eventSocket.emit('realtime_update', event_code, {
          roomId: room_id
        });
      }
    } catch (error: any) {
      console.error(error);
    }
  };
};

// Cancel event registration
const cancelRegToEventRequest = () => ({ type: CANCEL_REG_TO_EVENT_REQUEST });
const cancelRegToEventSuccess = () => ({ type: CANCEL_REG_TO_EVENT_SUCCESS });
export const cancelRegToEventReset = () => ({
  type: CANCEL_REG_TO_EVENT_RESET
});

export const cancelRegToEventAction = (
  event_code: string,
  appId: number,
  email: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(cancelRegToEventRequest());
      await api.cancelRegToEvent(event_code, appId, email);

      dispatch(cancelRegToEventSuccess());
      dispatch(getOneEvent(event_code, false));
    } catch (error: any) {
      dispatch(cancelRegToEventReset());
      toast.error('Failed');
    }
  };
};

export const getBookmarkSessions = (eventCode: string) => {
  return async (dispatch: Dispatch) => {
    const response = await api.getEventBookmark(eventCode);
    const bookmarks = response.data.data.bookmarks;

    dispatch({
      type: 'SET_EVENT_BOOKMARKS',
      payload: bookmarks
    });
  };
};

export const setBookmarkSessions = (
  eventCode: string,
  sessionId: number,
  type: string
) => {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await api.setEventBookmark(eventCode, sessionId, type);

    if (response.data.success) {
      const bookmarks = getState().events.eventBookmarks;

      let filteredBookmarks = [...bookmarks];

      if (type === 'add') {
        filteredBookmarks = [...bookmarks, sessionId];
      } else {
        filteredBookmarks = filteredBookmarks.filter(
          (bookmark) => bookmark !== sessionId
        );
      }

      dispatch({
        type: 'SET_EVENT_BOOKMARKS',
        payload: filteredBookmarks
      });
    }
  };
};

export const setEventLoading = (loading: boolean | null) => ({
  type: SET_ALL_EVENTS_lOADING,
  data: loading
});

export const resetEventsData = () => ({
  type: RESET_EVENTS_DATA
});

export const getTickerMessages = (data: any): any => ({
  type: GET_TICKER_MESSAGES,
  payload: data
});

export const updateSessionRate = (
  sessionId: number,
  rate: number,
  speakerId?: number
): any => ({
  type: UPDATE_SESSION_DATA,
  payload: { sessionId, rate, speakerId }
});

export function setRedirectionURL(url: string) {
  return {
    type: SET_REDIRECTION_URL,
    payload: url
  };
}

export const setIsJoined = (payload: any) => {
  return {
    type: SET_IS_JOINED,
    payload
  };
};
