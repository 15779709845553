import React, { memo, useEffect, useState } from 'react';

import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import ContainerAgendaCard from 'components/AgendaCard/ContainerAgendaCard';

let prevBookmarkedData: any = [];
let prevSessionTabs: any = [];

const AgendaTabs = ({ eventDays, bookmarkedData, eventBookmarks }: any) => {
  const [key, setKey]: any = useState(
    eventDays.length === 1 ? 'Event’s Agenda' : 'DAY 1'
  );
  const bookmarkedDataLength = bookmarkedData?.length;

  useEffect(() => {
    if (!bookmarkedDataLength && prevBookmarkedData?.length) {
      const defaultValue = eventDays.length === 1 ? 'Event’s Agenda' : 'DAY 1';
      setKey(defaultValue);
    }

    prevBookmarkedData = bookmarkedData;
    // eslint-disable-next-line
  }, [bookmarkedDataLength]);

  useEffect(() => {
    if (eventDays.length && prevSessionTabs.length !== eventDays.length) {
      const defaultValue = eventDays.length === 1 ? 'Event’s Agenda' : 'DAY 1';
      setKey(defaultValue);
    }

    prevSessionTabs = eventDays;
  }, [eventDays]);

  return (
    <Tabs
      activeKey={key}
      onSelect={setKey}
      id='uncontrolled-tab-example'
      className={'pl-3'}
    >
      {!!eventDays.length &&
        eventDays.map((sessionDay: any, index: number) => {
          return (
            <Tab
              eventKey={
                eventDays.length === 1 ? 'Event’s Agenda' : `DAY ${index + 1}`
              }
              title={
                eventDays.length === 1 ? 'Event’s Agenda' : `DAY ${index + 1}`
              }
              key={index}
            >
              {sessionDay.map((session: any) => {
                return (
                  <Row className='h-100 m-0' key={session.id}>
                    <Col className='d-flex flex-column h-100'>
                      <ContainerAgendaCard
                        session={session}
                        eventBookmarks={eventBookmarks}
                      />
                    </Col>
                  </Row>
                );
              })}
            </Tab>
          );
        })}
      {!!bookmarkedDataLength && (
        <Tab eventKey='My Agenda' title='My Agenda'>
          {bookmarkedData.map((session: any) => (
            <Row className='h-100 m-0' key={session.id}>
              <Col className='d-flex flex-column h-100'>
                <ContainerAgendaCard
                  session={session}
                  eventBookmarks={eventBookmarks}
                />
              </Col>
            </Row>
          ))}
        </Tab>
      )}
    </Tabs>
  );
};

export default memo(AgendaTabs);
