import React, { memo, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import eventPageHOC from 'hocs/eventPageHOC';
import { SidebarLayout } from 'layouts/index';
import { clearRoomData, getSessions, resetData } from 'store/sessions/actions';
import { getSponsors } from 'store/sponsors/actions';
import { CommunicationModules } from 'components';
import SponsorLogoCarousel from 'components/SponsorLogoCarousel/SponsorLogoCarousel';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import HotJarTracking from 'components/HotJarTracking/HotJarTracking';
import EventSessionView from 'components/EventSessionView/EventSessionView';
import './EventSessionPage.scss';

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id, roomId } = useParams();

  const {
    events: { currentEvent: { sponsors = [], customMenu = [] } = {} },
    sessions: { sessionsLoading },
    sponsors: { sponsors: sponsorsData },
  } = useSelector(state => state);

  useEffect(() => {
    return () => {
      dispatch(resetData());
    };
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    !id && navigate(`/events`);
    dispatch(getSponsors(id));
    return () => {
      dispatch(clearRoomData());
    };
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    const stageMenuPage = customMenu?.find(item => item.slug === 'stage') || {};
    if (stageMenuPage?.type === 'room') {
      dispatch(getSessions(id, roomId || stageMenuPage?.room_id));
    }
    //eslint-disable-next-line
  }, [customMenu, id, roomId]);

  useEffect(() => {
    let docClick;
    if (!window.interacted) {
      docClick = window.addEventListener('click', () => {
        window.interacted = true;
      });
    }
    return () => {
      window.removeEventListener('click', docClick);
    };
  }, []);

  return (
    <>
      <HotJarTracking />
      <SidebarLayout>
        <div className="event-sidebar-container content d-flex flex-column flex-column-fluid p-0">
          <Row className="event-sidebar-container-row h-100 m-0 flex-1">
            {sessionsLoading ? (
              <CustomLoader />
            ) : (
              <Col
                md="8"
                className="d-flex flex-column p-4 h-100"
                style={{ position: 'relative', minHeight: '300px' }}
              >
                <EventSessionView />
                {!!sponsors && !!sponsors.length && (
                  <SponsorLogoCarousel
                    sponsors={sponsors}
                    sponsorsData={sponsorsData}
                    eventId={id}
                    customMenu={customMenu}
                  />
                )}
              </Col>
            )}
            <CommunicationModules />
          </Row>
        </div>
      </SidebarLayout>
    </>
  );
};

export const EventSessionPage = memo(eventPageHOC(Index));
