import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './styles.scss'

const IconWithText = ({ as, content, icon, contentPosition, ...rest }) => {
  const _content = React.createElement(as, { style: { margin: 0 } }, content)

  const textIsLeft = contentPosition === 'left'

  const className = classNames('iconWithText', {
    'iconWithText--text-left': textIsLeft
  })

  return (
    <div className={className} {...rest}>
      {icon}
      {_content}
    </div>
  )
}

IconWithText.propTypes = {
  as: PropTypes.string,
  icon: PropTypes.node.isRequired,
  content: PropTypes.string.isRequired,
  contentPosition: PropTypes.string
}

IconWithText.defaultProps = {
  as: 'h4',
  contentPosition: 'right'
}

export default React.memo(IconWithText)
