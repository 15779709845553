import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import noop from 'lodash/noop';
import { useFeatherIcon } from 'hooks';
import 'external-svg-loader';

import './MenuBox.scss';

const MenuBox = ({
  content,
  active,
  extraClasses,
  onClick,
  url,
  color,
  isSessionActive
}) => {
  const className = classNames('menuBox', extraClasses, {
    menuBoxActive: active,
    'is-session-active': isSessionActive
  });
  const customColor = {
    '--customColor': color
  };
  const icon = useFeatherIcon(url);
  
  return (
    <div className={className} style={customColor} onClick={onClick}>
      <div className='menuIcon'>
        <svg
          data-src={icon}
          stroke={color}
          variant={'primary'}
        />
      </div>
      <span className='menuContent'> {content} </span>
    </div>
  );
};

MenuBox.propTypes = {
  active: PropTypes.bool,
  content: PropTypes.string.isRequired,
  extraClasses: PropTypes.string,
  onClick: PropTypes.func,
  url:PropTypes.string,
  color:PropTypes.string,
};

MenuBox.defaultProps = {
  active: false,
  extraClasses: null,
  onClick: noop
};

export default React.memo(MenuBox);
