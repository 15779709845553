import React from 'react';
import {
    selectIsConnectedToRoom,
    useHMSActions,
    useHMSStore
  } from "@100mslive/react-sdk";
  
  const Header = () => {
    const isConnected = useHMSStore(selectIsConnectedToRoom);
    const hmsActions = useHMSActions();
  
    return (
      <header>
        {isConnected && (
          <button
            id="leave-btn"
            className="btn-danger"
            onClick={() => hmsActions.leave()}
          >
            Leave Room
          </button>
        )}
      </header>
    );
  }
  
  export default Header;
  