export const SET_VONAGE_MEETING_CREDENTIALS = 'SET_VONAGE_MEETING_CREDENTIALS';
export const SET_VONAGE_SESSION = 'SET_VONAGE_SESSION';
export const SET_ARCHIVE = 'SET_ARCHIVE';
export const CLEAR_VONAGE_MEETING = 'CLEAR_VONAGE_MEETING';
export const SCREEN_SHARE_STOP = 'screen_share_stop';
export const CHANGE_MEETING_STATUS = 'change_meeting_status';
export const SET_AUDIO = 'SET_AUDIO';
export const SET_VIDEO_SOURCE = 'SET_VIDEO_SOURCE';
export const SET_VIDEO = 'SET_VIDEO';
export const SET_SCREEN_SHARING = 'SET_SCREEN_SHARING';
export const SET_PUBLISHER = 'SET_PUBLISHER';
export const SET_DEVICE_ID = 'SET_DEVICE_ID';
export const TURN_OFF_MIC_AND_CAMERA = 'TURN_OFF_MIC_AND_CAMERA';
