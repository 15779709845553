import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import classnames from 'classnames';
import './styles.scss';

const BoothCard = ({ description = '', website, name, logo, onClick }) => {
  const onRedirectToWebsite = (e) => {
    e.stopPropagation();
    if (website) window.open(website);
    else return false;
  };

  const classes = classnames('w-100 h-100 booth-card', {
    'cursor-pointer': website
  });

  return (
    <Card className={classes} onClick={onClick}>
      <Card.Header
        className='p-1 d-flex justify-content-start align-items-center'
        style={{ height: '70px' }}
      >
        <Card.Img
          onClick={onRedirectToWebsite}
          variant='top'
          src={logo}
          className='mh-100 h-auto w-auto pl-2'
          style={{ maxWidth: '50%' }}
        />
      </Card.Header>
      <Card.Body className='p-4'>
        <Card.Title className='text-dark mb-4'>{name}</Card.Title>
        <Card.Text className='text-dark'>{description}</Card.Text>
      </Card.Body>
    </Card>
  );
};

BoothCard.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  href: PropTypes.string,
  ownerName: PropTypes.string,
  thumbnail: PropTypes.node,
  onClick: PropTypes.func
};

BoothCard.defaultProps = {
  href: null,
  thumbnail: null
};

export default BoothCard;
