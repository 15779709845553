import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Card from 'react-bootstrap/Card'

import { VerticalIcon } from '../../icons/stockholm'
import HeaderDisplay from '../../atoms/HeaderDisplay'
import IconWithText from '../../molecules/IconWithText'

const EventCard = ({ cardWrapperClasses, date, description, time, title }) => {
  const bodyRef = useRef()

  const [image, setImage] = useState(null)

  useEffect(() => {
    if (bodyRef && bodyRef.current)
      setImage(
        <Card.Img
          src={`https://images.unsplash.com/photo-1587825140708-dfaf72ae4b04?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=${bodyRef.current.clientWidth}&h=375&q=80`}
          variant='top'
        />
      )
  }, [bodyRef])

  return (
    <Card className={cardWrapperClasses}>
      {image}
      <Card.Body ref={bodyRef}>
        <Card.Title>
          <HeaderDisplay content={title} variant={4} />
        </Card.Title>
        <Card.Subtitle className='mb-2 text-muted'>
          <IconWithText
            icon={<VerticalIcon />}
            content={date}
            contentPosition='left'
          />
          <IconWithText
            icon={
              <FontAwesomeIcon
                icon='calendar'
                size='lg'
                className='text-primary'
                style={{ height: '10px' }}
              />
            }
            content={time}
            contentPosition='left'
          />
        </Card.Subtitle>
        <Card.Text>{description}</Card.Text>
      </Card.Body>
    </Card>
  )
}

EventCard.propTypes = {
  cardWrapperClasses: PropTypes.string,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

EventCard.defaultProps = { cardWrapperClasses: '' }

export default React.memo(EventCard)
