import React from 'react';
import { Comment as CommentComponent } from 'react-banzai';
import { defaultUserImg } from 'constants/images';
interface IComments {
  comments: Array<Object>;
  setActiveCommentData: Function;
  removeComment: Function;
}

const Comments = ({ comments, setActiveCommentData, removeComment }: IComments) => {
  return (
    <div>
      {comments.map((comment: any) => {
        return (
          <CommentComponent
            key={comment.commentId}
            setActiveCommentData={setActiveCommentData}
            commentData={comment.commentData}
            commentSender={{
              name: comment.commentSender,
              img: comment.senderImg || defaultUserImg,
            }}
            date={comment.date}
            removeComment={() => removeComment(comment.commentId, comment.questionId)}
          />
        );
      })}
    </div>
  );
};

export default Comments;
