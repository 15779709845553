import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import * as api from 'api';
import { getMenuItem } from 'helpers/getRouteUrl';
import { addProtocol } from 'helpers/checkValidURL';
import CustomButtonModal from 'components/SponsorCard/CustomButtonModal';
import CharacterAvatar from 'components/CharacterAvatar';
import './SponsorCard.scss';

const SponsorCard: React.FC<Sponsors> = ({
  id: sponsorId,
  description = '',
  event_code,
  website,
  name: sponsorName,
  logo,
  virtual_room,
  booth_id,
  enable_meeting,
  sponsor_level = '',
  custom_buttons = [],
}) => {
  const {
    events: {
      currentEvent: { customMenu = [] },
    },
    auth: {
      user: { id: userId },
      currentLocation,
    },
    socket: { eventSocketId },
  } = useSelector((state: AppState) => state);

  const navigate = useNavigate();
  const location = useLocation();
  const { id: eventCode } = useParams() as { id: string };

  const onRedirectToWebsite = (e: any) => {
    e.stopPropagation();
    if (website) {
      const newURL = /(http(s?)):\/\//.test(website) ? website : 'http://' + website;
      window.open(newURL);
    }
  };

  const handleTrackAndRedirect = async (e: any) => {
    e.stopPropagation();
    await handleTrack('sponsorJoin');
    navigate(`/event/${event_code}/sponsors/${sponsorId}/stage`);
  };

  const handleCustomButtonClick = async (url: string, buttonId?: number, buttonName?: string) => {
    await handleTrack('sponsorButton', buttonId, buttonName);
    if (url) {
      const newURL = addProtocol(url);
      window.open(newURL);
    }
  };

  const handleTrack = (
    submitted: string,
    sponsorButtonId?: number,
    buttonName?: string
  ): Promise<any> => {
    const menuItem = getMenuItem(location, customMenu);
    return api.sponsorAreaTrack({
      event_code: eventCode,
      submitted,
      customMenuId: menuItem.id,
      sponsorId,
      buttonName,
      sponsorButtonId,
      socketId: eventSocketId,
      userId,
      location: currentLocation,
    });
  };

  const isSponsorNameLong = sponsorName.length > 38;

  const renderSponsorNameTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {sponsorName}
    </Tooltip>
  );

  return (
    <Card className="w-100 h-100 sponsor-card">
      <div className="sponsor-level">
        {!!sponsor_level && <span className="sponsor-level__name">{sponsor_level}</span>}
      </div>
      <Card.Header className="px-5 d-flex justify-content-center align-items-center">
        {logo ? (
          <Card.Img variant="top" src={logo} className="mw-100 h-auto w-auto pl-2" />
        ) : (
          <CharacterAvatar name={sponsorName} />
        )}
      </Card.Header>
      <Card.Body className="p-5 sponsor-card__body">
        <div className="mb-3 title">
          {isSponsorNameLong ? (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 350 }}
              overlay={renderSponsorNameTooltip}
            >
              <p className="m-0"> {sponsorName} </p>
            </OverlayTrigger>
          ) : (
            <p className="m-0"> {sponsorName} </p>
          )}
          <div className="d-flex flex-wrap justify-content-end">
            {!!booth_id && !!enable_meeting && virtual_room && (
              <Button
                variant="success"
                className="join-button m-1 py-1 px-6"
                onClick={handleTrackAndRedirect}
              >
                CHAT NOW
              </Button>
            )}
          </div>
        </div>
        <div className="text">{parse(description)}</div>
        <p className="link" onClick={onRedirectToWebsite}>
          {website}
        </p>
        <div className="footer">
          <div
            className="sponsor-card__body-links cursor-pointer"
            onClick={e => e.stopPropagation()}
          >
            {custom_buttons.map(
              ({
                id,
                url = '',
                button_text = '',
                type = '',
                html = '',
                button_color,
              }: SponsorCustomButton) =>
                type === 'HTML' ? (
                  <CustomButtonModal
                    key={id}
                    applyStyles={false}
                    html={html}
                    button_text={button_text}
                    button_color={button_color}
                    trackFn={() => handleCustomButtonClick(url, id, button_text)}
                  />
                ) : (
                  <button
                    key={id}
                    className="sponsor-card__body-links-item"
                    onClick={() => handleCustomButtonClick(url, id, button_text)}
                  >
                    <span>{button_text}</span>
                  </button>
                )
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SponsorCard;
