import React from 'react';
import classNames from 'classnames';

import Armchair from './Armchair';
import Book from './Book';
import Calendar from './Calendar';
import Camera from './Camera';
import Check from './Check';
import ClipboardList from './ClipboardList';
import Curtains from './Curtains';
import DeletedFile from './Delete';
import DoorOpen from './DoorOpen';
import Edit from './Edit';
import ExternalLink from './ExternalLink';
import Facebook from './Facebook';
import Home from './Home';
import Layout4Blocks from './Layout4Blocks';
import Like from './Like';
import Linkedin from './Linkedin';
import Mail from './Mail';
import Microphone from './Microphone';
import Minus from './Minus';
import More from './More';
import Notification from './Notification';
import Search from './Search';
import SendMessage from './SendMessage';
import Share from './Share';
import User from './User';
import Vertical from './Vertical';
import VideoCamera from './VideoCamera';
import Thumbtack from './Thumbtack';
import Twitter from './Twitter';

const IconDecorator = (Icon) => ({
  size = 'xl',
  variant = 'primary',
  wrapperStyles = {},
  className = '',
  ...restProps
}) => {
  const customStockholmIconClass = classNames(
    `stlm-icon-${variant}`,
    className
  );

  const iconWrapperClasses = classNames(
    'svg-icon',
    `svg-icon-${size} text-${variant} d-flex align-items-center`
  );

  return (
    <span className={iconWrapperClasses} style={wrapperStyles}>
      <Icon
        width='24px'
        height='24px'
        viewBox='0 0 24 24'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        className={customStockholmIconClass}
        {...restProps}
      />
    </span>
  );
};

const ArmchairIcon = React.memo(IconDecorator(Armchair));
const BookIcon = React.memo(IconDecorator(Book));
const CalendarIcon = React.memo(IconDecorator(Calendar));
const CameraIcon = React.memo(IconDecorator(Camera));
const CheckIcon = React.memo(IconDecorator(Check));
const ClipboardListIcon = React.memo(IconDecorator(ClipboardList));
const CurtainsIcon = React.memo(IconDecorator(Curtains));
const DeletedFileIcon = React.memo(IconDecorator(DeletedFile));
const DoorOpenIcon = React.memo(IconDecorator(DoorOpen));
const EditIcon = React.memo(IconDecorator(Edit));
const ExternalLinkIcon = React.memo(IconDecorator(ExternalLink));
const FacebookIcon = React.memo(IconDecorator(Facebook));
const HomeIcon = React.memo(IconDecorator(Home));
const Layout4BlocksIcon = React.memo(IconDecorator(Layout4Blocks));
const LikeIcon = React.memo(IconDecorator(Like));
const LinkedinIcon = React.memo(IconDecorator(Linkedin));
const MailIcon = React.memo(IconDecorator(Mail));
const MicrophoneIcon = React.memo(IconDecorator(Microphone));
const MinusIcon = React.memo(IconDecorator(Minus));
const MoreIcon = React.memo(IconDecorator(More));
const NotificationIcon = React.memo(IconDecorator(Notification));
const SearchIcon = React.memo(IconDecorator(Search));
const SendMessageIcon = React.memo(IconDecorator(SendMessage));
const ShareIcon = React.memo(IconDecorator(Share));
const UserIcon = React.memo(IconDecorator(User));
const VerticalIcon = React.memo(IconDecorator(Vertical));
const VideoCameraIcon = React.memo(IconDecorator(VideoCamera));
const ThumbtackIcon = React.memo(IconDecorator(Thumbtack));
const TwitterIcon = React.memo(IconDecorator(Twitter));

export {
  ArmchairIcon,
  BookIcon,
  CalendarIcon,
  CameraIcon,
  CheckIcon,
  ClipboardListIcon,
  CurtainsIcon,
  DeletedFileIcon,
  DoorOpenIcon,
  EditIcon,
  ExternalLinkIcon,
  FacebookIcon,
  HomeIcon,
  Layout4BlocksIcon,
  LikeIcon,
  LinkedinIcon,
  MailIcon,
  MicrophoneIcon,
  MinusIcon,
  MoreIcon,
  NotificationIcon,
  SearchIcon,
  SendMessageIcon,
  ShareIcon,
  UserIcon,
  VerticalIcon,
  VideoCameraIcon,
  ThumbtackIcon,
  TwitterIcon
};
