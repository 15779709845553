export const EVENT_UPDATED = 'event_updated';

export const GET_ALL = 'events/GET_ALL';
export const GET_ONE = 'events/GET_ONE';
export const GET_ONE_LOADING = 'events/GET_ONE_LOADING';
export const GET_ONE_REQUEST = 'events/GET_ONE_REQUEST';
export const GET_ONE_SUCCESS = 'events/GET_ONE_SUCCESS';
export const GET_ONE_FAILURE = 'events/GET_ONE_FAILURE';
export const GET_ACCOUNT_EVENTS = 'events/GET_ACCOUNT_EVENTS';
export const CLEAR_CURRENT_EVENT = 'events/CLEAR_CURRENT_EVENT';
export const SET_REGISTRATION_FIELDS = 'events/SET_REGISTRATION_FIELDS';
export const SET_REGISTRATION_FIELDS_LOADING =
  'events/SET_REGISTRATION_FIELDS_LOADING';
export const CLEAR_REGISTRATION_FIELDS = 'event/CLEAR_REGISTRATION_FIELDS';
export const CHANGE_REGISTRATION_FIELDS = 'event/CHANGE_REGISTRATION_FIELDS';
export const CHANGE_REGISTRATION_COPY_FIELDS =
  'event/CHANGE_REGISTRATION_COPY_FIELDS';
export const SET_IS_REGISTERED_TO_EVENT = 'event/SET_IS_REGISTERED_TO_EVENT';
export const SET_ALL_EVENTS_lOADING = 'event/SET_ALL_EVENTS_lOADING';
export const ClEAR_PARTICIPANTS_REG_DATA = 'events/ClEAR_PARTICIPANTS_REG_DATA';
export const SET_COUNTRY_STATES = 'events/SET_COUNTRY_STATES';
export const CHANGE_REG_AND_COPY_FIELDS = 'events/CHANGE_REG_AND_COPY_FIELDS';
export const GET_RECEPTION_DATA_REQUEST = 'events/GET_RECEPTION_DATA_REQUEST';
export const GET_RECEPTION_DATA_SUCCESS = 'events/GET_RECEPTION_DATA_SUCCESS';
export const GET_RECEPTION_DATA_FAILURE = 'events/GET_RECEPTION_DATA_FAILURE';
export const GET_ALL_SESSIONS_REQUEST = 'events/GET_ALL_SESSIONS_REQUEST';
export const GET_ALL_SESSIONS_SUCCESS = 'events/GET_ALL_SESSIONS_SUCCESS';
export const GET_ALL_SESSIONS_FAILURE = 'events/GET_ALL_SESSIONS_FAILURE';
export const GET_CUSTOM_MENU = 'events/GET_CUSTOM_MENU';
export const UPDATE_SESSION_DATA = 'events/UPDATE_SESSION_DATA';

export const GET_UPCOMING_EVENTS_REQUEST = 'events/GET_UPCOMING_EVENTS_REQUEST';
export const GET_UPCOMING_EVENTS_SUCCESS = 'events/GET_UPCOMING_EVENTS_SUCCESS';
export const GET_UPCOMING_EVENTS_FAILURE = 'events/GET_UPCOMING_EVENTS_FAILURE';
export const GET_PAST_EVENTS_REQUEST = 'events/GET_PAST_EVENTS_REQUEST';
export const GET_PAST_EVENTS_SUCCESS = 'events/GET_PAST_EVENTS_SUCCESS';
export const GET_PAST_EVENTS_FAILURE = 'events/GET_PAST_EVENTS_FAILURE';

// Cancel event registration
export const CANCEL_REG_TO_EVENT_REQUEST = 'events/CANCEL_REG_TO_EVENT_REQUEST';
export const CANCEL_REG_TO_EVENT_SUCCESS = 'events/CANCEL_REG_TO_EVENT_SUCCESS';
export const CANCEL_REG_TO_EVENT_RESET = 'events/CANCEL_REG_TO_EVENT_RESET';

export const ACTIVE_SESSION_ROOMS = 'active_sessions_rooms';
export const SET_ACTIVE_SESSIONS_ID = 'SET_ACTIVE_SESSIONS_ID';

export const SET_EVENT_BOOKMARKS = 'SET_EVENT_BOOKMARKS';
export const RESET_EVENTS_DATA = 'RESET_EVENTS';
export const GET_TICKER_MESSAGES = 'GET_TICKER_MESSAGES';

export const SET_REDIRECTION_URL = 'SET_REDIRECTION_URL';
export const SET_IS_JOINED = 'SET_IS_JOINED';
