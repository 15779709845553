import React, { useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash'
// import styles from './styles.module.scss'

import Form from 'react-bootstrap/Form'

import Button from '../../atoms/Button'

const ConfirmSignupForm = ({ onSubmit }) => {
  const [verificationCode, setVerificationCode] = useState('')
  const handleCodeChange = (evt) => setVerificationCode(evt.target.value)

  const handleSubmit = (evt) => {
    evt.preventDefault()
    onSubmit(verificationCode)
  }

  return (
    <Form>
      <Form.Group>
        <Form.Control
          placeholder='Verification code'
          onChange={handleCodeChange}
        />
      </Form.Group>
      <Button
        className='w-100'
        content='Verify your account'
        onClick={handleSubmit}
        type='submit'
      />
    </Form>
  )
}

ConfirmSignupForm.propTypes = {
  onSubmit: PropTypes.func
}

ConfirmSignupForm.defaultProps = {
  onSubmit: noop
}

export default ConfirmSignupForm
