import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Formik, FormikValues } from 'formik';
import { FormField } from 'components/FormField/FormField';
import { Alert, Button, Spinner } from 'react-bootstrap';
import * as yup from 'yup';
import { validationMessages } from 'constants/validationMessages';
import { useDispatch, useSelector } from 'react-redux';
import { changePasswordAction } from 'store/auth/actions';
import { isEmpty } from 'utils/checkTypes';

const initialValues = {
  username: '',
  code: '',
  password: '',
  confirmPassword: '',
};

const changePasswordSchema = yup.object().shape({
  username: yup.string().email(validationMessages.email).required(validationMessages.required),
  code: yup.string().required(validationMessages.required),
  password: yup
    .string()
    .min(8, validationMessages.tooShort + '8')
    .required(validationMessages.required),
  confirmPassword: yup
    .string()
    .required(validationMessages.required)
    .min(8, validationMessages.tooShort + '8')
    .oneOf([yup.ref('password')], validationMessages.passwordMatch),
});

const emailValidation = yup.object().shape({
  username: yup.string().email(validationMessages.email).required(validationMessages.required),
});

interface Props {
  title?: string;
  parseUrl?: boolean;
  successMessage?: string;
}

export const ChangePasswordForm: React.FC<Props> = ({
  title = 'Change Password',
  parseUrl = false,
  successMessage = 'Please check your e-mail.',
}) => {
  const dispatch = useDispatch();
  const { id: routeParams = '' } = useParams() as { id: string };
  const [formData, setFormData] = useState({ ...initialValues });
  const {
    changePasswordRequesting,
    changePasswordError,
    forgotPasswordEmail,
    forgotPasswordSuccess,
    forgotPasswordError,
    loggingIn,
  } = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    if (forgotPasswordSuccess) {
      setFormData((prevState: any) => {
        return {
          ...prevState,
          username: forgotPasswordEmail,
        };
      });
    }
  }, [forgotPasswordEmail, forgotPasswordSuccess]);

  useEffect(() => {
    if (parseUrl) {
      emailValidation.isValid({ username: forgotPasswordEmail }).then((valid: boolean) => {
        if (valid) {
          setFormData((prevState: any) => {
            return { ...prevState, username: forgotPasswordEmail };
          });
        }
      });
    }
  }, [parseUrl, forgotPasswordEmail]);

  const onSubmit = ({ username, code, password }: FormikValues) => {
    dispatch(changePasswordAction({ username, code, password, routeParams }));
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={changePasswordSchema}
      initialValues={{ ...formData }}
    >
      {({ values, errors }) => {
        const disabledButton = !Object.values(values).every(Boolean);
        return (
          <Form>
            {title && <h4 className="mb-7 text-center">{title}</h4>}
            {forgotPasswordSuccess && !forgotPasswordError && !changePasswordError && (
              <Alert variant="success">{successMessage}</Alert>
            )}
            {changePasswordError && <Alert variant="danger">{changePasswordError}</Alert>}
            <FormField type="text" name="username" placeholder="Enter your email" />
            <FormField type="text" name="code" placeholder="Verification code" />
            <FormField type="password" name="password" placeholder="New password" />
            <FormField type="password" name="confirmPassword" placeholder="Confirm new password" />
            <Button disabled={disabledButton || !isEmpty(errors)} type="submit">
              {changePasswordRequesting || loggingIn ? (
                <Spinner animation="border" role="status" aria-hidden="true" />
              ) : (
                'Change password'
              )}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
