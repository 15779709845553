import React, { useState } from 'react';
import linkifyHtml from 'linkify-html';
import Modal from 'react-bootstrap/Modal';
import { ButtonElement, CustomButtonProps } from './CustomButton';
import './CustomButtonModal.scss';

const CustomButtonModal: React.FC<CustomButtonProps> = ({
  color,
  html = '',
  icon,
  name
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <ButtonElement
        icon={icon}
        color={color}
        name={name}
        onClick={handleShow}
      />
      <Modal
        show={show}
        onHide={handleClose}
        size='lg'
        centered
        className='stage-custom-button-modal'
      >
        <Modal.Header closeButton={true} closeLabel='Close'>
          <Modal.Title>{name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: linkifyHtml(html) }} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustomButtonModal;
