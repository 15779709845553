import React, { useState } from 'react';

import { UserCard } from 'react-banzai';
import SpeakerModal from './SpeakerModal';

interface Props {
  speakers: Speaker[];
}

const Speakers: React.FC<Props> = ({ speakers }) => {
  const [show, setShow] = useState(false);
  const [activeSpeaker, setActiveSpeaker] = useState<any>({});

  const onClick = (speaker: Speaker) => {
    setShow(true);
    setActiveSpeaker(speaker);
  };
  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <div className='d-flex flex-wrap reception-speakers'>
        {speakers.map((speaker: Speaker, index: number) => (
          <UserCard
            name={`${speaker.first_name} ${speaker.last_name}`}
            key={index}
            src={speaker.photo}
            company={speaker.company}
            position={speaker.position}
            className='col-sm-6 mt-5'
            onClick={() => onClick(speaker)}
          />
        ))}
      </div>
      <SpeakerModal
        show={show}
        onHide={handleClose}
        activeSpeaker={activeSpeaker}
        hasNextSession={false}
      />
    </>
  );
};

export default Speakers;
