import {
  SET_TICKER_MESSAGE_VALUE,
  GET_TICKER_MESSAGES,
  DELETE_TICKER_MESSAGE,
  SHOW_TICKER_MESSAGE
} from './constants';

let initialState: any = {
  data: {},
  messages: {},
  messageUpdated: {},
  showTickerMessagesEditor: false
};

export const tickerMessages = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_TICKER_MESSAGE_VALUE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload.value
        }
      };
    case GET_TICKER_MESSAGES: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      };
    }
    case DELETE_TICKER_MESSAGE: {
      const removeProp = action.id.id;
      const { [removeProp]: remove, ...rest } = state.data;
      return {
        ...state,
        data: rest
      };
    }
    case SHOW_TICKER_MESSAGE: {
      return {
        ...state,
        showTickerMessagesEditor: action.payload
      };
    }
    default:
      return state;
  }
};
