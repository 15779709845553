export const rulesData = [
  {
    join_sponsor_live_meeting: 'Chat with a Sponsor',
    description:
      'Visit our Sponsor booths, click “CHAT NOW” and enter a message. For each sponsor you “CHAT NOW” with, you will be awarded {points} points.',
  },
  {
    click_sponsor_area_button: 'View Sponsor Resources',
    description:
      'For each resource you view within a Sponsor’s booth, you’ll be awarded {points} points.',
  },
  {
    attend_to_session: 'Attend a Session',
    description: 'For each session you attend, you’ll be awarded {points} points.',
  },
  {
    submit_qa: 'Submit a Question',
    description:
      'For each question, you submit during a live session in the Q&A tab you’ll be awarded {points} points (LIMIT: 2 QUESTIONS PER SESSION). Please note it must be a question, not a comment in the Q&A tab.',
  },
  {
    submit_poll: 'Submit a Poll',
    description: 'Participate in a poll during a live session to receive {points} points.',
  },
  {
    attend_to_event: 'Attend the Event',
    description: 'Log in to the event to receive {points} points.',
  },
  { check_in_to_event: 'Check in at the event' },
  { check_in_to_session: 'Check in at the session' },
  {
    check_in_to_sponsor: 'Check in at the sponsor area',
  },
  {
    survey_responses: 'Survey Responses',
  },
  {
    rate_speaker: 'Rate a Speaker',
    description: 'Rate our speaker(s) to receive points.',
  },
  {
    rate_session: 'Rate a Session',
    description: 'Rate our session(s) to receive points.',
  },
  {
    social_share: 'Social Share',
  },
  {
    attend_sponsor_area: 'Attend the sponsor area',
  },
];

export const ruleOrder = {
  join_sponsor_live_meeting: null,
  click_sponsor_area_button: null,
  attend_to_session: null,
  submit_qa: null,
  submit_poll: null,
  attend_to_event: null,
  check_in_to_event: null,
  check_in_to_session: null,
  check_in_to_sponsor: null,
  survey_responses: null,
  rate_speaker: null,
  rate_session: null,
  social_share: null,
  attend_sponsor_area: null,
};