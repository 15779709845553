import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import {
  editUserDataAction,
  changeUserPasswordAction,
  changeUserPasswordReset
} from 'store/auth/actions';

import {
  fieldList,
  profileSchema,
  changePasswordSchema
} from './constant-data';
import ProfileForm from './ProfileForm';
import { ChangePasswordModal } from './ProfileModals';

const activeStyle = {
  borderColor: '#2196f3'
};

const getBase64 = (file: File, cb: any) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    const { result = '' } = reader;
    cb(result);
  };
  reader.onerror = function (error) {
    console.error('Error: ', error);
  };
};

interface Props {
  chnageActivePage: () => void;
}

export const ContainerProfileForm: React.FC<Props> = ({ chnageActivePage }) => {
  const dispatch = useDispatch();
  const [avatarImage, setAvatarImage] = useState<any>('');
  const [changePassModalShow, setChangePassModalShow] = useState(false);
  const {
    user = {},
    editUserLoading,
    changeUserPassword
  } = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    setAvatarImage(user.image);
  }, [user]);

  const onDrop = (images: File[]) => {
    const file = images[0];

    getBase64(file, (result: any) => {
      setAvatarImage(result);
    });
  };

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    onDrop,
    accept: 'image/*'
  });

  const dropzoneStyle: any = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {})
    }),
    [isDragActive]
  );

  useEffect(() => {
    if (!changeUserPassword.loading && !changeUserPassword.error) {
      setChangePassModalShow(false);
    }
  }, [changeUserPassword]);

  const selectImage = () => {
    if (inputRef && inputRef.current) {
      // @ts-ignore
      inputRef.current.click();
    }
  };

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];

    getBase64(file, (result: any) => {
      setAvatarImage(result);
    });
  };

  const handleSaveChanges = async (values: User) => {
    const checkChanges = !Object.entries(values).every(
      ([a, b]) => (user[a] || '') === b
    );

    if (checkChanges || avatarImage !== user.image) {
      const data: User = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        company: values.company,
        headline: values.headline,
        linkedin: values.linkedin,
        bio: values.bio
      };
      avatarImage !== user.image && (data.image = avatarImage);

      await dispatch(editUserDataAction(data));
      chnageActivePage();
    }
  };

  const handleChangePassword = async (values: any) => {
    dispatch(changeUserPasswordAction(values));
  };

  const handleCloseModal = () => {
    setChangePassModalShow(false);
    dispatch(changeUserPasswordReset());
  };

  return (
    <>
      <ProfileForm
        user={user}
        image={avatarImage}
        dropzoneStyle={dropzoneStyle}
        fieldList={fieldList}
        profileSchema={profileSchema}
        loading={editUserLoading}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        selectImage={selectImage}
        handleFileUpload={handleFileUpload}
        handleSaveChanges={handleSaveChanges}
        setChangePassModalShow={() => setChangePassModalShow(true)}
        chnageActivePage={chnageActivePage}
      />
      <ChangePasswordModal
        showModal={changePassModalShow}
        changePasswordSchema={changePasswordSchema}
        changeUserPassword={changeUserPassword}
        handleCloseModal={handleCloseModal}
        handleChangePassword={handleChangePassword}
      />
    </>
  );
};
