import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import * as Styled from './TickerMessages.styled';

const TickerMessages = () => {
  const {
    tickerMessages: { data: tickerMessagesData = {} }
  } = useSelector((state: AppState) => state);

  const duration = useMemo(() => {
    const values: any = Object.values(tickerMessagesData);
    const tickerMessagesLength: any = values.reduce(
      (previousValue: any, currentValue: any) =>
        previousValue + (currentValue?.text?.length || 0)
    );
    //at least 20 seconds for animation
    let time = 20;
    if (tickerMessagesLength > 100) {
      let additionalTime = 10 * ((tickerMessagesLength - 100) / 100);
      time += additionalTime;
    }
    return time;
  }, [tickerMessagesData]);

  const publishedMessages = Object.values(tickerMessagesData).filter(
    ({ status }: any) => status === 'published'
  );

  const formatTickerText = (tieckerMessageText: any) => {
    const formattedText = tieckerMessageText
      .replaceAll('<p>&nbsp;</p>', ' ')
      .replaceAll('<p>', '<span style="margin-right: 8px">')
      .replaceAll('</p>', '</span>')
      .replaceAll('<br />', ' ');

    return parse(formattedText);
  };

  return (
    <Styled.TickerContainer>
      <Styled.TickerWrapper duration={`${duration}s`}>
        {publishedMessages.map(({ text, created_at }: any, index) => (
          <Styled.Item key={created_at + index}>
            {formatTickerText(text)}
          </Styled.Item>
        ))}
      </Styled.TickerWrapper>
    </Styled.TickerContainer>
  );
};

export default TickerMessages;
