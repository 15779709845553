import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Field from './Field';
import { IField } from './types';
import JoinEvent from './JoinEvent';
import {
  changeRegAndCopyFields,
  changeRegistrationFields
} from 'store/events/actions';

const FieldsContainer = (props: any) => {
  const {
    open,
    isUpdated,
    coreField,
    initial,
    register,
    setOpen,
    setShowCancelReg,
    clicked,
    setValidationErrors,
    validationErrors,
    setInitial,
    showRegisterView
  } = props;

  const {
    events: {
      participantRegData,
      currentEvent: { participant_is_registered }
    }
  } = useSelector((state: AppState) => state);

  const dispatch = useDispatch();

  const onChangeField = (fieldName: string, fieldDalue: any) => {
    setInitial(false);
    participant_is_registered
      ? dispatch(changeRegistrationFields(fieldName, fieldDalue))
      : dispatch(changeRegAndCopyFields(fieldName, fieldDalue));
  };

  return !showRegisterView && participant_is_registered ? (
    <JoinEvent
      isUpdated={isUpdated}
      coreField={coreField}
      open={open}
      register={register}
      setOpen={setOpen}
      setShowCancelReg={setShowCancelReg}
      validationErrors={validationErrors}
      clicked={clicked}
      initial={initial}
      onChangeField={onChangeField}
      setValidationErrors={setValidationErrors}
    />
  ) : (
    <div>
      <p className='font-weight-bold mb-3 h5'>Register for this webinar</p>
      <Form className='participant-reg-fields'>
        {coreField.map((field: IField) => {
          return (
            <Field
              {...field}
              clicked={clicked}
              required={field.required}
              validationErrors={validationErrors}
              setValidationErrors={(data: any) => setValidationErrors(data)}
              key={field.name}
              initial={initial}
              participantRegData={participantRegData}
              onChangeField={onChangeField}
            />
          );
        })}
      </Form>
    </div>
  );
};

export default FieldsContainer;
