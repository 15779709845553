import axios from 'axios';
import { REACT_APP_BACKEND_API_URL } from 'constants/index';
import { AuthToken } from 'helpers/index';
import { push } from 'connected-react-router';
import configureStore from 'store/store';
import ROUTES from 'constants/routes';

const store = configureStore();

const instance = axios.create({
  baseURL: REACT_APP_BACKEND_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  config => {
    const token = AuthToken.get('access_token');
    if (token) config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  res => {
    return res;
  },
  async err => {
    if (err.response) {
      const { data: { details: { code = null } = {} } = {} } = err?.response || {};
      if (code === 605 || code === 401) {
        try {
          const { data: { data: { access_token = null } = {} } = {} } = await instance.post(
            `${REACT_APP_BACKEND_API_URL}token`,
            {
              refresh_token: AuthToken.get('refresh_token'),
            }
          );
          AuthToken.set('access_token', access_token);
          return instance(err.config);
        } catch (_error: any) {
          store.dispatch(push(`${ROUTES.EVENTS}`));
          AuthToken.remove('access_token');
          AuthToken.remove('refresh_token');
          return Promise.reject('');
        }
      }
    }
    return Promise.reject(err);
  }
);

export { instance };

export function loginWithMagicLink(magic_token: string, eventCode?: string) {
  return instance.post(`${REACT_APP_BACKEND_API_URL}login-with-magic-link`, {
    magic_token,
    eventCode,
  });
}

export function sendMagicToken(email: string) {
  return instance.post(`${REACT_APP_BACKEND_API_URL}send-magic-link`, {
    email,
  });
}

export function login(email: string, password: string) {
  return instance.post(`${REACT_APP_BACKEND_API_URL}authenticate`, {
    email,
    password,
  });
}

export function getUser() {
  return instance.get(`${REACT_APP_BACKEND_API_URL}user-profile-data`);
}

export function refreshToken(REFRESH_TOKEN: string) {
  return instance.post(`${REACT_APP_BACKEND_API_URL}token`, {
    refresh_token: REFRESH_TOKEN,
  });
}

export function forgotPassword(email: string) {
  return instance.post(`${REACT_APP_BACKEND_API_URL}forget-password`, {
    email,
  });
}

export function resetPassword({ username, code, password }: any) {
  return instance.post(`${REACT_APP_BACKEND_API_URL}reset-password`, {
    email: username,
    verification_code: code,
    password,
    password_confirmation: password,
  });
}

export function signup({ first_name, last_name, email, password, passwordСonfirmation }: any) {
  return instance.post(`${REACT_APP_BACKEND_API_URL}sign-up`, {
    first_name,
    last_name,
    email: email,
    password: password,
    password_confirmation: passwordСonfirmation,
  });
}

export function confirmAccount({ email, code }: any) {
  return instance.post(`${REACT_APP_BACKEND_API_URL}confirm-account`, {
    email: email,
    verification_code: code,
  });
}

export function editUserData(data: User) {
  return instance.put(`${REACT_APP_BACKEND_API_URL}user-profile`, data);
}

export function changePassword(data: any) {
  return instance.put(`${REACT_APP_BACKEND_API_URL}change-user-password`, data);
}
