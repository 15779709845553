export const PARTICIPANTS_EVENTS = {
  UPDATE_ROSTER: 'update_roster',
  PARTICIPANT_DISCONNECT: 'roster_disconnect',
  GET_PARTICIPANTS: 'get_participants'
};

export const UPDATE_PARTICIPANTS_LIST = 'UPDATE_PARTICIPANTS_LIST';
export const CLEAR_PARTICIPANTS_LIST = 'CLEAR_PARTICIPANTS_LIST';
export const GET_MORE_PARTICIPANTS = 'GET_MORE_PARTICIPANTS';
export const SET_MORE_PARTICIPANTS_LOADING = 'SET_MORE_PARTICIPANTS_LOADING';
