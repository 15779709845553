import io from 'socket.io-client';
import { Dispatch } from 'redux';
import { getUserIsSessionSpeaker, getIsHost } from 'helpers/getUserRole';
import {
  disconnectSocket,
  setEventSocketId,
  setSocket
} from '../socket/actions';
import { REACT_APP_SOCKET_URL } from 'constants/index';
import { toast } from 'react-toastify';
import { AuthToken, getBrowser, getDeviceType, getOS } from 'helpers';
import realtimeUpdate from './realtime-update';
import { setPrizes, setRules } from 'store/leaderBoard/actions';
import { LEADERBOARD_EVENTS } from 'store/leaderBoard/constants';
import { eventUpdated, setActiveSessionsId } from 'store/events/actions';
import { ACTIVE_SESSION_ROOMS, EVENT_UPDATED } from '../events/constants';
import {
  CONNECT,
  CREATE_TICKER_MESSAGE,
  DELETE_TICKER_MESSAGE,
  EXPIRED_TOKEN,
  GET_TICKER_MESSAGES,
  NEW_TOKEN,
  TICKER_MESSAGE_UPDATED
} from './constants';
import {
  addTickerMessageField,
  deleteTickerMessage,
  getTickerMessages
} from '../tickerMessages/actions';
import { refreshToken } from 'api/auth';
import { logoutAction } from '../auth/actions';

const socketUrl: string = REACT_APP_SOCKET_URL || '';
const chatSocketTransports = ['websocket'];

async function createSocket(query: Object) {
  return io(socketUrl, {
    query,
    transports: chatSocketTransports
  });
}

let socket: any = null;

export default function connectToEventSocket(
  extUserId: any,
  chat_id: string = ''
) {
  return async function (dispatch: any, getState: () => AppState) {
    const {
      auth: {
        user: { first_name, last_name, email, image },
        currentLocation: location
      },
      events: {
        currentEvent: { customMenu, is_host, event_code }
      },
      sponsors: {
        currentOrganization: { is_host: isCurrentOrganizationHost }
      },
      sessions: { data: sessionsData = [] }
    } = getState();

    if (!chat_id) {
      return toast.error(
        'Settings are not given appropriately. Please contact with event manager'
      );
    }

    const name =
      first_name || last_name
        ? `${first_name} ${last_name}`.toString().trim()
        : email.slice(0, 1).toUpperCase();

    const isSessionSpeaker = getUserIsSessionSpeaker(sessionsData, email);
    const isHost = getIsHost(isCurrentOrganizationHost, is_host);

    socket = await createSocket({
      chatId: chat_id,
      externalUserId: extUserId,
      eventId: event_code,
      token: AuthToken.get('access_token'),
      isHost,
      name,
      userImage: image,
      eventSocket: true,
      browser: getBrowser(),
      OS: getOS(),
      deviceType: getDeviceType(),
      location
    });
    const REFRESH_TOKEN = AuthToken.get('refresh_token');
    socket.on(CONNECT, () => {
      dispatch(setSocket(socket, 'eventSocket'));
      dispatch(setEventSocketId(socket.id));

      socket.on(EVENT_UPDATED, function () {
        dispatch(eventUpdated(isSessionSpeaker));
      });

      socket.on(ACTIVE_SESSION_ROOMS, (data: any) => {
        dispatch(setActiveSessionsId(data));
      });

      socket.on(GET_TICKER_MESSAGES, (data: any) => {
        dispatch(getTickerMessages(data));
      });

      socket.on(TICKER_MESSAGE_UPDATED, (data: any) => {
        dispatch(getTickerMessages(data));
      });

      socket.on(CREATE_TICKER_MESSAGE, (data: any) => {
        dispatch(addTickerMessageField(data));
      });

      socket.on(DELETE_TICKER_MESSAGE, (data: any) => {
        dispatch(deleteTickerMessage(data));
      });

      socket.on(EXPIRED_TOKEN, () => {
        refreshToken(REFRESH_TOKEN)
          .then((response) => {
            const { data: { data: { access_token } } = {} } = response;
            if (access_token) {
              AuthToken.set('access_token', access_token);
              const { room_id } =
                customMenu.find((item: any) => item.slug === 'stage') || {};

              socket.emit(NEW_TOKEN, event_code, {
                accessToken: access_token,
                roomId: room_id
              });
            }
          })
          .catch((err) => {
            console.error(err);
            dispatch(logoutAction());
          });
      });
      realtimeUpdate(dispatch, socket);
    });

    socket.on(LEADERBOARD_EVENTS.UPDATED_RULES, ({ rules }: any) => {
      dispatch(setRules(rules));
    });

    socket.on(LEADERBOARD_EVENTS.UPDATED_PRIZES, ({ prizes }: any) => {
      dispatch(setPrizes(prizes));
    });
  };
}

export function disconnectFromEventSocket() {
  return async function (dispatch: Dispatch, getState: () => AppState) {
    const { socket: socketInstance } = getState();
    const eventSocketInstance = socketInstance['eventSocket'];

    eventSocketInstance?.disconnect();
    eventSocketInstance && dispatch(disconnectSocket('eventSocket'));
  };
}
