import React from 'react'
import PropTypes from 'prop-types'
import SponsorPanel from '../../molecules/SponsorPanel'
import './styles.scss'

const SponsorsBand = ({ sponsors }) => (
  <div className='sponsors-band d-flex'>
    {sponsors.map((sponsor, index) => (
      <SponsorPanel
        className='mr-10'
        key={`${index}-${sponsor.alt}`}
        src={sponsor.img}
        level='gold'
      />
    ))}
  </div>
)

const imgType = PropTypes.shape({
  src: PropTypes.string,
  alt: PropTypes.string
})

SponsorsBand.propTypes = {
  sponsors: PropTypes.arrayOf(imgType)
}

SponsorsBand.defaultProps = {
  sponsors: []
}

export default SponsorsBand
