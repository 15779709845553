import { REACT_APP_BACKEND_API_URL } from '../constants';
import { getBrowser, getDeviceType, getOS } from 'helpers';
import { instance } from 'api/auth';

// Tack custom menu
export function customMenuTrack(
  event_code: string,
  customMenuId: string | number,
  socketId: string,
  userId: string,
  location: string
) {
  const callCustomMenuTrack = () => {
    instance
      .post(`${REACT_APP_BACKEND_API_URL}store-interaction/${event_code}`, {
        action: 'The user clicked on the custom menu item',
        browserName: getBrowser(),
        operatingSystem: getOS(),
        deviceName: getDeviceType(),
        location,
        customMenuId: customMenuId,
        socketId,
        userId: Number(userId)
      })
      .catch((error: any) => {
        console.error(error);
      });
  };
  callCustomMenuTrack();
}

// Tack communication modules
interface CommunicationModulesParams {
  event_code: string;
  customMenuId?: string | number;
  submitted: string;
  sessionId?: number | string;
  sponsorId?: string;
  sponsorButtonId?: number | string;
  buttonName?: string | undefined;
  question_id?: string;
  poll_id?: string;
  sponsor_id?: string;
  socketId: string;
  userId: string;
  location: string;
}

export function communicationModulesTrack({
  event_code,
  customMenuId,
  submitted,
  sessionId,
  question_id,
  poll_id,
  sponsor_id,
  socketId,
  userId,
  location
}: CommunicationModulesParams) {
  const actions: any = {
    'Q&A': 'The user has submitted Q&A',
    hostPoll: 'The host has published a poll',
    userPoll: 'The user has submitted a poll'
  };
  const action = actions[submitted];
  const callCommunicationModulesTrack = () => {
    instance
      .post(`${REACT_APP_BACKEND_API_URL}store-interaction/${event_code}`, {
        action: action,
        browserName: getBrowser(),
        operatingSystem: getOS(),
        deviceName: getDeviceType(),
        location,
        customMenuId: customMenuId,
        sessionId: sessionId,
        question_id,
        poll_id,
        sponsor_id,
        socketId,
        userId: Number(userId)
      })
      .catch((error: any) => {
        console.error(error);
      });
  };
  callCommunicationModulesTrack();
}

export function sponsorAreaTrack({
  event_code,
  submitted,
  customMenuId,
  sponsorId,
  sponsorButtonId,
  buttonName,
  socketId,
  userId,
  location
}: CommunicationModulesParams) {
  const actions: any = {
    sponsorArea: 'The user has clicked the sponsor area',
    sponsorJoin: 'The user has joined sponsor live meeting',
    sponsorButton: 'The user has clicked the sponsor button'
  };
  const action = actions[submitted];

  const trackCall = () => {
    return instance
      .post(`${REACT_APP_BACKEND_API_URL}store-interaction/${event_code}`, {
        customMenuId,
        sponsor_id: sponsorId,
        organization_button_id: sponsorButtonId,
        action: action,
        browserName: getBrowser(),
        operatingSystem: getOS(),
        deviceName: getDeviceType(),
        location,
        sponsor_button_name: buttonName,
        socketId,
        userId
      })
      .catch((error: any) => {
        console.error(error);
      });
  };
  return trackCall();
}
