import {
  CLEAR_QA,
  CLEAR_SHOW_QUESTIONS_NOTIFICATION,
  LIKE_QUESTION,
  RECEIVE_ONE_QUESTION,
  RECEIVE_QUESTIONS,
  RECIEVE_COMMENT,
  REMOVE_COMMENT,
  REMOVE_QUESTION,
  SCROLL_TO_LAST_QA,
  SET_SHOW_QUESTIONS_NOTIFICATION,
  SET_TAB_OPEN_LAST_DATE,
} from './constants';

let initialState = {
  data: {},
  questionsRequestsCount: 0,
  scrollToLastElement: false,
  hasMore: false,
  showQuestionsNotification: false,
  tabOpenLastDate: '',
};

export function questionsAndAnswers(state = initialState, action: any): any {
  switch (action.type) {
    case SCROLL_TO_LAST_QA: {
      return {
        ...state,
        scrollToLastElement: !state.scrollToLastElement,
      };
    }
    case RECEIVE_QUESTIONS: {
      const { payload, isFirstCall, hasMore } = action;
      return {
        ...state,
        data: { ...payload, ...state.data },
        hasMore,
        questionsRequestsCount: isFirstCall ? 0 : state.questionsRequestsCount + 1,
      };
    }
    case RECEIVE_ONE_QUESTION: {
      let question = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.questionId]: question,
        },
        showQuestionsNotification: action.payload.sendNotification,
      };
    }
    case RECIEVE_COMMENT: {
      let { data }: { data: any } = state;
      let questions = { ...data };
      let question = questions[action.payload.questionId];

      if (question) {
        question.comments = [...question.comments, action.payload];
        questions = { ...questions, [action.payload.questionId]: question };
      }

      return {
        ...state,
        data: questions,
      };
    }
    case LIKE_QUESTION: {
      let { data }: { data: any } = state;
      let question = data[action.payload.questionId];
      question && (question.questionLikes = action.payload.questionLikes);

      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.questionId]: question,
        },
      };
    }
    case REMOVE_QUESTION: {
      let { data }: any = { ...state };
      let question = data[action.payload.questionId];
      question && delete data[question.questionId];
      return {
        ...state,
        data,
      };
    }
    case REMOVE_COMMENT: {
      let { data }: any = { ...state };
      let question = data[action.payload.questionId] || {};

      if (question.comments) {
        question.comments = question.comments.filter((comment: any) => {
          return comment.commentId !== action.payload.commentId;
        });
      }
      return {
        ...state,
        data,
      };
    }
    case CLEAR_QA: {
      return initialState;
    }
    case CLEAR_SHOW_QUESTIONS_NOTIFICATION: {
      return {
        ...state,
        showQuestionsNotification: false,
      };
    }
    case SET_TAB_OPEN_LAST_DATE: {
      return {
        ...state,
        tabOpenLastDate: action.payload,
      };
    }
    case SET_SHOW_QUESTIONS_NOTIFICATION: {
      return {
        ...state,
        showQuestionsNotification: true,
      };
    }
    default:
      return state;
  }
}
