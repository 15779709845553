import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, PollAnswers } from 'react-banzai';
import { ModalLayout } from 'layouts/ModalLayout';
import { editPoll } from 'helpers/poll';

interface Poll {
  poll_id: string;
  poll_options: Array<Object>;
  poll_status: string;
  poll_title: string;
  poll_type: string;
  poll_votes: number;
}

const Answered = () => {
  const pollsShared = useSelector((state: AppState) => {
    return state.poll.data.filter((poll: Poll) => {
      return poll.poll_status === 'shared';
    });
  });
  const pollsEnded = useSelector((state: AppState) => {
    return state.poll.data.filter((poll: Poll) => {
      return poll.poll_status === 'ended';
    });
  });
  const polls = [...pollsEnded, ...pollsShared];
  return polls.length ? (
    <div>
      {polls.map((poll: Poll) => (
        <PollItem poll={poll} key={poll.poll_id} />
      ))}
    </div>
  ) : (
    <div className='text-center m-3'> There is no result </div>
  );
};

interface IPollItem {
  poll: Poll;
}

const PollItem = ({ poll }: IPollItem) => {
  const [showPollShareModal, setShowPollShareModal] = useState(false);
  const [relaunchPollModal, setShowRelaunchPollModal] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const isPollShared = poll.poll_status === 'shared';

  const {
    app: {
      virtual_room: { chat_id }
    },
    socket: { socket }
  } = useSelector((state: AppState) => state);

  useEffect(() => {
    if (isPollShared) {
      setButtonText('Hide Results');
    } else {
      setButtonText('Share Results');
    }
  }, [isPollShared, poll.poll_status]);

  const editPollStatus = (poll_status: string) => {
    editPoll(socket, chat_id, { ...poll, poll_status });
  };

  const changePollStatusAndCloseModal = () => {
    const upcomingStatus = isPollShared ? 'ended' : 'shared';
    editPollStatus(upcomingStatus);
    handleSharePollCloseModal();
  };

  const onRelaunchButtonClick = () => {
    editPollStatus('published');
    handleCloseRelaunchPollModal();
  };

  const handleSharePollCloseModal = () => setShowPollShareModal(false);
  const handleCloseRelaunchPollModal = () => setShowRelaunchPollModal(false);
  const handleOpenRelaunchPollModal = () => setShowRelaunchPollModal(true);

  return (
    <>
      <PollAnswers
        buttonText={buttonText}
        relaunchButtonText='Relaunch'
        onRelaunchButtonClick={handleOpenRelaunchPollModal}
        onButtonClick={() => setShowPollShareModal(true)}
        pollData={poll}
      />
      <ModalLayout
        centered
        isVisible={showPollShareModal}
        onClose={handleCloseRelaunchPollModal}
        children={
          <div className='session-modal'>
            <div className='text text-center mb-3'>
              {`Are you sure you want to ${
                isPollShared ? 'hide' : 'share'
              } the results?`}
            </div>

            <div className='d-flex w-100 justify-content-center'>
              <Button className='mx-2' onClick={changePollStatusAndCloseModal}>
                Yes
              </Button>
              <Button
                className='mx-2'
                variant='outline-dark'
                onClick={handleSharePollCloseModal}
              >
                No
              </Button>
            </div>
          </div>
        }
      />
      <ModalLayout
        centered
        isVisible={relaunchPollModal}
        onClose={handleCloseRelaunchPollModal}
        children={
          <div className='session-modal'>
            <div className='text text-center mb-3'>
              Are you sure you want to relaunch the poll?
            </div>
            <div className='d-flex w-100 justify-content-center'>
              <Button className='mx-2' onClick={onRelaunchButtonClick}>
                Yes
              </Button>
              <Button
                className='mx-2'
                variant='outline-dark'
                onClick={handleCloseRelaunchPollModal}
              >
                No
              </Button>
            </div>
          </div>
        }
      />
    </>
  );
};

export default Answered;
