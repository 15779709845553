import React from 'react';
import { LinkedinIcon, TwitterIcon, UserCard } from 'react-banzai';
import { addProtocol } from 'helpers';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { useNextSession } from 'hooks/useSessionStatus';
import { ModalLayout } from 'layouts/ModalLayout';

interface Props {
  show: boolean;
  onHide: any;
  activeSpeaker: any;
  hasNextSession: boolean;
}

const SpeakerModal: React.FC<Props> = ({
  show,
  onHide,
  activeSpeaker,
  hasNextSession
}) => {
  const nextSession = useNextSession();

  const evenetsState = useSelector((state: AppState) => state.events);
  const { currentEvent = {} } = evenetsState;

  const updatedSpeakers = hasNextSession
    ? nextSession?.speakers
    : currentEvent?.speakers;

  const filteredRealTimeSpeaker = updatedSpeakers?.find(
    (item: any) => item.id === activeSpeaker.id
  );

  const generateSpeakersBio = () => {
    if (filteredRealTimeSpeaker?.bio) {
      return parse(filteredRealTimeSpeaker?.bio);
    } else if (activeSpeaker?.bio) {
      return parse(activeSpeaker?.bio);
    }
  };

  return (
    <ModalLayout isVisible={show} onClose={onHide} centered>
      <div className='d-flex text-break'>
        {(activeSpeaker.twitter || activeSpeaker.linkedin) && (
          <div className='mr-3'>
            {activeSpeaker.twitter && (
              <div className='mb-3'>
                <a
                  href={addProtocol(activeSpeaker.twitter)}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <TwitterIcon style={{ fill: '#525252' }} />
                </a>
              </div>
            )}
            {activeSpeaker.linkedin && (
              <div>
                <a
                  href={addProtocol(activeSpeaker.linkedin)}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <LinkedinIcon style={{ fill: '#525252' }} />
                </a>
              </div>
            )}
          </div>
        )}
        <div>
          <div className='d-flex'>
            <UserCard name='' src={activeSpeaker.photo} clickable={false} />
            <div>
              <h1 className=' d-inline'>
                {filteredRealTimeSpeaker?.first_name
                  ? filteredRealTimeSpeaker?.first_name
                  : activeSpeaker.first_name}
              </h1>
              <h1 className='ml-3 d-inline'>
                {filteredRealTimeSpeaker?.last_name
                  ? filteredRealTimeSpeaker?.last_name
                  : activeSpeaker.last_name}
              </h1>
              <p className='m-0'>
                {filteredRealTimeSpeaker?.company
                  ? filteredRealTimeSpeaker?.company
                  : activeSpeaker.company}
              </p>
              <p className='m-0'>
                {filteredRealTimeSpeaker?.title
                  ? filteredRealTimeSpeaker?.title
                  : activeSpeaker.title}
              </p>
            </div>
          </div>
          <div className='mt-6 speaker-modal-bio'>
            <div>{generateSpeakersBio()}</div>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default SpeakerModal;
