import React, { FC, memo } from 'react';
import { applyTimezone, parseDate } from 'helpers/parseDate';
import AddToCalendar from '@culturehq/add-to-calendar';
import './AddToCalendarShow.scss';

interface IProps {
  event: any;
  label?: any;
  location?: any;
  showStartDate?: boolean;
  description?: string;
}

const AddToCalendarShow: FC<IProps> = ({
  event,
  label,
  location,
  description,
  showStartDate
}) => {
  const details = description || (event && event.description) || '';

  const eventData = {
    name: event.name,
    location: location || '',
    details: details
      .replace(/<\/?[^>]+(>|$)/g, '')
      .replace(/(\r\n|\n|\r)/gm, ''),
    startsAt: parseDate(
      applyTimezone(event.start_date, 'YYYY/MM/DD HH:mm:ss'),
      'Pp'
    ),
    endsAt: parseDate(
      applyTimezone(event.end_date, 'YYYY/MM/DD HH:mm:ss'),
      'Pp'
    )
  };

  const calendarHandleClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <div className='add-to-calendar-show' onClick={calendarHandleClick}>
      <span>{showStartDate && 'Start Date:'}</span>
      <AddToCalendar
        event={eventData}
        children={
          label ||
          applyTimezone(event.start_date, 'dddd, MMMM Do, YYYY [at] h:mm A z')
        }
        filename={event.name}
      />
    </div>
  );
};

export default memo(AddToCalendarShow);
