import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { MenuLink, UserIcon } from 'react-banzai';
import ROUTES from 'constants/routes';
import { logoutAction } from 'store/auth/actions';
import { getOneEvent } from 'store/events/actions';
import { useIsExpectedPage } from 'hooks/usePageLocation';
import './style.scss';

const DropdownTopbarItemToggler = React.forwardRef((props: any, ref: any) => {
  return (
    <div
      ref={ref}
      className="topbar-item"
      onClick={e => {
        e.preventDefault();
        props.onClick(e);
      }}
    >
      {props.children}
    </div>
  );
});

DropdownTopbarItemToggler.displayName = 'DropdownTopbarItemToggler';

const PrivateHeader: React.FC = () => {
  const { pathname } = useLocation();
  const { id: eventId = '' } = useParams() as { id: string };
  const dispatch = useDispatch();
  const {
    auth: {
      user: { first_name, last_name, image, id: userId },
    },
    events: {
      oneEventLoading,
      currentEvent: { event_code },
    },
  } = useSelector((state: AppState) => state);

  const isEventOverviewPage = useIsExpectedPage(ROUTES.EVENT);

  const name = useMemo(() => {
    const firstName = first_name || '';
    const lastName = last_name || '';
    return firstName + ' ' + lastName;
  }, [first_name, last_name]);

  const signOut = () => {
    dispatch(logoutAction());
    isEventOverviewPage && dispatch(getOneEvent(event_code, false));
  };

  const isHideFindEvent = eventId && userId;

  return (
    <>
      {!isHideFindEvent && !oneEventLoading && (
        <div className="d-flex font-weight-bolder font-size-base text-uppercase header-menu-event">
          <div className="d-flex align-items-center px-2 mx-3 header-event-link">
            <MenuLink to={ROUTES.EVENTS} pathname={pathname}>
              <span>Find Events</span>
            </MenuLink>
          </div>
        </div>
      )}
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-user-profile">
          <div
            className={'btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 mx-3'}
          >
            <span className="text-dark-50 font-weight-bolder font-size-base d-none d-sm-inline name-desc">
              {name}
            </span>
            <span className="symbol symbol-35 ml-2">
              <span className="symbol-label font-size-h5 font-weight-bold">
                {image ? (
                  <img src={image} alt="..." className="header-avatar" />
                ) : (
                  <UserIcon variant="primary" />
                )}
              </span>
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-md dropdown-header-menu">
          <div className="navi navi-spacer-x-0 pt-5">
            <Link to="/profile" className="navi-item px-8 cursor-pointer">
              <div className="navi-link">
                <div className="navi-icon mr-2" style={{ height: '24px' }}>
                  {image ? (
                    <img src={image} alt="..." className="header-avatar h-100" />
                  ) : (
                    <UserIcon />
                  )}
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold cursor-pointer">My Profile</div>
                </div>
              </div>
            </Link>
            <Link to="/my-events" className="navi-item px-8 cursor-pointer">
              <div className="navi-link">
                <div className="navi-icon mr-2" style={{ height: '24px' }}>
                  <FontAwesomeIcon icon={faCalendar as IconProp} size="lg" color="ffffff" />
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold cursor-pointer">My Events</div>
                </div>
              </div>
            </Link>
            <div className="navi-separator mt-3"></div>
            <div className="navi-footer px-8 py-5">
              <button className="btn btn-light-primary font-weight-bold mr-2" onClick={signOut}>
                Sign out
              </button>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default PrivateHeader;
