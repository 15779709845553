import { useEffect, useState } from 'react';
import transparentIcon from 'assets/feather/transparent.svg';

export function useFeatherIcon(name: string | undefined): any {
  const [icon, setIcon] = useState('');

  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      if (name) {
        const icon = await import(`assets/feather/${name}`);
        icon && isMounted && setIcon(icon.default);
      }
    }
    fetchData();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [name]);

  return icon || transparentIcon;
}
