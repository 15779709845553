import React from 'react';
import { SidebarLayout } from 'layouts/index';
import AgendaCardsPage from 'components/AgendaCardsPage/AgendaCardsPage';
import eventPageHOC from 'hocs/eventPageHOC';

const Index: React.FC = () => {
  return (
    <SidebarLayout>
      <AgendaCardsPage />
    </SidebarLayout>
  );
};

export const AgendaPage = eventPageHOC(Index);
