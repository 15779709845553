import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { clearShowPollNotification, setLastPollDate } from 'store/poll/actions';

import Builder from './components/Builder';
import AnsweredPolls from './components/Answered';
import Questions from './components/Questions';
import Shared from './components/Shared';
import './styles.scss';

export const Poll: React.FC = () => {
  const dispatch = useDispatch();
  const { orgId = '' } = useParams() as { orgId: string };
  const [activeTab, setActiveTab] = useState<any>('questions')
  const { socket } = useSelector((state: AppState) => state.socket);
  const {
    app: {
      virtual_room: { chat_id }
    },
    events: {
      currentEvent: {
        is_host: is_host_currentEvent
      }
    },
    poll: {
      data: pollData
    },
    sponsors: {
      currentOrganization: {
        is_host: is_host_org
      }
    }
  } = useSelector((state: AppState) => state);

  const sharedPolls = pollData.filter((poll: any) => poll.poll_status === 'shared');
  const sharedPollsLength = sharedPolls.length;

  const is_host = orgId ? is_host_org : is_host_currentEvent;

  useEffect(() => {
    const pollWasSeenDate = new Date().getTime();
    dispatch(clearShowPollNotification());
    socket?.emit('poll_was_seen', chat_id, { date: pollWasSeenDate });
    dispatch(setLastPollDate(pollWasSeenDate));

    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    !sharedPollsLength && setActiveTab('questions')
  }, [sharedPollsLength]);

  return (
    <div className="d-flex flex-column h-100 aside-content">
      {is_host ? (
        <Tabs className="polls-tabs" defaultActiveKey="builder">
          <Tab eventKey="builder" title="Builder" className="h-100">
            <Builder />
          </Tab>
          <Tab eventKey="inProgress" title="In Progress" className="h-100">
            <Questions hostView={true} />
          </Tab>
          <Tab eventKey="answered" title="Answered" className="h-100">
            <AnsweredPolls />
          </Tab>
        </Tabs>
      ) : (
        <Tabs className="polls-tabs" activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
          <Tab eventKey="questions" title="Questions" className="h-100">
            <Questions hostView={false} />
          </Tab>
          {!!sharedPollsLength && (
            <Tab eventKey="shared" title="Shared Results" className="h-100">
              <Shared />
            </Tab>
          )}
        </Tabs>
      )}
    </div>
  );
};
