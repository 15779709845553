import { REACT_APP_BACKEND_API_URL } from 'constants/index';
import { instance } from './auth';

export async function getLeaders(eventCode: string) {
  return instance.get(`${REACT_APP_BACKEND_API_URL}leaders/${eventCode}`);
}

export async function getMyPoints(eventCode: string) {
  return instance.get(`${REACT_APP_BACKEND_API_URL}my-points/${eventCode}`);
}

export async function getRules(eventCode: string) {
  return instance.get(`${REACT_APP_BACKEND_API_URL}rules/${eventCode}`);
}

export async function getPrizes(eventCode: string) {
  return instance.get(`${REACT_APP_BACKEND_API_URL}prizes/${eventCode}`);
}
