import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { banzaiLogo } from 'constants/images';
import TickerMessages from '../TickerMessages/TickerMessages';
import './Footer.scss';

const Footer: React.FC = () => {
  const [showTickerLine, setShowTickerLine] = useState(false);
  const {
    tickerMessages: { showTickerMessagesEditor, data }
  } = useSelector((state: AppState) => state);

  useMemo(
    () =>
      Object.values(data).some((item: any) => item.text !== '')
        ? setShowTickerLine(true)
        : setShowTickerLine(false),
    [data]
  );

  return (
    <div className='d-flex flex-row-reverse bg-white border-top border-secondary overflow-hidden'>
      <div className='px-4 d-flex bg-white justify-content-end align-items-center buttons-group-wrapper'>
        <p className='m-0'>Powered by</p>
        <button
          className='btn btn-link font-weight-bold'
          onClick={() => {
            window.open('https://www.banzai.io/');
          }}
        >
          <img src={banzaiLogo} alt='logo' className='footer-logo' />
        </button>
      </div>
      {showTickerMessagesEditor && showTickerLine ? (
        <div className='ticker-message-wrapper h-100'>
          <TickerMessages />
        </div>
      ) : null}
    </div>
  );
};

export default Footer;
