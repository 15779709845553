import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BoothCard } from 'react-banzai';
interface Props {
  sponsors: Sponsors[];
  event_code: string;
}

const Exhibitors: React.FC<Props> = ({ sponsors = [], event_code }) => {
  return (
    <Row className='m-0'>
      {(sponsors as Sponsors[]).map((sponsor: Sponsors, index: number) => (
        <Col key={index} xl={4} sm={6} xs={12} className='mt-5 pl-0'>
          <BoothCard onClick={() => {}} {...sponsor} event_code={event_code} />
        </Col>
      ))}
    </Row>
  );
};

export default Exhibitors;
