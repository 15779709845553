import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Button, DraftPoll, PollBuilder } from 'react-banzai';
import { getMenuItem } from 'helpers';
import { SortableElement } from 'react-sortable-hoc';
import { Poll } from './Builder';
import * as api from 'api';
import { ModalLayout } from 'layouts/ModalLayout';
import { deletePoll, editPoll } from 'helpers/poll';

interface EditPollProps {
  poll: Poll;
  onEditPoll: (poll: Poll) => void;
  onPublishClick: () => void;
  onDeletePoll: () => void;
}

const SortableItem = SortableElement(({ poll }: any) => {
  const { id: event_code } = useParams() as { id: string };
  const location = useLocation();

  const {
    socket: { socket, eventSocketId },
    auth: {
      user: { id: userId },
      currentLocation
    },
    app: {
      virtual_room: { chat_id }
    },
    events: {
      currentEvent: { customMenu = [] }
    }
  } = useSelector((state: AppState) => state);

  const { poll_id } = poll;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPublishModal, setShowPublishedModal] = useState(false);

  const onEditPoll = (newPollData: any) => {
    const {
      poll_id,
      question: poll_title,
      options: poll_options,
      type: poll_type
    } = newPollData;
    editPoll(socket, chat_id, {
      poll_id,
      poll_title,
      poll_type,
      poll_options,
      poll_status: 'draft'
    });
  };

  const onPublishClick = () => {
    editPoll(socket, chat_id, {
      ...poll,
      poll_status: 'published'
    });
    handlePublishPollCloseModal();

    const menuItem = getMenuItem(location, customMenu);
    if (menuItem) {
      api.communicationModulesTrack({
        event_code,
        customMenuId: menuItem.id,
        submitted: 'hostPoll',
        socketId: eventSocketId,
        userId,
        location: currentLocation
      });
    }
  };

  const handleDeletePollOpenModal = () => {
    setShowDeleteModal(true);
  };

  const handleDeletePollCloseModal = () => {
    setShowDeleteModal(false);
  };

  const handlePublishPollCloseModal = () => {
    setShowPublishedModal(false);
  };

  const handlePublishPollOpenModal = () => {
    setShowPublishedModal(true);
  };

  const onDeletePoll = () => {
    deletePoll(socket, chat_id, poll_id);
    handleDeletePollCloseModal();
  };

  return (
    <>
      <EditPoll
        key={poll_id}
        poll={poll}
        onEditPoll={onEditPoll}
        onPublishClick={handlePublishPollOpenModal}
        onDeletePoll={handleDeletePollOpenModal}
      />
      <ModalLayout
        centered
        isVisible={showDeleteModal}
        onClose={handleDeletePollCloseModal}
        children={
          <div className='session-modal'>
            <div className='text text-center mb-3'>
              Are you sure you want to delete the poll?
            </div>
            <div className='d-flex w-100 justify-content-center'>
              <Button className='mx-2' onClick={onDeletePoll}>
                Yes
              </Button>
              <Button
                className='mx-2'
                variant='outline-dark'
                onClick={handleDeletePollCloseModal}
              >
                No
              </Button>
            </div>
          </div>
        }
      />
      <ModalLayout
        centered
        isVisible={showPublishModal}
        onClose={handlePublishPollCloseModal}
        children={
          <div className='session-modal'>
            <div className='text text-center mb-3'>
              Are you sure you want to publish the poll?
            </div>

            <div className='d-flex w-100 justify-content-center'>
              <Button className='mx-2' onClick={onPublishClick}>
                Yes
              </Button>
              <Button
                className='mx-2'
                variant='outline-dark'
                onClick={handlePublishPollCloseModal}
              >
                No
              </Button>
            </div>
          </div>
        }
      />
    </>
  );
});

const EditPoll = ({
  poll,
  onEditPoll,
  onPublishClick,
  onDeletePoll
}: EditPollProps) => {
  const [editMode, setEditMode] = useState(false);
  const _onEditPoll = (data: Poll) => {
    const { poll_id } = poll;
    onEditPoll({ ...data, poll_id });
    setEditMode(false);
  };

  const { poll_type, poll_title, poll_options } = poll;
  return (
    <div>
      {editMode ? (
        <PollBuilder
          isEditing={true}
          initialOptionType={poll_type}
          questionText={poll_title}
          initialOptions={poll_options}
          onSubmit={(data: Poll) => _onEditPoll(data)}
          onReset={() => setEditMode(false)}
        />
      ) : (
        <DraftPoll
          poll={poll}
          title={poll_title}
          type={poll_type}
          options={poll_options}
          onEditClick={() => setEditMode(true)}
          onDeleteClick={onDeletePoll}
          onPublishClick={onPublishClick}
        />
      )}
    </div>
  );
};

export default SortableItem;
