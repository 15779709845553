export const SIGNUP_REQUEST = 'auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'auth/SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'auth/SIGNUP_FAILURE';
export const LOGIN_REQUEST = 'auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'auth/LOGIN_FAILURE';
export const VERIFY_REQUEST = 'auth/VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'auth/VERIFY_SUCCESS';
export const VERIFY_FAILURE = 'auth/VERIFY_FAILURE';
export const FORGOT_PASSSWORD_REQUEST = 'auth/FORGOT_PASSSWORD_REQUEST';
export const FORGOT_PASSSWORD_SUCCESS = 'auth/FORGOT_PASSSWORD_SUCCESS';
export const FORGOT_PASSSWORD_FAILURE = 'auth/FORGOT_PASSSWORD_FAILURE';
export const CHANGE_PASSSWORD_REQUEST = 'auth/CHANGE_PASSSWORD_REQUEST';
export const CHANGE_PASSSWORD_SUCCESS = 'auth/CHANGE_PASSSWORD_SUCCESS';
export const CHANGE_PASSSWORD_FAILURE = 'auth/CHANGE_PASSSWORD_FAILURE';
export const AUTH_RESET = 'auth/RESET';
export const MAGIC_LINK_REQUEST = 'auth/MAGIC_LINK_REQUEST';
export const MAGIC_LINK_SUCCESS = 'auth/MAGIC_LINK_SUCCESS';
export const MAGIC_LINK_FAILURE = 'auth/MAGIC_LINK_FAILURE';
export const EDIT_USER_DATA_REQUEST = 'auth/EDIT_USER_DATA_REQUEST';
export const EDIT_USER_DATA_SUCCESS = 'auth/EDIT_USER_DATA_SUCCESS';
export const EDIT_USER_DATA_FAILURE = 'auth/EDIT_USER_DATA_FAILURE';
export const CHANGE_USER_PASSWORD_REQUEST = 'auth/CHANGE_USER_PASSWORD_REQUEST';
export const CHANGE_USER_PASSWORD_FAILURE = 'auth/CHANGE_USER_PASSWORD_FAILURE';
export const CHANGE_USER_PASSWORD_RESET = 'auth/CHANGE_USER_PASSWORD_RESET';
export const SET_USER_CURRENT_LOCATION = 'SET_USER_CURRENT_LOCATION';
export const LOGOUT = 'auth/LOGOUT';
