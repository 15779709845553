import {
  CLEAR_PARTICIPANTS_LIST,
  GET_MORE_PARTICIPANTS,
  SET_MORE_PARTICIPANTS_LOADING,
  UPDATE_PARTICIPANTS_LIST
} from './constants';
import { initialCount } from 'constants/participnats';

let initialState = {
  data: {},
  visibleParticipantsLoading: false,
  visibleParticipants: {}
};

export function participants(state = initialState, action: any): any {
  switch (action.type) {
    case UPDATE_PARTICIPANTS_LIST: {
      let participantsToShow: any = {};
      for (let i = 0; i < initialCount; i++) {
        const [currentKey = '', currentValue = {}] =
          Object.entries(action.payload)[i] || [];

        let { email: currentValueEmail = '' }: any = currentValue;
        let filteredItemsByEmail = Object.values(participantsToShow).find(
          ({ email }: any) => email === currentValueEmail
        );

        if (currentKey && !filteredItemsByEmail) {
          participantsToShow[currentKey] = currentValue;
        }
      }
      return {
        ...state,
        data: action.payload,
        visibleParticipants: participantsToShow
      };
    }
    case GET_MORE_PARTICIPANTS: {
      const participantsToShow: any = { ...state.visibleParticipants };
      const start = Object.keys(participantsToShow).length;
      for (let i = start; i < start + 5; i++) {
        const [currentKey = '', currentValue = {}] =
          Object.entries(state.data)[i] || [];
        let { email: currentValueEmail = '' }: any = currentValue;
        let filteredItemsByEmail = Object.values(participantsToShow).find(
          ({ email }: any) => email === currentValueEmail
        );

        if (currentKey && !filteredItemsByEmail) {
          participantsToShow[currentKey] = currentValue;
        }
      }
      return {
        ...state,
        visibleParticipants: participantsToShow,
        visibleParticipantsLoading: false
      };
    }
    case SET_MORE_PARTICIPANTS_LOADING: {
      return {
        ...state,
        visibleParticipantsLoading: action.payload
      };
    }
    case CLEAR_PARTICIPANTS_LIST: {
      return initialState;
    }
    default:
      return state;
  }
}
