import React from 'react';
import { Button } from 'react-banzai';
import ProgressBar from 'react-bootstrap/ProgressBar';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

const PollAnswers = ({
  buttonText,
  pollData,
  onButtonClick,
  onDeleteButtonClick,
  deleteButtonText,
  showVotesCount,
  relaunchButtonText,
  onRelaunchButtonClick,
}) => {
  const showResult = (count, votes) =>
    showVotesCount ? `${count}%(${votes ? votes.length : 0})` : `${count}%`;
  const {
    poll_options: pollOptions,
    poll_title: pollTitle,
    poll_votes: pollVotes,
    archivedParticipants,
  } = pollData;
  return (
    <div className="m-2 p-2 border-bottom">
      <div className="m-2 d-flex justify-content-between">
        <span className="font-weight-bolder">{pollTitle}</span>
      </div>
      {pollOptions.map(option => {
        const { votes = [], id, value = '' } = option;
        const visibleOptionVotes = showVotesCount
          ? votes.filter(val => archivedParticipants.includes(val))
          : votes;
        const votesCount =
          visibleOptionVotes && pollVotes
            ? Math.round((visibleOptionVotes.length / pollVotes) * 100)
            : 0;

        return (
          <div key={id}>
            <div className="m-2 d-flex justify-content-between">
              <span>{value}</span>
              <span style={{ whiteSpace: 'nowrap', marginLeft: '10px' }}>
                {showResult(votesCount, visibleOptionVotes)}
              </span>
            </div>
            <ProgressBar className="m-2" now={votesCount} />
          </div>
        );
      })}
      {!!buttonText.trim() && (
        <Button size="sm" className="m-2" onClick={onButtonClick}>
          {buttonText}
        </Button>
      )}
      {!!relaunchButtonText && (
        <Button size="sm" className="m-2" variant="warning" onClick={onRelaunchButtonClick}>
          {relaunchButtonText}
        </Button>
      )}
      {!!deleteButtonText?.trim() && (
        <Button size="sm" className="m-2" variant="danger" onClick={onDeleteButtonClick}>
          {deleteButtonText}
        </Button>
      )}
    </div>
  );
};

PollAnswers.propTypes = {
  buttonText: PropTypes.string,
  deleteButtonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  pollData: PropTypes.object.isRequired,
  onDeleteButtonClick: PropTypes.func,
  showVotesCount: PropTypes.bool,
  relaunchButtonText: PropTypes.string,
  onRelaunchButtonClick: PropTypes.func,
};

PollAnswers.defaultProps = {
  buttonText: '',
  deleteButtonText: '',
  onButtonClick: noop,
  onDeleteButtonClick: noop,
  showVotesCount: true,
  relaunchButtonText: '',
  onRelaunchButtonClick: noop,
};

export default PollAnswers;
