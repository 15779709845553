import React, { useMemo, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ROUTES from 'constants/routes';
import { replaceUrlParams } from 'helpers';
import { useIsHost, useUserIsCurrentEventSpeaker } from 'hooks/useUserRole';
import Rules from './Rules';
import { MyPoints } from './MyPoints';
import { Prizes } from './Prizes';
import { LeaderBoard } from './LeaderBoard';

const LeaderBoardTabs = () => {
  const { pathname } = useLocation();
  const {
    events: {
      currentEvent: { is_host: currentEventIsHost, event_code }
    },
    sponsors: {
      currentOrganization: { is_host: currentOrganizationIsHost }
    },
    auth: {
      user: { email }
    }
  } = useSelector((state: AppState) => state);

  const isHost = useIsHost();
  const userIsCurrentEventSpeaker = useUserIsCurrentEventSpeaker();

  const isHostOrSpeaker = useMemo(() => {
    return (
      replaceUrlParams(ROUTES.RECEPTION, event_code) !== pathname &&
      (isHost || !!userIsCurrentEventSpeaker)
    );
    // eslint-disable-next-line
  }, [currentOrganizationIsHost, currentEventIsHost, email]);

  const leaderBoardTabs = useMemo(() => {
    const tabs = [
      {
        title: 'Leaderboard',
        component: <LeaderBoard eventCode={event_code} />
      },
      {
        title: 'Prizes',
        component: <Prizes eventCode={event_code} />
      },
      {
        title: 'Rules',
        component: <Rules eventCode={event_code} />
      }
    ];

    const myPointsTab = {
      title: 'My Points',
      component: <MyPoints eventCode={event_code} />
    };

    !isHostOrSpeaker && tabs.push(myPointsTab);

    return tabs;
    // eslint-disable-next-line
  }, [event_code, currentEventIsHost, currentOrganizationIsHost]);

  return (
    <Tabs className='mb-3'>
      {leaderBoardTabs.map(({ title = '', component }) => (
        <Tab eventKey={title} title={title} key={title}>
          {component}
        </Tab>
      ))}
    </Tabs>
  );
};

export default memo(LeaderBoardTabs);
