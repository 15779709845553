import { Dispatch } from 'redux';
import * as api from 'api/index';
import { SET_VIRTUAL_ROOM } from 'store/app/constants';
import { CHANGE_MEETING_STATUS, TURN_OFF_MIC_AND_CAMERA } from 'store/vonage/constants';
import { joinVonageMeeting, setAudio, setVideo, stopVonageRecording } from 'store/vonage/actions';
import {
  GET_STAGE_SESSIONS_RESET,
  RESET_DATA,
  SESSIONS_RECORD_FAILURE,
  SESSIONS_RECORD_REQUEST,
  SESSIONS_RECORD_SUCCESS,
  SET_GREEN_ROOM_DATA,
  SET_GREEN_ROOM_LOADING,
  SET_SESSION_TIME,
  SET_SHOW_MUTE_BUTTON,
  SET_STAGE_SESSIONS,
  STAGE_SESSIONS_REQUEST_LOADING,
} from './constants';
import { getActiveSession, getNextSession } from 'helpers/getSession';

export const getSessions = (event_code: string, roomId: string | number) => {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    try {
      const {
        events: { currentEvent: { customMenu = [] } = {} },
      } = getState();

      const { virtual_room } = customMenu.find((item: any) => item.room_id === +roomId);

      if (virtual_room) {
        dispatch({
          type: SET_VIRTUAL_ROOM,
          payload: virtual_room,
        });
      }

      const {
        data: { data },
      } = await api.getSessions(event_code, roomId);

      dispatch(updateSessions(data, roomId));
    } catch (err: any) {
      console.error(err);
    }
  };
};

export const updateSessions = (sessions: any, roomId: string | number | null) => {
  return {
    type: SET_STAGE_SESSIONS,
    payload: sessions,
    roomId,
  };
};

export const startSessionFromGreenRoom = (oldActiveSession: any) => {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const {
      events: {
        currentEvent: { event_code, customMenu },
      },
      socket: { socket },
    } = getState();

    const {
      virtual_room: { chat_id },
      room_id: roomId,
      id: stageMenuPageId,
    } = customMenu.find((item: any) => item.slug === 'stage') || {};

    const actionType = oldActiveSession ? 'next' : 'start';
    actionType === 'next' && socket.emit(TURN_OFF_MIC_AND_CAMERA, chat_id);

    try {
      await api.changeSessions(roomId, actionType, event_code, stageMenuPageId);
      const {
        data: { data: sessions },
      } = await api.getSessions(event_code, roomId);

      const activeSession = getActiveSession(sessions);
      const nextSession = getNextSession(sessions);

      const { virtual_room_session: { room_id = 0 } = {} } = activeSession || nextSession || {};
      socket.emit(CHANGE_MEETING_STATUS, chat_id, {
        status: 'started',
        sessionStartTime: new Date(),
        sessions,
        roomId,
      });
      room_id && (await dispatch(joinVonageMeeting(room_id, event_code)));
    } catch (err) {
      console.log(err);
    }
  };
};

export const changeSessionAction = (
  event_code: string,
  roomId: string | number | null,
  action: string
) => {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    try {
      dispatch(stageSessionRequestLoading(true));

      const {
        app: {
          virtual_room: { id: virtualRoomId, chat_id },
        },
        socket: { socket },
        vonage: {
          session: vonageSession = null,
          archive: { archiveId },
        },
        events: {
          currentEvent: { customMenu = [] },
        },
      } = getState();

      if (action !== 'start') {
        if (vonageSession) {
          archiveId && dispatch(stopVonageRecording());
          virtualRoomId && event_code && (await api.finishVonageMeeting(virtualRoomId, event_code));
          dispatch(setAudio(false));
          dispatch(setVideo(false));
        }
        if (vonageSession?.communication) {
          vonageSession.endCall();
        }
      }

      if (action === 'next' || action === 'previous') {
        socket?.emit('hide_mute_button', chat_id);
      }
      const { id: stageMenuPageId } = customMenu.find((item: any) => item.slug === 'stage') || {};
      if (!roomId) throw new Error('invalid roomId');

      await api.changeSessions(roomId, action, event_code, stageMenuPageId);
      const now = new Date();

      const {
        data: { data: sessions },
      } = await api.getSessions(event_code, roomId);

      const [activeSession, nextSession] = [
        sessions?.find(({ status: sessionStatus }: any) => sessionStatus === 'active'),
        sessions?.find(({ status: sessionStatus }: any) => sessionStatus === 'next'),
      ];

      const { virtual_room_session: { room_id = 0 } = {} } = activeSession || nextSession || {};

      const { virtual_room_session: { type: activeSessionType = '' } = {} } = activeSession || {};

      if (activeSessionType === 'live') {
        room_id && (await dispatch(joinVonageMeeting(room_id, event_code)));
      }

      const emitedData =
        action === 'stop'
          ? {
              status: 'finished',
              recordStartTime: null,
              sessionStartTime: null,
              archiveId: null,
              archiveName: null,
            }
          : {
              status: 'started',
              sessionStartTime: now,
            };
      socket.emit(TURN_OFF_MIC_AND_CAMERA, chat_id);
      socket.emit(CHANGE_MEETING_STATUS, chat_id, {
        ...emitedData,
        sessions,
        roomId,
      });
    } catch (err: any) {
      console.error(err);
    } finally {
      dispatch(stageSessionRequestLoading(false));
    }
  };
};

export const getSessionRecord = (id: string | number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(getSessionRecordRequest());

      const {
        data: { data },
      } = await api.getSessionRecord(id);

      dispatch(getSessionRecordSuccess(data));
    } catch (error: any) {
      dispatch(getSessionRecordFailure());
    }
  };
};

export const clearRoomData = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_VIRTUAL_ROOM,
      payload: '',
    });
  };
};

const stageSessionRequestLoading = (payload: boolean) => ({
  type: STAGE_SESSIONS_REQUEST_LOADING,
  payload,
});

// get session record
const getSessionRecordRequest = () => ({ type: SESSIONS_RECORD_REQUEST });

const getSessionRecordSuccess = (data: any) => ({
  type: SESSIONS_RECORD_SUCCESS,
  data,
});

const getSessionRecordFailure = () => ({ type: SESSIONS_RECORD_FAILURE });

export const setSessionStartTime = (payload: any) => ({
  type: SET_SESSION_TIME,
  payload,
});

export const resetSessions = () => ({
  type: GET_STAGE_SESSIONS_RESET,
});

export const setShowMuteButton = (payload: boolean) => ({
  type: SET_SHOW_MUTE_BUTTON,
  payload,
});

export const resetData = () => ({
  type: RESET_DATA,
});

export const rateSessionAction = (rate: number, sessionId: number) => () =>
  api.sessionRate(sessionId, rate);

export const rateSpeakerAction = (rate: number, sessionId: number, speakerId: number) => () =>
  api.speakerRate(speakerId, sessionId, rate);

const setGreenRoomData = (payload: Array<any>) => ({
  type: SET_GREEN_ROOM_DATA,
  payload,
});

const setGreenRoomLoading = (payload: boolean) => ({
  type: SET_GREEN_ROOM_LOADING,
  payload,
});

export const getGreenRoomsAction = (event_code: string, roomId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    let {
      sessions: { data: sessions = [] },
    } = getState();

    const isGreenRoom = window?.location?.pathname?.includes('green-room');

    const isVonageType = sessions.some(
      ({ virtual_room_session }: any) => virtual_room_session?.type === 'live'
    );

    if (isVonageType || isGreenRoom) {
      try {
        dispatch(setGreenRoomLoading(true));
        const {
          data: { data: greenRoomData },
        } = await api.getGreenRooms(event_code, roomId);
        dispatch(setGreenRoomData(greenRoomData));
      } catch (error: any) {
        console.log(error);
      } finally {
        dispatch(setGreenRoomLoading(false));
      }
    }
  };
};
