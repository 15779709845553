import {
  CHANGE_ACTIVE_TAB,
  CHAT_EVENTS,
  CLEAR_CHAT,
  ERROR_MESSAGES,
  LOADED_ADMIN_MESSAGES,
  LOADED_MESSAGES,
  PIN_MESSAGE,
  RECEIVE_ADMIN_MESSAGE,
  RECEIVE_ADMIN_MESSAGES,
  RECEIVE_MESSAGE,
  RECEIVE_MESSAGES,
  REMOVE_MESSAGE,
  SEND_MESSAGE,
  SET_MESSAGE_REF_PART_ID
} from './constants';
import * as api from 'api';

export const sendMessage: any = (payload: any) => {
  return {
    type: SEND_MESSAGE,
    payload
  };
};

export const pinMessage: any = (payload: any) => {
  return {
    type: PIN_MESSAGE,
    payload
  };
};

export const removeMessage: any = (payload: any) => {
  return {
    type: REMOVE_MESSAGE,
    payload
  };
};

export const receiveMessage: any = (payload: any) => {
  return {
    type: RECEIVE_MESSAGE,
    payload
  };
};

export const receiveAdminMessage: any = (payload: any) => {
  return {
    type: RECEIVE_ADMIN_MESSAGE,
    payload
  };
};

export const clearChat: any = () => {
  return {
    type: CLEAR_CHAT
  };
};

export const clearPublicChat: any = () => {
  return {
    type: CHAT_EVENTS.CLEAR_PUBLIC_CHAT
  };
};

export const clearAdminChat: any = () => {
  return {
    type: CHAT_EVENTS.CLEAR_ADMIN_CHAT
  };
};

export const loadedMessages: any = (payload: any) => {
  return {
    type: LOADED_MESSAGES,
    payload
  };
};

export const loadedAdminMessages: any = (payload: any) => {
  return {
    type: LOADED_ADMIN_MESSAGES,
    payload
  };
};

export const changeActiveTab: any = (payload: any) => {
  return {
    type: CHANGE_ACTIVE_TAB,
    payload
  };
};

export const receiveMessages: any = (payload: any, isFirstCall: boolean) => {
  return {
    type: RECEIVE_MESSAGES,
    payload,
    isFirstCall
  };
};

export const receiveAdminMessages: any = (
  payload: any,
  isFirstCall: boolean
) => {
  return {
    type: RECEIVE_ADMIN_MESSAGES,
    payload,
    isFirstCall
  };
};

export const errorMessages: any = (payload: any) => {
  return {
    type: ERROR_MESSAGES,
    payload
  };
};

export const setMessageRefPartId: any = (payload: string) => {
  return {
    type: SET_MESSAGE_REF_PART_ID,
    payload
  };
};

export const getMessages: any = (isFirstCall: boolean) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(loadedMessages(true));
      const {
        chat: { messages },
        app: {
          virtual_room: { chat_id }
        },
        auth: {
          user: { id: partId }
        }
      } = getState();

      const count = isFirstCall ? 0 : messages.length;
      const { data } = await api.getChatMessages(count, chat_id, partId);
      dispatch(receiveMessages(data, isFirstCall));
    } catch (error: any) {
      const { details: { code = null } = {} } = error.response?.data || {};
      console.error(code);
      dispatch(errorMessages(code));
    } finally {
      dispatch(loadedMessages(false));
    }
  };
};

export const getAdminMessages: any = (
  isFirstCall: boolean,
  sessionId: number | string | null
) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(loadedAdminMessages(true));
      const {
        chat: { adminMessages },
        app: {
          virtual_room: { chat_id }
        },
        auth: {
          user: { id: partId }
        }
      } = getState();

      if (chat_id) {
        const { data } = await api.getAdminChatMessages(
          isFirstCall ? 0 : adminMessages.length,
          chat_id,
          partId,
          sessionId
        );
        const {
          app: {
            virtual_room: { chat_id: newChatId }
          }
        } = getState();

        if (chat_id === newChatId) {
          dispatch(receiveAdminMessages(data, isFirstCall));
        }
      }
    } catch (error: any) {
      console.error('Admin chat error!');
    } finally {
      dispatch(loadedAdminMessages(false));
    }
  };
};
