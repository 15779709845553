import React from 'react';
import { useSelector } from 'react-redux';
import { PollAnswers } from 'react-banzai';

interface Poll {
  poll_id: string;
  poll_options: Array<Object>;
  poll_status: string;
  poll_title: string;
  poll_type: string;
  poll_votes: number;
}

const Shared = () => {
  const polls = useSelector((state: AppState) => {
    return (
      state.poll.data?.filter((poll: any) => poll.poll_status === 'shared') ||
      []
    );
  });

  return (
    <div>
      {polls.map((poll: Poll) => (
        <PollAnswers key={poll.poll_id} pollData={poll} />
      ))}
    </div>
  );
};

export default Shared;
