import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { noop, uniqBy } from 'lodash';
import { formattedSender } from 'utils/ChatUtils';
import { useIsHost } from 'hooks/useUserRole';
import { setMessageRefPartId } from 'store/chat/actions';
import ChatMessage from '../../molecules/ChatMessage';
import SendMessage from '../../molecules/SendMessage';
import './styles.scss';

const ChatBox = forwardRef(
  (
    {
      removeMessage,
      loaded,
      isAdminChat,
      currentUser,
      messages,
      onNewMessage,
      onPinClick,
      activeTab,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const chatboxCursor = useRef(null);
    const chatFirstItem = useRef(null);
    const sendMessageBoxRef = useRef();
    const chatboxEl = useRef();
    const [options, setOptions] = useState([{ value: 'all', label: 'All' }]);
    const {
      chat: { messageRefPartId, activeChat },
      participants: { data: participants },
      auth: {
        user: { id, first_name, last_name },
      },
    } = useSelector(state => state);

    const isHost = useIsHost();
    const participantsData = uniqBy(Object.values(participants), 'partId');

    const changeMessageRef = ({ value }) => {
      dispatch(setMessageRefPartId(value));
    };

    useEffect(() => {
      // scroll to bottom on messages change
      const element = chatboxEl?.current;

      if (messages.length <= 20) {
        chatboxCursor.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }
      if (element) {
        if (element.scrollTop <= 20 && chatFirstItem.current) {
          chatFirstItem.current.scrollIntoView();
        } else if (
          chatboxEl.current.scrollTop + 400 >=
          chatboxEl.current.scrollHeight - chatboxEl.current.clientHeight
        ) {
          element.lastChild.scrollIntoView();
        }
        chatFirstItem.current = element.childNodes[1];
      }
    }, [chatboxCursor, chatboxEl, messages]);

    useEffect(() => {
      const chatBoxElement = chatboxEl?.current;
      chatBoxElement.lastChild.scrollIntoView();
    }, [activeChat, activeTab]);

    const handleNewMessage = (text, mentions) => {
      let senderId;
      let messageRefId;
      let externalUserId;
      Object.entries(participants).forEach(([key, participant]) => {
        if (participant.partId === id) senderId = key;
        if (participant.partId === messageRefPartId) {
          externalUserId = participant.externalUserId;
          messageRefId = key;
        }
      });
      const extUserId = formattedSender({
        id,
        first_name,
        last_name,
      });
      onNewMessage(
        text,
        mentions,
        senderId,
        extUserId,
        messageRefId,
        messageRefPartId,
        externalUserId
      );
    };

    const messageTextHeight = sendMessageBoxRef.current?.clientHeight || 0;

    useEffect(() => {
      const data = [{ value: 'all', label: 'All' }];
      participantsData.forEach(({ partId: value, name: label }) => {
        value !== id &&
          label.trim() &&
          data.push({
            value,
            label,
          });
      });
      setOptions(data);
      // eslint-disable-next-line
    }, [participantsData?.length]);

    return (
      <div className="chatbox h-100 aside-content" ref={ref}>
        <div
          className="p-2 chatbox__messages"
          style={{
            maxHeight: `calc( 100vh - ${messageTextHeight + 100}px)`,
          }}
          ref={chatboxEl}
        >
          <div className="text-center">
            {loaded && <Spinner animation="border" role="status" aria-hidden="true" />}
          </div>
          {messages.map(message => {
            return (
              <ChatMessage
                isAdminChat={isAdminChat}
                key={message.messageId}
                {...message}
                isHost={isHost}
                isMe={message.senderPartId === currentUser.id}
                onPinClick={() => onPinClick({ messageId: message.messageId })}
                removeMessage={() => removeMessage(message.messageId)}
              />
            );
          })}
          <div className="float-left clearfix" ref={chatboxCursor} />
        </div>
        <div className="chatbox__sendmessage">
          {!isAdminChat && (
            <ReactSelect
              className="participants-list"
              onChange={changeMessageRef}
              options={options}
              defaultValue={options[0]}
              menuPlacement="top"
            />
          )}
          <SendMessage
            onMessageSent={handleNewMessage}
            ref={sendMessageBoxRef}
            isAdminChat={isAdminChat}
          />
        </div>
      </div>
    );
  }
);

const chatUserType = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

const chatMessageType = PropTypes.shape({
  from: chatUserType,
  text: PropTypes.string,
  isMe: PropTypes.bool,
});

ChatBox.propTypes = {
  isAdminChat: PropTypes.bool,
  loaded: PropTypes.bool,
  currentUser: chatUserType.isRequired,
  messages: PropTypes.arrayOf(chatMessageType),
  onPinClick: PropTypes.func,
  removeMessage: PropTypes.func,
};

ChatBox.defaultProps = {
  isAdminChat: false,
  loaded: false,
  messages: [],
  onPinClick: noop,
  removeMessage: noop,
  currentUser: { name: '', id: '' },
};

export default memo(ChatBox);
