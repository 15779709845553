import React, { useEffect, useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import TickerMessageCard from 'components/TickerMessageCard/TickerMessageCard';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { REACT_APP_TICKER_MESSAGES_MAX_COUNT } from 'constants/index';

const CreateTickerMessages = () => {
  const {
    socket: { eventSocket },
    tickerMessages: { data = {} },
    events: {
      currentEvent: { event_code }
    }
  } = useSelector((state: AppState) => state);

  const [messageArrows, setMessageArrows]: any = useState<any>({});
  const [tickerLocalCount, setTickerLocalCount]: any = useState<number>();
  const tickerMessagesLength = Object.keys(data).length;

  useEffect(() => {
    setTickerLocalCount(tickerMessagesLength);
  }, [tickerMessagesLength]);

  const handleMessageAdd = () => {
    if (tickerLocalCount < REACT_APP_TICKER_MESSAGES_MAX_COUNT) {
      setTickerLocalCount(tickerLocalCount + 1);
      eventSocket?.emit('create_ticker_message', event_code);
    }
  };

  const isSaveButtonDisabled =
    tickerMessagesLength >= REACT_APP_TICKER_MESSAGES_MAX_COUNT;

  const addNewButtonClasses = classNames('w-100 my-3', {
    'cursor-pointer': !isSaveButtonDisabled,
    'cursor-initial': isSaveButtonDisabled
  });

  return (
    <div className='wrapper overflow-hidden p-2'>
      <div className='accordion-wrapper'>
        <Accordion>
          {Object.entries(data).map(([messageKey, value], messageIndex) => (
            <TickerMessageCard
              key={messageKey}
              messageKey={messageKey}
              value={value}
              messageIndex={messageIndex}
              isMessageToggled={messageArrows[messageKey]}
              toggleMessageArrow={() =>
                setMessageArrows({
                  [messageKey]: !messageArrows[messageKey]
                })
              }
            />
          ))}
          <Button
            onClick={handleMessageAdd}
            disabled={isSaveButtonDisabled}
            className={addNewButtonClasses}
            variant='outline-primary'
          >
            Add New
          </Button>
        </Accordion>
      </div>
    </div>
  );
};
export default CreateTickerMessages;
