import {
  SET_ACTIVE_TAB,
  SET_IS_COMMUNICATION_MODULES_AVAILABLE
} from './constants';

const initialState = {
  activeTab: '',
  isCommunicationModulesAvailable: true
};

export const communicationModules = (state = { ...initialState }, action: any) => {
  switch (action.type) {
    case SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload
      };
    }
    case SET_IS_COMMUNICATION_MODULES_AVAILABLE: {
      return {
        ...state,
        isCommunicationModulesAvailable: action.payload
      };
    }
    default:
      return state;
  }
};
