type Sender = {
  id: string;
  first_name: string;
  last_name: string;
};

export const formattedSender = ({ id, first_name, last_name }: Sender): string => {
  first_name = first_name || '';
  last_name = last_name || '';
  return `${id}#${first_name} ${last_name}`.trim();
};
