import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { SidebarLayout } from 'layouts/index';
import { CommunicationModules } from 'components';
import { Chime } from 'meeting';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './SponsorRoom.scss';
import { clearCurrentOrganization, getOrganization } from 'store/sponsors/actions';
import eventPageHOC from 'hocs/eventPageHOC';

const Index: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, orgId }: any = useParams();

  useEffect(() => {
    if (!id) {
      navigate(`/events`);
    } else {
      dispatch(getOrganization(id, orgId));
    }
    return () => {
      dispatch(clearCurrentOrganization());
    };
    // eslint-disable-next-line
  }, [id]);

  return (
    <SidebarLayout>
      <Row className="h-100 m-0 event-sidebar-container-row">
        <Col className="d-flex flex-column p-4 h-100">
          <Chime />
        </Col>
        <CommunicationModules />
      </Row>
    </SidebarLayout>
  );
};

export const SponsorRoomPage = eventPageHOC(Index);
