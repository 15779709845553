import React, { memo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { MenuBox } from 'react-banzai';
import * as api from 'api';
import ROUTES from 'constants/routes';
import AgendaCardsPage from 'components/AgendaCardsPage/AgendaCardsPage';
import LeaderBoardCardsPage from 'components/LeaderBoardCardsPage/LeaderBoardCardsPage';
import { addProtocol, checkValidURL, replaceUrlParams, replaceUrlParamsRoom } from 'helpers';
import { useIsHost } from 'hooks/useUserRole';
import { useActiveSession } from 'hooks/useSessionStatus';
import { useIsExpectedPage } from 'hooks/usePageLocation';
import { setAudio, setVideo } from 'store/vonage/actions';
import { setShowConfirmationModal } from 'store/confirmationModal/actions';
import modalTexts from 'constants/modalTexts';
import { setActiveTab } from 'store/communicationModules/actions';
import { useCommunicationTabData } from 'components/CommunicationModules/useCommunicationTabData';
import './Aside.scss';

interface MenuLinkProps {
  to?: any;
  pathname?: any;
  children?: any;
  item?: any;
}

const MenuLink: React.FC<MenuLinkProps> = React.memo(
  ({ to = '', pathname = '', item, children }) => {
    const dispatch = useDispatch();

    const { type, url = '' } = item;
    const navigate = useNavigate();
    const { id = '' } = useParams() as { id: string };;
    const [popupShow, setPopupShow] = useState(false);
    const [isAgendaModal, setIsAgendaModal] = useState(false);
    const [isLeaderBoardModal, setIsLeaderBoardModal] = useState(false);
    const _children = React.cloneElement(children, {
      active: pathname.startsWith(to),
    });
    const {
      participants: { data: participants },
      auth: {
        user: { id: userId },
        currentLocation,
      },
      socket: { eventSocketId },
    } = useSelector((state: AppState) => state);

    const isHost = useIsHost();
    const activeSession = useActiveSession();
    const isSponsorRoomPage = useIsExpectedPage(ROUTES.SPONSOR_ROOM);

    const isParticipants = Object.entries(participants);
    const participantData: any =
      isParticipants.length &&
      isParticipants.find((partPair: any) => partPair[1]?.partId === userId);

    const is_speaker =
      participantData &&
      activeSession?.speakers?.find(
        ({ email: speakerEmail }: any) => speakerEmail === participantData[1]['email']
      );

    const isShowConfirmationModal: boolean = activeSession && (isHost || is_speaker);

    const onClick = (e: any) => {
      const mainItems = ['reception', 'stage', 'agenda', 'sponsors', 'leaderboard'];
      const toLink = to.split('/')[3];
      const pathnameLink = pathname.split('/')[3];

      if (toLink === 'agenda' && pathnameLink === 'stage') {
        setPopupShow(true);
        setIsAgendaModal(true);
        e.preventDefault();
      } else setIsAgendaModal(false);

      if (toLink === 'leaderboard' && pathnameLink === 'stage') {
        setPopupShow(true);
        setIsLeaderBoardModal(true);
        e.preventDefault();
      } else {
        setIsLeaderBoardModal(false);
      }

      if (!mainItems.includes(toLink)) {
        e.preventDefault();
        if (type === 'popup') {
          setPopupShow(true);
        } else if (type === 'url' && checkValidURL(url)) {
          const newURL = addProtocol(url);
          window.open(newURL);
        }
      }

      if (!isSponsorRoomPage && (toLink === pathnameLink || type === 'url')) {
        return;
      }

      const isTypePopup =
        type === 'popup' ||
        ((toLink === 'agenda' || toLink === 'leaderboard') && pathnameLink === 'stage');

      const customMenuTrack: any = () => {
        api.customMenuTrack(id, item.id, eventSocketId, userId, currentLocation);
        !isTypePopup && navigate(to);
      };

      const isOutRoom = pathnameLink === 'room' || pathnameLink === 'stage';

      if (isShowConfirmationModal && isOutRoom) {
        !isTypePopup &&
          dispatch(
            setShowConfirmationModal({
              text: modalTexts.leaveSession,
              callback: customMenuTrack,
            })
          );
      } else {
        customMenuTrack();
      }
      dispatch(setAudio(false));
      dispatch(setVideo(false));
    };

    return (
      <>
        <div onClick={onClick}>{_children}</div>
        <Modal
          show={popupShow}
          onHide={() => setPopupShow(false)}
          className="custom-menu-popup-type"
          size="xl"
          centered
        >
          <Modal.Body className="overflow-auto d-flex h-100">
            {isAgendaModal ? (
              <AgendaCardsPage />
            ) : isLeaderBoardModal ? (
              <LeaderBoardCardsPage />
            ) : (
              <iframe
                src={url}
                frameBorder={0}
                width="100%"
                height="100%"
                className="p-0 m-0 border-0"
                title="..."
              />
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
);

interface AsideProps {
  setShowSidebar?: any;
}

export const Aside: React.FC<AsideProps> = () => {
  const { pathname } = useLocation();
  const { id = '' }: any = useParams();
  const dispatch = useDispatch();
  const {
    events: {
      activeSessionsRoomId,
      currentEvent: { customMenu = [] },
    },
  } = useSelector((state: AppState) => state);

  const tabData = useCommunicationTabData();
  const defaultTab = tabData.find(({ show }: any) => show === 1);
  const activeDefaultTitle = defaultTab?.title.replace(/\s/g, '').toLowerCase();

  const getRouteUrl = (item: any) => {
    let linkTo: any = '';

    if (item.slug) {
      const slugRout = item.slug.toUpperCase();
      linkTo = replaceUrlParams(ROUTES[slugRout], id);
    } else if (item.type === 'room' && item.slug === null) {
      let initialRoute = replaceUrlParams(ROUTES.ROOM, id);
      linkTo = replaceUrlParamsRoom(initialRoute, item.room_id);
    } else {
      linkTo = item.type;
    }
    return linkTo;
  };

  return (
    <div
      style={{
        width: 'fit-content',
        maxHeight: '100vh',
        position: 'unset',
      }}
      id="kt_aside"
      className="aside d-flex aside-fixed shadow-none"
    >
      <div
        className="aside-primary d-flex flex-column align-items-center flex-row-auto mw-100 h-100"
        style={{ backgroundColor: '#fff' }}
      >
        <div className="aside-nav d-flex flex-column flex-column-fluid pt-4 ps ps--active-y w-100 h-100 overflow-y-auto overflow-x-hidden scrollbar-none">
          <ul className="list-unstyled flex-column m-0" role="tablist">
            {customMenu.map((item: any) => {
              const link: any = getRouteUrl(item);
              return (
                <li
                  key={item.id}
                  className="nav-item mb-3"
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                >
                  <MenuLink to={link} pathname={pathname} item={item}>
                    <MenuBox
                      onClick={() => {
                        dispatch(setActiveTab(activeDefaultTitle))
                      }}
                      content={item.name}
                      extraClasses="btn btn-clean"
                      url={item.icon}
                      color={item.color}
                      isSessionActive={activeSessionsRoomId.includes(item?.room_id?.toString())}
                    />
                  </MenuLink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default memo(Aside);
