import React, { ReactNode } from 'react';
import { ErrorMessage, Field, FieldProps } from 'formik';

interface Props {
  children?: ReactNode;
  value?: string;
  name: string;
}

export const FormCheckbox: React.FC<Props> = ({ children, name, ...props }: Props) => {
  return (
    <Field name={name} className="form-group">
      {({ field }: FieldProps) => (
        <>
          <div className="form-check">
            <input className="form-check-input" id={name} type="checkbox" {...field} />
            <label className="form-check-label" htmlFor={name}>
              {children}
            </label>
          </div>
          {
            // @ts-ignore
            <ErrorMessage name={name} className="text-danger">
              {(msg: any) => <div className="text-danger">{msg}</div>}
            </ErrorMessage>
          }
        </>
      )}
    </Field>
  );
};
