import React, { useEffect } from 'react';
import { getRules } from 'store/leaderBoard/actions';
import { useDispatch, useSelector } from 'react-redux';
import { rulesData, ruleOrder } from 'constants/rulesData';

interface Props {
  eventCode: string;
}

const Rules: React.FC<Props> = ({ eventCode = '' }) => {
  const dispatch = useDispatch();
  const {
    leaderBoard: { rules },
  } = useSelector((state: AppState) => state);

  useEffect(() => {
    eventCode && dispatch(getRules(eventCode));
    // eslint-disable-next-line
  }, [eventCode]);

  let orderedRules =  {...ruleOrder, ...rules};

  const rulesKeys: any = Object.keys(orderedRules);

  const renderRuleItemDescription = (description: string, point: any) => {
    if (description) {
      description = description.replace('{points}', point);
      return <li className="rule-description ml-4">{description}</li>;
    }
  };

  return (
    <div className="m-4">
      {rulesKeys.map((ruleKey: string) => {
        const singleRule: any = rulesData.find((rule: any) => rule.hasOwnProperty(ruleKey));
        const point = rules[ruleKey];

        return (
          !!point && (
            <div key={ruleKey} className="rules-container d-flex">
              <div className="col-md-8">
                <h1 className="rule-name">{singleRule[ruleKey]}</h1>
                {renderRuleItemDescription(singleRule?.description, point)}
              </div>
              <div className="col-md-4">
                <h1 className="rule-point">{point}</h1>
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default Rules;
