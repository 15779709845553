import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import * as api from 'api';
import { isEmpty, isPrimitive } from 'utils/checkTypes';
import { getRouteUrl } from 'helpers';
import CancelRegistrationModal from './CancelRegistrationModal';
import FieldsContainer from './FieldsContainer';
import {
  cancelRegToEventReset,
  clearParticipantsRegData,
  clearRegistrationFields,
  getParticipantsRegistartionInfo,
  registerToEvent,
} from '../../store/events/actions';
import './style.scss';

export const EventRegistrationForm = () => {
  const { id = '' } = useParams() as { id: string };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [initial, setInitial] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});
  const [showCancelReg, setShowCancelReg] = useState(0);
  const [showRegisterView, setShowRegisterView] = useState<boolean>(false);

  const {
    auth: { loggedIn },
    events: {
      registrationFields: { coreField: coreFieldData = [] },
      participantRegData,
      participantRegDataCopy,
      registrationFieldsLoading,
      cancelReg,
      currentEvent: { customMenu, event_code = '', participant_is_registered, is_host, name },
    },
  } = useSelector((state: AppState) => state);

  const coreField = coreFieldData.filter((elm: any) => elm.display === 'selected');
  const isUpdated = !_.isEqual(participantRegData, participantRegDataCopy);
  const isCustomMenuEmpty = _.isEmpty(customMenu);

  useEffect(() => {
    dispatch(getParticipantsRegistartionInfo(id));
    return () => {
      dispatch(clearRegistrationFields());
      dispatch(clearParticipantsRegData());
    };
  }, [id, dispatch, loggedIn]);

  useEffect(() => {
    let errors: any = {};
    coreField.forEach((field: any) => {
      const { name, required } = field;
      if (name) {
        let val = participantRegData[name];
        if (participantRegData[name]) {
          if (name === 'opt-in') {
            val = participantRegData[name] === 'Yes';
          } else if (name === 'checkbox' || name === 'multicheck') {
            val = participantRegData[name].length;
          }
        }

        const checkValue = isPrimitive(val) ? !val : isEmpty(val);
        const hasError = required && checkValue;
        errors[name] = hasError;
        setValidationErrors({ ...errors, [name]: hasError });
      }
    });
    if (Object.values(errors).some(Boolean)) {
      setOpen(true);
    }
    // eslint-disable-next-line
  }, [coreField.length, participantRegData]);

  useEffect(() => {
    if (!cancelReg.loading) {
      setShowCancelReg(cancelReg.success ? 2 : 0);
    }
  }, [cancelReg]);

  const redirectToReceptionPage = () => {
    const link = getRouteUrl(customMenu, id);
    link && navigate(link);
  };

  const register = async (isUpdated = true) => {
    setClicked(true);
    setInitial(true);
    const isDisabled = Object.values(validationErrors).some(Boolean);
    const link: any = getRouteUrl(customMenu, id);
    if (!isDisabled) {
      if (isUpdated) {
        await dispatch(registerToEvent(link));
        setValidationErrors({});
        setClicked(false);
      }
      if (loggedIn) {
        await api.joinEventPost(event_code);
        redirectToReceptionPage();
      }
    }
  };

  const handleRegisterClick = () => {
    setShowRegisterView(true);
    register();
  };

  const onResetCancelReg = () => {
    dispatch(cancelRegToEventReset());
  };

  useEffect(() => {
    setShowRegisterView(!participant_is_registered);

    // eslint-disable-next-line
  }, []);

  const isParticipantRegisterButtonDisabled =
    clicked || Object.values(validationErrors).some(Boolean);

  const registrationView = !!coreField.length ? (
    <>
      <FieldsContainer
        open={open}
        isUpdated={isUpdated}
        coreField={coreField}
        setInitial={setInitial}
        initial={initial}
        register={register}
        setOpen={setOpen}
        setShowCancelReg={setShowCancelReg}
        clicked={clicked}
        setValidationErrors={setValidationErrors}
        validationErrors={validationErrors}
        showRegisterView={showRegisterView}
      />
      {(!participant_is_registered || showRegisterView) && (
        <Button
          onClick={() => handleRegisterClick()}
          variant="primary"
          type="submit"
          className="w-100 h5"
          disabled={isParticipantRegisterButtonDisabled}
        >
          Register
        </Button>
      )}
    </>
  ) : (
    <div className="text-center">Currently, you can’t be registered to this event</div>
  );

  const isHostCheckView = is_host ? (
    <Button
      onClick={async () => {
        await api.joinEventPost(event_code);
        redirectToReceptionPage();
      }}
      variant="success"
      className="w-100 h5"
      disabled={isCustomMenuEmpty}
    >
      Join
    </Button>
  ) : (
    registrationView
  );

  return registrationFieldsLoading ? (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <Spinner animation="border" />
    </div>
  ) : (
    <div>
      {isHostCheckView}
      <CancelRegistrationModal
        loading={cancelReg.loading}
        name={name}
        show={showCancelReg}
        onHide={() => setShowCancelReg(0)}
        onResetCancelReg={() => {
          setShowCancelReg(0);
          onResetCancelReg();
        }}
      />
    </div>
  );
};
