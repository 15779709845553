import ROUTES from 'constants/routes';
import { replaceUrlParams, replaceUrlParamsRoom } from 'helpers/index';
import _ from 'lodash';

export const getRouteUrl = (customMenu: any, id: string) => {
  let linkToRoot: string = '';
  let linkTo = '';
  const menuRoot = customMenu.find((menuItem: any) => menuItem.is_root_page);
  if (!_.isEmpty(customMenu)) {
    if (menuRoot === undefined) {
      linkToRoot = customMenu
        .find((menuItem: any) => menuItem['type'] === 'room')
        ?.slug.toUpperCase();
      linkTo = replaceUrlParams(ROUTES[linkToRoot], id);
    } else if (menuRoot.slug === null) {
      let initialRout = replaceUrlParams(ROUTES.ROOM, id);
      linkTo = replaceUrlParamsRoom(initialRout, menuRoot.room_id);
    } else {
      linkToRoot = menuRoot.slug.toUpperCase();
      linkTo = replaceUrlParams(ROUTES[linkToRoot], id);
    }
  } else {
    linkTo = ROUTES.EVENTS;
  }
  return linkTo;
};

const splitLocationToPathnames = (location: any) =>
  typeof location === 'string'
    ? location?.split('/')
    : location?.pathname?.split('/');

export const getMenuItem = (location: any, customMenu: any) => {
  const pathname = splitLocationToPathnames(location) || [];
  return customMenu.find((menuItem: any) =>
    pathname[3] === 'room'
      ? +pathname[4] === menuItem.room_id
      : pathname[3] === menuItem.slug
  );
};
