import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const AnonAvatar = ({ classes, content, variant }) => {
  const className = classNames(
    'symbol',
    'symbol-35',
    `symbol-light-${variant}`,
    classes
  );

  // regular text color for the 'secondary' variant is not visible
  const customStyle = variant === 'secondary' ? { color: '#b5b5c3' } : {};

  return (
    <div className={className}>
      <span className='symbol-label' style={customStyle}>
        {content}
      </span>
    </div>
  );
};

AnonAvatar.propTypes = {
  classes: PropTypes.string,
  content: PropTypes.string.isRequired,
  variant: PropTypes.string
};

AnonAvatar.defaultProps = {
  classes: null,
  variant: 'primary'
};

export default AnonAvatar;
