import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { Row, Spinner } from 'react-bootstrap';
import { SidebarLayout } from 'layouts/index';
import eventPageHOC from 'hocs/eventPageHOC';
import { EventCard, CommunicationModules } from 'components';
import CustomButtonsCarousel from 'components/CustomButtonsCarousel/CustomButtonsCarousel';
import { getReceptionData } from 'store/events/actions';
import HotJarTracking from 'components/HotJarTracking/HotJarTracking';
import 'scss/event-detail.scss';

const Index: React.FC = () => {
  const { currentEvent, receptionLoading } = useSelector((state: AppState) => state.events);
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const dispatch = useDispatch();

  const { custom_buttons = {} } = useSelector((state: AppState) => state.app.virtual_room || {});
  const { customMenu = [] } = useSelector(
    (state: AppState) => state.events.currentEvent,
    _.isEqual
  );

  useEffect(() => {
    if (!id) {
      navigate(`/events`);
    }

    if (!receptionLoading) {
      dispatch(getReceptionData());
    }
    // eslint-disable-next-line
  }, [id, customMenu]);

  return receptionLoading ? (
    <div className="d-flex align-items-center justify-content-center w-100 h-100">
      <Spinner animation="border" role="status" aria-hidden="true" />
    </div>
  ) : (
    <>
      <HotJarTracking />
      <SidebarLayout hideEventName>
        <div className="content d-flex flex-column h-100 p-0 event-sidebar-container">
          <Row className="h-100 m-0 event-sidebar-container-row">
            <div className="flex-1 h-100 d-flex flex-column p-4 event-sidebar-container-row-content">
              <EventCard event={currentEvent} />
              {!!custom_buttons?.length && <CustomButtonsCarousel customButtons={custom_buttons} />}
            </div>
            <CommunicationModules />
          </Row>
        </div>
      </SidebarLayout>
    </>
  );
};

export const ReceptionPage = eventPageHOC(Index);
