import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import './LeaderBoardstyles.scss';

interface Props {
  now: number;
  place: number;
  company: string;
  name: string;
  score: number;
  progressVariant: any;
}

const defaultProps: Props = {
  now: 0,
  place: 0,
  company: '',
  name: '',
  score: 0,
  progressVariant: '',
};

export const LeaderItem: React.FC<Props> = ({
  now,
  place,
  company,
  name,
  score,
  progressVariant,
}) => {
  return (
    <tr className="leader-item-container">
      <td>
        <h2 className="leader-place mr-4">{place}</h2>
      </td>
      <td className="d-flex justify-content-between flex-column mx-2">
        <div className="leader-name">{name}</div>
        <h2 className="leader-company">{company}</h2>
      </td>
      <td className="progress-bar-wrapper">
        <ProgressBar now={now} variant={progressVariant} />
      </td>
      <td>
        <h1 className="total-score">{score}</h1>
      </td>
    </tr>
  );
};

LeaderItem.defaultProps = defaultProps;
