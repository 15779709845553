import React from 'react';
import { useSelector } from 'react-redux';
import AddToCalendarShow from 'components/AddToCalendarShow/AddToCalendarShow';
import Card from 'react-bootstrap/Card';
import classNames from 'classnames';
import _ from 'lodash';
import Collapse from 'react-bootstrap/Collapse';
import SocialMediaSharing from './SocialMediaSharing';
import { IField } from './types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Field from './Field';

const JoinEvent = (props: any) => {
  const {
    isUpdated,
    coreField,
    open,
    register,
    setOpen,
    setShowCancelReg,
    validationErrors,
    setValidationErrors,
    clicked,
    initial,
    onChangeField,
  } = props;

  const {
    events: {
      participantRegData,
      currentEvent,
      currentEvent: { customMenu },
    },
  } = useSelector((state: AppState) => state);

  const isCustomMenuEmpty = _.isEmpty(customMenu);

  return (
    <div className="d-flex flex-column">
      <Card style={{ borderColor: '#3699FF' }} className="mb-4">
        <Card.Body className="p-4">
          <h5 className="m-0">You’re registered!</h5>
        </Card.Body>
      </Card>
      <div className="add-to-calendar-custom-button">
        <AddToCalendarShow
          event={currentEvent}
          label="+ Add to calendar"
          description={currentEvent.ics_description}
        />
      </div>
      <Button
        onClick={() => register(isUpdated)}
        variant="success"
        className={classNames('w-100 h5', open && 'order-3')}
        disabled={isUpdated ? false : !!isCustomMenuEmpty}
      >
        {isUpdated ? 'Update and join' : 'Join'}
      </Button>
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        variant="link"
        className={classNames('p-0 mt-4', { 'order-1': open })}
      >
        Modify personal information
      </Button>
      <Button
        variant="link"
        className={classNames('w-100 p-0 mt-4', open && 'order-3')}
        onClick={() => setShowCancelReg(1)}
      >
        Cancel registration
      </Button>

      <Collapse in={open} className={classNames(open && 'order-2')}>
        <Form className="participant-reg-fields">
          {coreField.map((field: IField) => {
            return (
              <Field
                {...field}
                clicked={clicked}
                required={field.required}
                validationErrors={validationErrors}
                setValidationErrors={(data: any) => setValidationErrors(data)}
                key={field.name}
                initial={initial}
                participantRegData={participantRegData}
                onChangeField={onChangeField}
              />
            );
          })}
        </Form>
      </Collapse>
      <SocialMediaSharing />
    </div>
  );
};

export default JoinEvent;