import { format } from 'date-fns';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

export const parseDate = (
  timestamp: string | number | Date,
  formatType: string
): string => format(new Date(timestamp), formatType);

export const applyTimezone = (
  date: string | number | Date,
  format = 'YYYY-MM-DD HH:mm:ss'
) => {
  return moment
    .tz(moment.parseZone(date), momentTimezone.tz.guess())
    .format(format);
};
