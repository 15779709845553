import React from 'react'
import { SearchIcon } from '../../icons/stockholm'
import PropTypes from 'prop-types'

const SearchBar = ({ placeholder, onChange, variant }) => {
  return (
    <div className='input-group input-group-solid'>
      <div className='input-group-prepend'>
        <span className='input-group-text'>
          <span className='svg-icon svg-icon-lg'>
            <SearchIcon variant={variant} />
          </span>
        </span>
      </div>
      <input
        type='text'
        className='form-control py-4 h-auto'
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  )
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  variant: PropTypes.string
}

SearchBar.defaultProps = {
  placeholder: 'Search',
  onChange: () => {},
  variant: 'secondary'
}

export default SearchBar
