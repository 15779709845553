import React from 'react';
import Button from 'react-bootstrap/Button';
import 'external-svg-loader';
import CustomButtonModal from './CustomButtonModal';
import classnames from 'classnames';
import { checkValidURL, addProtocol } from 'helpers';
import { useFeatherIcon } from 'hooks';

export interface CustomButtonProps {
  color?: string;
  html?: string;
  icon?: string;
  id?: number;
  name?: string;
  type?: string;
  url?: string;
  className?: string;
}

export interface ButtonElementProps extends CustomButtonProps {
  disabled?: boolean;
  onClick?: () => void;
}

export const ButtonElement: React.FC<ButtonElementProps> = ({
  icon,
  color,
  name,
  disabled,
  onClick
}) => {
  const iconURL = useFeatherIcon(icon);

  return (
    <Button
      title={name}
      variant='light'
      onClick={onClick}
      className='w-100 d-flex flex-column bg-white align-items-center justify-content-center'
      disabled={disabled}
    >
      <svg data-src={iconURL} stroke={color} />
      <div className='custom-button-desc'>{name}</div>
    </Button>
  );
};

const CustomButton: React.FC<CustomButtonProps> = (props) => {
  const { color, icon, name, type, url = '', className } = props;

  return (
    <div className={classnames(className)}>
      {type === 'HTML' ? (
        <CustomButtonModal {...props} />
      ) : (
        <ButtonElement
          icon={icon}
          color={color}
          name={name}
          disabled={!checkValidURL(url)}
          onClick={() => {
            const newURL = addProtocol(url);
            return window.open(newURL);
          }}
        />
      )}
    </div>
  );
};

export default CustomButton;
