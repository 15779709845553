import React from 'react';
import { UserCarousel } from 'react-banzai';
import { CustomButtonProps } from 'components';
import CustomButton from 'components/CustomButton/CustomButton';

interface Props {
  customButtons: CustomButtonProps[];
}

const carouselSettings = {
  slidesToShow: 8,
  slidesToScroll: 8,
  swipe: true,
  infinite: false
};

const CustomButtonsCarousel: React.FC<Props> = ({ customButtons }) => {
  return (
    <div className='w-100 pt-2 mb-2'>
      <UserCarousel configs={carouselSettings} className='mx-10 px-4'>
        {customButtons.map((button: CustomButtonProps) => (
          <CustomButton key={button.id} {...button} className='px-2' />
        ))}
      </UserCarousel>
    </div>
  );
};

export default CustomButtonsCarousel;
