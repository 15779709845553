import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Accordion from 'react-bootstrap/Accordion';
import Collapse from 'react-bootstrap/Collapse';
import TextShow from 'components/TextShow/TextShow';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import { LikeIcon } from 'react-banzai';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import UserIcon from 'components/userIcon/UserIcon';
import { useIsHost } from 'hooks/useUserRole';

const AccordionItem = ({ item, activeIndex, changeActiveIndex, collapseCallBack, isLastItem }) => {
  const {
    questionData,
    date,
    questionSender,
    questionId,
    questionLikes,
    children,
    comments,
    isQuestionLiked,
  } = item;

  const classes = classNames('d-flex w-100 ');

  const formattedDate = useMemo(() => {
    return moment(new Date(date)).format('HH:mm A');
  }, [date]);
  const isHost = useIsHost();

  return item && Object.keys(item).length ? (
    <div className="mb-5">
      <div className={classes}>
        <UserIcon
          img={questionSender.img}
          className="symbol symbol-circle symbol-35 mr-2 question-sender-img-wrapper"
        />
        <div className="d-flex flex-column w-100">
          <div className="rounded d-flex flex-column quest-item">
            <div className="font-weight-bolder min-h-20px ">{questionSender.name}</div>
            <div className="mt-2 mb-2 pr-3 d-flex align-items-center font-weight-bold">
              <TextShow text={questionData} textShowCount={150} />
              {isHost && (
                <FontAwesomeIcon
                  className="cursor-pointer"
                  style={{ width: '24px', height: '14px' }}
                  icon={faTrash}
                  color="#B5B5C3"
                  onClick={() => item.removeQuestion(questionId)}
                />
              )}
              <div className="quest-item-date text-muted font-size-sm text-center message-time p-1 message-content-date">
                {' '}
                {formattedDate}{' '}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center">
              <LikeIcon
                className="cursor-pointer"
                onClick={() => item.onLikeClick(questionId)}
                size="md"
                variant={isQuestionLiked ? 'primary' : 'secondary'}
              />
              <div className="pl-2 pr-2">{questionLikes?.length || 0}</div>
            </div>
            <Accordion.Toggle
              as={Button}
              variant="link"
              onClick={() => changeActiveIndex(item, children)}
              eventKey={questionId}
              className="py-0"
            >
              {`Comment ( ${comments.length} )`}
            </Accordion.Toggle>
          </div>
        </div>
      </div>
      <div>
        <Collapse in={activeIndex === questionId} onEntered={collapseCallBack}>
          <div id="children">{children}</div>
        </Collapse>
      </div>
      <div className="comment-ref" id={questionId}></div>
    </div>
  ) : null;
};

AccordionItem.propTypes = {
  item: PropTypes.shape({
    comments: PropTypes.array,
    className: PropTypes.string,
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    questionData: PropTypes.string,
    questionId: PropTypes.string,
    questionSender: PropTypes.shape({
      name: PropTypes.string,
      img: PropTypes.string,
    }),
    questionLikes: PropTypes.arrayOf(PropTypes.string),
    isQuestionLiked: PropTypes.bool,
    onLikeClick: PropTypes.func,
    removeQuestion: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  }),
  activeIndex: PropTypes.string,
  changeActiveIndex: PropTypes.func,
  isLastItem: PropTypes.bool,
};

AccordionItem.defaultProps = {
  item: {
    className: '',
    date: '',
    questionData: '',
    questionId: '',
    questionSender: {
      name: '',
      img: '',
    },
    questionLikes: [],
    isQuestionLiked: false,
    onLikeClick: () => {
      return;
    },
    removeQuestion: () => {
      return;
    },
    children: null,
    comments: [],
  },
  activeIndex: '',
  changeActiveIndex: () => {
    return;
  },
  isLastItem: false,
};

export default memo(AccordionItem);
