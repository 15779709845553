import React from 'react'
import PropTypes from 'prop-types'
import Badge from 'react-bootstrap/Badge'
import './styles.scss'

const SponsorCard = ({ src, alt }) => (
  <div className='sponsor-card'>
    <img className='sponsor__image' src={src} alt={alt} />
    <Badge
      variant='secondary'
      className='sponsor-card__badge sponsor-card__badge--gold'
    >
      Gold
    </Badge>
  </div>
)

SponsorCard.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
}

SponsorCard.defaultProps = {
  src: '',
  alt: ''
}


export default SponsorCard
