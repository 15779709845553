import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import createRootReducer from './rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';

export const history = createBrowserHistory();
// eslint-disable-next-line no-empty-pattern
export default function configureStore() {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        thunkMiddleware
      )
    )
  );

  return store;
}
