import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Participant from '../../molecules/Participant';
import './style.scss';
import Spinner from 'react-bootstrap/Spinner';

const Participants = forwardRef(
  ({ items, meetingControls, label, isControlsEnabled, showCompany, loading }, ref) => (
    <fieldset
      ref={ref}
      className="mt-7 ps ps--active-y flex-column-fluid overflow-auto participants"
    >
      {label && <legend>{label}</legend>}
      {items?.map(item => {
        return (
          item && (
            <Participant
              key={item.partId}
              isControlsEnabled={isControlsEnabled}
              partId={item.partId}
              name={item.name}
              cameraEnabled={item.cameraEnabled}
              micEnabled={item.micEnabled}
              screenSharing={item.screenSharing}
              showControls={item.showControls}
              userImage={item.userImage}
              alt={item.alt}
              company={item.company}
              showCompany={showCompany}
              position={item.position}
              moreActions={item.moreActions}
              meetingControls={meetingControls}
            />
          )
        );
      })}
      <div>{!!loading && <Spinner animation="border" className="d-block m-auto"></Spinner>}</div>
    </fieldset>
  )
);

Participants.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      showControls: PropTypes.bool,
      userImage: PropTypes.string,
      alt: PropTypes.string,
      position: PropTypes.string,
      moreActions: PropTypes.array,
      meetingControls: PropTypes.bool,
      company: PropTypes.string,
    })
  ).isRequired,
  meetingControls: PropTypes.bool,
  label: PropTypes.string,
  isControlsEnabled: PropTypes.bool,
  showCompany: PropTypes.bool.isRequired,
};

Participants.defaultProps = {
  meetingControls: false,
  label: '',
  isControlsEnabled: false,
};
export default Participants;
