import React, { useEffect } from 'react';
import { getPrizes } from 'store/leaderBoard/actions';
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';

interface Props {
  eventCode: string;
}

export const Prizes: React.FC<Props> = ({ eventCode = '' }) => {
  const dispatch = useDispatch();

  const {
    leaderBoard: { prizes: prizesData = '' }
  } = useSelector((state: AppState) => state);

  useEffect(() => {
    eventCode && dispatch(getPrizes(eventCode));
    // eslint-disable-next-line
  }, [eventCode]);

  return prizesData && <div className='m-4'>{parse(prizesData)}</div>;
};
