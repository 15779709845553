import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col } from 'react-bootstrap';
import { setActiveTab } from 'store/communicationModules/actions';
import {
  CLEAR_SHOW_QUESTIONS_NOTIFICATION,
  SET_SHOW_QUESTIONS_NOTIFICATION,
} from 'store/questionsAndAnswers/constants';
import { CLEAR_SHOW_POLL_NOTIFICATION, SET_SHOW_POLL_NOTIFICATION } from 'store/poll/constants';
import { TabDataView } from './TabDataView';
import { tabsKeys } from './constants';
import './styles.scss';

export const CommunicationTabs: React.FC = () => {
  const dispatch = useDispatch();
  const {
    questionsAndAnswers: { tabOpenLastDate, data: questionsData },
    poll: { data: pollsData = [], lastPollDate },
    auth: {
      user: { id: userId },
    },
  } = useSelector((state: AppState) => state);

  const defaultTab = tabsKeys[0];

  const filteredPolls: any = useMemo(() => {
    return (
      pollsData?.filter((poll: any) => {
        return poll.poll_status === 'published' && !poll?.archivedParticipants?.includes(userId);
      }) || []
    );
    //  eslint-disable-next-line
  }, [pollsData]);

  const filteredPollsLength = filteredPolls?.length;

  const filteredUnseenPollsByDate = useMemo(() => {
    return lastPollDate && filteredPolls.filter((poll: any) => poll.edit_date > lastPollDate);
    //  eslint-disable-next-line
  }, [filteredPollsLength, lastPollDate]);

  const filteredUnseenPollsByDateLength = filteredUnseenPollsByDate?.length;

  const arrayOfQuestions = Object.values(questionsData);
  const arrayOfQuestionsLength = arrayOfQuestions?.length;

  const filteredUnseenQuestionsByDate = useMemo(() => {
    return (
      tabOpenLastDate && arrayOfQuestions.filter((question: any) => question.date > tabOpenLastDate)
    );
    //  eslint-disable-next-line
  }, [arrayOfQuestionsLength, tabOpenLastDate]);

  const filteredUnseenQuestionsByDateLength = filteredUnseenQuestionsByDate?.length;

  const setQuestionNotification = () => {
    filteredUnseenQuestionsByDateLength
      ? dispatch({
          type: SET_SHOW_QUESTIONS_NOTIFICATION,
        })
      : dispatch({
          type: CLEAR_SHOW_QUESTIONS_NOTIFICATION,
        });
  };

  const setPollNotification = () => {
    filteredUnseenPollsByDateLength
      ? dispatch({
          type: SET_SHOW_POLL_NOTIFICATION,
        })
      : dispatch({
          type: CLEAR_SHOW_POLL_NOTIFICATION,
        });
  };

  useEffect(() => {
    window.innerWidth > 768 && dispatch(setActiveTab(defaultTab));
    // eslint-disable-next-line
  }, [defaultTab]);

  useEffect(() => {
    setQuestionNotification();

    //  eslint-disable-next-line
  }, [filteredUnseenQuestionsByDateLength]);

  useEffect(() => {
    setPollNotification();

    //  eslint-disable-next-line
  }, [filteredUnseenPollsByDateLength]);

  return (
    <Col
      md={4}
      style={{ overflow: 'hidden' }}
      className="d-flex flex-column p-0 min-h-100 flex-1 communication-modules"
    >
      <TabDataView />
    </Col>
  );
};
