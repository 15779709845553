import React from 'react'
import PropTypes from 'prop-types'

const HeaderDisplay = ({ as, content, variant }) => {
  const _variant = `display-${variant}`

  const header = React.createElement(as, { className: _variant }, content)
  return header
}

HeaderDisplay.propTypes = {
  as: PropTypes.string,
  content: PropTypes.string.isRequired,
  variant: PropTypes.number
}

HeaderDisplay.defaultProps = {
  as: 'h1',
  variant: 1
}

export default React.memo(HeaderDisplay)
