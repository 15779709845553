import { store } from 'index';
import _ from 'lodash';
import { getRouteUrl } from 'helpers';
import { UPDATED_CUSTOM_MENU, UPDATED_ROOM, UPDATED_SPONSORS } from './constants';
import { GET_CUSTOM_MENU } from 'store/events/constants';
import { SET_SPONSORS } from 'store/sponsors/constants';
import { SET_VIRTUAL_ROOM } from 'store/app/constants';
import { SET_STAGE_SESSIONS } from 'store/sessions/constants';
import { setRedirectionURL } from 'store/events/actions';

export default function (dispatch: any, socket: any) {
  //----------------------------custom menu----------------------------------------
  socket.on(UPDATED_CUSTOM_MENU, function ({ customMenu = [] }: any) {
    const state: AppState = store.getState();
    const { pathname } = window.location;
    const {
      events: {
        currentEvent: { event_code },
      },
    } = state;

    let customItems = customMenu.filter((data: any) => !data.disable_from_event);
    const route = pathname.toString()?.split('/')[3];
    const isRouteDisabled = customMenu.find(
      (item: any) => item.slug === route && item.disable_from_event
    );

    if (isRouteDisabled) {
      const url = getRouteUrl(customItems, event_code);
      url && dispatch(setRedirectionURL(url));
    }

    if (_.isEmpty(customItems)) {
      dispatch(setRedirectionURL(`/event/${event_code}`));
    }

    dispatch({
      type: GET_CUSTOM_MENU,
      data: customItems,
    });
  });

  //----------------------------------room--------------------------------------
  socket.on(UPDATED_ROOM, function ({ roomSessions = [] }: any) {
    dispatch({
      type: SET_STAGE_SESSIONS,
      payload: roomSessions,
    });
  });

  //-----------------------------------sponsors-------------------------------------
  socket.on(UPDATED_SPONSORS, function ({ sponsors = [] }: any) {
    const state: AppState = store.getState();
    const {
      sponsors: {
        currentOrganization: { id: currentOrganizationId = '' },
      },
    } = state;
    dispatch({
      type: SET_SPONSORS,
      data: sponsors,
    });
    
    if (currentOrganizationId) {
      const { virtual_room } =
        sponsors.find(({ id: sponsorId }: any) => sponsorId === currentOrganizationId) || {};

      dispatch({
        type: SET_VIRTUAL_ROOM,
        payload: virtual_room,
      });
    }
  });
}
