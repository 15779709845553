import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import { Link } from 'react-router-dom';

const MenuLink = ({ to, pathname, children }) => {
  const _children = React.cloneElement(children);
  const active = pathname.startsWith(to);
  const className = classNames({ 'active-menu-item': active });
  return <Link to={to} className={className}>{_children}</Link>;
};

MenuLink.propTypes = {
  to: PropTypes.string,
  pathname: PropTypes.string,
  children: PropTypes.node.isRequired
};

MenuLink.defaultProps = {
  to: '',
  pathname: '',
};

export default React.memo(MenuLink);
