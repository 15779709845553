import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

const HelmetCustom = () => {
  const { name, favicon } = useSelector(
    (state: AppState) => state.events.currentEvent
  );

  return (
    <Helmet>
      <link rel='shortcut icon' href={favicon || '/favicon.ico'} />
      <title>{name || 'High Attendance'}</title>
    </Helmet>
  );
};

export default HelmetCustom;
