import React, { memo, useEffect, useState } from 'react';
import { defaultUserImg } from 'constants/images';

interface Props {
  img: string;
  className?: string;
  imgClassName?: string;
}

const UserIcon = ({ img, className, imgClassName }: Props) => {
  const [imgSrc, setImgSrc] = useState(defaultUserImg);

  useEffect(() => {
    if (img) {
      const asyncFetch = async () => {
        try {
          await fetch(img, {
            method: 'GET',
            mode: 'no-cors'
          });

          setImgSrc(img);
        } catch {
          console.error('Image is not valid');
        }
      };

      asyncFetch();
    }
  }, [img]);

  return (
    <div className={className}>
      <img src={imgSrc} alt='' className={imgClassName} />
    </div>
  );
};

export default memo(UserIcon);
