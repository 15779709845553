import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { Button, PollOptionCreator } from 'react-banzai';
import { v4 as uuidv4 } from 'uuid';

const arr = [
  {
    id: uuidv4(),
    value: '',
    votes: []
  },
  {
    id: uuidv4(),
    value: '',
    votes: []
  }
];

const Builder = ({
                   isEditing,
                   questionText,
                   initialOptions,
                   onSubmit,
                   initialOptionType,
                   onReset
                 }) => {
  const [optionType, setOptionType] = useState(initialOptionType);
  let initOptions = arr;
  let initialTitle = '';

  if (isEditing) {
    initOptions = initialOptions;
    initialTitle = questionText;
  }

  const [options, setOptions] = useState(initOptions);
  const [title, setTitle] = useState(initialTitle);

  const optionsChange = (value, id) => {
    const data = options.map((item) =>
      item.id === id ? { ...item, value } : item
    );
    setOptions(data);
  };

  const addOption = () => {
    setOptions([
      ...options,
      {
        id: uuidv4(),
        value: '',
        votes: []
      }
    ]);
  };

  const reset = () => {
    onReset();
    setTitle(initialTitle);
    setOptions(initOptions);
  };
  const removeOption = (id) => {
    const data = options.filter((option) => option.id !== id);
    setOptions(data);
  };

  const isPollEmpty = () => {
    const emptyQuestion = !title.trim();
    const emtpyOption = (option) => !option.value.trim();
    const notValidOptions = options.some(emtpyOption);
    if (emptyQuestion || notValidOptions) {
      return true;
    }
    return false;
  };

  const submit = (e) => {
    e.preventDefault();
    onSubmit({
      question: title,
      type: optionType,
      options
    });

    setTitle(initialTitle);
    setOptions(initOptions);
  };

  const showMinusButton = options.length > 2;
  return (
    <Form className='form mt-2 p-2' onSubmit={submit}>
      <Form.Control
        type='text'
        placeholder='Question'
        className='mb-4'
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <div className='d-flex ml-2'>
        <Form.Check
          type='radio'
          inline
          label='Single Choice'
          name='optionType'
          id='chioce-way1'
          checked={optionType === 'single'}
          onChange={() => setOptionType('single')}
        />
        <Form.Check
          type='radio'
          inline
          label='Multiple Choices'
          name='optionType'
          id='chioce-way2'
          className='ml-3'
          checked={optionType === 'multiple'}
          onChange={() => setOptionType('multiple')}
        />
      </div>
      {options.map((option) => (
        <PollOptionCreator
          key={option.id}
          value={option.value}
          id={option.id}
          onChange={optionsChange}
          showMinusButton={showMinusButton}
          onMinusClick={removeOption}
        />
      ))}

      <div>
        <Button variant='outline-primary' size='sm' block onClick={addOption} className='mb-2'>
          Add option
        </Button>
        {isEditing && (<>
          <div>
            <Button variant='outline-primary' size='sm' block onClick={reset}>
              Cancel
            </Button>
          </div>
        </>
        )}
          <Button
            block
            size='sm'
            variant='primary'
            type='submit'
            disabled={isPollEmpty()}
            className="mt-2"
          >
            Save Poll
          </Button>
      </div>
    </Form>
  );
};

Builder.propTypes = {
  isEditing: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      votes: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  initialOptionType: PropTypes.string,
  questionText: PropTypes.string,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func
};

Builder.defaultProps = {
  isEditing: false,
  initialOptions: [
    {
      id: uuidv4(),
      value: '1',
      votes: []
    },
    {
      id: uuidv4(),
      value: '2',
      votes: []
    }
  ],
  initialOptionType: 'single',
  onReset: () => {
    return
  },
  onSubmit: () => {
    return
  }
};

export default Builder;
