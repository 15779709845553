import { CHANGE_UI_CONFIG, SET_VIRTUAL_ROOM } from './constants';

// I will use this to statically load some initial UI configs. When we set some backend for UI configs, we'll use redux thunks.

const UIConfig = {
  isSidebarOpen: false
};

const initialState = {
  UIConfig,
  virtual_room: ''
};

export function app(state = { ...initialState }, action: any) {
  switch (action.type) {
    case SET_VIRTUAL_ROOM:
      return {
        ...state,
        virtual_room: action.payload
      };
    case CHANGE_UI_CONFIG:
      const newUIConfig = {
        ...UIConfig,
        ...action.payload
      };

      return {
        ...state,
        UIConfig: newUIConfig
      };

    default:
      return state;
  }
}
