import React from 'react';
import { SidebarLayout } from 'layouts/index';
import LeaderBoardCardsPage from 'components/LeaderBoardCardsPage/LeaderBoardCardsPage';

export const LeaderBoardPage: React.FC = () => {
  return (
    <SidebarLayout>
      <LeaderBoardCardsPage />
    </SidebarLayout>
  );
};
