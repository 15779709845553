import { useSelector } from 'react-redux';
import {
  getUserIsSessionSpeaker,
  getIsHost,
  getUserIsCurrentEventSpeaker
} from 'helpers/getUserRole';

export const useIsHost = () => {
  const {
    events: {
      currentEvent: { is_host: isEventHost }
    },
    sponsors: {
      currentOrganization: { is_host: isCurrentOrganizationHost }
    }
  } = useSelector((state: AppState) => state);

  return getIsHost(isCurrentOrganizationHost, isEventHost);
};

export const useUserIsSessionSpeaker = () => {
  const {
    auth: { user: { email: userEmail } } = {},
    sessions: { data: sessionData = [] }
  } = useSelector((state: AppState) => state);

  return getUserIsSessionSpeaker(sessionData, userEmail);
};

export const useUserIsCurrentEventSpeaker = () => {
  const {
    events: { currentEvent: { speakers = [] } = {} },
    auth: { user: { email = '' } = {} }
  } = useSelector((state: AppState) => state);

  return getUserIsCurrentEventSpeaker(speakers, email);
};
